import {
  BlockchainListRequest,
  BlockchainListResponse,
} from "../../modelspb/blockchain";
import { appFetch } from "../fetch/appFetch";

export class BlockchainsService {
  fetch = appFetch;

  BlockchainListRequest(
    req: BlockchainListRequest.AsObjectPartial,
    network: string,
  ): Promise<BlockchainListResponse.AsObject> {
    return this.fetch(network + "/api/chains/blockchains/list", {
      blockchainList: req,
    }).then(({ blockchainList }) => blockchainList!);
  }
}

export const blockchainsService = new BlockchainsService();
