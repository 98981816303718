/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.13.0
 * source: models.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./validate/validate";
import * as pb_1 from "google-protobuf";
export enum SortOrder {
    SORT_ORDER_DESC = 0,
    SORT_ORDER_ASC = 1
}
export enum BlockchainKind {
    BlockchainKindAVM = 0,
    BlockchainKindPVM = 1,
    BlockchainKindEVM = 2
}
export enum TokenKind {
    TokenKindERC20 = 0,
    TokenKindERC721 = 1,
    TokenKindERC1155 = 2
}
export enum AVMTransactionKind {
    AVMTransactionKindBase = 0,
    AVMTransactionKindCreateAsset = 1,
    AVMTransactionKindOperation = 2,
    AVMTransactionKindImport = 3,
    AVMTransactionKindExport = 4
}
export enum AVMTransactionOperationKind {
    AVMTransactionOperationKindSecp256k1Mint = 0,
    AVMTransactionOperationKindNftMint = 1,
    AVMTransactionOperationKindNftTransfer = 2
}
export enum PVMTransactionKind {
    PVMTransactionKindBase = 0,
    PVMTransactionKindAddValidator = 1,
    PVMTransactionKindAddSubnetValidator = 2,
    PVMTransactionKindAddDelegator = 3,
    PVMTransactionKindCreateChain = 4,
    PVMTransactionKindCreateSubnet = 5,
    PVMTransactionKindImport = 6,
    PVMTransactionKindExport = 7,
    PVMTransactionKindAdvanceTime = 8,
    PVMTransactionKindRewardValidator = 9,
    PVMTransactionKindRemoveSupernetValidator = 10,
    PVMTransactionKindTransformSupernet = 11,
    PVMTransactionKindAddPermissionlessValidator = 12,
    PVMTransactionKindAddPermissionlessDelegator = 13
}
export enum CoreETHTransactionKind {
    CoreETHTransactionKindExport = 0,
    CoreETHTransactionKindImport = 1
}
export class Page extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        number?: number;
        size?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("number" in data && data.number != undefined) {
                this.number = data.number;
            }
            if ("size" in data && data.size != undefined) {
                this.size = data.size;
            }
        }
    }
    get number() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set number(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get size() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set size(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: Page.AsObjectPartial): Page {
        if (!data) {
            return new Page();
        }
        const message = new Page({});
        if (data.number != null) {
            message.number = data.number;
        }
        if (data.size != null) {
            message.size = data.size;
        }
        return message;
    }
    toObject() {
        const data: Page.AsObject = {
            number: this.number,
            size: this.size
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.number != 0)
            writer.writeInt64(1, this.number);
        if (this.size != 0)
            writer.writeInt64(2, this.size);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Page {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Page();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.number = reader.readInt64();
                    break;
                case 2:
                    message.size = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): Page {
        return Page.deserialize(bytes);
    }
}
export namespace Page {
    export type AsObject = {
        number: number;
        size: number;
    };
    export type AsObjectPartial = {
        number?: number;
        size?: number;
    };
}
export class NavigationData extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        number?: number;
        size?: number;
        total?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("number" in data && data.number != undefined) {
                this.number = data.number;
            }
            if ("size" in data && data.size != undefined) {
                this.size = data.size;
            }
            if ("total" in data && data.total != undefined) {
                this.total = data.total;
            }
        }
    }
    get number() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set number(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get size() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set size(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get total() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set total(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data?: NavigationData.AsObjectPartial): NavigationData {
        if (!data) {
            return new NavigationData();
        }
        const message = new NavigationData({});
        if (data.number != null) {
            message.number = data.number;
        }
        if (data.size != null) {
            message.size = data.size;
        }
        if (data.total != null) {
            message.total = data.total;
        }
        return message;
    }
    toObject() {
        const data: NavigationData.AsObject = {
            number: this.number,
            size: this.size,
            total: this.total
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.number != 0)
            writer.writeInt64(1, this.number);
        if (this.size != 0)
            writer.writeInt64(2, this.size);
        if (this.total != 0)
            writer.writeInt64(3, this.total);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NavigationData {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NavigationData();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.number = reader.readInt64();
                    break;
                case 2:
                    message.size = reader.readInt64();
                    break;
                case 3:
                    message.total = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): NavigationData {
        return NavigationData.deserialize(bytes);
    }
}
export namespace NavigationData {
    export type AsObject = {
        number: number;
        size: number;
        total: number;
    };
    export type AsObjectPartial = {
        number?: number;
        size?: number;
        total?: number;
    };
}
export class ErrorResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        code?: number;
        message?: string;
        detail?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("code" in data && data.code != undefined) {
                this.code = data.code;
            }
            if ("message" in data && data.message != undefined) {
                this.message = data.message;
            }
            if ("detail" in data && data.detail != undefined) {
                this.detail = data.detail;
            }
        }
    }
    get code() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set code(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get message() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set message(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get detail() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set detail(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data?: ErrorResponse.AsObjectPartial): ErrorResponse {
        if (!data) {
            return new ErrorResponse();
        }
        const message = new ErrorResponse({});
        if (data.code != null) {
            message.code = data.code;
        }
        if (data.message != null) {
            message.message = data.message;
        }
        if (data.detail != null) {
            message.detail = data.detail;
        }
        return message;
    }
    toObject() {
        const data: ErrorResponse.AsObject = {
            code: this.code,
            message: this.message,
            detail: this.detail
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.code != 0)
            writer.writeInt64(1, this.code);
        if (this.message.length)
            writer.writeString(2, this.message);
        if (this.detail.length)
            writer.writeString(3, this.detail);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ErrorResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ErrorResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.code = reader.readInt64();
                    break;
                case 2:
                    message.message = reader.readString();
                    break;
                case 3:
                    message.detail = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ErrorResponse {
        return ErrorResponse.deserialize(bytes);
    }
}
export namespace ErrorResponse {
    export type AsObject = {
        code: number;
        message: string;
        detail: string;
    };
    export type AsObjectPartial = {
        code?: number;
        message?: string;
        detail?: string;
    };
}
export class EmptyMessage extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data?: EmptyMessage.AsObjectPartial): EmptyMessage {
        if (!data) {
            return new EmptyMessage();
        }
        const message = new EmptyMessage({});
        return message;
    }
    toObject() {
        const data: EmptyMessage.AsObject = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): EmptyMessage {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new EmptyMessage();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): EmptyMessage {
        return EmptyMessage.deserialize(bytes);
    }
}
export namespace EmptyMessage {
    export type AsObject = {};
    export type AsObjectPartial = {};
}
export class ServerTimeRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data?: ServerTimeRequest.AsObjectPartial): ServerTimeRequest {
        if (!data) {
            return new ServerTimeRequest();
        }
        const message = new ServerTimeRequest({});
        return message;
    }
    toObject() {
        const data: ServerTimeRequest.AsObject = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ServerTimeRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ServerTimeRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ServerTimeRequest {
        return ServerTimeRequest.deserialize(bytes);
    }
}
export namespace ServerTimeRequest {
    export type AsObject = {};
    export type AsObjectPartial = {};
}
export class ServerTimeResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        timestamp?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("timestamp" in data && data.timestamp != undefined) {
                this.timestamp = data.timestamp;
            }
        }
    }
    get timestamp() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set timestamp(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data?: ServerTimeResponse.AsObjectPartial): ServerTimeResponse {
        if (!data) {
            return new ServerTimeResponse();
        }
        const message = new ServerTimeResponse({});
        if (data.timestamp != null) {
            message.timestamp = data.timestamp;
        }
        return message;
    }
    toObject() {
        const data: ServerTimeResponse.AsObject = {
            timestamp: this.timestamp
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.timestamp != 0)
            writer.writeInt64(1, this.timestamp);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ServerTimeResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ServerTimeResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.timestamp = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ServerTimeResponse {
        return ServerTimeResponse.deserialize(bytes);
    }
}
export namespace ServerTimeResponse {
    export type AsObject = {
        timestamp: number;
    };
    export type AsObjectPartial = {
        timestamp?: number;
    };
}
