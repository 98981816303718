import { Request, Response } from "../../modelspb/api";

export const baseApiUrl = "";
export const protobufHeader = {
  name: "Content-Type",
  value: "application/x-protobuf",
};

export const appFetchProtoWithHeaders = (
  path: string,
  request?: Request.AsObjectPartial | null,
  additionalHeaders?: Headers,
): Promise<Response.AsObject> => {
  let body: BodyInit | undefined;
  if (request) {
    try {
      body = Request.fromObject(request).serializeBinary();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`serialization error ${error}, request: ${request}`);
      throw error;
    }
  }

  const headers = new Headers(additionalHeaders);
  headers?.set(protobufHeader.name, protobufHeader.value);

  return fetch(baseApiUrl + path, {
    method: "POST",
    mode: "cors",
    headers,
    body,
  }).then((response) => {
    if (response.headers.get(protobufHeader.name) === protobufHeader.value) {
      // ok response from backend or error response from backend
      return response.arrayBuffer().then((buffer) => {
        const uint8Arr = new Uint8Array(buffer);
        let protoResp: Response.AsObject;
        try {
          protoResp = Response.deserializeBinary(uint8Arr).toObject();
        } catch (error) {
          console.error(
            `${path} deserialization error ${error}, resp: ${uint8Arr}`,
          );
          throw error;
        }
        if (response.ok && !protoResp.error) {
          return protoResp;
        }
        throw protoResp.error;
      });
    }
    // error response from nginx or other middleware
    if (response.ok) {
      throw Error(
        `expected protobuf, but got ${response.headers.get(
          protobufHeader.name,
        )}`,
      );
    } else {
      throw Error(response.statusText);
    }
  });
};
