import { Request, Response } from "../../modelspb/api";
import { errorToUserText } from "../errors/functions";
import { appFetchProtoWithHeaders } from "./appFetchProtoWithHeaders";

export const appFetch = (
  path: string,
  request?: Request.AsObjectPartial | null, // if null, request will be without body
  heasders?: Headers,
): Promise<Response.AsObject> => {
  return appFetchProtoWithHeaders(path, request, heasders).catch((error) => {
    throw errorToUserText(error);
  });
};
