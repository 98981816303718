/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.13.0
 * source: validator.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./validate/validate";
import * as dependency_2 from "./models";
import * as pb_1 from "google-protobuf";
export class Validator extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        nodeId?: string;
        nodeName?: string;
        nodeIconPath?: string;
        uptime?: number;
        connected?: boolean;
        validations?: Validator.Validation[];
        delegators?: Validator.Delegator[];
        supernets?: Validator.SupernetValidator[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [4, 5, 6], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("nodeId" in data && data.nodeId != undefined) {
                this.nodeId = data.nodeId;
            }
            if ("nodeName" in data && data.nodeName != undefined) {
                this.nodeName = data.nodeName;
            }
            if ("nodeIconPath" in data && data.nodeIconPath != undefined) {
                this.nodeIconPath = data.nodeIconPath;
            }
            if ("uptime" in data && data.uptime != undefined) {
                this.uptime = data.uptime;
            }
            if ("connected" in data && data.connected != undefined) {
                this.connected = data.connected;
            }
            if ("validations" in data && data.validations != undefined) {
                this.validations = data.validations;
            }
            if ("delegators" in data && data.delegators != undefined) {
                this.delegators = data.delegators;
            }
            if ("supernets" in data && data.supernets != undefined) {
                this.supernets = data.supernets;
            }
        }
    }
    get nodeId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set nodeId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get nodeName() {
        return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
    }
    set nodeName(value: string) {
        pb_1.Message.setField(this, 7, value);
    }
    get nodeIconPath() {
        return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
    }
    set nodeIconPath(value: string) {
        pb_1.Message.setField(this, 8, value);
    }
    get uptime() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set uptime(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get connected() {
        return pb_1.Message.getFieldWithDefault(this, 3, false) as boolean;
    }
    set connected(value: boolean) {
        pb_1.Message.setField(this, 3, value);
    }
    get validations() {
        return pb_1.Message.getRepeatedWrapperField(this, Validator.Validation, 4) as Validator.Validation[];
    }
    set validations(value: Validator.Validation[]) {
        pb_1.Message.setRepeatedWrapperField(this, 4, value);
    }
    get delegators() {
        return pb_1.Message.getRepeatedWrapperField(this, Validator.Delegator, 5) as Validator.Delegator[];
    }
    set delegators(value: Validator.Delegator[]) {
        pb_1.Message.setRepeatedWrapperField(this, 5, value);
    }
    get supernets() {
        return pb_1.Message.getRepeatedWrapperField(this, Validator.SupernetValidator, 6) as Validator.SupernetValidator[];
    }
    set supernets(value: Validator.SupernetValidator[]) {
        pb_1.Message.setRepeatedWrapperField(this, 6, value);
    }
    static fromObject(data?: Validator.AsObjectPartial): Validator {
        if (!data) {
            return new Validator();
        }
        const message = new Validator({});
        if (data.nodeId != null) {
            message.nodeId = data.nodeId;
        }
        if (data.nodeName != null) {
            message.nodeName = data.nodeName;
        }
        if (data.nodeIconPath != null) {
            message.nodeIconPath = data.nodeIconPath;
        }
        if (data.uptime != null) {
            message.uptime = data.uptime;
        }
        if (data.connected != null) {
            message.connected = data.connected;
        }
        if (data.validations != null) {
            message.validations = data.validations.map(item => Validator.Validation.fromObject(item));
        }
        if (data.delegators != null) {
            message.delegators = data.delegators.map(item => Validator.Delegator.fromObject(item));
        }
        if (data.supernets != null) {
            message.supernets = data.supernets.map(item => Validator.SupernetValidator.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: Validator.AsObject = {
            nodeId: this.nodeId,
            nodeName: this.nodeName,
            nodeIconPath: this.nodeIconPath,
            uptime: this.uptime,
            connected: this.connected,
            validations: this.validations.map((item: Validator.Validation) => item.toObject()),
            delegators: this.delegators.map((item: Validator.Delegator) => item.toObject()),
            supernets: this.supernets.map((item: Validator.SupernetValidator) => item.toObject())
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.nodeId.length)
            writer.writeString(1, this.nodeId);
        if (this.nodeName.length)
            writer.writeString(7, this.nodeName);
        if (this.nodeIconPath.length)
            writer.writeString(8, this.nodeIconPath);
        if (this.uptime != 0)
            writer.writeUint32(2, this.uptime);
        if (this.connected != false)
            writer.writeBool(3, this.connected);
        if (this.validations.length)
            writer.writeRepeatedMessage(4, this.validations, (item: Validator.Validation) => item!.serialize(writer));
        if (this.delegators.length)
            writer.writeRepeatedMessage(5, this.delegators, (item: Validator.Delegator) => item!.serialize(writer));
        if (this.supernets.length)
            writer.writeRepeatedMessage(6, this.supernets, (item: Validator.SupernetValidator) => item!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Validator {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Validator();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.nodeId = reader.readString();
                    break;
                case 7:
                    message.nodeName = reader.readString();
                    break;
                case 8:
                    message.nodeIconPath = reader.readString();
                    break;
                case 2:
                    message.uptime = reader.readUint32();
                    break;
                case 3:
                    message.connected = reader.readBool();
                    break;
                case 4:
                    reader.readMessage(message.validations, () => pb_1.Message.addToRepeatedWrapperField(message, 4, Validator.Validation.deserialize(reader), Validator.Validation));
                    break;
                case 5:
                    reader.readMessage(message.delegators, () => pb_1.Message.addToRepeatedWrapperField(message, 5, Validator.Delegator.deserialize(reader), Validator.Delegator));
                    break;
                case 6:
                    reader.readMessage(message.supernets, () => pb_1.Message.addToRepeatedWrapperField(message, 6, Validator.SupernetValidator.deserialize(reader), Validator.SupernetValidator));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): Validator {
        return Validator.deserialize(bytes);
    }
}
export namespace Validator {
    export type AsObject = {
        nodeId: string;
        nodeName: string;
        nodeIconPath: string;
        uptime: number;
        connected: boolean;
        validations: Validator.Validation.AsObject[];
        delegators: Validator.Delegator.AsObject[];
        supernets: Validator.SupernetValidator.AsObject[];
    };
    export type AsObjectPartial = {
        nodeId?: string;
        nodeName?: string;
        nodeIconPath?: string;
        uptime?: number;
        connected?: boolean;
        validations?: Validator.Validation.AsObjectPartial[];
        delegators?: Validator.Delegator.AsObjectPartial[];
        supernets?: Validator.SupernetValidator.AsObjectPartial[];
    };
    export class RewardOwner extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            locktime?: number;
            threshold?: number;
            addresses?: string[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("locktime" in data && data.locktime != undefined) {
                    this.locktime = data.locktime;
                }
                if ("threshold" in data && data.threshold != undefined) {
                    this.threshold = data.threshold;
                }
                if ("addresses" in data && data.addresses != undefined) {
                    this.addresses = data.addresses;
                }
            }
        }
        get locktime() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set locktime(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get threshold() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set threshold(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get addresses() {
            return pb_1.Message.getFieldWithDefault(this, 3, []) as string[];
        }
        set addresses(value: string[]) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data?: RewardOwner.AsObjectPartial): RewardOwner {
            if (!data) {
                return new RewardOwner();
            }
            const message = new RewardOwner({});
            if (data.locktime != null) {
                message.locktime = data.locktime;
            }
            if (data.threshold != null) {
                message.threshold = data.threshold;
            }
            if (data.addresses != null) {
                message.addresses = data.addresses;
            }
            return message;
        }
        toObject() {
            const data: RewardOwner.AsObject = {
                locktime: this.locktime,
                threshold: this.threshold,
                addresses: this.addresses
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.locktime != 0)
                writer.writeUint64(1, this.locktime);
            if (this.threshold != 0)
                writer.writeUint32(2, this.threshold);
            if (this.addresses.length)
                writer.writeRepeatedString(3, this.addresses);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RewardOwner {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RewardOwner();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.locktime = reader.readUint64();
                        break;
                    case 2:
                        message.threshold = reader.readUint32();
                        break;
                    case 3:
                        pb_1.Message.addToRepeatedField(message, 3, reader.readString());
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): RewardOwner {
            return RewardOwner.deserialize(bytes);
        }
    }
    export namespace RewardOwner {
        export type AsObject = {
            locktime: number;
            threshold: number;
            addresses: string[];
        };
        export type AsObjectPartial = {
            locktime?: number;
            threshold?: number;
            addresses?: string[];
        };
    }
    export class Validation extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            txId?: string;
            startTime?: number;
            endTime?: number;
            stakeAmount?: string;
            rewardOwner?: Validator.RewardOwner;
            potentialReward?: string;
            delegationFee?: number;
            resultReward?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("txId" in data && data.txId != undefined) {
                    this.txId = data.txId;
                }
                if ("startTime" in data && data.startTime != undefined) {
                    this.startTime = data.startTime;
                }
                if ("endTime" in data && data.endTime != undefined) {
                    this.endTime = data.endTime;
                }
                if ("stakeAmount" in data && data.stakeAmount != undefined) {
                    this.stakeAmount = data.stakeAmount;
                }
                if ("rewardOwner" in data && data.rewardOwner != undefined) {
                    this.rewardOwner = data.rewardOwner;
                }
                if ("potentialReward" in data && data.potentialReward != undefined) {
                    this.potentialReward = data.potentialReward;
                }
                if ("delegationFee" in data && data.delegationFee != undefined) {
                    this.delegationFee = data.delegationFee;
                }
                if ("resultReward" in data && data.resultReward != undefined) {
                    this.resultReward = data.resultReward;
                }
            }
        }
        get txId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set txId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get startTime() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set startTime(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get endTime() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set endTime(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get stakeAmount() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set stakeAmount(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get rewardOwner() {
            return pb_1.Message.getWrapperField(this, Validator.RewardOwner, 5) as Validator.RewardOwner | undefined;
        }
        set rewardOwner(value: Validator.RewardOwner | undefined) {
            pb_1.Message.setWrapperField(this, 5, value);
        }
        get hasRewardOwner() {
            return pb_1.Message.getField(this, 5) != null;
        }
        clearRewardOwner(): void {
            this.rewardOwner = undefined!;
        }
        get potentialReward() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set potentialReward(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        get delegationFee() {
            return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
        }
        set delegationFee(value: number) {
            pb_1.Message.setField(this, 7, value);
        }
        get resultReward() {
            return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
        }
        set resultReward(value: string) {
            pb_1.Message.setField(this, 8, value);
        }
        static fromObject(data?: Validation.AsObjectPartial): Validation {
            if (!data) {
                return new Validation();
            }
            const message = new Validation({});
            if (data.txId != null) {
                message.txId = data.txId;
            }
            if (data.startTime != null) {
                message.startTime = data.startTime;
            }
            if (data.endTime != null) {
                message.endTime = data.endTime;
            }
            if (data.stakeAmount != null) {
                message.stakeAmount = data.stakeAmount;
            }
            if (data.rewardOwner != null) {
                message.rewardOwner = Validator.RewardOwner.fromObject(data.rewardOwner);
            }
            if (data.potentialReward != null) {
                message.potentialReward = data.potentialReward;
            }
            if (data.delegationFee != null) {
                message.delegationFee = data.delegationFee;
            }
            if (data.resultReward != null) {
                message.resultReward = data.resultReward;
            }
            return message;
        }
        toObject() {
            const data: Validation.AsObject = {
                txId: this.txId,
                startTime: this.startTime,
                endTime: this.endTime,
                stakeAmount: this.stakeAmount,
                potentialReward: this.potentialReward,
                delegationFee: this.delegationFee,
                resultReward: this.resultReward
            };
            if (this.rewardOwner != null) {
                data.rewardOwner = this.rewardOwner.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.txId.length)
                writer.writeString(1, this.txId);
            if (this.startTime != 0)
                writer.writeUint64(2, this.startTime);
            if (this.endTime != 0)
                writer.writeUint64(3, this.endTime);
            if (this.stakeAmount.length)
                writer.writeString(4, this.stakeAmount);
            if (this.hasRewardOwner)
                writer.writeMessage(5, this.rewardOwner, () => this.rewardOwner!.serialize(writer));
            if (this.potentialReward.length)
                writer.writeString(6, this.potentialReward);
            if (this.delegationFee != 0)
                writer.writeUint32(7, this.delegationFee);
            if (this.resultReward.length)
                writer.writeString(8, this.resultReward);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Validation {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Validation();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.txId = reader.readString();
                        break;
                    case 2:
                        message.startTime = reader.readUint64();
                        break;
                    case 3:
                        message.endTime = reader.readUint64();
                        break;
                    case 4:
                        message.stakeAmount = reader.readString();
                        break;
                    case 5:
                        reader.readMessage(message.rewardOwner, () => message.rewardOwner = Validator.RewardOwner.deserialize(reader));
                        break;
                    case 6:
                        message.potentialReward = reader.readString();
                        break;
                    case 7:
                        message.delegationFee = reader.readUint32();
                        break;
                    case 8:
                        message.resultReward = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Validation {
            return Validation.deserialize(bytes);
        }
    }
    export namespace Validation {
        export type AsObject = {
            txId: string;
            startTime: number;
            endTime: number;
            stakeAmount: string;
            rewardOwner?: Validator.RewardOwner.AsObject;
            potentialReward: string;
            delegationFee: number;
            resultReward: string;
        };
        export type AsObjectPartial = {
            txId?: string;
            startTime?: number;
            endTime?: number;
            stakeAmount?: string;
            rewardOwner?: Validator.RewardOwner.AsObjectPartial;
            potentialReward?: string;
            delegationFee?: number;
            resultReward?: string;
        };
    }
    export class Delegator extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            txId?: string;
            startTime?: number;
            endTime?: number;
            stakeAmount?: string;
            rewardOwner?: Validator.RewardOwner;
            potentialReward?: string;
            nodeId?: string;
            resultReward?: string;
            supernetId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("txId" in data && data.txId != undefined) {
                    this.txId = data.txId;
                }
                if ("startTime" in data && data.startTime != undefined) {
                    this.startTime = data.startTime;
                }
                if ("endTime" in data && data.endTime != undefined) {
                    this.endTime = data.endTime;
                }
                if ("stakeAmount" in data && data.stakeAmount != undefined) {
                    this.stakeAmount = data.stakeAmount;
                }
                if ("rewardOwner" in data && data.rewardOwner != undefined) {
                    this.rewardOwner = data.rewardOwner;
                }
                if ("potentialReward" in data && data.potentialReward != undefined) {
                    this.potentialReward = data.potentialReward;
                }
                if ("nodeId" in data && data.nodeId != undefined) {
                    this.nodeId = data.nodeId;
                }
                if ("resultReward" in data && data.resultReward != undefined) {
                    this.resultReward = data.resultReward;
                }
                if ("supernetId" in data && data.supernetId != undefined) {
                    this.supernetId = data.supernetId;
                }
            }
        }
        get txId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set txId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get startTime() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set startTime(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get endTime() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set endTime(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get stakeAmount() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set stakeAmount(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get rewardOwner() {
            return pb_1.Message.getWrapperField(this, Validator.RewardOwner, 5) as Validator.RewardOwner | undefined;
        }
        set rewardOwner(value: Validator.RewardOwner | undefined) {
            pb_1.Message.setWrapperField(this, 5, value);
        }
        get hasRewardOwner() {
            return pb_1.Message.getField(this, 5) != null;
        }
        clearRewardOwner(): void {
            this.rewardOwner = undefined!;
        }
        get potentialReward() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set potentialReward(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        get nodeId() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set nodeId(value: string) {
            pb_1.Message.setField(this, 7, value);
        }
        get resultReward() {
            return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
        }
        set resultReward(value: string) {
            pb_1.Message.setField(this, 8, value);
        }
        get supernetId() {
            return pb_1.Message.getFieldWithDefault(this, 9, "") as string;
        }
        set supernetId(value: string) {
            pb_1.Message.setField(this, 9, value);
        }
        static fromObject(data?: Delegator.AsObjectPartial): Delegator {
            if (!data) {
                return new Delegator();
            }
            const message = new Delegator({});
            if (data.txId != null) {
                message.txId = data.txId;
            }
            if (data.startTime != null) {
                message.startTime = data.startTime;
            }
            if (data.endTime != null) {
                message.endTime = data.endTime;
            }
            if (data.stakeAmount != null) {
                message.stakeAmount = data.stakeAmount;
            }
            if (data.rewardOwner != null) {
                message.rewardOwner = Validator.RewardOwner.fromObject(data.rewardOwner);
            }
            if (data.potentialReward != null) {
                message.potentialReward = data.potentialReward;
            }
            if (data.nodeId != null) {
                message.nodeId = data.nodeId;
            }
            if (data.resultReward != null) {
                message.resultReward = data.resultReward;
            }
            if (data.supernetId != null) {
                message.supernetId = data.supernetId;
            }
            return message;
        }
        toObject() {
            const data: Delegator.AsObject = {
                txId: this.txId,
                startTime: this.startTime,
                endTime: this.endTime,
                stakeAmount: this.stakeAmount,
                potentialReward: this.potentialReward,
                nodeId: this.nodeId,
                resultReward: this.resultReward,
                supernetId: this.supernetId
            };
            if (this.rewardOwner != null) {
                data.rewardOwner = this.rewardOwner.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.txId.length)
                writer.writeString(1, this.txId);
            if (this.startTime != 0)
                writer.writeUint64(2, this.startTime);
            if (this.endTime != 0)
                writer.writeUint64(3, this.endTime);
            if (this.stakeAmount.length)
                writer.writeString(4, this.stakeAmount);
            if (this.hasRewardOwner)
                writer.writeMessage(5, this.rewardOwner, () => this.rewardOwner!.serialize(writer));
            if (this.potentialReward.length)
                writer.writeString(6, this.potentialReward);
            if (this.nodeId.length)
                writer.writeString(7, this.nodeId);
            if (this.resultReward.length)
                writer.writeString(8, this.resultReward);
            if (this.supernetId.length)
                writer.writeString(9, this.supernetId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Delegator {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Delegator();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.txId = reader.readString();
                        break;
                    case 2:
                        message.startTime = reader.readUint64();
                        break;
                    case 3:
                        message.endTime = reader.readUint64();
                        break;
                    case 4:
                        message.stakeAmount = reader.readString();
                        break;
                    case 5:
                        reader.readMessage(message.rewardOwner, () => message.rewardOwner = Validator.RewardOwner.deserialize(reader));
                        break;
                    case 6:
                        message.potentialReward = reader.readString();
                        break;
                    case 7:
                        message.nodeId = reader.readString();
                        break;
                    case 8:
                        message.resultReward = reader.readString();
                        break;
                    case 9:
                        message.supernetId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Delegator {
            return Delegator.deserialize(bytes);
        }
    }
    export namespace Delegator {
        export type AsObject = {
            txId: string;
            startTime: number;
            endTime: number;
            stakeAmount: string;
            rewardOwner?: Validator.RewardOwner.AsObject;
            potentialReward: string;
            nodeId: string;
            resultReward: string;
            supernetId: string;
        };
        export type AsObjectPartial = {
            txId?: string;
            startTime?: number;
            endTime?: number;
            stakeAmount?: string;
            rewardOwner?: Validator.RewardOwner.AsObjectPartial;
            potentialReward?: string;
            nodeId?: string;
            resultReward?: string;
            supernetId?: string;
        };
    }
    export class SupernetValidator extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            txId?: string;
            startTime?: number;
            endTime?: number;
            stakeAmount?: string;
            supernetId?: string;
            resultReward?: string;
            validatorRewardOwner?: Validator.RewardOwner;
            delegatorRewardOwner?: Validator.RewardOwner;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("txId" in data && data.txId != undefined) {
                    this.txId = data.txId;
                }
                if ("startTime" in data && data.startTime != undefined) {
                    this.startTime = data.startTime;
                }
                if ("endTime" in data && data.endTime != undefined) {
                    this.endTime = data.endTime;
                }
                if ("stakeAmount" in data && data.stakeAmount != undefined) {
                    this.stakeAmount = data.stakeAmount;
                }
                if ("supernetId" in data && data.supernetId != undefined) {
                    this.supernetId = data.supernetId;
                }
                if ("resultReward" in data && data.resultReward != undefined) {
                    this.resultReward = data.resultReward;
                }
                if ("validatorRewardOwner" in data && data.validatorRewardOwner != undefined) {
                    this.validatorRewardOwner = data.validatorRewardOwner;
                }
                if ("delegatorRewardOwner" in data && data.delegatorRewardOwner != undefined) {
                    this.delegatorRewardOwner = data.delegatorRewardOwner;
                }
            }
        }
        get txId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set txId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get startTime() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set startTime(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get endTime() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set endTime(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get stakeAmount() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set stakeAmount(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get supernetId() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set supernetId(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get resultReward() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set resultReward(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        get validatorRewardOwner() {
            return pb_1.Message.getWrapperField(this, Validator.RewardOwner, 7) as Validator.RewardOwner | undefined;
        }
        set validatorRewardOwner(value: Validator.RewardOwner | undefined) {
            pb_1.Message.setWrapperField(this, 7, value);
        }
        get hasValidatorRewardOwner() {
            return pb_1.Message.getField(this, 7) != null;
        }
        clearValidatorRewardOwner(): void {
            this.validatorRewardOwner = undefined!;
        }
        get delegatorRewardOwner() {
            return pb_1.Message.getWrapperField(this, Validator.RewardOwner, 8) as Validator.RewardOwner | undefined;
        }
        set delegatorRewardOwner(value: Validator.RewardOwner | undefined) {
            pb_1.Message.setWrapperField(this, 8, value);
        }
        get hasDelegatorRewardOwner() {
            return pb_1.Message.getField(this, 8) != null;
        }
        clearDelegatorRewardOwner(): void {
            this.delegatorRewardOwner = undefined!;
        }
        static fromObject(data?: SupernetValidator.AsObjectPartial): SupernetValidator {
            if (!data) {
                return new SupernetValidator();
            }
            const message = new SupernetValidator({});
            if (data.txId != null) {
                message.txId = data.txId;
            }
            if (data.startTime != null) {
                message.startTime = data.startTime;
            }
            if (data.endTime != null) {
                message.endTime = data.endTime;
            }
            if (data.stakeAmount != null) {
                message.stakeAmount = data.stakeAmount;
            }
            if (data.supernetId != null) {
                message.supernetId = data.supernetId;
            }
            if (data.resultReward != null) {
                message.resultReward = data.resultReward;
            }
            if (data.validatorRewardOwner != null) {
                message.validatorRewardOwner = Validator.RewardOwner.fromObject(data.validatorRewardOwner);
            }
            if (data.delegatorRewardOwner != null) {
                message.delegatorRewardOwner = Validator.RewardOwner.fromObject(data.delegatorRewardOwner);
            }
            return message;
        }
        toObject() {
            const data: SupernetValidator.AsObject = {
                txId: this.txId,
                startTime: this.startTime,
                endTime: this.endTime,
                stakeAmount: this.stakeAmount,
                supernetId: this.supernetId,
                resultReward: this.resultReward
            };
            if (this.validatorRewardOwner != null) {
                data.validatorRewardOwner = this.validatorRewardOwner.toObject();
            }
            if (this.delegatorRewardOwner != null) {
                data.delegatorRewardOwner = this.delegatorRewardOwner.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.txId.length)
                writer.writeString(1, this.txId);
            if (this.startTime != 0)
                writer.writeUint64(2, this.startTime);
            if (this.endTime != 0)
                writer.writeUint64(3, this.endTime);
            if (this.stakeAmount.length)
                writer.writeString(4, this.stakeAmount);
            if (this.supernetId.length)
                writer.writeString(5, this.supernetId);
            if (this.resultReward.length)
                writer.writeString(6, this.resultReward);
            if (this.hasValidatorRewardOwner)
                writer.writeMessage(7, this.validatorRewardOwner, () => this.validatorRewardOwner!.serialize(writer));
            if (this.hasDelegatorRewardOwner)
                writer.writeMessage(8, this.delegatorRewardOwner, () => this.delegatorRewardOwner!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SupernetValidator {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SupernetValidator();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.txId = reader.readString();
                        break;
                    case 2:
                        message.startTime = reader.readUint64();
                        break;
                    case 3:
                        message.endTime = reader.readUint64();
                        break;
                    case 4:
                        message.stakeAmount = reader.readString();
                        break;
                    case 5:
                        message.supernetId = reader.readString();
                        break;
                    case 6:
                        message.resultReward = reader.readString();
                        break;
                    case 7:
                        reader.readMessage(message.validatorRewardOwner, () => message.validatorRewardOwner = Validator.RewardOwner.deserialize(reader));
                        break;
                    case 8:
                        reader.readMessage(message.delegatorRewardOwner, () => message.delegatorRewardOwner = Validator.RewardOwner.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SupernetValidator {
            return SupernetValidator.deserialize(bytes);
        }
    }
    export namespace SupernetValidator {
        export type AsObject = {
            txId: string;
            startTime: number;
            endTime: number;
            stakeAmount: string;
            supernetId: string;
            resultReward: string;
            validatorRewardOwner?: Validator.RewardOwner.AsObject;
            delegatorRewardOwner?: Validator.RewardOwner.AsObject;
        };
        export type AsObjectPartial = {
            txId?: string;
            startTime?: number;
            endTime?: number;
            stakeAmount?: string;
            supernetId?: string;
            resultReward?: string;
            validatorRewardOwner?: Validator.RewardOwner.AsObjectPartial;
            delegatorRewardOwner?: Validator.RewardOwner.AsObjectPartial;
        };
    }
}
export class ValidatorListRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[2, 3, 4, 5, 6, 7]];
    constructor(data?: any[] | ({
        page?: dependency_2.Page;
        sortBy?: ValidatorListRequest.SortField;
        sortOrder?: dependency_2.SortOrder;
    } & (({
        all?: boolean;
        id?: never;
        active?: never;
        supernetId?: never;
        activeSupernetId?: never;
        ids?: never;
    } | {
        all?: never;
        id?: string;
        active?: never;
        supernetId?: never;
        activeSupernetId?: never;
        ids?: never;
    } | {
        all?: never;
        id?: never;
        active?: boolean;
        supernetId?: never;
        activeSupernetId?: never;
        ids?: never;
    } | {
        all?: never;
        id?: never;
        active?: never;
        supernetId?: string;
        activeSupernetId?: never;
        ids?: never;
    } | {
        all?: never;
        id?: never;
        active?: never;
        supernetId?: never;
        activeSupernetId?: string;
        ids?: never;
    } | {
        all?: never;
        id?: never;
        active?: never;
        supernetId?: never;
        activeSupernetId?: never;
        ids?: ValidatorListRequest.IdList;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("all" in data && data.all != undefined) {
                this.all = data.all;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("active" in data && data.active != undefined) {
                this.active = data.active;
            }
            if ("supernetId" in data && data.supernetId != undefined) {
                this.supernetId = data.supernetId;
            }
            if ("activeSupernetId" in data && data.activeSupernetId != undefined) {
                this.activeSupernetId = data.activeSupernetId;
            }
            if ("ids" in data && data.ids != undefined) {
                this.ids = data.ids;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
            if ("sortBy" in data && data.sortBy != undefined) {
                this.sortBy = data.sortBy;
            }
            if ("sortOrder" in data && data.sortOrder != undefined) {
                this.sortOrder = data.sortOrder;
            }
        }
    }
    get all() {
        return pb_1.Message.getFieldWithDefault(this, 2, false) as boolean;
    }
    set all(value: boolean) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasAll() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearAll(): void {
        this.all = undefined!;
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setOneofField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasId() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearId(): void {
        this.id = undefined!;
    }
    get active() {
        return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
    }
    set active(value: boolean) {
        pb_1.Message.setOneofField(this, 4, this.#one_of_decls[0]!, value);
    }
    get hasActive() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearActive(): void {
        this.active = undefined!;
    }
    get supernetId() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set supernetId(value: string) {
        pb_1.Message.setOneofField(this, 5, this.#one_of_decls[0]!, value);
    }
    get hasSupernetId() {
        return pb_1.Message.getField(this, 5) != null;
    }
    clearSupernetId(): void {
        this.supernetId = undefined!;
    }
    get activeSupernetId() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set activeSupernetId(value: string) {
        pb_1.Message.setOneofField(this, 6, this.#one_of_decls[0]!, value);
    }
    get hasActiveSupernetId() {
        return pb_1.Message.getField(this, 6) != null;
    }
    clearActiveSupernetId(): void {
        this.activeSupernetId = undefined!;
    }
    get ids() {
        return pb_1.Message.getWrapperField(this, ValidatorListRequest.IdList, 7) as ValidatorListRequest.IdList | undefined;
    }
    set ids(value: ValidatorListRequest.IdList | undefined) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0]!, value);
    }
    get hasIds() {
        return pb_1.Message.getField(this, 7) != null;
    }
    clearIds(): void {
        this.ids = undefined!;
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_2.Page, 1) as dependency_2.Page | undefined;
    }
    set page(value: dependency_2.Page | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    get sortBy() {
        return pb_1.Message.getFieldWithDefault(this, 8, ValidatorListRequest.SortField.SORT_FIELD_STARTED_ON) as ValidatorListRequest.SortField;
    }
    set sortBy(value: ValidatorListRequest.SortField) {
        pb_1.Message.setField(this, 8, value);
    }
    get sortOrder() {
        return pb_1.Message.getFieldWithDefault(this, 9, dependency_2.SortOrder.SORT_ORDER_DESC) as dependency_2.SortOrder;
    }
    set sortOrder(value: dependency_2.SortOrder) {
        pb_1.Message.setField(this, 9, value);
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "all" | "id" | "active" | "supernetId" | "activeSupernetId" | "ids";
        } = {
            0: "none",
            2: "all",
            3: "id",
            4: "active",
            5: "supernetId",
            6: "activeSupernetId",
            7: "ids"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2, 3, 4, 5, 6, 7])]!;
    }
    static fromObject(data?: ValidatorListRequest.AsObjectPartial): ValidatorListRequest {
        if (!data) {
            return new ValidatorListRequest();
        }
        const message = new ValidatorListRequest({});
        if (data.all != null) {
            message.all = data.all;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.active != null) {
            message.active = data.active;
        }
        if (data.supernetId != null) {
            message.supernetId = data.supernetId;
        }
        if (data.activeSupernetId != null) {
            message.activeSupernetId = data.activeSupernetId;
        }
        if (data.ids != null) {
            message.ids = ValidatorListRequest.IdList.fromObject(data.ids);
        }
        if (data.page != null) {
            message.page = dependency_2.Page.fromObject(data.page);
        }
        if (data.sortBy != null) {
            message.sortBy = data.sortBy;
        }
        if (data.sortOrder != null) {
            message.sortOrder = data.sortOrder;
        }
        return message;
    }
    toObject() {
        const data: ValidatorListRequest.AsObject = {
            all: this.all,
            id: this.id,
            active: this.active,
            supernetId: this.supernetId,
            activeSupernetId: this.activeSupernetId,
            sortBy: this.sortBy,
            sortOrder: this.sortOrder
        };
        if (this.ids != null) {
            data.ids = this.ids.toObject();
        }
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasAll)
            writer.writeBool(2, this.all);
        if (this.hasId)
            writer.writeString(3, this.id);
        if (this.hasActive)
            writer.writeBool(4, this.active);
        if (this.hasSupernetId)
            writer.writeString(5, this.supernetId);
        if (this.hasActiveSupernetId)
            writer.writeString(6, this.activeSupernetId);
        if (this.hasIds)
            writer.writeMessage(7, this.ids, () => this.ids!.serialize(writer));
        if (this.hasPage)
            writer.writeMessage(1, this.page, () => this.page!.serialize(writer));
        if (this.sortBy != ValidatorListRequest.SortField.SORT_FIELD_STARTED_ON)
            writer.writeEnum(8, this.sortBy);
        if (this.sortOrder != dependency_2.SortOrder.SORT_ORDER_DESC)
            writer.writeEnum(9, this.sortOrder);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ValidatorListRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ValidatorListRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 2:
                    message.all = reader.readBool();
                    break;
                case 3:
                    message.id = reader.readString();
                    break;
                case 4:
                    message.active = reader.readBool();
                    break;
                case 5:
                    message.supernetId = reader.readString();
                    break;
                case 6:
                    message.activeSupernetId = reader.readString();
                    break;
                case 7:
                    reader.readMessage(message.ids, () => message.ids = ValidatorListRequest.IdList.deserialize(reader));
                    break;
                case 1:
                    reader.readMessage(message.page, () => message.page = dependency_2.Page.deserialize(reader));
                    break;
                case 8:
                    message.sortBy = reader.readEnum();
                    break;
                case 9:
                    message.sortOrder = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ValidatorListRequest {
        return ValidatorListRequest.deserialize(bytes);
    }
}
export namespace ValidatorListRequest {
    export type AsObject = {
        all: boolean;
        id: string;
        active: boolean;
        supernetId: string;
        activeSupernetId: string;
        ids?: ValidatorListRequest.IdList.AsObject;
        page?: dependency_2.Page.AsObject;
        sortBy: ValidatorListRequest.SortField;
        sortOrder: dependency_2.SortOrder;
    };
    export type AsObjectPartial = {
        all?: boolean;
        id?: string;
        active?: boolean;
        supernetId?: string;
        activeSupernetId?: string;
        ids?: ValidatorListRequest.IdList.AsObjectPartial;
        page?: dependency_2.Page.AsObjectPartial;
        sortBy?: ValidatorListRequest.SortField;
        sortOrder?: dependency_2.SortOrder;
    };
    export enum SortField {
        SORT_FIELD_STARTED_ON = 0,
        SORT_FIELD_AMOUNT = 1,
        SORT_FIELD_DELEGATIONS = 2,
        SORT_FIELD_ENDS_ON = 3,
        SORT_FIELD_FEE = 4,
        SORT_FIELD_UPTIME = 5,
        SORT_FIELD_UP = 6
    }
    export class IdList extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            ids?: string[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("ids" in data && data.ids != undefined) {
                    this.ids = data.ids;
                }
            }
        }
        get ids() {
            return pb_1.Message.getFieldWithDefault(this, 1, []) as string[];
        }
        set ids(value: string[]) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data?: IdList.AsObjectPartial): IdList {
            if (!data) {
                return new IdList();
            }
            const message = new IdList({});
            if (data.ids != null) {
                message.ids = data.ids;
            }
            return message;
        }
        toObject() {
            const data: IdList.AsObject = {
                ids: this.ids
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.ids.length)
                writer.writeRepeatedString(1, this.ids);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): IdList {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new IdList();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        pb_1.Message.addToRepeatedField(message, 1, reader.readString());
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): IdList {
            return IdList.deserialize(bytes);
        }
    }
    export namespace IdList {
        export type AsObject = {
            ids: string[];
        };
        export type AsObjectPartial = {
            ids?: string[];
        };
    }
}
export class ValidatorListResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        validators?: Validator[];
        navigation?: dependency_2.NavigationData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("validators" in data && data.validators != undefined) {
                this.validators = data.validators;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
        }
    }
    get validators() {
        return pb_1.Message.getRepeatedWrapperField(this, Validator, 1) as Validator[];
    }
    set validators(value: Validator[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_2.NavigationData, 2) as dependency_2.NavigationData | undefined;
    }
    set navigation(value: dependency_2.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    static fromObject(data?: ValidatorListResponse.AsObjectPartial): ValidatorListResponse {
        if (!data) {
            return new ValidatorListResponse();
        }
        const message = new ValidatorListResponse({});
        if (data.validators != null) {
            message.validators = data.validators.map(item => Validator.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_2.NavigationData.fromObject(data.navigation);
        }
        return message;
    }
    toObject() {
        const data: ValidatorListResponse.AsObject = {
            validators: this.validators.map((item: Validator) => item.toObject())
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.validators.length)
            writer.writeRepeatedMessage(1, this.validators, (item: Validator) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(2, this.navigation, () => this.navigation!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ValidatorListResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ValidatorListResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.validators, () => pb_1.Message.addToRepeatedWrapperField(message, 1, Validator.deserialize(reader), Validator));
                    break;
                case 2:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_2.NavigationData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ValidatorListResponse {
        return ValidatorListResponse.deserialize(bytes);
    }
}
export namespace ValidatorListResponse {
    export type AsObject = {
        validators: Validator.AsObject[];
        navigation?: dependency_2.NavigationData.AsObject;
    };
    export type AsObjectPartial = {
        validators?: Validator.AsObjectPartial[];
        navigation?: dependency_2.NavigationData.AsObjectPartial;
    };
}
export class DelegationListRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[2, 3, 4]];
    constructor(data?: any[] | ({
        page?: dependency_2.Page;
        sortBy?: DelegationListRequest.SortField;
        sortOrder?: dependency_2.SortOrder;
    } & (({
        all?: boolean;
        txId?: never;
        active?: never;
    } | {
        all?: never;
        txId?: string;
        active?: never;
    } | {
        all?: never;
        txId?: never;
        active?: boolean;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("all" in data && data.all != undefined) {
                this.all = data.all;
            }
            if ("txId" in data && data.txId != undefined) {
                this.txId = data.txId;
            }
            if ("active" in data && data.active != undefined) {
                this.active = data.active;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
            if ("sortBy" in data && data.sortBy != undefined) {
                this.sortBy = data.sortBy;
            }
            if ("sortOrder" in data && data.sortOrder != undefined) {
                this.sortOrder = data.sortOrder;
            }
        }
    }
    get all() {
        return pb_1.Message.getFieldWithDefault(this, 2, false) as boolean;
    }
    set all(value: boolean) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasAll() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearAll(): void {
        this.all = undefined!;
    }
    get txId() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set txId(value: string) {
        pb_1.Message.setOneofField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasTxId() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearTxId(): void {
        this.txId = undefined!;
    }
    get active() {
        return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
    }
    set active(value: boolean) {
        pb_1.Message.setOneofField(this, 4, this.#one_of_decls[0]!, value);
    }
    get hasActive() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearActive(): void {
        this.active = undefined!;
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_2.Page, 1) as dependency_2.Page | undefined;
    }
    set page(value: dependency_2.Page | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    get sortBy() {
        return pb_1.Message.getFieldWithDefault(this, 8, DelegationListRequest.SortField.SORT_FIELD_STARTED_ON) as DelegationListRequest.SortField;
    }
    set sortBy(value: DelegationListRequest.SortField) {
        pb_1.Message.setField(this, 8, value);
    }
    get sortOrder() {
        return pb_1.Message.getFieldWithDefault(this, 9, dependency_2.SortOrder.SORT_ORDER_DESC) as dependency_2.SortOrder;
    }
    set sortOrder(value: dependency_2.SortOrder) {
        pb_1.Message.setField(this, 9, value);
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "all" | "txId" | "active";
        } = {
            0: "none",
            2: "all",
            3: "txId",
            4: "active"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2, 3, 4])]!;
    }
    static fromObject(data?: DelegationListRequest.AsObjectPartial): DelegationListRequest {
        if (!data) {
            return new DelegationListRequest();
        }
        const message = new DelegationListRequest({});
        if (data.all != null) {
            message.all = data.all;
        }
        if (data.txId != null) {
            message.txId = data.txId;
        }
        if (data.active != null) {
            message.active = data.active;
        }
        if (data.page != null) {
            message.page = dependency_2.Page.fromObject(data.page);
        }
        if (data.sortBy != null) {
            message.sortBy = data.sortBy;
        }
        if (data.sortOrder != null) {
            message.sortOrder = data.sortOrder;
        }
        return message;
    }
    toObject() {
        const data: DelegationListRequest.AsObject = {
            all: this.all,
            txId: this.txId,
            active: this.active,
            sortBy: this.sortBy,
            sortOrder: this.sortOrder
        };
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasAll)
            writer.writeBool(2, this.all);
        if (this.hasTxId)
            writer.writeString(3, this.txId);
        if (this.hasActive)
            writer.writeBool(4, this.active);
        if (this.hasPage)
            writer.writeMessage(1, this.page, () => this.page!.serialize(writer));
        if (this.sortBy != DelegationListRequest.SortField.SORT_FIELD_STARTED_ON)
            writer.writeEnum(8, this.sortBy);
        if (this.sortOrder != dependency_2.SortOrder.SORT_ORDER_DESC)
            writer.writeEnum(9, this.sortOrder);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DelegationListRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DelegationListRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 2:
                    message.all = reader.readBool();
                    break;
                case 3:
                    message.txId = reader.readString();
                    break;
                case 4:
                    message.active = reader.readBool();
                    break;
                case 1:
                    reader.readMessage(message.page, () => message.page = dependency_2.Page.deserialize(reader));
                    break;
                case 8:
                    message.sortBy = reader.readEnum();
                    break;
                case 9:
                    message.sortOrder = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DelegationListRequest {
        return DelegationListRequest.deserialize(bytes);
    }
}
export namespace DelegationListRequest {
    export type AsObject = {
        all: boolean;
        txId: string;
        active: boolean;
        page?: dependency_2.Page.AsObject;
        sortBy: DelegationListRequest.SortField;
        sortOrder: dependency_2.SortOrder;
    };
    export type AsObjectPartial = {
        all?: boolean;
        txId?: string;
        active?: boolean;
        page?: dependency_2.Page.AsObjectPartial;
        sortBy?: DelegationListRequest.SortField;
        sortOrder?: dependency_2.SortOrder;
    };
    export enum SortField {
        SORT_FIELD_STARTED_ON = 0,
        SORT_FIELD_AMOUNT = 1,
        SORT_FIELD_POTENTIAL_REWARD = 2,
        SORT_FIELD_RESULT_REWARD = 3,
        SORT_FIELD_ENDS_ON = 4
    }
}
export class DelegationListResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        delegators?: Validator.Delegator[];
        navigation?: dependency_2.NavigationData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("delegators" in data && data.delegators != undefined) {
                this.delegators = data.delegators;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
        }
    }
    get delegators() {
        return pb_1.Message.getRepeatedWrapperField(this, Validator.Delegator, 1) as Validator.Delegator[];
    }
    set delegators(value: Validator.Delegator[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_2.NavigationData, 2) as dependency_2.NavigationData | undefined;
    }
    set navigation(value: dependency_2.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    static fromObject(data?: DelegationListResponse.AsObjectPartial): DelegationListResponse {
        if (!data) {
            return new DelegationListResponse();
        }
        const message = new DelegationListResponse({});
        if (data.delegators != null) {
            message.delegators = data.delegators.map(item => Validator.Delegator.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_2.NavigationData.fromObject(data.navigation);
        }
        return message;
    }
    toObject() {
        const data: DelegationListResponse.AsObject = {
            delegators: this.delegators.map((item: Validator.Delegator) => item.toObject())
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.delegators.length)
            writer.writeRepeatedMessage(1, this.delegators, (item: Validator.Delegator) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(2, this.navigation, () => this.navigation!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DelegationListResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DelegationListResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.delegators, () => pb_1.Message.addToRepeatedWrapperField(message, 1, Validator.Delegator.deserialize(reader), Validator.Delegator));
                    break;
                case 2:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_2.NavigationData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DelegationListResponse {
        return DelegationListResponse.deserialize(bytes);
    }
}
export namespace DelegationListResponse {
    export type AsObject = {
        delegators: Validator.Delegator.AsObject[];
        navigation?: dependency_2.NavigationData.AsObject;
    };
    export type AsObjectPartial = {
        delegators?: Validator.Delegator.AsObjectPartial[];
        navigation?: dependency_2.NavigationData.AsObjectPartial;
    };
}
export class ValidatorAddRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        validator?: Validator;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("validator" in data && data.validator != undefined) {
                this.validator = data.validator;
            }
        }
    }
    get validator() {
        return pb_1.Message.getWrapperField(this, Validator, 1) as Validator | undefined;
    }
    set validator(value: Validator | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasValidator() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearValidator(): void {
        this.validator = undefined!;
    }
    static fromObject(data?: ValidatorAddRequest.AsObjectPartial): ValidatorAddRequest {
        if (!data) {
            return new ValidatorAddRequest();
        }
        const message = new ValidatorAddRequest({});
        if (data.validator != null) {
            message.validator = Validator.fromObject(data.validator);
        }
        return message;
    }
    toObject() {
        const data: ValidatorAddRequest.AsObject = {};
        if (this.validator != null) {
            data.validator = this.validator.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasValidator)
            writer.writeMessage(1, this.validator, () => this.validator!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ValidatorAddRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ValidatorAddRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.validator, () => message.validator = Validator.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ValidatorAddRequest {
        return ValidatorAddRequest.deserialize(bytes);
    }
}
export namespace ValidatorAddRequest {
    export type AsObject = {
        validator?: Validator.AsObject;
    };
    export type AsObjectPartial = {
        validator?: Validator.AsObjectPartial;
    };
}
export class ValidatorAddResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        validator?: Validator;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("validator" in data && data.validator != undefined) {
                this.validator = data.validator;
            }
        }
    }
    get validator() {
        return pb_1.Message.getWrapperField(this, Validator, 1) as Validator | undefined;
    }
    set validator(value: Validator | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasValidator() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearValidator(): void {
        this.validator = undefined!;
    }
    static fromObject(data?: ValidatorAddResponse.AsObjectPartial): ValidatorAddResponse {
        if (!data) {
            return new ValidatorAddResponse();
        }
        const message = new ValidatorAddResponse({});
        if (data.validator != null) {
            message.validator = Validator.fromObject(data.validator);
        }
        return message;
    }
    toObject() {
        const data: ValidatorAddResponse.AsObject = {};
        if (this.validator != null) {
            data.validator = this.validator.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasValidator)
            writer.writeMessage(1, this.validator, () => this.validator!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ValidatorAddResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ValidatorAddResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.validator, () => message.validator = Validator.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ValidatorAddResponse {
        return ValidatorAddResponse.deserialize(bytes);
    }
}
export namespace ValidatorAddResponse {
    export type AsObject = {
        validator?: Validator.AsObject;
    };
    export type AsObjectPartial = {
        validator?: Validator.AsObjectPartial;
    };
}
export class ValidatorAddSupernetRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        nodeId?: string;
        supernetValidator?: Validator.SupernetValidator;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("nodeId" in data && data.nodeId != undefined) {
                this.nodeId = data.nodeId;
            }
            if ("supernetValidator" in data && data.supernetValidator != undefined) {
                this.supernetValidator = data.supernetValidator;
            }
        }
    }
    get nodeId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set nodeId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get supernetValidator() {
        return pb_1.Message.getWrapperField(this, Validator.SupernetValidator, 2) as Validator.SupernetValidator | undefined;
    }
    set supernetValidator(value: Validator.SupernetValidator | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasSupernetValidator() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearSupernetValidator(): void {
        this.supernetValidator = undefined!;
    }
    static fromObject(data?: ValidatorAddSupernetRequest.AsObjectPartial): ValidatorAddSupernetRequest {
        if (!data) {
            return new ValidatorAddSupernetRequest();
        }
        const message = new ValidatorAddSupernetRequest({});
        if (data.nodeId != null) {
            message.nodeId = data.nodeId;
        }
        if (data.supernetValidator != null) {
            message.supernetValidator = Validator.SupernetValidator.fromObject(data.supernetValidator);
        }
        return message;
    }
    toObject() {
        const data: ValidatorAddSupernetRequest.AsObject = {
            nodeId: this.nodeId
        };
        if (this.supernetValidator != null) {
            data.supernetValidator = this.supernetValidator.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.nodeId.length)
            writer.writeString(1, this.nodeId);
        if (this.hasSupernetValidator)
            writer.writeMessage(2, this.supernetValidator, () => this.supernetValidator!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ValidatorAddSupernetRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ValidatorAddSupernetRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.nodeId = reader.readString();
                    break;
                case 2:
                    reader.readMessage(message.supernetValidator, () => message.supernetValidator = Validator.SupernetValidator.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ValidatorAddSupernetRequest {
        return ValidatorAddSupernetRequest.deserialize(bytes);
    }
}
export namespace ValidatorAddSupernetRequest {
    export type AsObject = {
        nodeId: string;
        supernetValidator?: Validator.SupernetValidator.AsObject;
    };
    export type AsObjectPartial = {
        nodeId?: string;
        supernetValidator?: Validator.SupernetValidator.AsObjectPartial;
    };
}
export class ValidatorAddSupernetResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        validator?: Validator;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("validator" in data && data.validator != undefined) {
                this.validator = data.validator;
            }
        }
    }
    get validator() {
        return pb_1.Message.getWrapperField(this, Validator, 1) as Validator | undefined;
    }
    set validator(value: Validator | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasValidator() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearValidator(): void {
        this.validator = undefined!;
    }
    static fromObject(data?: ValidatorAddSupernetResponse.AsObjectPartial): ValidatorAddSupernetResponse {
        if (!data) {
            return new ValidatorAddSupernetResponse();
        }
        const message = new ValidatorAddSupernetResponse({});
        if (data.validator != null) {
            message.validator = Validator.fromObject(data.validator);
        }
        return message;
    }
    toObject() {
        const data: ValidatorAddSupernetResponse.AsObject = {};
        if (this.validator != null) {
            data.validator = this.validator.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasValidator)
            writer.writeMessage(1, this.validator, () => this.validator!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ValidatorAddSupernetResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ValidatorAddSupernetResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.validator, () => message.validator = Validator.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ValidatorAddSupernetResponse {
        return ValidatorAddSupernetResponse.deserialize(bytes);
    }
}
export namespace ValidatorAddSupernetResponse {
    export type AsObject = {
        validator?: Validator.AsObject;
    };
    export type AsObjectPartial = {
        validator?: Validator.AsObjectPartial;
    };
}
export class ValidatorRemoveSupernetRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        nodeId?: string;
        supernetId?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("nodeId" in data && data.nodeId != undefined) {
                this.nodeId = data.nodeId;
            }
            if ("supernetId" in data && data.supernetId != undefined) {
                this.supernetId = data.supernetId;
            }
        }
    }
    get nodeId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set nodeId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get supernetId() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set supernetId(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: ValidatorRemoveSupernetRequest.AsObjectPartial): ValidatorRemoveSupernetRequest {
        if (!data) {
            return new ValidatorRemoveSupernetRequest();
        }
        const message = new ValidatorRemoveSupernetRequest({});
        if (data.nodeId != null) {
            message.nodeId = data.nodeId;
        }
        if (data.supernetId != null) {
            message.supernetId = data.supernetId;
        }
        return message;
    }
    toObject() {
        const data: ValidatorRemoveSupernetRequest.AsObject = {
            nodeId: this.nodeId,
            supernetId: this.supernetId
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.nodeId.length)
            writer.writeString(1, this.nodeId);
        if (this.supernetId.length)
            writer.writeString(2, this.supernetId);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ValidatorRemoveSupernetRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ValidatorRemoveSupernetRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.nodeId = reader.readString();
                    break;
                case 2:
                    message.supernetId = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ValidatorRemoveSupernetRequest {
        return ValidatorRemoveSupernetRequest.deserialize(bytes);
    }
}
export namespace ValidatorRemoveSupernetRequest {
    export type AsObject = {
        nodeId: string;
        supernetId: string;
    };
    export type AsObjectPartial = {
        nodeId?: string;
        supernetId?: string;
    };
}
export class ValidatorRemoveSupernetResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        validator?: Validator;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("validator" in data && data.validator != undefined) {
                this.validator = data.validator;
            }
        }
    }
    get validator() {
        return pb_1.Message.getWrapperField(this, Validator, 1) as Validator | undefined;
    }
    set validator(value: Validator | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasValidator() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearValidator(): void {
        this.validator = undefined!;
    }
    static fromObject(data?: ValidatorRemoveSupernetResponse.AsObjectPartial): ValidatorRemoveSupernetResponse {
        if (!data) {
            return new ValidatorRemoveSupernetResponse();
        }
        const message = new ValidatorRemoveSupernetResponse({});
        if (data.validator != null) {
            message.validator = Validator.fromObject(data.validator);
        }
        return message;
    }
    toObject() {
        const data: ValidatorRemoveSupernetResponse.AsObject = {};
        if (this.validator != null) {
            data.validator = this.validator.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasValidator)
            writer.writeMessage(1, this.validator, () => this.validator!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ValidatorRemoveSupernetResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ValidatorRemoveSupernetResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.validator, () => message.validator = Validator.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ValidatorRemoveSupernetResponse {
        return ValidatorRemoveSupernetResponse.deserialize(bytes);
    }
}
export namespace ValidatorRemoveSupernetResponse {
    export type AsObject = {
        validator?: Validator.AsObject;
    };
    export type AsObjectPartial = {
        validator?: Validator.AsObjectPartial;
    };
}
export class DelegatorAddRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        nodeId?: string;
        delegator?: Validator.Delegator;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("nodeId" in data && data.nodeId != undefined) {
                this.nodeId = data.nodeId;
            }
            if ("delegator" in data && data.delegator != undefined) {
                this.delegator = data.delegator;
            }
        }
    }
    get nodeId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set nodeId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get delegator() {
        return pb_1.Message.getWrapperField(this, Validator.Delegator, 2) as Validator.Delegator | undefined;
    }
    set delegator(value: Validator.Delegator | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasDelegator() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearDelegator(): void {
        this.delegator = undefined!;
    }
    static fromObject(data?: DelegatorAddRequest.AsObjectPartial): DelegatorAddRequest {
        if (!data) {
            return new DelegatorAddRequest();
        }
        const message = new DelegatorAddRequest({});
        if (data.nodeId != null) {
            message.nodeId = data.nodeId;
        }
        if (data.delegator != null) {
            message.delegator = Validator.Delegator.fromObject(data.delegator);
        }
        return message;
    }
    toObject() {
        const data: DelegatorAddRequest.AsObject = {
            nodeId: this.nodeId
        };
        if (this.delegator != null) {
            data.delegator = this.delegator.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.nodeId.length)
            writer.writeString(1, this.nodeId);
        if (this.hasDelegator)
            writer.writeMessage(2, this.delegator, () => this.delegator!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DelegatorAddRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DelegatorAddRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.nodeId = reader.readString();
                    break;
                case 2:
                    reader.readMessage(message.delegator, () => message.delegator = Validator.Delegator.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DelegatorAddRequest {
        return DelegatorAddRequest.deserialize(bytes);
    }
}
export namespace DelegatorAddRequest {
    export type AsObject = {
        nodeId: string;
        delegator?: Validator.Delegator.AsObject;
    };
    export type AsObjectPartial = {
        nodeId?: string;
        delegator?: Validator.Delegator.AsObjectPartial;
    };
}
export class DelegatorAddResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        validator?: Validator;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("validator" in data && data.validator != undefined) {
                this.validator = data.validator;
            }
        }
    }
    get validator() {
        return pb_1.Message.getWrapperField(this, Validator, 1) as Validator | undefined;
    }
    set validator(value: Validator | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasValidator() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearValidator(): void {
        this.validator = undefined!;
    }
    static fromObject(data?: DelegatorAddResponse.AsObjectPartial): DelegatorAddResponse {
        if (!data) {
            return new DelegatorAddResponse();
        }
        const message = new DelegatorAddResponse({});
        if (data.validator != null) {
            message.validator = Validator.fromObject(data.validator);
        }
        return message;
    }
    toObject() {
        const data: DelegatorAddResponse.AsObject = {};
        if (this.validator != null) {
            data.validator = this.validator.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasValidator)
            writer.writeMessage(1, this.validator, () => this.validator!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DelegatorAddResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DelegatorAddResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.validator, () => message.validator = Validator.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DelegatorAddResponse {
        return DelegatorAddResponse.deserialize(bytes);
    }
}
export namespace DelegatorAddResponse {
    export type AsObject = {
        validator?: Validator.AsObject;
    };
    export type AsObjectPartial = {
        validator?: Validator.AsObjectPartial;
    };
}
export class ValidatorUpdateRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        nodeId?: string;
        potentialReward?: string;
        uptime?: number;
        connected?: boolean;
        delegatorReward?: ValidatorUpdateRequest.DelegatorPotentialReward[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [5], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("nodeId" in data && data.nodeId != undefined) {
                this.nodeId = data.nodeId;
            }
            if ("potentialReward" in data && data.potentialReward != undefined) {
                this.potentialReward = data.potentialReward;
            }
            if ("uptime" in data && data.uptime != undefined) {
                this.uptime = data.uptime;
            }
            if ("connected" in data && data.connected != undefined) {
                this.connected = data.connected;
            }
            if ("delegatorReward" in data && data.delegatorReward != undefined) {
                this.delegatorReward = data.delegatorReward;
            }
        }
    }
    get nodeId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set nodeId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get potentialReward() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set potentialReward(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get uptime() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set uptime(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get connected() {
        return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
    }
    set connected(value: boolean) {
        pb_1.Message.setField(this, 4, value);
    }
    get delegatorReward() {
        return pb_1.Message.getRepeatedWrapperField(this, ValidatorUpdateRequest.DelegatorPotentialReward, 5) as ValidatorUpdateRequest.DelegatorPotentialReward[];
    }
    set delegatorReward(value: ValidatorUpdateRequest.DelegatorPotentialReward[]) {
        pb_1.Message.setRepeatedWrapperField(this, 5, value);
    }
    static fromObject(data?: ValidatorUpdateRequest.AsObjectPartial): ValidatorUpdateRequest {
        if (!data) {
            return new ValidatorUpdateRequest();
        }
        const message = new ValidatorUpdateRequest({});
        if (data.nodeId != null) {
            message.nodeId = data.nodeId;
        }
        if (data.potentialReward != null) {
            message.potentialReward = data.potentialReward;
        }
        if (data.uptime != null) {
            message.uptime = data.uptime;
        }
        if (data.connected != null) {
            message.connected = data.connected;
        }
        if (data.delegatorReward != null) {
            message.delegatorReward = data.delegatorReward.map(item => ValidatorUpdateRequest.DelegatorPotentialReward.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: ValidatorUpdateRequest.AsObject = {
            nodeId: this.nodeId,
            potentialReward: this.potentialReward,
            uptime: this.uptime,
            connected: this.connected,
            delegatorReward: this.delegatorReward.map((item: ValidatorUpdateRequest.DelegatorPotentialReward) => item.toObject())
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.nodeId.length)
            writer.writeString(1, this.nodeId);
        if (this.potentialReward.length)
            writer.writeString(2, this.potentialReward);
        if (this.uptime != 0)
            writer.writeUint32(3, this.uptime);
        if (this.connected != false)
            writer.writeBool(4, this.connected);
        if (this.delegatorReward.length)
            writer.writeRepeatedMessage(5, this.delegatorReward, (item: ValidatorUpdateRequest.DelegatorPotentialReward) => item!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ValidatorUpdateRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ValidatorUpdateRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.nodeId = reader.readString();
                    break;
                case 2:
                    message.potentialReward = reader.readString();
                    break;
                case 3:
                    message.uptime = reader.readUint32();
                    break;
                case 4:
                    message.connected = reader.readBool();
                    break;
                case 5:
                    reader.readMessage(message.delegatorReward, () => pb_1.Message.addToRepeatedWrapperField(message, 5, ValidatorUpdateRequest.DelegatorPotentialReward.deserialize(reader), ValidatorUpdateRequest.DelegatorPotentialReward));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ValidatorUpdateRequest {
        return ValidatorUpdateRequest.deserialize(bytes);
    }
}
export namespace ValidatorUpdateRequest {
    export type AsObject = {
        nodeId: string;
        potentialReward: string;
        uptime: number;
        connected: boolean;
        delegatorReward: ValidatorUpdateRequest.DelegatorPotentialReward.AsObject[];
    };
    export type AsObjectPartial = {
        nodeId?: string;
        potentialReward?: string;
        uptime?: number;
        connected?: boolean;
        delegatorReward?: ValidatorUpdateRequest.DelegatorPotentialReward.AsObjectPartial[];
    };
    export class DelegatorPotentialReward extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            txid?: string;
            potentialReward?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("txid" in data && data.txid != undefined) {
                    this.txid = data.txid;
                }
                if ("potentialReward" in data && data.potentialReward != undefined) {
                    this.potentialReward = data.potentialReward;
                }
            }
        }
        get txid() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set txid(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get potentialReward() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set potentialReward(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data?: DelegatorPotentialReward.AsObjectPartial): DelegatorPotentialReward {
            if (!data) {
                return new DelegatorPotentialReward();
            }
            const message = new DelegatorPotentialReward({});
            if (data.txid != null) {
                message.txid = data.txid;
            }
            if (data.potentialReward != null) {
                message.potentialReward = data.potentialReward;
            }
            return message;
        }
        toObject() {
            const data: DelegatorPotentialReward.AsObject = {
                txid: this.txid,
                potentialReward: this.potentialReward
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.txid.length)
                writer.writeString(1, this.txid);
            if (this.potentialReward.length)
                writer.writeString(2, this.potentialReward);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DelegatorPotentialReward {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DelegatorPotentialReward();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.txid = reader.readString();
                        break;
                    case 2:
                        message.potentialReward = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): DelegatorPotentialReward {
            return DelegatorPotentialReward.deserialize(bytes);
        }
    }
    export namespace DelegatorPotentialReward {
        export type AsObject = {
            txid: string;
            potentialReward: string;
        };
        export type AsObjectPartial = {
            txid?: string;
            potentialReward?: string;
        };
    }
}
export class ValidatorUpdateResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        validator?: Validator;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("validator" in data && data.validator != undefined) {
                this.validator = data.validator;
            }
        }
    }
    get validator() {
        return pb_1.Message.getWrapperField(this, Validator, 1) as Validator | undefined;
    }
    set validator(value: Validator | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasValidator() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearValidator(): void {
        this.validator = undefined!;
    }
    static fromObject(data?: ValidatorUpdateResponse.AsObjectPartial): ValidatorUpdateResponse {
        if (!data) {
            return new ValidatorUpdateResponse();
        }
        const message = new ValidatorUpdateResponse({});
        if (data.validator != null) {
            message.validator = Validator.fromObject(data.validator);
        }
        return message;
    }
    toObject() {
        const data: ValidatorUpdateResponse.AsObject = {};
        if (this.validator != null) {
            data.validator = this.validator.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasValidator)
            writer.writeMessage(1, this.validator, () => this.validator!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ValidatorUpdateResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ValidatorUpdateResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.validator, () => message.validator = Validator.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ValidatorUpdateResponse {
        return ValidatorUpdateResponse.deserialize(bytes);
    }
}
export namespace ValidatorUpdateResponse {
    export type AsObject = {
        validator?: Validator.AsObject;
    };
    export type AsObjectPartial = {
        validator?: Validator.AsObjectPartial;
    };
}
export class SaveValidatorRewardRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        txid?: string;
        reward?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("txid" in data && data.txid != undefined) {
                this.txid = data.txid;
            }
            if ("reward" in data && data.reward != undefined) {
                this.reward = data.reward;
            }
        }
    }
    get txid() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set txid(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get reward() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set reward(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: SaveValidatorRewardRequest.AsObjectPartial): SaveValidatorRewardRequest {
        if (!data) {
            return new SaveValidatorRewardRequest();
        }
        const message = new SaveValidatorRewardRequest({});
        if (data.txid != null) {
            message.txid = data.txid;
        }
        if (data.reward != null) {
            message.reward = data.reward;
        }
        return message;
    }
    toObject() {
        const data: SaveValidatorRewardRequest.AsObject = {
            txid: this.txid,
            reward: this.reward
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.txid.length)
            writer.writeString(1, this.txid);
        if (this.reward.length)
            writer.writeString(2, this.reward);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SaveValidatorRewardRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SaveValidatorRewardRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.txid = reader.readString();
                    break;
                case 2:
                    message.reward = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SaveValidatorRewardRequest {
        return SaveValidatorRewardRequest.deserialize(bytes);
    }
}
export namespace SaveValidatorRewardRequest {
    export type AsObject = {
        txid: string;
        reward: string;
    };
    export type AsObjectPartial = {
        txid?: string;
        reward?: string;
    };
}
export class SaveValidatorRewardResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        validator?: Validator;
        delegator?: Validator.Delegator;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("validator" in data && data.validator != undefined) {
                this.validator = data.validator;
            }
            if ("delegator" in data && data.delegator != undefined) {
                this.delegator = data.delegator;
            }
        }
    }
    get validator() {
        return pb_1.Message.getWrapperField(this, Validator, 1) as Validator | undefined;
    }
    set validator(value: Validator | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasValidator() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearValidator(): void {
        this.validator = undefined!;
    }
    get delegator() {
        return pb_1.Message.getWrapperField(this, Validator.Delegator, 2) as Validator.Delegator | undefined;
    }
    set delegator(value: Validator.Delegator | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasDelegator() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearDelegator(): void {
        this.delegator = undefined!;
    }
    static fromObject(data?: SaveValidatorRewardResponse.AsObjectPartial): SaveValidatorRewardResponse {
        if (!data) {
            return new SaveValidatorRewardResponse();
        }
        const message = new SaveValidatorRewardResponse({});
        if (data.validator != null) {
            message.validator = Validator.fromObject(data.validator);
        }
        if (data.delegator != null) {
            message.delegator = Validator.Delegator.fromObject(data.delegator);
        }
        return message;
    }
    toObject() {
        const data: SaveValidatorRewardResponse.AsObject = {};
        if (this.validator != null) {
            data.validator = this.validator.toObject();
        }
        if (this.delegator != null) {
            data.delegator = this.delegator.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasValidator)
            writer.writeMessage(1, this.validator, () => this.validator!.serialize(writer));
        if (this.hasDelegator)
            writer.writeMessage(2, this.delegator, () => this.delegator!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SaveValidatorRewardResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SaveValidatorRewardResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.validator, () => message.validator = Validator.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.delegator, () => message.delegator = Validator.Delegator.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SaveValidatorRewardResponse {
        return SaveValidatorRewardResponse.deserialize(bytes);
    }
}
export namespace SaveValidatorRewardResponse {
    export type AsObject = {
        validator?: Validator.AsObject;
        delegator?: Validator.Delegator.AsObject;
    };
    export type AsObjectPartial = {
        validator?: Validator.AsObjectPartial;
        delegator?: Validator.Delegator.AsObjectPartial;
    };
}
export class SupernetsValidatorsRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data?: SupernetsValidatorsRequest.AsObjectPartial): SupernetsValidatorsRequest {
        if (!data) {
            return new SupernetsValidatorsRequest();
        }
        const message = new SupernetsValidatorsRequest({});
        return message;
    }
    toObject() {
        const data: SupernetsValidatorsRequest.AsObject = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SupernetsValidatorsRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SupernetsValidatorsRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SupernetsValidatorsRequest {
        return SupernetsValidatorsRequest.deserialize(bytes);
    }
}
export namespace SupernetsValidatorsRequest {
    export type AsObject = {};
    export type AsObjectPartial = {};
}
export class SupernetsValidatorResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        validators?: Map<string, number>;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("validators" in data && data.validators != undefined) {
                this.validators = data.validators;
            }
        }
        if (!this.validators)
            this.validators = new Map();
    }
    get validators() {
        return pb_1.Message.getField(this, 1) as any as Map<string, number>;
    }
    set validators(value: Map<string, number>) {
        pb_1.Message.setField(this, 1, value as any);
    }
    static fromObject(data?: SupernetsValidatorResponse.AsObjectPartial): SupernetsValidatorResponse {
        if (!data) {
            return new SupernetsValidatorResponse();
        }
        const message = new SupernetsValidatorResponse({});
        if (typeof data.validators == "object") {
            message.validators = new Map(Object.entries(data.validators));
        }
        return message;
    }
    toObject() {
        const data: SupernetsValidatorResponse.AsObject = {
            validators: (Object.fromEntries)(this.validators)
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        for (const [key, value] of this.validators) {
            writer.writeMessage(1, this.validators, () => {
                writer.writeString(1, key);
                writer.writeInt64(2, value);
            });
        }
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SupernetsValidatorResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SupernetsValidatorResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.validators as any, reader, reader.readString, reader.readInt64));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SupernetsValidatorResponse {
        return SupernetsValidatorResponse.deserialize(bytes);
    }
}
export namespace SupernetsValidatorResponse {
    export type AsObject = {
        validators: {
            [key: string]: number;
        };
    };
    export type AsObjectPartial = {
        validators?: {
            [key: string]: number;
        };
    };
}
export class StakesRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        rewardOwners?: string[];
        page?: dependency_2.Page;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("rewardOwners" in data && data.rewardOwners != undefined) {
                this.rewardOwners = data.rewardOwners;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get rewardOwners() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as string[];
    }
    set rewardOwners(value: string[]) {
        pb_1.Message.setField(this, 1, value);
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_2.Page, 2) as dependency_2.Page | undefined;
    }
    set page(value: dependency_2.Page | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    static fromObject(data?: StakesRequest.AsObjectPartial): StakesRequest {
        if (!data) {
            return new StakesRequest();
        }
        const message = new StakesRequest({});
        if (data.rewardOwners != null) {
            message.rewardOwners = data.rewardOwners;
        }
        if (data.page != null) {
            message.page = dependency_2.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: StakesRequest.AsObject = {
            rewardOwners: this.rewardOwners
        };
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.rewardOwners.length)
            writer.writeRepeatedString(1, this.rewardOwners);
        if (this.hasPage)
            writer.writeMessage(2, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): StakesRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new StakesRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    pb_1.Message.addToRepeatedField(message, 1, reader.readString());
                    break;
                case 2:
                    reader.readMessage(message.page, () => message.page = dependency_2.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): StakesRequest {
        return StakesRequest.deserialize(bytes);
    }
}
export namespace StakesRequest {
    export type AsObject = {
        rewardOwners: string[];
        page?: dependency_2.Page.AsObject;
    };
    export type AsObjectPartial = {
        rewardOwners?: string[];
        page?: dependency_2.Page.AsObjectPartial;
    };
}
export class StakesResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        list?: StakesResponse.Stake[];
        navigation?: dependency_2.NavigationData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("list" in data && data.list != undefined) {
                this.list = data.list;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
        }
    }
    get list() {
        return pb_1.Message.getRepeatedWrapperField(this, StakesResponse.Stake, 1) as StakesResponse.Stake[];
    }
    set list(value: StakesResponse.Stake[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_2.NavigationData, 2) as dependency_2.NavigationData | undefined;
    }
    set navigation(value: dependency_2.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    static fromObject(data?: StakesResponse.AsObjectPartial): StakesResponse {
        if (!data) {
            return new StakesResponse();
        }
        const message = new StakesResponse({});
        if (data.list != null) {
            message.list = data.list.map(item => StakesResponse.Stake.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_2.NavigationData.fromObject(data.navigation);
        }
        return message;
    }
    toObject() {
        const data: StakesResponse.AsObject = {
            list: this.list.map((item: StakesResponse.Stake) => item.toObject())
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.list.length)
            writer.writeRepeatedMessage(1, this.list, (item: StakesResponse.Stake) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(2, this.navigation, () => this.navigation!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): StakesResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new StakesResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.list, () => pb_1.Message.addToRepeatedWrapperField(message, 1, StakesResponse.Stake.deserialize(reader), StakesResponse.Stake));
                    break;
                case 2:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_2.NavigationData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): StakesResponse {
        return StakesResponse.deserialize(bytes);
    }
}
export namespace StakesResponse {
    export type AsObject = {
        list: StakesResponse.Stake.AsObject[];
        navigation?: dependency_2.NavigationData.AsObject;
    };
    export type AsObjectPartial = {
        list?: StakesResponse.Stake.AsObjectPartial[];
        navigation?: dependency_2.NavigationData.AsObjectPartial;
    };
    export class Stake extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            Kind?: StakesResponse.Stake.StakeKind;
            TxID?: string;
            StartTime?: number;
            EndTime?: number;
            StakeAmount?: string;
            PotentialReward?: string;
            NodeID?: string;
            Connected?: boolean;
            Uptime?: number;
            ResultReward?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("Kind" in data && data.Kind != undefined) {
                    this.Kind = data.Kind;
                }
                if ("TxID" in data && data.TxID != undefined) {
                    this.TxID = data.TxID;
                }
                if ("StartTime" in data && data.StartTime != undefined) {
                    this.StartTime = data.StartTime;
                }
                if ("EndTime" in data && data.EndTime != undefined) {
                    this.EndTime = data.EndTime;
                }
                if ("StakeAmount" in data && data.StakeAmount != undefined) {
                    this.StakeAmount = data.StakeAmount;
                }
                if ("PotentialReward" in data && data.PotentialReward != undefined) {
                    this.PotentialReward = data.PotentialReward;
                }
                if ("NodeID" in data && data.NodeID != undefined) {
                    this.NodeID = data.NodeID;
                }
                if ("Connected" in data && data.Connected != undefined) {
                    this.Connected = data.Connected;
                }
                if ("Uptime" in data && data.Uptime != undefined) {
                    this.Uptime = data.Uptime;
                }
                if ("ResultReward" in data && data.ResultReward != undefined) {
                    this.ResultReward = data.ResultReward;
                }
            }
        }
        get Kind() {
            return pb_1.Message.getFieldWithDefault(this, 1, StakesResponse.Stake.StakeKind.KindValidation) as StakesResponse.Stake.StakeKind;
        }
        set Kind(value: StakesResponse.Stake.StakeKind) {
            pb_1.Message.setField(this, 1, value);
        }
        get TxID() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set TxID(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get StartTime() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set StartTime(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get EndTime() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set EndTime(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get StakeAmount() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set StakeAmount(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get PotentialReward() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set PotentialReward(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        get NodeID() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set NodeID(value: string) {
            pb_1.Message.setField(this, 7, value);
        }
        get Connected() {
            return pb_1.Message.getFieldWithDefault(this, 8, false) as boolean;
        }
        set Connected(value: boolean) {
            pb_1.Message.setField(this, 8, value);
        }
        get Uptime() {
            return pb_1.Message.getFieldWithDefault(this, 9, 0) as number;
        }
        set Uptime(value: number) {
            pb_1.Message.setField(this, 9, value);
        }
        get ResultReward() {
            return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
        }
        set ResultReward(value: string) {
            pb_1.Message.setField(this, 10, value);
        }
        static fromObject(data?: Stake.AsObjectPartial): Stake {
            if (!data) {
                return new Stake();
            }
            const message = new Stake({});
            if (data.Kind != null) {
                message.Kind = data.Kind;
            }
            if (data.TxID != null) {
                message.TxID = data.TxID;
            }
            if (data.StartTime != null) {
                message.StartTime = data.StartTime;
            }
            if (data.EndTime != null) {
                message.EndTime = data.EndTime;
            }
            if (data.StakeAmount != null) {
                message.StakeAmount = data.StakeAmount;
            }
            if (data.PotentialReward != null) {
                message.PotentialReward = data.PotentialReward;
            }
            if (data.NodeID != null) {
                message.NodeID = data.NodeID;
            }
            if (data.Connected != null) {
                message.Connected = data.Connected;
            }
            if (data.Uptime != null) {
                message.Uptime = data.Uptime;
            }
            if (data.ResultReward != null) {
                message.ResultReward = data.ResultReward;
            }
            return message;
        }
        toObject() {
            const data: Stake.AsObject = {
                Kind: this.Kind,
                TxID: this.TxID,
                StartTime: this.StartTime,
                EndTime: this.EndTime,
                StakeAmount: this.StakeAmount,
                PotentialReward: this.PotentialReward,
                NodeID: this.NodeID,
                Connected: this.Connected,
                Uptime: this.Uptime,
                ResultReward: this.ResultReward
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.Kind != StakesResponse.Stake.StakeKind.KindValidation)
                writer.writeEnum(1, this.Kind);
            if (this.TxID.length)
                writer.writeString(2, this.TxID);
            if (this.StartTime != 0)
                writer.writeInt64(3, this.StartTime);
            if (this.EndTime != 0)
                writer.writeInt64(4, this.EndTime);
            if (this.StakeAmount.length)
                writer.writeString(5, this.StakeAmount);
            if (this.PotentialReward.length)
                writer.writeString(6, this.PotentialReward);
            if (this.NodeID.length)
                writer.writeString(7, this.NodeID);
            if (this.Connected != false)
                writer.writeBool(8, this.Connected);
            if (this.Uptime != 0)
                writer.writeInt32(9, this.Uptime);
            if (this.ResultReward.length)
                writer.writeString(10, this.ResultReward);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Stake {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Stake();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.Kind = reader.readEnum();
                        break;
                    case 2:
                        message.TxID = reader.readString();
                        break;
                    case 3:
                        message.StartTime = reader.readInt64();
                        break;
                    case 4:
                        message.EndTime = reader.readInt64();
                        break;
                    case 5:
                        message.StakeAmount = reader.readString();
                        break;
                    case 6:
                        message.PotentialReward = reader.readString();
                        break;
                    case 7:
                        message.NodeID = reader.readString();
                        break;
                    case 8:
                        message.Connected = reader.readBool();
                        break;
                    case 9:
                        message.Uptime = reader.readInt32();
                        break;
                    case 10:
                        message.ResultReward = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Stake {
            return Stake.deserialize(bytes);
        }
    }
    export namespace Stake {
        export type AsObject = {
            Kind: StakesResponse.Stake.StakeKind;
            TxID: string;
            StartTime: number;
            EndTime: number;
            StakeAmount: string;
            PotentialReward: string;
            NodeID: string;
            Connected: boolean;
            Uptime: number;
            ResultReward: string;
        };
        export type AsObjectPartial = {
            Kind?: StakesResponse.Stake.StakeKind;
            TxID?: string;
            StartTime?: number;
            EndTime?: number;
            StakeAmount?: string;
            PotentialReward?: string;
            NodeID?: string;
            Connected?: boolean;
            Uptime?: number;
            ResultReward?: string;
        };
        export enum StakeKind {
            KindValidation = 0,
            KindDelegation = 1
        }
    }
}
export class TotalStakeAmountRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        address?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
        }
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data?: TotalStakeAmountRequest.AsObjectPartial): TotalStakeAmountRequest {
        if (!data) {
            return new TotalStakeAmountRequest();
        }
        const message = new TotalStakeAmountRequest({});
        if (data.address != null) {
            message.address = data.address;
        }
        return message;
    }
    toObject() {
        const data: TotalStakeAmountRequest.AsObject = {
            address: this.address
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.address.length)
            writer.writeString(1, this.address);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TotalStakeAmountRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TotalStakeAmountRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.address = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TotalStakeAmountRequest {
        return TotalStakeAmountRequest.deserialize(bytes);
    }
}
export namespace TotalStakeAmountRequest {
    export type AsObject = {
        address: string;
    };
    export type AsObjectPartial = {
        address?: string;
    };
}
export class TotalStakeAmountResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        totalStakeAmount?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("totalStakeAmount" in data && data.totalStakeAmount != undefined) {
                this.totalStakeAmount = data.totalStakeAmount;
            }
        }
    }
    get totalStakeAmount() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set totalStakeAmount(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data?: TotalStakeAmountResponse.AsObjectPartial): TotalStakeAmountResponse {
        if (!data) {
            return new TotalStakeAmountResponse();
        }
        const message = new TotalStakeAmountResponse({});
        if (data.totalStakeAmount != null) {
            message.totalStakeAmount = data.totalStakeAmount;
        }
        return message;
    }
    toObject() {
        const data: TotalStakeAmountResponse.AsObject = {
            totalStakeAmount: this.totalStakeAmount
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.totalStakeAmount.length)
            writer.writeString(1, this.totalStakeAmount);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TotalStakeAmountResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TotalStakeAmountResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.totalStakeAmount = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TotalStakeAmountResponse {
        return TotalStakeAmountResponse.deserialize(bytes);
    }
}
export namespace TotalStakeAmountResponse {
    export type AsObject = {
        totalStakeAmount: string;
    };
    export type AsObjectPartial = {
        totalStakeAmount?: string;
    };
}
export class NodeDetailsUpdateRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        nodeId?: string;
        name?: string;
        icon?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("nodeId" in data && data.nodeId != undefined) {
                this.nodeId = data.nodeId;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("icon" in data && data.icon != undefined) {
                this.icon = data.icon;
            }
        }
    }
    get nodeId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set nodeId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get icon() {
        return pb_1.Message.getFieldWithDefault(this, 3, new Uint8Array()) as Uint8Array;
    }
    set icon(value: Uint8Array) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data?: NodeDetailsUpdateRequest.AsObjectPartial): NodeDetailsUpdateRequest {
        if (!data) {
            return new NodeDetailsUpdateRequest();
        }
        const message = new NodeDetailsUpdateRequest({});
        if (data.nodeId != null) {
            message.nodeId = data.nodeId;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.icon != null) {
            message.icon = data.icon;
        }
        return message;
    }
    toObject() {
        const data: NodeDetailsUpdateRequest.AsObject = {
            nodeId: this.nodeId,
            name: this.name,
            icon: this.icon
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.nodeId.length)
            writer.writeString(1, this.nodeId);
        if (this.name.length)
            writer.writeString(2, this.name);
        if (this.icon.length)
            writer.writeBytes(3, this.icon);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NodeDetailsUpdateRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NodeDetailsUpdateRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.nodeId = reader.readString();
                    break;
                case 2:
                    message.name = reader.readString();
                    break;
                case 3:
                    message.icon = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): NodeDetailsUpdateRequest {
        return NodeDetailsUpdateRequest.deserialize(bytes);
    }
}
export namespace NodeDetailsUpdateRequest {
    export type AsObject = {
        nodeId: string;
        name: string;
        icon: Uint8Array;
    };
    export type AsObjectPartial = {
        nodeId?: string;
        name?: string;
        icon?: Uint8Array;
    };
}
export class NodeDetailsUpdateResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        nodeId?: string;
        name?: string;
        iconPath?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("nodeId" in data && data.nodeId != undefined) {
                this.nodeId = data.nodeId;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("iconPath" in data && data.iconPath != undefined) {
                this.iconPath = data.iconPath;
            }
        }
    }
    get nodeId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set nodeId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get iconPath() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set iconPath(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data?: NodeDetailsUpdateResponse.AsObjectPartial): NodeDetailsUpdateResponse {
        if (!data) {
            return new NodeDetailsUpdateResponse();
        }
        const message = new NodeDetailsUpdateResponse({});
        if (data.nodeId != null) {
            message.nodeId = data.nodeId;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.iconPath != null) {
            message.iconPath = data.iconPath;
        }
        return message;
    }
    toObject() {
        const data: NodeDetailsUpdateResponse.AsObject = {
            nodeId: this.nodeId,
            name: this.name,
            iconPath: this.iconPath
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.nodeId.length)
            writer.writeString(1, this.nodeId);
        if (this.name.length)
            writer.writeString(2, this.name);
        if (this.iconPath.length)
            writer.writeString(3, this.iconPath);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NodeDetailsUpdateResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NodeDetailsUpdateResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.nodeId = reader.readString();
                    break;
                case 2:
                    message.name = reader.readString();
                    break;
                case 3:
                    message.iconPath = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): NodeDetailsUpdateResponse {
        return NodeDetailsUpdateResponse.deserialize(bytes);
    }
}
export namespace NodeDetailsUpdateResponse {
    export type AsObject = {
        nodeId: string;
        name: string;
        iconPath: string;
    };
    export type AsObjectPartial = {
        nodeId?: string;
        name?: string;
        iconPath?: string;
    };
}
export class NodeDetailsRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data?: NodeDetailsRequest.AsObjectPartial): NodeDetailsRequest {
        if (!data) {
            return new NodeDetailsRequest();
        }
        const message = new NodeDetailsRequest({});
        return message;
    }
    toObject() {
        const data: NodeDetailsRequest.AsObject = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NodeDetailsRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NodeDetailsRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): NodeDetailsRequest {
        return NodeDetailsRequest.deserialize(bytes);
    }
}
export namespace NodeDetailsRequest {
    export type AsObject = {};
    export type AsObjectPartial = {};
}
export class NodeDetails extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        nodeId?: string;
        name?: string;
        iconPath?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("nodeId" in data && data.nodeId != undefined) {
                this.nodeId = data.nodeId;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("iconPath" in data && data.iconPath != undefined) {
                this.iconPath = data.iconPath;
            }
        }
    }
    get nodeId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set nodeId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get iconPath() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set iconPath(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data?: NodeDetails.AsObjectPartial): NodeDetails {
        if (!data) {
            return new NodeDetails();
        }
        const message = new NodeDetails({});
        if (data.nodeId != null) {
            message.nodeId = data.nodeId;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.iconPath != null) {
            message.iconPath = data.iconPath;
        }
        return message;
    }
    toObject() {
        const data: NodeDetails.AsObject = {
            nodeId: this.nodeId,
            name: this.name,
            iconPath: this.iconPath
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.nodeId.length)
            writer.writeString(1, this.nodeId);
        if (this.name.length)
            writer.writeString(2, this.name);
        if (this.iconPath.length)
            writer.writeString(3, this.iconPath);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NodeDetails {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NodeDetails();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.nodeId = reader.readString();
                    break;
                case 2:
                    message.name = reader.readString();
                    break;
                case 3:
                    message.iconPath = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): NodeDetails {
        return NodeDetails.deserialize(bytes);
    }
}
export namespace NodeDetails {
    export type AsObject = {
        nodeId: string;
        name: string;
        iconPath: string;
    };
    export type AsObjectPartial = {
        nodeId?: string;
        name?: string;
        iconPath?: string;
    };
}
export class NodeDetailsResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        list?: NodeDetails[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("list" in data && data.list != undefined) {
                this.list = data.list;
            }
        }
    }
    get list() {
        return pb_1.Message.getRepeatedWrapperField(this, NodeDetails, 1) as NodeDetails[];
    }
    set list(value: NodeDetails[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data?: NodeDetailsResponse.AsObjectPartial): NodeDetailsResponse {
        if (!data) {
            return new NodeDetailsResponse();
        }
        const message = new NodeDetailsResponse({});
        if (data.list != null) {
            message.list = data.list.map(item => NodeDetails.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: NodeDetailsResponse.AsObject = {
            list: this.list.map((item: NodeDetails) => item.toObject())
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.list.length)
            writer.writeRepeatedMessage(1, this.list, (item: NodeDetails) => item!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NodeDetailsResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NodeDetailsResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.list, () => pb_1.Message.addToRepeatedWrapperField(message, 1, NodeDetails.deserialize(reader), NodeDetails));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): NodeDetailsResponse {
        return NodeDetailsResponse.deserialize(bytes);
    }
}
export namespace NodeDetailsResponse {
    export type AsObject = {
        list: NodeDetails.AsObject[];
    };
    export type AsObjectPartial = {
        list?: NodeDetails.AsObjectPartial[];
    };
}
