/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.13.0
 * source: erc1155.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./validate/validate";
import * as dependency_2 from "./models";
import * as pb_1 from "google-protobuf";
export class ERC1155Token extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        name?: string;
        symbol?: string;
        address?: string;
        holders?: number;
        transfersCount?: number;
        tokensCount?: string;
        site?: string;
        email?: string;
        twitter?: string;
        whitepaper?: string;
        coinmarketcap?: string;
        customName?: string;
        tags?: string[];
        blockchainIndex?: number;
        totalSupply?: string;
        transfersCount24h?: number;
        transfersCount2d?: number;
        iconPath?: string;
        disabled?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [12], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("symbol" in data && data.symbol != undefined) {
                this.symbol = data.symbol;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("holders" in data && data.holders != undefined) {
                this.holders = data.holders;
            }
            if ("transfersCount" in data && data.transfersCount != undefined) {
                this.transfersCount = data.transfersCount;
            }
            if ("tokensCount" in data && data.tokensCount != undefined) {
                this.tokensCount = data.tokensCount;
            }
            if ("site" in data && data.site != undefined) {
                this.site = data.site;
            }
            if ("email" in data && data.email != undefined) {
                this.email = data.email;
            }
            if ("twitter" in data && data.twitter != undefined) {
                this.twitter = data.twitter;
            }
            if ("whitepaper" in data && data.whitepaper != undefined) {
                this.whitepaper = data.whitepaper;
            }
            if ("coinmarketcap" in data && data.coinmarketcap != undefined) {
                this.coinmarketcap = data.coinmarketcap;
            }
            if ("customName" in data && data.customName != undefined) {
                this.customName = data.customName;
            }
            if ("tags" in data && data.tags != undefined) {
                this.tags = data.tags;
            }
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("totalSupply" in data && data.totalSupply != undefined) {
                this.totalSupply = data.totalSupply;
            }
            if ("transfersCount24h" in data && data.transfersCount24h != undefined) {
                this.transfersCount24h = data.transfersCount24h;
            }
            if ("transfersCount2d" in data && data.transfersCount2d != undefined) {
                this.transfersCount2d = data.transfersCount2d;
            }
            if ("iconPath" in data && data.iconPath != undefined) {
                this.iconPath = data.iconPath;
            }
            if ("disabled" in data && data.disabled != undefined) {
                this.disabled = data.disabled;
            }
        }
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get symbol() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set symbol(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get holders() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set holders(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get transfersCount() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set transfersCount(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get tokensCount() {
        return pb_1.Message.getFieldWithDefault(this, 14, "") as string;
    }
    set tokensCount(value: string) {
        pb_1.Message.setField(this, 14, value);
    }
    get site() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set site(value: string) {
        pb_1.Message.setField(this, 6, value);
    }
    get email() {
        return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
    }
    set email(value: string) {
        pb_1.Message.setField(this, 7, value);
    }
    get twitter() {
        return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
    }
    set twitter(value: string) {
        pb_1.Message.setField(this, 8, value);
    }
    get whitepaper() {
        return pb_1.Message.getFieldWithDefault(this, 9, "") as string;
    }
    set whitepaper(value: string) {
        pb_1.Message.setField(this, 9, value);
    }
    get coinmarketcap() {
        return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
    }
    set coinmarketcap(value: string) {
        pb_1.Message.setField(this, 10, value);
    }
    get customName() {
        return pb_1.Message.getFieldWithDefault(this, 11, "") as string;
    }
    set customName(value: string) {
        pb_1.Message.setField(this, 11, value);
    }
    get tags() {
        return pb_1.Message.getFieldWithDefault(this, 12, []) as string[];
    }
    set tags(value: string[]) {
        pb_1.Message.setField(this, 12, value);
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 13, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 13, value);
    }
    get totalSupply() {
        return pb_1.Message.getFieldWithDefault(this, 15, "") as string;
    }
    set totalSupply(value: string) {
        pb_1.Message.setField(this, 15, value);
    }
    get transfersCount24h() {
        return pb_1.Message.getFieldWithDefault(this, 16, 0) as number;
    }
    set transfersCount24h(value: number) {
        pb_1.Message.setField(this, 16, value);
    }
    get transfersCount2d() {
        return pb_1.Message.getFieldWithDefault(this, 17, 0) as number;
    }
    set transfersCount2d(value: number) {
        pb_1.Message.setField(this, 17, value);
    }
    get iconPath() {
        return pb_1.Message.getFieldWithDefault(this, 18, "") as string;
    }
    set iconPath(value: string) {
        pb_1.Message.setField(this, 18, value);
    }
    get disabled() {
        return pb_1.Message.getFieldWithDefault(this, 19, false) as boolean;
    }
    set disabled(value: boolean) {
        pb_1.Message.setField(this, 19, value);
    }
    static fromObject(data?: ERC1155Token.AsObjectPartial): ERC1155Token {
        if (!data) {
            return new ERC1155Token();
        }
        const message = new ERC1155Token({});
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.symbol != null) {
            message.symbol = data.symbol;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.holders != null) {
            message.holders = data.holders;
        }
        if (data.transfersCount != null) {
            message.transfersCount = data.transfersCount;
        }
        if (data.tokensCount != null) {
            message.tokensCount = data.tokensCount;
        }
        if (data.site != null) {
            message.site = data.site;
        }
        if (data.email != null) {
            message.email = data.email;
        }
        if (data.twitter != null) {
            message.twitter = data.twitter;
        }
        if (data.whitepaper != null) {
            message.whitepaper = data.whitepaper;
        }
        if (data.coinmarketcap != null) {
            message.coinmarketcap = data.coinmarketcap;
        }
        if (data.customName != null) {
            message.customName = data.customName;
        }
        if (data.tags != null) {
            message.tags = data.tags;
        }
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.totalSupply != null) {
            message.totalSupply = data.totalSupply;
        }
        if (data.transfersCount24h != null) {
            message.transfersCount24h = data.transfersCount24h;
        }
        if (data.transfersCount2d != null) {
            message.transfersCount2d = data.transfersCount2d;
        }
        if (data.iconPath != null) {
            message.iconPath = data.iconPath;
        }
        if (data.disabled != null) {
            message.disabled = data.disabled;
        }
        return message;
    }
    toObject() {
        const data: ERC1155Token.AsObject = {
            name: this.name,
            symbol: this.symbol,
            address: this.address,
            holders: this.holders,
            transfersCount: this.transfersCount,
            tokensCount: this.tokensCount,
            site: this.site,
            email: this.email,
            twitter: this.twitter,
            whitepaper: this.whitepaper,
            coinmarketcap: this.coinmarketcap,
            customName: this.customName,
            tags: this.tags,
            blockchainIndex: this.blockchainIndex,
            totalSupply: this.totalSupply,
            transfersCount24h: this.transfersCount24h,
            transfersCount2d: this.transfersCount2d,
            iconPath: this.iconPath,
            disabled: this.disabled
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.name.length)
            writer.writeString(1, this.name);
        if (this.symbol.length)
            writer.writeString(2, this.symbol);
        if (this.address.length)
            writer.writeString(3, this.address);
        if (this.holders != 0)
            writer.writeInt64(4, this.holders);
        if (this.transfersCount != 0)
            writer.writeInt64(5, this.transfersCount);
        if (this.tokensCount.length)
            writer.writeString(14, this.tokensCount);
        if (this.site.length)
            writer.writeString(6, this.site);
        if (this.email.length)
            writer.writeString(7, this.email);
        if (this.twitter.length)
            writer.writeString(8, this.twitter);
        if (this.whitepaper.length)
            writer.writeString(9, this.whitepaper);
        if (this.coinmarketcap.length)
            writer.writeString(10, this.coinmarketcap);
        if (this.customName.length)
            writer.writeString(11, this.customName);
        if (this.tags.length)
            writer.writeRepeatedString(12, this.tags);
        if (this.blockchainIndex != 0)
            writer.writeInt64(13, this.blockchainIndex);
        if (this.totalSupply.length)
            writer.writeString(15, this.totalSupply);
        if (this.transfersCount24h != 0)
            writer.writeInt64(16, this.transfersCount24h);
        if (this.transfersCount2d != 0)
            writer.writeInt64(17, this.transfersCount2d);
        if (this.iconPath.length)
            writer.writeString(18, this.iconPath);
        if (this.disabled != false)
            writer.writeBool(19, this.disabled);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155Token {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155Token();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.name = reader.readString();
                    break;
                case 2:
                    message.symbol = reader.readString();
                    break;
                case 3:
                    message.address = reader.readString();
                    break;
                case 4:
                    message.holders = reader.readInt64();
                    break;
                case 5:
                    message.transfersCount = reader.readInt64();
                    break;
                case 14:
                    message.tokensCount = reader.readString();
                    break;
                case 6:
                    message.site = reader.readString();
                    break;
                case 7:
                    message.email = reader.readString();
                    break;
                case 8:
                    message.twitter = reader.readString();
                    break;
                case 9:
                    message.whitepaper = reader.readString();
                    break;
                case 10:
                    message.coinmarketcap = reader.readString();
                    break;
                case 11:
                    message.customName = reader.readString();
                    break;
                case 12:
                    pb_1.Message.addToRepeatedField(message, 12, reader.readString());
                    break;
                case 13:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 15:
                    message.totalSupply = reader.readString();
                    break;
                case 16:
                    message.transfersCount24h = reader.readInt64();
                    break;
                case 17:
                    message.transfersCount2d = reader.readInt64();
                    break;
                case 18:
                    message.iconPath = reader.readString();
                    break;
                case 19:
                    message.disabled = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155Token {
        return ERC1155Token.deserialize(bytes);
    }
}
export namespace ERC1155Token {
    export type AsObject = {
        name: string;
        symbol: string;
        address: string;
        holders: number;
        transfersCount: number;
        tokensCount: string;
        site: string;
        email: string;
        twitter: string;
        whitepaper: string;
        coinmarketcap: string;
        customName: string;
        tags: string[];
        blockchainIndex: number;
        totalSupply: string;
        transfersCount24h: number;
        transfersCount2d: number;
        iconPath: string;
        disabled: boolean;
    };
    export type AsObjectPartial = {
        name?: string;
        symbol?: string;
        address?: string;
        holders?: number;
        transfersCount?: number;
        tokensCount?: string;
        site?: string;
        email?: string;
        twitter?: string;
        whitepaper?: string;
        coinmarketcap?: string;
        customName?: string;
        tags?: string[];
        blockchainIndex?: number;
        totalSupply?: string;
        transfersCount24h?: number;
        transfersCount2d?: number;
        iconPath?: string;
        disabled?: boolean;
    };
}
export class ERC1155Transfer extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        txId?: string;
        txIndex?: number;
        txIndexInBc?: number;
        methodName?: string;
        timestamp?: number;
        from?: string;
        to?: string;
        id?: string;
        value?: string;
        meta?: ERC1155Metadata;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("txId" in data && data.txId != undefined) {
                this.txId = data.txId;
            }
            if ("txIndex" in data && data.txIndex != undefined) {
                this.txIndex = data.txIndex;
            }
            if ("txIndexInBc" in data && data.txIndexInBc != undefined) {
                this.txIndexInBc = data.txIndexInBc;
            }
            if ("methodName" in data && data.methodName != undefined) {
                this.methodName = data.methodName;
            }
            if ("timestamp" in data && data.timestamp != undefined) {
                this.timestamp = data.timestamp;
            }
            if ("from" in data && data.from != undefined) {
                this.from = data.from;
            }
            if ("to" in data && data.to != undefined) {
                this.to = data.to;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("value" in data && data.value != undefined) {
                this.value = data.value;
            }
            if ("meta" in data && data.meta != undefined) {
                this.meta = data.meta;
            }
        }
    }
    get txId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set txId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get txIndex() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set txIndex(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get txIndexInBc() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set txIndexInBc(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get methodName() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set methodName(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get timestamp() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set timestamp(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get from() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set from(value: string) {
        pb_1.Message.setField(this, 6, value);
    }
    get to() {
        return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
    }
    set to(value: string) {
        pb_1.Message.setField(this, 7, value);
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 8, value);
    }
    get value() {
        return pb_1.Message.getFieldWithDefault(this, 9, "") as string;
    }
    set value(value: string) {
        pb_1.Message.setField(this, 9, value);
    }
    get meta() {
        return pb_1.Message.getWrapperField(this, ERC1155Metadata, 10) as ERC1155Metadata | undefined;
    }
    set meta(value: ERC1155Metadata | undefined) {
        pb_1.Message.setWrapperField(this, 10, value);
    }
    get hasMeta() {
        return pb_1.Message.getField(this, 10) != null;
    }
    clearMeta(): void {
        this.meta = undefined!;
    }
    static fromObject(data?: ERC1155Transfer.AsObjectPartial): ERC1155Transfer {
        if (!data) {
            return new ERC1155Transfer();
        }
        const message = new ERC1155Transfer({});
        if (data.txId != null) {
            message.txId = data.txId;
        }
        if (data.txIndex != null) {
            message.txIndex = data.txIndex;
        }
        if (data.txIndexInBc != null) {
            message.txIndexInBc = data.txIndexInBc;
        }
        if (data.methodName != null) {
            message.methodName = data.methodName;
        }
        if (data.timestamp != null) {
            message.timestamp = data.timestamp;
        }
        if (data.from != null) {
            message.from = data.from;
        }
        if (data.to != null) {
            message.to = data.to;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.value != null) {
            message.value = data.value;
        }
        if (data.meta != null) {
            message.meta = ERC1155Metadata.fromObject(data.meta);
        }
        return message;
    }
    toObject() {
        const data: ERC1155Transfer.AsObject = {
            txId: this.txId,
            txIndex: this.txIndex,
            txIndexInBc: this.txIndexInBc,
            methodName: this.methodName,
            timestamp: this.timestamp,
            from: this.from,
            to: this.to,
            id: this.id,
            value: this.value
        };
        if (this.meta != null) {
            data.meta = this.meta.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.txId.length)
            writer.writeString(1, this.txId);
        if (this.txIndex != 0)
            writer.writeInt64(2, this.txIndex);
        if (this.txIndexInBc != 0)
            writer.writeInt64(3, this.txIndexInBc);
        if (this.methodName.length)
            writer.writeString(4, this.methodName);
        if (this.timestamp != 0)
            writer.writeInt64(5, this.timestamp);
        if (this.from.length)
            writer.writeString(6, this.from);
        if (this.to.length)
            writer.writeString(7, this.to);
        if (this.id.length)
            writer.writeString(8, this.id);
        if (this.value.length)
            writer.writeString(9, this.value);
        if (this.hasMeta)
            writer.writeMessage(10, this.meta, () => this.meta!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155Transfer {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155Transfer();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.txId = reader.readString();
                    break;
                case 2:
                    message.txIndex = reader.readInt64();
                    break;
                case 3:
                    message.txIndexInBc = reader.readInt64();
                    break;
                case 4:
                    message.methodName = reader.readString();
                    break;
                case 5:
                    message.timestamp = reader.readInt64();
                    break;
                case 6:
                    message.from = reader.readString();
                    break;
                case 7:
                    message.to = reader.readString();
                    break;
                case 8:
                    message.id = reader.readString();
                    break;
                case 9:
                    message.value = reader.readString();
                    break;
                case 10:
                    reader.readMessage(message.meta, () => message.meta = ERC1155Metadata.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155Transfer {
        return ERC1155Transfer.deserialize(bytes);
    }
}
export namespace ERC1155Transfer {
    export type AsObject = {
        txId: string;
        txIndex: number;
        txIndexInBc: number;
        methodName: string;
        timestamp: number;
        from: string;
        to: string;
        id: string;
        value: string;
        meta?: ERC1155Metadata.AsObject;
    };
    export type AsObjectPartial = {
        txId?: string;
        txIndex?: number;
        txIndexInBc?: number;
        methodName?: string;
        timestamp?: number;
        from?: string;
        to?: string;
        id?: string;
        value?: string;
        meta?: ERC1155Metadata.AsObjectPartial;
    };
}
export class ERC1155Holder extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        address?: string;
        name?: string;
        value?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("value" in data && data.value != undefined) {
                this.value = data.value;
            }
        }
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get value() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set value(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data?: ERC1155Holder.AsObjectPartial): ERC1155Holder {
        if (!data) {
            return new ERC1155Holder();
        }
        const message = new ERC1155Holder({});
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.value != null) {
            message.value = data.value;
        }
        return message;
    }
    toObject() {
        const data: ERC1155Holder.AsObject = {
            address: this.address,
            name: this.name,
            value: this.value
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.address.length)
            writer.writeString(1, this.address);
        if (this.name.length)
            writer.writeString(2, this.name);
        if (this.value.length)
            writer.writeString(3, this.value);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155Holder {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155Holder();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.address = reader.readString();
                    break;
                case 2:
                    message.name = reader.readString();
                    break;
                case 3:
                    message.value = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155Holder {
        return ERC1155Holder.deserialize(bytes);
    }
}
export namespace ERC1155Holder {
    export type AsObject = {
        address: string;
        name: string;
        value: string;
    };
    export type AsObjectPartial = {
        address?: string;
        name?: string;
        value?: string;
    };
}
export class ERC1155NFT extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        address?: string;
        tokenId?: string;
        metadataLink?: string;
        metadata?: string;
        name?: string;
        description?: string;
        imageLink?: string;
        minted?: string;
        owner?: string;
        balance?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("tokenId" in data && data.tokenId != undefined) {
                this.tokenId = data.tokenId;
            }
            if ("metadataLink" in data && data.metadataLink != undefined) {
                this.metadataLink = data.metadataLink;
            }
            if ("metadata" in data && data.metadata != undefined) {
                this.metadata = data.metadata;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("description" in data && data.description != undefined) {
                this.description = data.description;
            }
            if ("imageLink" in data && data.imageLink != undefined) {
                this.imageLink = data.imageLink;
            }
            if ("minted" in data && data.minted != undefined) {
                this.minted = data.minted;
            }
            if ("owner" in data && data.owner != undefined) {
                this.owner = data.owner;
            }
            if ("balance" in data && data.balance != undefined) {
                this.balance = data.balance;
            }
        }
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get tokenId() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set tokenId(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get metadataLink() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set metadataLink(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get metadata() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set metadata(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 5, value);
    }
    get description() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set description(value: string) {
        pb_1.Message.setField(this, 6, value);
    }
    get imageLink() {
        return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
    }
    set imageLink(value: string) {
        pb_1.Message.setField(this, 7, value);
    }
    get minted() {
        return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
    }
    set minted(value: string) {
        pb_1.Message.setField(this, 8, value);
    }
    get owner() {
        return pb_1.Message.getFieldWithDefault(this, 9, "") as string;
    }
    set owner(value: string) {
        pb_1.Message.setField(this, 9, value);
    }
    get balance() {
        return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
    }
    set balance(value: string) {
        pb_1.Message.setField(this, 10, value);
    }
    static fromObject(data?: ERC1155NFT.AsObjectPartial): ERC1155NFT {
        if (!data) {
            return new ERC1155NFT();
        }
        const message = new ERC1155NFT({});
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.tokenId != null) {
            message.tokenId = data.tokenId;
        }
        if (data.metadataLink != null) {
            message.metadataLink = data.metadataLink;
        }
        if (data.metadata != null) {
            message.metadata = data.metadata;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.description != null) {
            message.description = data.description;
        }
        if (data.imageLink != null) {
            message.imageLink = data.imageLink;
        }
        if (data.minted != null) {
            message.minted = data.minted;
        }
        if (data.owner != null) {
            message.owner = data.owner;
        }
        if (data.balance != null) {
            message.balance = data.balance;
        }
        return message;
    }
    toObject() {
        const data: ERC1155NFT.AsObject = {
            address: this.address,
            tokenId: this.tokenId,
            metadataLink: this.metadataLink,
            metadata: this.metadata,
            name: this.name,
            description: this.description,
            imageLink: this.imageLink,
            minted: this.minted,
            owner: this.owner,
            balance: this.balance
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.address.length)
            writer.writeString(1, this.address);
        if (this.tokenId.length)
            writer.writeString(2, this.tokenId);
        if (this.metadataLink.length)
            writer.writeString(3, this.metadataLink);
        if (this.metadata.length)
            writer.writeString(4, this.metadata);
        if (this.name.length)
            writer.writeString(5, this.name);
        if (this.description.length)
            writer.writeString(6, this.description);
        if (this.imageLink.length)
            writer.writeString(7, this.imageLink);
        if (this.minted.length)
            writer.writeString(8, this.minted);
        if (this.owner.length)
            writer.writeString(9, this.owner);
        if (this.balance.length)
            writer.writeString(10, this.balance);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155NFT {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155NFT();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.address = reader.readString();
                    break;
                case 2:
                    message.tokenId = reader.readString();
                    break;
                case 3:
                    message.metadataLink = reader.readString();
                    break;
                case 4:
                    message.metadata = reader.readString();
                    break;
                case 5:
                    message.name = reader.readString();
                    break;
                case 6:
                    message.description = reader.readString();
                    break;
                case 7:
                    message.imageLink = reader.readString();
                    break;
                case 8:
                    message.minted = reader.readString();
                    break;
                case 9:
                    message.owner = reader.readString();
                    break;
                case 10:
                    message.balance = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155NFT {
        return ERC1155NFT.deserialize(bytes);
    }
}
export namespace ERC1155NFT {
    export type AsObject = {
        address: string;
        tokenId: string;
        metadataLink: string;
        metadata: string;
        name: string;
        description: string;
        imageLink: string;
        minted: string;
        owner: string;
        balance: string;
    };
    export type AsObjectPartial = {
        address?: string;
        tokenId?: string;
        metadataLink?: string;
        metadata?: string;
        name?: string;
        description?: string;
        imageLink?: string;
        minted?: string;
        owner?: string;
        balance?: string;
    };
}
export class ERC1155NFTList extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        nfts?: ERC1155NFT[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("nfts" in data && data.nfts != undefined) {
                this.nfts = data.nfts;
            }
        }
    }
    get nfts() {
        return pb_1.Message.getRepeatedWrapperField(this, ERC1155NFT, 1) as ERC1155NFT[];
    }
    set nfts(value: ERC1155NFT[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data?: ERC1155NFTList.AsObjectPartial): ERC1155NFTList {
        if (!data) {
            return new ERC1155NFTList();
        }
        const message = new ERC1155NFTList({});
        if (data.nfts != null) {
            message.nfts = data.nfts.map(item => ERC1155NFT.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: ERC1155NFTList.AsObject = {
            nfts: this.nfts.map((item: ERC1155NFT) => item.toObject())
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.nfts.length)
            writer.writeRepeatedMessage(1, this.nfts, (item: ERC1155NFT) => item!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155NFTList {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155NFTList();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.nfts, () => pb_1.Message.addToRepeatedWrapperField(message, 1, ERC1155NFT.deserialize(reader), ERC1155NFT));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155NFTList {
        return ERC1155NFTList.deserialize(bytes);
    }
}
export namespace ERC1155NFTList {
    export type AsObject = {
        nfts: ERC1155NFT.AsObject[];
    };
    export type AsObjectPartial = {
        nfts?: ERC1155NFT.AsObjectPartial[];
    };
}
export class ERC1155Metadata extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        address?: string;
        name?: string;
        symbol?: string;
        iconPath?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("symbol" in data && data.symbol != undefined) {
                this.symbol = data.symbol;
            }
            if ("iconPath" in data && data.iconPath != undefined) {
                this.iconPath = data.iconPath;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get symbol() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set symbol(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get iconPath() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set iconPath(value: string) {
        pb_1.Message.setField(this, 5, value);
    }
    static fromObject(data?: ERC1155Metadata.AsObjectPartial): ERC1155Metadata {
        if (!data) {
            return new ERC1155Metadata();
        }
        const message = new ERC1155Metadata({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.symbol != null) {
            message.symbol = data.symbol;
        }
        if (data.iconPath != null) {
            message.iconPath = data.iconPath;
        }
        return message;
    }
    toObject() {
        const data: ERC1155Metadata.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address,
            name: this.name,
            symbol: this.symbol,
            iconPath: this.iconPath
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (this.name.length)
            writer.writeString(3, this.name);
        if (this.symbol.length)
            writer.writeString(4, this.symbol);
        if (this.iconPath.length)
            writer.writeString(5, this.iconPath);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155Metadata {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155Metadata();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                case 3:
                    message.name = reader.readString();
                    break;
                case 4:
                    message.symbol = reader.readString();
                    break;
                case 5:
                    message.iconPath = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155Metadata {
        return ERC1155Metadata.deserialize(bytes);
    }
}
export namespace ERC1155Metadata {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
        name: string;
        symbol: string;
        iconPath: string;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
        name?: string;
        symbol?: string;
        iconPath?: string;
    };
}
export class ERC1155Balance extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        meta?: ERC1155Metadata;
        balance?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("meta" in data && data.meta != undefined) {
                this.meta = data.meta;
            }
            if ("balance" in data && data.balance != undefined) {
                this.balance = data.balance;
            }
        }
    }
    get meta() {
        return pb_1.Message.getWrapperField(this, ERC1155Metadata, 1) as ERC1155Metadata | undefined;
    }
    set meta(value: ERC1155Metadata | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasMeta() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearMeta(): void {
        this.meta = undefined!;
    }
    get balance() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set balance(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: ERC1155Balance.AsObjectPartial): ERC1155Balance {
        if (!data) {
            return new ERC1155Balance();
        }
        const message = new ERC1155Balance({});
        if (data.meta != null) {
            message.meta = ERC1155Metadata.fromObject(data.meta);
        }
        if (data.balance != null) {
            message.balance = data.balance;
        }
        return message;
    }
    toObject() {
        const data: ERC1155Balance.AsObject = {
            balance: this.balance
        };
        if (this.meta != null) {
            data.meta = this.meta.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasMeta)
            writer.writeMessage(1, this.meta, () => this.meta!.serialize(writer));
        if (this.balance.length)
            writer.writeString(2, this.balance);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155Balance {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155Balance();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.meta, () => message.meta = ERC1155Metadata.deserialize(reader));
                    break;
                case 2:
                    message.balance = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155Balance {
        return ERC1155Balance.deserialize(bytes);
    }
}
export namespace ERC1155Balance {
    export type AsObject = {
        meta?: ERC1155Metadata.AsObject;
        balance: string;
    };
    export type AsObjectPartial = {
        meta?: ERC1155Metadata.AsObjectPartial;
        balance?: string;
    };
}
export class ERC1155TokenDetails extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        token?: ERC1155Token;
        transfers?: ERC1155Transfer[];
        totalTransfers?: number;
        holders?: ERC1155Holder[];
        totalHolders?: number;
        inventory?: ERC1155NFT[];
        totalInventory?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1, 3, 6], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("token" in data && data.token != undefined) {
                this.token = data.token;
            }
            if ("transfers" in data && data.transfers != undefined) {
                this.transfers = data.transfers;
            }
            if ("totalTransfers" in data && data.totalTransfers != undefined) {
                this.totalTransfers = data.totalTransfers;
            }
            if ("holders" in data && data.holders != undefined) {
                this.holders = data.holders;
            }
            if ("totalHolders" in data && data.totalHolders != undefined) {
                this.totalHolders = data.totalHolders;
            }
            if ("inventory" in data && data.inventory != undefined) {
                this.inventory = data.inventory;
            }
            if ("totalInventory" in data && data.totalInventory != undefined) {
                this.totalInventory = data.totalInventory;
            }
        }
    }
    get token() {
        return pb_1.Message.getWrapperField(this, ERC1155Token, 5) as ERC1155Token | undefined;
    }
    set token(value: ERC1155Token | undefined) {
        pb_1.Message.setWrapperField(this, 5, value);
    }
    get hasToken() {
        return pb_1.Message.getField(this, 5) != null;
    }
    clearToken(): void {
        this.token = undefined!;
    }
    get transfers() {
        return pb_1.Message.getRepeatedWrapperField(this, ERC1155Transfer, 1) as ERC1155Transfer[];
    }
    set transfers(value: ERC1155Transfer[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get totalTransfers() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set totalTransfers(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get holders() {
        return pb_1.Message.getRepeatedWrapperField(this, ERC1155Holder, 3) as ERC1155Holder[];
    }
    set holders(value: ERC1155Holder[]) {
        pb_1.Message.setRepeatedWrapperField(this, 3, value);
    }
    get totalHolders() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set totalHolders(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get inventory() {
        return pb_1.Message.getRepeatedWrapperField(this, ERC1155NFT, 6) as ERC1155NFT[];
    }
    set inventory(value: ERC1155NFT[]) {
        pb_1.Message.setRepeatedWrapperField(this, 6, value);
    }
    get totalInventory() {
        return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
    }
    set totalInventory(value: number) {
        pb_1.Message.setField(this, 7, value);
    }
    static fromObject(data?: ERC1155TokenDetails.AsObjectPartial): ERC1155TokenDetails {
        if (!data) {
            return new ERC1155TokenDetails();
        }
        const message = new ERC1155TokenDetails({});
        if (data.token != null) {
            message.token = ERC1155Token.fromObject(data.token);
        }
        if (data.transfers != null) {
            message.transfers = data.transfers.map(item => ERC1155Transfer.fromObject(item));
        }
        if (data.totalTransfers != null) {
            message.totalTransfers = data.totalTransfers;
        }
        if (data.holders != null) {
            message.holders = data.holders.map(item => ERC1155Holder.fromObject(item));
        }
        if (data.totalHolders != null) {
            message.totalHolders = data.totalHolders;
        }
        if (data.inventory != null) {
            message.inventory = data.inventory.map(item => ERC1155NFT.fromObject(item));
        }
        if (data.totalInventory != null) {
            message.totalInventory = data.totalInventory;
        }
        return message;
    }
    toObject() {
        const data: ERC1155TokenDetails.AsObject = {
            transfers: this.transfers.map((item: ERC1155Transfer) => item.toObject()),
            totalTransfers: this.totalTransfers,
            holders: this.holders.map((item: ERC1155Holder) => item.toObject()),
            totalHolders: this.totalHolders,
            inventory: this.inventory.map((item: ERC1155NFT) => item.toObject()),
            totalInventory: this.totalInventory
        };
        if (this.token != null) {
            data.token = this.token.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasToken)
            writer.writeMessage(5, this.token, () => this.token!.serialize(writer));
        if (this.transfers.length)
            writer.writeRepeatedMessage(1, this.transfers, (item: ERC1155Transfer) => item!.serialize(writer));
        if (this.totalTransfers != 0)
            writer.writeInt64(2, this.totalTransfers);
        if (this.holders.length)
            writer.writeRepeatedMessage(3, this.holders, (item: ERC1155Holder) => item!.serialize(writer));
        if (this.totalHolders != 0)
            writer.writeInt64(4, this.totalHolders);
        if (this.inventory.length)
            writer.writeRepeatedMessage(6, this.inventory, (item: ERC1155NFT) => item!.serialize(writer));
        if (this.totalInventory != 0)
            writer.writeInt64(7, this.totalInventory);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155TokenDetails {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155TokenDetails();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 5:
                    reader.readMessage(message.token, () => message.token = ERC1155Token.deserialize(reader));
                    break;
                case 1:
                    reader.readMessage(message.transfers, () => pb_1.Message.addToRepeatedWrapperField(message, 1, ERC1155Transfer.deserialize(reader), ERC1155Transfer));
                    break;
                case 2:
                    message.totalTransfers = reader.readInt64();
                    break;
                case 3:
                    reader.readMessage(message.holders, () => pb_1.Message.addToRepeatedWrapperField(message, 3, ERC1155Holder.deserialize(reader), ERC1155Holder));
                    break;
                case 4:
                    message.totalHolders = reader.readInt64();
                    break;
                case 6:
                    reader.readMessage(message.inventory, () => pb_1.Message.addToRepeatedWrapperField(message, 6, ERC1155NFT.deserialize(reader), ERC1155NFT));
                    break;
                case 7:
                    message.totalInventory = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155TokenDetails {
        return ERC1155TokenDetails.deserialize(bytes);
    }
}
export namespace ERC1155TokenDetails {
    export type AsObject = {
        token?: ERC1155Token.AsObject;
        transfers: ERC1155Transfer.AsObject[];
        totalTransfers: number;
        holders: ERC1155Holder.AsObject[];
        totalHolders: number;
        inventory: ERC1155NFT.AsObject[];
        totalInventory: number;
    };
    export type AsObjectPartial = {
        token?: ERC1155Token.AsObjectPartial;
        transfers?: ERC1155Transfer.AsObjectPartial[];
        totalTransfers?: number;
        holders?: ERC1155Holder.AsObjectPartial[];
        totalHolders?: number;
        inventory?: ERC1155NFT.AsObjectPartial[];
        totalInventory?: number;
    };
}
export class ERC1155ApprovalForAllAction extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        address?: string;
        owner?: string;
        spender?: string;
        approved?: boolean;
        name?: string;
        symbol?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("owner" in data && data.owner != undefined) {
                this.owner = data.owner;
            }
            if ("spender" in data && data.spender != undefined) {
                this.spender = data.spender;
            }
            if ("approved" in data && data.approved != undefined) {
                this.approved = data.approved;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("symbol" in data && data.symbol != undefined) {
                this.symbol = data.symbol;
            }
        }
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get owner() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set owner(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get spender() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set spender(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get approved() {
        return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
    }
    set approved(value: boolean) {
        pb_1.Message.setField(this, 4, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 5, value);
    }
    get symbol() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set symbol(value: string) {
        pb_1.Message.setField(this, 6, value);
    }
    static fromObject(data?: ERC1155ApprovalForAllAction.AsObjectPartial): ERC1155ApprovalForAllAction {
        if (!data) {
            return new ERC1155ApprovalForAllAction();
        }
        const message = new ERC1155ApprovalForAllAction({});
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.owner != null) {
            message.owner = data.owner;
        }
        if (data.spender != null) {
            message.spender = data.spender;
        }
        if (data.approved != null) {
            message.approved = data.approved;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.symbol != null) {
            message.symbol = data.symbol;
        }
        return message;
    }
    toObject() {
        const data: ERC1155ApprovalForAllAction.AsObject = {
            address: this.address,
            owner: this.owner,
            spender: this.spender,
            approved: this.approved,
            name: this.name,
            symbol: this.symbol
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.address.length)
            writer.writeString(1, this.address);
        if (this.owner.length)
            writer.writeString(2, this.owner);
        if (this.spender.length)
            writer.writeString(3, this.spender);
        if (this.approved != false)
            writer.writeBool(4, this.approved);
        if (this.name.length)
            writer.writeString(5, this.name);
        if (this.symbol.length)
            writer.writeString(6, this.symbol);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155ApprovalForAllAction {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155ApprovalForAllAction();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.address = reader.readString();
                    break;
                case 2:
                    message.owner = reader.readString();
                    break;
                case 3:
                    message.spender = reader.readString();
                    break;
                case 4:
                    message.approved = reader.readBool();
                    break;
                case 5:
                    message.name = reader.readString();
                    break;
                case 6:
                    message.symbol = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155ApprovalForAllAction {
        return ERC1155ApprovalForAllAction.deserialize(bytes);
    }
}
export namespace ERC1155ApprovalForAllAction {
    export type AsObject = {
        address: string;
        owner: string;
        spender: string;
        approved: boolean;
        name: string;
        symbol: string;
    };
    export type AsObjectPartial = {
        address?: string;
        owner?: string;
        spender?: string;
        approved?: boolean;
        name?: string;
        symbol?: string;
    };
}
export class ERC1155TokenListRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        page?: dependency_2.Page;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_2.Page, 2) as dependency_2.Page | undefined;
    }
    set page(value: dependency_2.Page | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    static fromObject(data?: ERC1155TokenListRequest.AsObjectPartial): ERC1155TokenListRequest {
        if (!data) {
            return new ERC1155TokenListRequest();
        }
        const message = new ERC1155TokenListRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.page != null) {
            message.page = dependency_2.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: ERC1155TokenListRequest.AsObject = {
            blockchainIndex: this.blockchainIndex
        };
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.hasPage)
            writer.writeMessage(2, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155TokenListRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155TokenListRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    reader.readMessage(message.page, () => message.page = dependency_2.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155TokenListRequest {
        return ERC1155TokenListRequest.deserialize(bytes);
    }
}
export namespace ERC1155TokenListRequest {
    export type AsObject = {
        blockchainIndex: number;
        page?: dependency_2.Page.AsObject;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        page?: dependency_2.Page.AsObjectPartial;
    };
}
export class ERC1155TokenListResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        erc1155Tokens?: ERC1155Token[];
        navigation?: dependency_2.NavigationData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("erc1155Tokens" in data && data.erc1155Tokens != undefined) {
                this.erc1155Tokens = data.erc1155Tokens;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
        }
    }
    get erc1155Tokens() {
        return pb_1.Message.getRepeatedWrapperField(this, ERC1155Token, 1) as ERC1155Token[];
    }
    set erc1155Tokens(value: ERC1155Token[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_2.NavigationData, 2) as dependency_2.NavigationData | undefined;
    }
    set navigation(value: dependency_2.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    static fromObject(data?: ERC1155TokenListResponse.AsObjectPartial): ERC1155TokenListResponse {
        if (!data) {
            return new ERC1155TokenListResponse();
        }
        const message = new ERC1155TokenListResponse({});
        if (data.erc1155Tokens != null) {
            message.erc1155Tokens = data.erc1155Tokens.map(item => ERC1155Token.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_2.NavigationData.fromObject(data.navigation);
        }
        return message;
    }
    toObject() {
        const data: ERC1155TokenListResponse.AsObject = {
            erc1155Tokens: this.erc1155Tokens.map((item: ERC1155Token) => item.toObject())
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.erc1155Tokens.length)
            writer.writeRepeatedMessage(1, this.erc1155Tokens, (item: ERC1155Token) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(2, this.navigation, () => this.navigation!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155TokenListResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155TokenListResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.erc1155Tokens, () => pb_1.Message.addToRepeatedWrapperField(message, 1, ERC1155Token.deserialize(reader), ERC1155Token));
                    break;
                case 2:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_2.NavigationData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155TokenListResponse {
        return ERC1155TokenListResponse.deserialize(bytes);
    }
}
export namespace ERC1155TokenListResponse {
    export type AsObject = {
        erc1155Tokens: ERC1155Token.AsObject[];
        navigation?: dependency_2.NavigationData.AsObject;
    };
    export type AsObjectPartial = {
        erc1155Tokens?: ERC1155Token.AsObjectPartial[];
        navigation?: dependency_2.NavigationData.AsObjectPartial;
    };
}
export class ERC1155TokenDetailsRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        address?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: ERC1155TokenDetailsRequest.AsObjectPartial): ERC1155TokenDetailsRequest {
        if (!data) {
            return new ERC1155TokenDetailsRequest();
        }
        const message = new ERC1155TokenDetailsRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        return message;
    }
    toObject() {
        const data: ERC1155TokenDetailsRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155TokenDetailsRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155TokenDetailsRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155TokenDetailsRequest {
        return ERC1155TokenDetailsRequest.deserialize(bytes);
    }
}
export namespace ERC1155TokenDetailsRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
    };
}
export class ERC1155TokenDetailsResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        details?: ERC1155TokenDetails;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("details" in data && data.details != undefined) {
                this.details = data.details;
            }
        }
    }
    get details() {
        return pb_1.Message.getWrapperField(this, ERC1155TokenDetails, 1) as ERC1155TokenDetails | undefined;
    }
    set details(value: ERC1155TokenDetails | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasDetails() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearDetails(): void {
        this.details = undefined!;
    }
    static fromObject(data?: ERC1155TokenDetailsResponse.AsObjectPartial): ERC1155TokenDetailsResponse {
        if (!data) {
            return new ERC1155TokenDetailsResponse();
        }
        const message = new ERC1155TokenDetailsResponse({});
        if (data.details != null) {
            message.details = ERC1155TokenDetails.fromObject(data.details);
        }
        return message;
    }
    toObject() {
        const data: ERC1155TokenDetailsResponse.AsObject = {};
        if (this.details != null) {
            data.details = this.details.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasDetails)
            writer.writeMessage(1, this.details, () => this.details!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155TokenDetailsResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155TokenDetailsResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.details, () => message.details = ERC1155TokenDetails.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155TokenDetailsResponse {
        return ERC1155TokenDetailsResponse.deserialize(bytes);
    }
}
export namespace ERC1155TokenDetailsResponse {
    export type AsObject = {
        details?: ERC1155TokenDetails.AsObject;
    };
    export type AsObjectPartial = {
        details?: ERC1155TokenDetails.AsObjectPartial;
    };
}
export class ERC1155TransferListRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[2, 3, 4, 6, 7, 8]];
    constructor(data?: any[] | ({
        blockchainIndex?: number;
        page?: dependency_2.Page;
    } & (({
        address?: string;
        account?: never;
        all?: never;
        singleToken?: never;
        addressAccount?: never;
        singleTokenAccount?: never;
    } | {
        address?: never;
        account?: string;
        all?: never;
        singleToken?: never;
        addressAccount?: never;
        singleTokenAccount?: never;
    } | {
        address?: never;
        account?: never;
        all?: boolean;
        singleToken?: never;
        addressAccount?: never;
        singleTokenAccount?: never;
    } | {
        address?: never;
        account?: never;
        all?: never;
        singleToken?: ERC1155TransferListRequest.SingleToken;
        addressAccount?: never;
        singleTokenAccount?: never;
    } | {
        address?: never;
        account?: never;
        all?: never;
        singleToken?: never;
        addressAccount?: ERC1155TransferListRequest.AddressAccount;
        singleTokenAccount?: never;
    } | {
        address?: never;
        account?: never;
        all?: never;
        singleToken?: never;
        addressAccount?: never;
        singleTokenAccount?: ERC1155TransferListRequest.SingleTokenAccount;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("account" in data && data.account != undefined) {
                this.account = data.account;
            }
            if ("all" in data && data.all != undefined) {
                this.all = data.all;
            }
            if ("singleToken" in data && data.singleToken != undefined) {
                this.singleToken = data.singleToken;
            }
            if ("addressAccount" in data && data.addressAccount != undefined) {
                this.addressAccount = data.addressAccount;
            }
            if ("singleTokenAccount" in data && data.singleTokenAccount != undefined) {
                this.singleTokenAccount = data.singleTokenAccount;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasAddress() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearAddress(): void {
        this.address = undefined!;
    }
    get account() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set account(value: string) {
        pb_1.Message.setOneofField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasAccount() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearAccount(): void {
        this.account = undefined!;
    }
    get all() {
        return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
    }
    set all(value: boolean) {
        pb_1.Message.setOneofField(this, 4, this.#one_of_decls[0]!, value);
    }
    get hasAll() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearAll(): void {
        this.all = undefined!;
    }
    get singleToken() {
        return pb_1.Message.getWrapperField(this, ERC1155TransferListRequest.SingleToken, 6) as ERC1155TransferListRequest.SingleToken | undefined;
    }
    set singleToken(value: ERC1155TransferListRequest.SingleToken | undefined) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0]!, value);
    }
    get hasSingleToken() {
        return pb_1.Message.getField(this, 6) != null;
    }
    clearSingleToken(): void {
        this.singleToken = undefined!;
    }
    get addressAccount() {
        return pb_1.Message.getWrapperField(this, ERC1155TransferListRequest.AddressAccount, 7) as ERC1155TransferListRequest.AddressAccount | undefined;
    }
    set addressAccount(value: ERC1155TransferListRequest.AddressAccount | undefined) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0]!, value);
    }
    get hasAddressAccount() {
        return pb_1.Message.getField(this, 7) != null;
    }
    clearAddressAccount(): void {
        this.addressAccount = undefined!;
    }
    get singleTokenAccount() {
        return pb_1.Message.getWrapperField(this, ERC1155TransferListRequest.SingleTokenAccount, 8) as ERC1155TransferListRequest.SingleTokenAccount | undefined;
    }
    set singleTokenAccount(value: ERC1155TransferListRequest.SingleTokenAccount | undefined) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0]!, value);
    }
    get hasSingleTokenAccount() {
        return pb_1.Message.getField(this, 8) != null;
    }
    clearSingleTokenAccount(): void {
        this.singleTokenAccount = undefined!;
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_2.Page, 5) as dependency_2.Page | undefined;
    }
    set page(value: dependency_2.Page | undefined) {
        pb_1.Message.setWrapperField(this, 5, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 5) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "address" | "account" | "all" | "singleToken" | "addressAccount" | "singleTokenAccount";
        } = {
            0: "none",
            2: "address",
            3: "account",
            4: "all",
            6: "singleToken",
            7: "addressAccount",
            8: "singleTokenAccount"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2, 3, 4, 6, 7, 8])]!;
    }
    static fromObject(data?: ERC1155TransferListRequest.AsObjectPartial): ERC1155TransferListRequest {
        if (!data) {
            return new ERC1155TransferListRequest();
        }
        const message = new ERC1155TransferListRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.account != null) {
            message.account = data.account;
        }
        if (data.all != null) {
            message.all = data.all;
        }
        if (data.singleToken != null) {
            message.singleToken = ERC1155TransferListRequest.SingleToken.fromObject(data.singleToken);
        }
        if (data.addressAccount != null) {
            message.addressAccount = ERC1155TransferListRequest.AddressAccount.fromObject(data.addressAccount);
        }
        if (data.singleTokenAccount != null) {
            message.singleTokenAccount = ERC1155TransferListRequest.SingleTokenAccount.fromObject(data.singleTokenAccount);
        }
        if (data.page != null) {
            message.page = dependency_2.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: ERC1155TransferListRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address,
            account: this.account,
            all: this.all
        };
        if (this.singleToken != null) {
            data.singleToken = this.singleToken.toObject();
        }
        if (this.addressAccount != null) {
            data.addressAccount = this.addressAccount.toObject();
        }
        if (this.singleTokenAccount != null) {
            data.singleTokenAccount = this.singleTokenAccount.toObject();
        }
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.hasAddress)
            writer.writeString(2, this.address);
        if (this.hasAccount)
            writer.writeString(3, this.account);
        if (this.hasAll)
            writer.writeBool(4, this.all);
        if (this.hasSingleToken)
            writer.writeMessage(6, this.singleToken, () => this.singleToken!.serialize(writer));
        if (this.hasAddressAccount)
            writer.writeMessage(7, this.addressAccount, () => this.addressAccount!.serialize(writer));
        if (this.hasSingleTokenAccount)
            writer.writeMessage(8, this.singleTokenAccount, () => this.singleTokenAccount!.serialize(writer));
        if (this.hasPage)
            writer.writeMessage(5, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155TransferListRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155TransferListRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                case 3:
                    message.account = reader.readString();
                    break;
                case 4:
                    message.all = reader.readBool();
                    break;
                case 6:
                    reader.readMessage(message.singleToken, () => message.singleToken = ERC1155TransferListRequest.SingleToken.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.addressAccount, () => message.addressAccount = ERC1155TransferListRequest.AddressAccount.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.singleTokenAccount, () => message.singleTokenAccount = ERC1155TransferListRequest.SingleTokenAccount.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.page, () => message.page = dependency_2.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155TransferListRequest {
        return ERC1155TransferListRequest.deserialize(bytes);
    }
}
export namespace ERC1155TransferListRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
        account: string;
        all: boolean;
        singleToken?: ERC1155TransferListRequest.SingleToken.AsObject;
        addressAccount?: ERC1155TransferListRequest.AddressAccount.AsObject;
        singleTokenAccount?: ERC1155TransferListRequest.SingleTokenAccount.AsObject;
        page?: dependency_2.Page.AsObject;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
        account?: string;
        all?: boolean;
        singleToken?: ERC1155TransferListRequest.SingleToken.AsObjectPartial;
        addressAccount?: ERC1155TransferListRequest.AddressAccount.AsObjectPartial;
        singleTokenAccount?: ERC1155TransferListRequest.SingleTokenAccount.AsObjectPartial;
        page?: dependency_2.Page.AsObjectPartial;
    };
    export class SingleToken extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            address?: string;
            tokenId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("address" in data && data.address != undefined) {
                    this.address = data.address;
                }
                if ("tokenId" in data && data.tokenId != undefined) {
                    this.tokenId = data.tokenId;
                }
            }
        }
        get address() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set address(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get tokenId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set tokenId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data?: SingleToken.AsObjectPartial): SingleToken {
            if (!data) {
                return new SingleToken();
            }
            const message = new SingleToken({});
            if (data.address != null) {
                message.address = data.address;
            }
            if (data.tokenId != null) {
                message.tokenId = data.tokenId;
            }
            return message;
        }
        toObject() {
            const data: SingleToken.AsObject = {
                address: this.address,
                tokenId: this.tokenId
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.address.length)
                writer.writeString(1, this.address);
            if (this.tokenId.length)
                writer.writeString(2, this.tokenId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SingleToken {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SingleToken();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.address = reader.readString();
                        break;
                    case 2:
                        message.tokenId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SingleToken {
            return SingleToken.deserialize(bytes);
        }
    }
    export namespace SingleToken {
        export type AsObject = {
            address: string;
            tokenId: string;
        };
        export type AsObjectPartial = {
            address?: string;
            tokenId?: string;
        };
    }
    export class AddressAccount extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            address?: string;
            account?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("address" in data && data.address != undefined) {
                    this.address = data.address;
                }
                if ("account" in data && data.account != undefined) {
                    this.account = data.account;
                }
            }
        }
        get address() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set address(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get account() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set account(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data?: AddressAccount.AsObjectPartial): AddressAccount {
            if (!data) {
                return new AddressAccount();
            }
            const message = new AddressAccount({});
            if (data.address != null) {
                message.address = data.address;
            }
            if (data.account != null) {
                message.account = data.account;
            }
            return message;
        }
        toObject() {
            const data: AddressAccount.AsObject = {
                address: this.address,
                account: this.account
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.address.length)
                writer.writeString(1, this.address);
            if (this.account.length)
                writer.writeString(2, this.account);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AddressAccount {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AddressAccount();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.address = reader.readString();
                        break;
                    case 2:
                        message.account = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): AddressAccount {
            return AddressAccount.deserialize(bytes);
        }
    }
    export namespace AddressAccount {
        export type AsObject = {
            address: string;
            account: string;
        };
        export type AsObjectPartial = {
            address?: string;
            account?: string;
        };
    }
    export class SingleTokenAccount extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            address?: string;
            tokenId?: string;
            account?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("address" in data && data.address != undefined) {
                    this.address = data.address;
                }
                if ("tokenId" in data && data.tokenId != undefined) {
                    this.tokenId = data.tokenId;
                }
                if ("account" in data && data.account != undefined) {
                    this.account = data.account;
                }
            }
        }
        get address() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set address(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get tokenId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set tokenId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get account() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set account(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data?: SingleTokenAccount.AsObjectPartial): SingleTokenAccount {
            if (!data) {
                return new SingleTokenAccount();
            }
            const message = new SingleTokenAccount({});
            if (data.address != null) {
                message.address = data.address;
            }
            if (data.tokenId != null) {
                message.tokenId = data.tokenId;
            }
            if (data.account != null) {
                message.account = data.account;
            }
            return message;
        }
        toObject() {
            const data: SingleTokenAccount.AsObject = {
                address: this.address,
                tokenId: this.tokenId,
                account: this.account
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.address.length)
                writer.writeString(1, this.address);
            if (this.tokenId.length)
                writer.writeString(2, this.tokenId);
            if (this.account.length)
                writer.writeString(3, this.account);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SingleTokenAccount {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SingleTokenAccount();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.address = reader.readString();
                        break;
                    case 2:
                        message.tokenId = reader.readString();
                        break;
                    case 3:
                        message.account = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SingleTokenAccount {
            return SingleTokenAccount.deserialize(bytes);
        }
    }
    export namespace SingleTokenAccount {
        export type AsObject = {
            address: string;
            tokenId: string;
            account: string;
        };
        export type AsObjectPartial = {
            address?: string;
            tokenId?: string;
            account?: string;
        };
    }
}
export class ERC1155TransferListResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        transfers?: ERC1155Transfer[];
        navigation?: dependency_2.NavigationData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("transfers" in data && data.transfers != undefined) {
                this.transfers = data.transfers;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
        }
    }
    get transfers() {
        return pb_1.Message.getRepeatedWrapperField(this, ERC1155Transfer, 1) as ERC1155Transfer[];
    }
    set transfers(value: ERC1155Transfer[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_2.NavigationData, 2) as dependency_2.NavigationData | undefined;
    }
    set navigation(value: dependency_2.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    static fromObject(data?: ERC1155TransferListResponse.AsObjectPartial): ERC1155TransferListResponse {
        if (!data) {
            return new ERC1155TransferListResponse();
        }
        const message = new ERC1155TransferListResponse({});
        if (data.transfers != null) {
            message.transfers = data.transfers.map(item => ERC1155Transfer.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_2.NavigationData.fromObject(data.navigation);
        }
        return message;
    }
    toObject() {
        const data: ERC1155TransferListResponse.AsObject = {
            transfers: this.transfers.map((item: ERC1155Transfer) => item.toObject())
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.transfers.length)
            writer.writeRepeatedMessage(1, this.transfers, (item: ERC1155Transfer) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(2, this.navigation, () => this.navigation!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155TransferListResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155TransferListResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.transfers, () => pb_1.Message.addToRepeatedWrapperField(message, 1, ERC1155Transfer.deserialize(reader), ERC1155Transfer));
                    break;
                case 2:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_2.NavigationData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155TransferListResponse {
        return ERC1155TransferListResponse.deserialize(bytes);
    }
}
export namespace ERC1155TransferListResponse {
    export type AsObject = {
        transfers: ERC1155Transfer.AsObject[];
        navigation?: dependency_2.NavigationData.AsObject;
    };
    export type AsObjectPartial = {
        transfers?: ERC1155Transfer.AsObjectPartial[];
        navigation?: dependency_2.NavigationData.AsObjectPartial;
    };
}
export class ERC1155HolderListRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[4, 5, 6, 7]];
    constructor(data?: any[] | ({
        blockchainIndex?: number;
        address?: string;
        page?: dependency_2.Page;
    } & (({
        all?: boolean;
        account?: never;
        singleToken?: never;
        singleTokenAccount?: never;
    } | {
        all?: never;
        account?: string;
        singleToken?: never;
        singleTokenAccount?: never;
    } | {
        all?: never;
        account?: never;
        singleToken?: string;
        singleTokenAccount?: never;
    } | {
        all?: never;
        account?: never;
        singleToken?: never;
        singleTokenAccount?: ERC1155HolderListRequest.SingleTokenAccount;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("all" in data && data.all != undefined) {
                this.all = data.all;
            }
            if ("account" in data && data.account != undefined) {
                this.account = data.account;
            }
            if ("singleToken" in data && data.singleToken != undefined) {
                this.singleToken = data.singleToken;
            }
            if ("singleTokenAccount" in data && data.singleTokenAccount != undefined) {
                this.singleTokenAccount = data.singleTokenAccount;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get all() {
        return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
    }
    set all(value: boolean) {
        pb_1.Message.setOneofField(this, 4, this.#one_of_decls[0]!, value);
    }
    get hasAll() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearAll(): void {
        this.all = undefined!;
    }
    get account() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set account(value: string) {
        pb_1.Message.setOneofField(this, 5, this.#one_of_decls[0]!, value);
    }
    get hasAccount() {
        return pb_1.Message.getField(this, 5) != null;
    }
    clearAccount(): void {
        this.account = undefined!;
    }
    get singleToken() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set singleToken(value: string) {
        pb_1.Message.setOneofField(this, 6, this.#one_of_decls[0]!, value);
    }
    get hasSingleToken() {
        return pb_1.Message.getField(this, 6) != null;
    }
    clearSingleToken(): void {
        this.singleToken = undefined!;
    }
    get singleTokenAccount() {
        return pb_1.Message.getWrapperField(this, ERC1155HolderListRequest.SingleTokenAccount, 7) as ERC1155HolderListRequest.SingleTokenAccount | undefined;
    }
    set singleTokenAccount(value: ERC1155HolderListRequest.SingleTokenAccount | undefined) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0]!, value);
    }
    get hasSingleTokenAccount() {
        return pb_1.Message.getField(this, 7) != null;
    }
    clearSingleTokenAccount(): void {
        this.singleTokenAccount = undefined!;
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_2.Page, 3) as dependency_2.Page | undefined;
    }
    set page(value: dependency_2.Page | undefined) {
        pb_1.Message.setWrapperField(this, 3, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "all" | "account" | "singleToken" | "singleTokenAccount";
        } = {
            0: "none",
            4: "all",
            5: "account",
            6: "singleToken",
            7: "singleTokenAccount"
        };
        return cases[pb_1.Message.computeOneofCase(this, [4, 5, 6, 7])]!;
    }
    static fromObject(data?: ERC1155HolderListRequest.AsObjectPartial): ERC1155HolderListRequest {
        if (!data) {
            return new ERC1155HolderListRequest();
        }
        const message = new ERC1155HolderListRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.all != null) {
            message.all = data.all;
        }
        if (data.account != null) {
            message.account = data.account;
        }
        if (data.singleToken != null) {
            message.singleToken = data.singleToken;
        }
        if (data.singleTokenAccount != null) {
            message.singleTokenAccount = ERC1155HolderListRequest.SingleTokenAccount.fromObject(data.singleTokenAccount);
        }
        if (data.page != null) {
            message.page = dependency_2.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: ERC1155HolderListRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address,
            all: this.all,
            account: this.account,
            singleToken: this.singleToken
        };
        if (this.singleTokenAccount != null) {
            data.singleTokenAccount = this.singleTokenAccount.toObject();
        }
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (this.hasAll)
            writer.writeBool(4, this.all);
        if (this.hasAccount)
            writer.writeString(5, this.account);
        if (this.hasSingleToken)
            writer.writeString(6, this.singleToken);
        if (this.hasSingleTokenAccount)
            writer.writeMessage(7, this.singleTokenAccount, () => this.singleTokenAccount!.serialize(writer));
        if (this.hasPage)
            writer.writeMessage(3, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155HolderListRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155HolderListRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                case 4:
                    message.all = reader.readBool();
                    break;
                case 5:
                    message.account = reader.readString();
                    break;
                case 6:
                    message.singleToken = reader.readString();
                    break;
                case 7:
                    reader.readMessage(message.singleTokenAccount, () => message.singleTokenAccount = ERC1155HolderListRequest.SingleTokenAccount.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.page, () => message.page = dependency_2.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155HolderListRequest {
        return ERC1155HolderListRequest.deserialize(bytes);
    }
}
export namespace ERC1155HolderListRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
        all: boolean;
        account: string;
        singleToken: string;
        singleTokenAccount?: ERC1155HolderListRequest.SingleTokenAccount.AsObject;
        page?: dependency_2.Page.AsObject;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
        all?: boolean;
        account?: string;
        singleToken?: string;
        singleTokenAccount?: ERC1155HolderListRequest.SingleTokenAccount.AsObjectPartial;
        page?: dependency_2.Page.AsObjectPartial;
    };
    export class SingleTokenAccount extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            tokenId?: string;
            account?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("tokenId" in data && data.tokenId != undefined) {
                    this.tokenId = data.tokenId;
                }
                if ("account" in data && data.account != undefined) {
                    this.account = data.account;
                }
            }
        }
        get tokenId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set tokenId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get account() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set account(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data?: SingleTokenAccount.AsObjectPartial): SingleTokenAccount {
            if (!data) {
                return new SingleTokenAccount();
            }
            const message = new SingleTokenAccount({});
            if (data.tokenId != null) {
                message.tokenId = data.tokenId;
            }
            if (data.account != null) {
                message.account = data.account;
            }
            return message;
        }
        toObject() {
            const data: SingleTokenAccount.AsObject = {
                tokenId: this.tokenId,
                account: this.account
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.tokenId.length)
                writer.writeString(1, this.tokenId);
            if (this.account.length)
                writer.writeString(2, this.account);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SingleTokenAccount {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SingleTokenAccount();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.tokenId = reader.readString();
                        break;
                    case 2:
                        message.account = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SingleTokenAccount {
            return SingleTokenAccount.deserialize(bytes);
        }
    }
    export namespace SingleTokenAccount {
        export type AsObject = {
            tokenId: string;
            account: string;
        };
        export type AsObjectPartial = {
            tokenId?: string;
            account?: string;
        };
    }
}
export class ERC1155HolderListResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        holders?: ERC1155Holder[];
        navigation?: dependency_2.NavigationData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("holders" in data && data.holders != undefined) {
                this.holders = data.holders;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
        }
    }
    get holders() {
        return pb_1.Message.getRepeatedWrapperField(this, ERC1155Holder, 1) as ERC1155Holder[];
    }
    set holders(value: ERC1155Holder[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_2.NavigationData, 2) as dependency_2.NavigationData | undefined;
    }
    set navigation(value: dependency_2.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    static fromObject(data?: ERC1155HolderListResponse.AsObjectPartial): ERC1155HolderListResponse {
        if (!data) {
            return new ERC1155HolderListResponse();
        }
        const message = new ERC1155HolderListResponse({});
        if (data.holders != null) {
            message.holders = data.holders.map(item => ERC1155Holder.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_2.NavigationData.fromObject(data.navigation);
        }
        return message;
    }
    toObject() {
        const data: ERC1155HolderListResponse.AsObject = {
            holders: this.holders.map((item: ERC1155Holder) => item.toObject())
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.holders.length)
            writer.writeRepeatedMessage(1, this.holders, (item: ERC1155Holder) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(2, this.navigation, () => this.navigation!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155HolderListResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155HolderListResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.holders, () => pb_1.Message.addToRepeatedWrapperField(message, 1, ERC1155Holder.deserialize(reader), ERC1155Holder));
                    break;
                case 2:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_2.NavigationData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155HolderListResponse {
        return ERC1155HolderListResponse.deserialize(bytes);
    }
}
export namespace ERC1155HolderListResponse {
    export type AsObject = {
        holders: ERC1155Holder.AsObject[];
        navigation?: dependency_2.NavigationData.AsObject;
    };
    export type AsObjectPartial = {
        holders?: ERC1155Holder.AsObjectPartial[];
        navigation?: dependency_2.NavigationData.AsObjectPartial;
    };
}
export class ERC1155InventoryListRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[4, 5, 6, 7]];
    constructor(data?: any[] | ({
        blockchainIndex?: number;
        address?: string;
        page?: dependency_2.Page;
    } & (({
        all?: boolean;
        owner?: never;
        singleToken?: never;
        singleTokenAccount?: never;
    } | {
        all?: never;
        owner?: string;
        singleToken?: never;
        singleTokenAccount?: never;
    } | {
        all?: never;
        owner?: never;
        singleToken?: string;
        singleTokenAccount?: never;
    } | {
        all?: never;
        owner?: never;
        singleToken?: never;
        singleTokenAccount?: ERC1155InventoryListRequest.SingleTokenAccount;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("all" in data && data.all != undefined) {
                this.all = data.all;
            }
            if ("owner" in data && data.owner != undefined) {
                this.owner = data.owner;
            }
            if ("singleToken" in data && data.singleToken != undefined) {
                this.singleToken = data.singleToken;
            }
            if ("singleTokenAccount" in data && data.singleTokenAccount != undefined) {
                this.singleTokenAccount = data.singleTokenAccount;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get all() {
        return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
    }
    set all(value: boolean) {
        pb_1.Message.setOneofField(this, 4, this.#one_of_decls[0]!, value);
    }
    get hasAll() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearAll(): void {
        this.all = undefined!;
    }
    get owner() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set owner(value: string) {
        pb_1.Message.setOneofField(this, 5, this.#one_of_decls[0]!, value);
    }
    get hasOwner() {
        return pb_1.Message.getField(this, 5) != null;
    }
    clearOwner(): void {
        this.owner = undefined!;
    }
    get singleToken() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set singleToken(value: string) {
        pb_1.Message.setOneofField(this, 6, this.#one_of_decls[0]!, value);
    }
    get hasSingleToken() {
        return pb_1.Message.getField(this, 6) != null;
    }
    clearSingleToken(): void {
        this.singleToken = undefined!;
    }
    get singleTokenAccount() {
        return pb_1.Message.getWrapperField(this, ERC1155InventoryListRequest.SingleTokenAccount, 7) as ERC1155InventoryListRequest.SingleTokenAccount | undefined;
    }
    set singleTokenAccount(value: ERC1155InventoryListRequest.SingleTokenAccount | undefined) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0]!, value);
    }
    get hasSingleTokenAccount() {
        return pb_1.Message.getField(this, 7) != null;
    }
    clearSingleTokenAccount(): void {
        this.singleTokenAccount = undefined!;
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_2.Page, 3) as dependency_2.Page | undefined;
    }
    set page(value: dependency_2.Page | undefined) {
        pb_1.Message.setWrapperField(this, 3, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "all" | "owner" | "singleToken" | "singleTokenAccount";
        } = {
            0: "none",
            4: "all",
            5: "owner",
            6: "singleToken",
            7: "singleTokenAccount"
        };
        return cases[pb_1.Message.computeOneofCase(this, [4, 5, 6, 7])]!;
    }
    static fromObject(data?: ERC1155InventoryListRequest.AsObjectPartial): ERC1155InventoryListRequest {
        if (!data) {
            return new ERC1155InventoryListRequest();
        }
        const message = new ERC1155InventoryListRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.all != null) {
            message.all = data.all;
        }
        if (data.owner != null) {
            message.owner = data.owner;
        }
        if (data.singleToken != null) {
            message.singleToken = data.singleToken;
        }
        if (data.singleTokenAccount != null) {
            message.singleTokenAccount = ERC1155InventoryListRequest.SingleTokenAccount.fromObject(data.singleTokenAccount);
        }
        if (data.page != null) {
            message.page = dependency_2.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: ERC1155InventoryListRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address,
            all: this.all,
            owner: this.owner,
            singleToken: this.singleToken
        };
        if (this.singleTokenAccount != null) {
            data.singleTokenAccount = this.singleTokenAccount.toObject();
        }
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (this.hasAll)
            writer.writeBool(4, this.all);
        if (this.hasOwner)
            writer.writeString(5, this.owner);
        if (this.hasSingleToken)
            writer.writeString(6, this.singleToken);
        if (this.hasSingleTokenAccount)
            writer.writeMessage(7, this.singleTokenAccount, () => this.singleTokenAccount!.serialize(writer));
        if (this.hasPage)
            writer.writeMessage(3, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155InventoryListRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155InventoryListRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                case 4:
                    message.all = reader.readBool();
                    break;
                case 5:
                    message.owner = reader.readString();
                    break;
                case 6:
                    message.singleToken = reader.readString();
                    break;
                case 7:
                    reader.readMessage(message.singleTokenAccount, () => message.singleTokenAccount = ERC1155InventoryListRequest.SingleTokenAccount.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.page, () => message.page = dependency_2.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155InventoryListRequest {
        return ERC1155InventoryListRequest.deserialize(bytes);
    }
}
export namespace ERC1155InventoryListRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
        all: boolean;
        owner: string;
        singleToken: string;
        singleTokenAccount?: ERC1155InventoryListRequest.SingleTokenAccount.AsObject;
        page?: dependency_2.Page.AsObject;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
        all?: boolean;
        owner?: string;
        singleToken?: string;
        singleTokenAccount?: ERC1155InventoryListRequest.SingleTokenAccount.AsObjectPartial;
        page?: dependency_2.Page.AsObjectPartial;
    };
    export class SingleTokenAccount extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            tokenId?: string;
            account?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("tokenId" in data && data.tokenId != undefined) {
                    this.tokenId = data.tokenId;
                }
                if ("account" in data && data.account != undefined) {
                    this.account = data.account;
                }
            }
        }
        get tokenId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set tokenId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get account() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set account(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data?: SingleTokenAccount.AsObjectPartial): SingleTokenAccount {
            if (!data) {
                return new SingleTokenAccount();
            }
            const message = new SingleTokenAccount({});
            if (data.tokenId != null) {
                message.tokenId = data.tokenId;
            }
            if (data.account != null) {
                message.account = data.account;
            }
            return message;
        }
        toObject() {
            const data: SingleTokenAccount.AsObject = {
                tokenId: this.tokenId,
                account: this.account
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.tokenId.length)
                writer.writeString(1, this.tokenId);
            if (this.account.length)
                writer.writeString(2, this.account);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SingleTokenAccount {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SingleTokenAccount();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.tokenId = reader.readString();
                        break;
                    case 2:
                        message.account = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SingleTokenAccount {
            return SingleTokenAccount.deserialize(bytes);
        }
    }
    export namespace SingleTokenAccount {
        export type AsObject = {
            tokenId: string;
            account: string;
        };
        export type AsObjectPartial = {
            tokenId?: string;
            account?: string;
        };
    }
}
export class ERC1155InventoryListResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        nfts?: ERC1155NFT[];
        navigation?: dependency_2.NavigationData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("nfts" in data && data.nfts != undefined) {
                this.nfts = data.nfts;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
        }
    }
    get nfts() {
        return pb_1.Message.getRepeatedWrapperField(this, ERC1155NFT, 1) as ERC1155NFT[];
    }
    set nfts(value: ERC1155NFT[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_2.NavigationData, 2) as dependency_2.NavigationData | undefined;
    }
    set navigation(value: dependency_2.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    static fromObject(data?: ERC1155InventoryListResponse.AsObjectPartial): ERC1155InventoryListResponse {
        if (!data) {
            return new ERC1155InventoryListResponse();
        }
        const message = new ERC1155InventoryListResponse({});
        if (data.nfts != null) {
            message.nfts = data.nfts.map(item => ERC1155NFT.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_2.NavigationData.fromObject(data.navigation);
        }
        return message;
    }
    toObject() {
        const data: ERC1155InventoryListResponse.AsObject = {
            nfts: this.nfts.map((item: ERC1155NFT) => item.toObject())
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.nfts.length)
            writer.writeRepeatedMessage(1, this.nfts, (item: ERC1155NFT) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(2, this.navigation, () => this.navigation!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155InventoryListResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155InventoryListResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.nfts, () => pb_1.Message.addToRepeatedWrapperField(message, 1, ERC1155NFT.deserialize(reader), ERC1155NFT));
                    break;
                case 2:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_2.NavigationData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155InventoryListResponse {
        return ERC1155InventoryListResponse.deserialize(bytes);
    }
}
export namespace ERC1155InventoryListResponse {
    export type AsObject = {
        nfts: ERC1155NFT.AsObject[];
        navigation?: dependency_2.NavigationData.AsObject;
    };
    export type AsObjectPartial = {
        nfts?: ERC1155NFT.AsObjectPartial[];
        navigation?: dependency_2.NavigationData.AsObjectPartial;
    };
}
export class ERC1155UpdateIconRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        address?: string;
        icon?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("icon" in data && data.icon != undefined) {
                this.icon = data.icon;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get icon() {
        return pb_1.Message.getFieldWithDefault(this, 3, new Uint8Array()) as Uint8Array;
    }
    set icon(value: Uint8Array) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data?: ERC1155UpdateIconRequest.AsObjectPartial): ERC1155UpdateIconRequest {
        if (!data) {
            return new ERC1155UpdateIconRequest();
        }
        const message = new ERC1155UpdateIconRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.icon != null) {
            message.icon = data.icon;
        }
        return message;
    }
    toObject() {
        const data: ERC1155UpdateIconRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address,
            icon: this.icon
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (this.icon.length)
            writer.writeBytes(3, this.icon);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155UpdateIconRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155UpdateIconRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                case 3:
                    message.icon = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155UpdateIconRequest {
        return ERC1155UpdateIconRequest.deserialize(bytes);
    }
}
export namespace ERC1155UpdateIconRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
        icon: Uint8Array;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
        icon?: Uint8Array;
    };
}
export class ERC1155UpdateIconResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        token?: ERC1155Token;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("token" in data && data.token != undefined) {
                this.token = data.token;
            }
        }
    }
    get token() {
        return pb_1.Message.getWrapperField(this, ERC1155Token, 1) as ERC1155Token | undefined;
    }
    set token(value: ERC1155Token | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasToken() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearToken(): void {
        this.token = undefined!;
    }
    static fromObject(data?: ERC1155UpdateIconResponse.AsObjectPartial): ERC1155UpdateIconResponse {
        if (!data) {
            return new ERC1155UpdateIconResponse();
        }
        const message = new ERC1155UpdateIconResponse({});
        if (data.token != null) {
            message.token = ERC1155Token.fromObject(data.token);
        }
        return message;
    }
    toObject() {
        const data: ERC1155UpdateIconResponse.AsObject = {};
        if (this.token != null) {
            data.token = this.token.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasToken)
            writer.writeMessage(1, this.token, () => this.token!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155UpdateIconResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155UpdateIconResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.token, () => message.token = ERC1155Token.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155UpdateIconResponse {
        return ERC1155UpdateIconResponse.deserialize(bytes);
    }
}
export namespace ERC1155UpdateIconResponse {
    export type AsObject = {
        token?: ERC1155Token.AsObject;
    };
    export type AsObjectPartial = {
        token?: ERC1155Token.AsObjectPartial;
    };
}
export class ERC1155UpdateRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        address?: string;
        token?: ERC1155Token;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("token" in data && data.token != undefined) {
                this.token = data.token;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get token() {
        return pb_1.Message.getWrapperField(this, ERC1155Token, 3) as ERC1155Token | undefined;
    }
    set token(value: ERC1155Token | undefined) {
        pb_1.Message.setWrapperField(this, 3, value);
    }
    get hasToken() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearToken(): void {
        this.token = undefined!;
    }
    static fromObject(data?: ERC1155UpdateRequest.AsObjectPartial): ERC1155UpdateRequest {
        if (!data) {
            return new ERC1155UpdateRequest();
        }
        const message = new ERC1155UpdateRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.token != null) {
            message.token = ERC1155Token.fromObject(data.token);
        }
        return message;
    }
    toObject() {
        const data: ERC1155UpdateRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address
        };
        if (this.token != null) {
            data.token = this.token.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (this.hasToken)
            writer.writeMessage(3, this.token, () => this.token!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155UpdateRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155UpdateRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                case 3:
                    reader.readMessage(message.token, () => message.token = ERC1155Token.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155UpdateRequest {
        return ERC1155UpdateRequest.deserialize(bytes);
    }
}
export namespace ERC1155UpdateRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
        token?: ERC1155Token.AsObject;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
        token?: ERC1155Token.AsObjectPartial;
    };
}
export class ERC1155UpdateResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        token?: ERC1155Token;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("token" in data && data.token != undefined) {
                this.token = data.token;
            }
        }
    }
    get token() {
        return pb_1.Message.getWrapperField(this, ERC1155Token, 1) as ERC1155Token | undefined;
    }
    set token(value: ERC1155Token | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasToken() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearToken(): void {
        this.token = undefined!;
    }
    static fromObject(data?: ERC1155UpdateResponse.AsObjectPartial): ERC1155UpdateResponse {
        if (!data) {
            return new ERC1155UpdateResponse();
        }
        const message = new ERC1155UpdateResponse({});
        if (data.token != null) {
            message.token = ERC1155Token.fromObject(data.token);
        }
        return message;
    }
    toObject() {
        const data: ERC1155UpdateResponse.AsObject = {};
        if (this.token != null) {
            data.token = this.token.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasToken)
            writer.writeMessage(1, this.token, () => this.token!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155UpdateResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155UpdateResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.token, () => message.token = ERC1155Token.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155UpdateResponse {
        return ERC1155UpdateResponse.deserialize(bytes);
    }
}
export namespace ERC1155UpdateResponse {
    export type AsObject = {
        token?: ERC1155Token.AsObject;
    };
    export type AsObjectPartial = {
        token?: ERC1155Token.AsObjectPartial;
    };
}
export class ERC1155TokenGetOrCreateRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        address?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: ERC1155TokenGetOrCreateRequest.AsObjectPartial): ERC1155TokenGetOrCreateRequest {
        if (!data) {
            return new ERC1155TokenGetOrCreateRequest();
        }
        const message = new ERC1155TokenGetOrCreateRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        return message;
    }
    toObject() {
        const data: ERC1155TokenGetOrCreateRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155TokenGetOrCreateRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155TokenGetOrCreateRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155TokenGetOrCreateRequest {
        return ERC1155TokenGetOrCreateRequest.deserialize(bytes);
    }
}
export namespace ERC1155TokenGetOrCreateRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
    };
}
export class ERC1155TokensAddRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        tokens?: ERC1155Token[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("tokens" in data && data.tokens != undefined) {
                this.tokens = data.tokens;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get tokens() {
        return pb_1.Message.getRepeatedWrapperField(this, ERC1155Token, 2) as ERC1155Token[];
    }
    set tokens(value: ERC1155Token[]) {
        pb_1.Message.setRepeatedWrapperField(this, 2, value);
    }
    static fromObject(data?: ERC1155TokensAddRequest.AsObjectPartial): ERC1155TokensAddRequest {
        if (!data) {
            return new ERC1155TokensAddRequest();
        }
        const message = new ERC1155TokensAddRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.tokens != null) {
            message.tokens = data.tokens.map(item => ERC1155Token.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: ERC1155TokensAddRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            tokens: this.tokens.map((item: ERC1155Token) => item.toObject())
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.tokens.length)
            writer.writeRepeatedMessage(2, this.tokens, (item: ERC1155Token) => item!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155TokensAddRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155TokensAddRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    reader.readMessage(message.tokens, () => pb_1.Message.addToRepeatedWrapperField(message, 2, ERC1155Token.deserialize(reader), ERC1155Token));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155TokensAddRequest {
        return ERC1155TokensAddRequest.deserialize(bytes);
    }
}
export namespace ERC1155TokensAddRequest {
    export type AsObject = {
        blockchainIndex: number;
        tokens: ERC1155Token.AsObject[];
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        tokens?: ERC1155Token.AsObjectPartial[];
    };
}
export class ERC1155TokenAddResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        token?: ERC1155Token;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("token" in data && data.token != undefined) {
                this.token = data.token;
            }
        }
    }
    get token() {
        return pb_1.Message.getWrapperField(this, ERC1155Token, 1) as ERC1155Token | undefined;
    }
    set token(value: ERC1155Token | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasToken() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearToken(): void {
        this.token = undefined!;
    }
    static fromObject(data?: ERC1155TokenAddResponse.AsObjectPartial): ERC1155TokenAddResponse {
        if (!data) {
            return new ERC1155TokenAddResponse();
        }
        const message = new ERC1155TokenAddResponse({});
        if (data.token != null) {
            message.token = ERC1155Token.fromObject(data.token);
        }
        return message;
    }
    toObject() {
        const data: ERC1155TokenAddResponse.AsObject = {};
        if (this.token != null) {
            data.token = this.token.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasToken)
            writer.writeMessage(1, this.token, () => this.token!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155TokenAddResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155TokenAddResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.token, () => message.token = ERC1155Token.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155TokenAddResponse {
        return ERC1155TokenAddResponse.deserialize(bytes);
    }
}
export namespace ERC1155TokenAddResponse {
    export type AsObject = {
        token?: ERC1155Token.AsObject;
    };
    export type AsObjectPartial = {
        token?: ERC1155Token.AsObjectPartial;
    };
}
export class ERC1155TransfersAddRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        transfers?: ERC1155TransfersAddRequest.ERC1155TransferToAdd[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("transfers" in data && data.transfers != undefined) {
                this.transfers = data.transfers;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get transfers() {
        return pb_1.Message.getRepeatedWrapperField(this, ERC1155TransfersAddRequest.ERC1155TransferToAdd, 2) as ERC1155TransfersAddRequest.ERC1155TransferToAdd[];
    }
    set transfers(value: ERC1155TransfersAddRequest.ERC1155TransferToAdd[]) {
        pb_1.Message.setRepeatedWrapperField(this, 2, value);
    }
    static fromObject(data?: ERC1155TransfersAddRequest.AsObjectPartial): ERC1155TransfersAddRequest {
        if (!data) {
            return new ERC1155TransfersAddRequest();
        }
        const message = new ERC1155TransfersAddRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.transfers != null) {
            message.transfers = data.transfers.map(item => ERC1155TransfersAddRequest.ERC1155TransferToAdd.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: ERC1155TransfersAddRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            transfers: this.transfers.map((item: ERC1155TransfersAddRequest.ERC1155TransferToAdd) => item.toObject())
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.transfers.length)
            writer.writeRepeatedMessage(2, this.transfers, (item: ERC1155TransfersAddRequest.ERC1155TransferToAdd) => item!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155TransfersAddRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155TransfersAddRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    reader.readMessage(message.transfers, () => pb_1.Message.addToRepeatedWrapperField(message, 2, ERC1155TransfersAddRequest.ERC1155TransferToAdd.deserialize(reader), ERC1155TransfersAddRequest.ERC1155TransferToAdd));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155TransfersAddRequest {
        return ERC1155TransfersAddRequest.deserialize(bytes);
    }
}
export namespace ERC1155TransfersAddRequest {
    export type AsObject = {
        blockchainIndex: number;
        transfers: ERC1155TransfersAddRequest.ERC1155TransferToAdd.AsObject[];
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        transfers?: ERC1155TransfersAddRequest.ERC1155TransferToAdd.AsObjectPartial[];
    };
    export class ERC1155TransferToAdd extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            tokenAddress?: string;
            transfer?: ERC1155Transfer;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("tokenAddress" in data && data.tokenAddress != undefined) {
                    this.tokenAddress = data.tokenAddress;
                }
                if ("transfer" in data && data.transfer != undefined) {
                    this.transfer = data.transfer;
                }
            }
        }
        get tokenAddress() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set tokenAddress(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get transfer() {
            return pb_1.Message.getWrapperField(this, ERC1155Transfer, 2) as ERC1155Transfer | undefined;
        }
        set transfer(value: ERC1155Transfer | undefined) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get hasTransfer() {
            return pb_1.Message.getField(this, 2) != null;
        }
        clearTransfer(): void {
            this.transfer = undefined!;
        }
        static fromObject(data?: ERC1155TransferToAdd.AsObjectPartial): ERC1155TransferToAdd {
            if (!data) {
                return new ERC1155TransferToAdd();
            }
            const message = new ERC1155TransferToAdd({});
            if (data.tokenAddress != null) {
                message.tokenAddress = data.tokenAddress;
            }
            if (data.transfer != null) {
                message.transfer = ERC1155Transfer.fromObject(data.transfer);
            }
            return message;
        }
        toObject() {
            const data: ERC1155TransferToAdd.AsObject = {
                tokenAddress: this.tokenAddress
            };
            if (this.transfer != null) {
                data.transfer = this.transfer.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.tokenAddress.length)
                writer.writeString(1, this.tokenAddress);
            if (this.hasTransfer)
                writer.writeMessage(2, this.transfer, () => this.transfer!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155TransferToAdd {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155TransferToAdd();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.tokenAddress = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.transfer, () => message.transfer = ERC1155Transfer.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ERC1155TransferToAdd {
            return ERC1155TransferToAdd.deserialize(bytes);
        }
    }
    export namespace ERC1155TransferToAdd {
        export type AsObject = {
            tokenAddress: string;
            transfer?: ERC1155Transfer.AsObject;
        };
        export type AsObjectPartial = {
            tokenAddress?: string;
            transfer?: ERC1155Transfer.AsObjectPartial;
        };
    }
}
export class ERC1155BalancesRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        address?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: ERC1155BalancesRequest.AsObjectPartial): ERC1155BalancesRequest {
        if (!data) {
            return new ERC1155BalancesRequest();
        }
        const message = new ERC1155BalancesRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        return message;
    }
    toObject() {
        const data: ERC1155BalancesRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155BalancesRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155BalancesRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155BalancesRequest {
        return ERC1155BalancesRequest.deserialize(bytes);
    }
}
export namespace ERC1155BalancesRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
    };
}
export class ERC1155BalancesResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        balances?: ERC1155Balance[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("balances" in data && data.balances != undefined) {
                this.balances = data.balances;
            }
        }
    }
    get balances() {
        return pb_1.Message.getRepeatedWrapperField(this, ERC1155Balance, 1) as ERC1155Balance[];
    }
    set balances(value: ERC1155Balance[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data?: ERC1155BalancesResponse.AsObjectPartial): ERC1155BalancesResponse {
        if (!data) {
            return new ERC1155BalancesResponse();
        }
        const message = new ERC1155BalancesResponse({});
        if (data.balances != null) {
            message.balances = data.balances.map(item => ERC1155Balance.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: ERC1155BalancesResponse.AsObject = {
            balances: this.balances.map((item: ERC1155Balance) => item.toObject())
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.balances.length)
            writer.writeRepeatedMessage(1, this.balances, (item: ERC1155Balance) => item!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155BalancesResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155BalancesResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.balances, () => pb_1.Message.addToRepeatedWrapperField(message, 1, ERC1155Balance.deserialize(reader), ERC1155Balance));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155BalancesResponse {
        return ERC1155BalancesResponse.deserialize(bytes);
    }
}
export namespace ERC1155BalancesResponse {
    export type AsObject = {
        balances: ERC1155Balance.AsObject[];
    };
    export type AsObjectPartial = {
        balances?: ERC1155Balance.AsObjectPartial[];
    };
}
export class ERC1155DisableRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        address?: string;
        disabled?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("disabled" in data && data.disabled != undefined) {
                this.disabled = data.disabled;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get disabled() {
        return pb_1.Message.getFieldWithDefault(this, 3, false) as boolean;
    }
    set disabled(value: boolean) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data?: ERC1155DisableRequest.AsObjectPartial): ERC1155DisableRequest {
        if (!data) {
            return new ERC1155DisableRequest();
        }
        const message = new ERC1155DisableRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.disabled != null) {
            message.disabled = data.disabled;
        }
        return message;
    }
    toObject() {
        const data: ERC1155DisableRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address,
            disabled: this.disabled
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (this.disabled != false)
            writer.writeBool(3, this.disabled);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155DisableRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155DisableRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                case 3:
                    message.disabled = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155DisableRequest {
        return ERC1155DisableRequest.deserialize(bytes);
    }
}
export namespace ERC1155DisableRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
        disabled: boolean;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
        disabled?: boolean;
    };
}
export class ERC1155DisableResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        token?: ERC1155Token;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("token" in data && data.token != undefined) {
                this.token = data.token;
            }
        }
    }
    get token() {
        return pb_1.Message.getWrapperField(this, ERC1155Token, 1) as ERC1155Token | undefined;
    }
    set token(value: ERC1155Token | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasToken() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearToken(): void {
        this.token = undefined!;
    }
    static fromObject(data?: ERC1155DisableResponse.AsObjectPartial): ERC1155DisableResponse {
        if (!data) {
            return new ERC1155DisableResponse();
        }
        const message = new ERC1155DisableResponse({});
        if (data.token != null) {
            message.token = ERC1155Token.fromObject(data.token);
        }
        return message;
    }
    toObject() {
        const data: ERC1155DisableResponse.AsObject = {};
        if (this.token != null) {
            data.token = this.token.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasToken)
            writer.writeMessage(1, this.token, () => this.token!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155DisableResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155DisableResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.token, () => message.token = ERC1155Token.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155DisableResponse {
        return ERC1155DisableResponse.deserialize(bytes);
    }
}
export namespace ERC1155DisableResponse {
    export type AsObject = {
        token?: ERC1155Token.AsObject;
    };
    export type AsObjectPartial = {
        token?: ERC1155Token.AsObjectPartial;
    };
}
export class ERC1155TokenListUnfilteredRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        page?: dependency_2.Page;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_2.Page, 2) as dependency_2.Page | undefined;
    }
    set page(value: dependency_2.Page | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    static fromObject(data?: ERC1155TokenListUnfilteredRequest.AsObjectPartial): ERC1155TokenListUnfilteredRequest {
        if (!data) {
            return new ERC1155TokenListUnfilteredRequest();
        }
        const message = new ERC1155TokenListUnfilteredRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.page != null) {
            message.page = dependency_2.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: ERC1155TokenListUnfilteredRequest.AsObject = {
            blockchainIndex: this.blockchainIndex
        };
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.hasPage)
            writer.writeMessage(2, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155TokenListUnfilteredRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155TokenListUnfilteredRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    reader.readMessage(message.page, () => message.page = dependency_2.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC1155TokenListUnfilteredRequest {
        return ERC1155TokenListUnfilteredRequest.deserialize(bytes);
    }
}
export namespace ERC1155TokenListUnfilteredRequest {
    export type AsObject = {
        blockchainIndex: number;
        page?: dependency_2.Page.AsObject;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        page?: dependency_2.Page.AsObjectPartial;
    };
}
