/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.13.0
 * source: block.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./validate/validate";
import * as dependency_2 from "./models";
import * as dependency_3 from "./transaction";
import * as pb_1 from "google-protobuf";
export class BlockAVM extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        height?: number;
        parents?: string[];
        parentIndicesInBc?: number[];
        isStopVertex?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2, 3], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("height" in data && data.height != undefined) {
                this.height = data.height;
            }
            if ("parents" in data && data.parents != undefined) {
                this.parents = data.parents;
            }
            if ("parentIndicesInBc" in data && data.parentIndicesInBc != undefined) {
                this.parentIndicesInBc = data.parentIndicesInBc;
            }
            if ("isStopVertex" in data && data.isStopVertex != undefined) {
                this.isStopVertex = data.isStopVertex;
            }
        }
    }
    get height() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set height(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get parents() {
        return pb_1.Message.getFieldWithDefault(this, 2, []) as string[];
    }
    set parents(value: string[]) {
        pb_1.Message.setField(this, 2, value);
    }
    get parentIndicesInBc() {
        return pb_1.Message.getFieldWithDefault(this, 3, []) as number[];
    }
    set parentIndicesInBc(value: number[]) {
        pb_1.Message.setField(this, 3, value);
    }
    get isStopVertex() {
        return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
    }
    set isStopVertex(value: boolean) {
        pb_1.Message.setField(this, 4, value);
    }
    static fromObject(data?: BlockAVM.AsObjectPartial): BlockAVM {
        if (!data) {
            return new BlockAVM();
        }
        const message = new BlockAVM({});
        if (data.height != null) {
            message.height = data.height;
        }
        if (data.parents != null) {
            message.parents = data.parents;
        }
        if (data.parentIndicesInBc != null) {
            message.parentIndicesInBc = data.parentIndicesInBc;
        }
        if (data.isStopVertex != null) {
            message.isStopVertex = data.isStopVertex;
        }
        return message;
    }
    toObject() {
        const data: BlockAVM.AsObject = {
            height: this.height,
            parents: this.parents,
            parentIndicesInBc: this.parentIndicesInBc,
            isStopVertex: this.isStopVertex
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.height != 0)
            writer.writeUint64(1, this.height);
        if (this.parents.length)
            writer.writeRepeatedString(2, this.parents);
        if (this.parentIndicesInBc.length)
            writer.writePackedInt64(3, this.parentIndicesInBc);
        if (this.isStopVertex != false)
            writer.writeBool(4, this.isStopVertex);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockAVM {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockAVM();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.height = reader.readUint64();
                    break;
                case 2:
                    pb_1.Message.addToRepeatedField(message, 2, reader.readString());
                    break;
                case 3:
                    message.parentIndicesInBc = reader.readPackedInt64();
                    break;
                case 4:
                    message.isStopVertex = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BlockAVM {
        return BlockAVM.deserialize(bytes);
    }
}
export namespace BlockAVM {
    export type AsObject = {
        height: number;
        parents: string[];
        parentIndicesInBc: number[];
        isStopVertex: boolean;
    };
    export type AsObjectPartial = {
        height?: number;
        parents?: string[];
        parentIndicesInBc?: number[];
        isStopVertex?: boolean;
    };
}
export class BlockPVM extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        height?: number;
        parent?: string;
        parentIndexInBc?: number;
        kind?: BlockPVM.Kind;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("height" in data && data.height != undefined) {
                this.height = data.height;
            }
            if ("parent" in data && data.parent != undefined) {
                this.parent = data.parent;
            }
            if ("parentIndexInBc" in data && data.parentIndexInBc != undefined) {
                this.parentIndexInBc = data.parentIndexInBc;
            }
            if ("kind" in data && data.kind != undefined) {
                this.kind = data.kind;
            }
        }
    }
    get height() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set height(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get parent() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set parent(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get parentIndexInBc() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set parentIndexInBc(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get kind() {
        return pb_1.Message.getFieldWithDefault(this, 4, BlockPVM.Kind.KindStandard) as BlockPVM.Kind;
    }
    set kind(value: BlockPVM.Kind) {
        pb_1.Message.setField(this, 4, value);
    }
    static fromObject(data?: BlockPVM.AsObjectPartial): BlockPVM {
        if (!data) {
            return new BlockPVM();
        }
        const message = new BlockPVM({});
        if (data.height != null) {
            message.height = data.height;
        }
        if (data.parent != null) {
            message.parent = data.parent;
        }
        if (data.parentIndexInBc != null) {
            message.parentIndexInBc = data.parentIndexInBc;
        }
        if (data.kind != null) {
            message.kind = data.kind;
        }
        return message;
    }
    toObject() {
        const data: BlockPVM.AsObject = {
            height: this.height,
            parent: this.parent,
            parentIndexInBc: this.parentIndexInBc,
            kind: this.kind
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.height != 0)
            writer.writeInt64(1, this.height);
        if (this.parent.length)
            writer.writeString(2, this.parent);
        if (this.parentIndexInBc != 0)
            writer.writeInt64(3, this.parentIndexInBc);
        if (this.kind != BlockPVM.Kind.KindStandard)
            writer.writeEnum(4, this.kind);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockPVM {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockPVM();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.height = reader.readInt64();
                    break;
                case 2:
                    message.parent = reader.readString();
                    break;
                case 3:
                    message.parentIndexInBc = reader.readInt64();
                    break;
                case 4:
                    message.kind = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BlockPVM {
        return BlockPVM.deserialize(bytes);
    }
}
export namespace BlockPVM {
    export type AsObject = {
        height: number;
        parent: string;
        parentIndexInBc: number;
        kind: BlockPVM.Kind;
    };
    export type AsObjectPartial = {
        height?: number;
        parent?: string;
        parentIndexInBc?: number;
        kind?: BlockPVM.Kind;
    };
    export enum Kind {
        KindStandard = 0,
        KindCommit = 1,
        KindProposal = 2,
        KindAbort = 3,
        KindAtomic = 4,
        KindGenesis = 5
    }
}
export class BlockEVM extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        number?: number;
        parent?: string;
        parentIndexInBc?: number;
        nonce?: number;
        difficulty?: number;
        size?: number;
        gasLimit?: number;
        gasUsed?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("number" in data && data.number != undefined) {
                this.number = data.number;
            }
            if ("parent" in data && data.parent != undefined) {
                this.parent = data.parent;
            }
            if ("parentIndexInBc" in data && data.parentIndexInBc != undefined) {
                this.parentIndexInBc = data.parentIndexInBc;
            }
            if ("nonce" in data && data.nonce != undefined) {
                this.nonce = data.nonce;
            }
            if ("difficulty" in data && data.difficulty != undefined) {
                this.difficulty = data.difficulty;
            }
            if ("size" in data && data.size != undefined) {
                this.size = data.size;
            }
            if ("gasLimit" in data && data.gasLimit != undefined) {
                this.gasLimit = data.gasLimit;
            }
            if ("gasUsed" in data && data.gasUsed != undefined) {
                this.gasUsed = data.gasUsed;
            }
        }
    }
    get number() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set number(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get parent() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set parent(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get parentIndexInBc() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set parentIndexInBc(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get nonce() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set nonce(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get difficulty() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set difficulty(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get size() {
        return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
    }
    set size(value: number) {
        pb_1.Message.setField(this, 6, value);
    }
    get gasLimit() {
        return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
    }
    set gasLimit(value: number) {
        pb_1.Message.setField(this, 7, value);
    }
    get gasUsed() {
        return pb_1.Message.getFieldWithDefault(this, 8, 0) as number;
    }
    set gasUsed(value: number) {
        pb_1.Message.setField(this, 8, value);
    }
    static fromObject(data?: BlockEVM.AsObjectPartial): BlockEVM {
        if (!data) {
            return new BlockEVM();
        }
        const message = new BlockEVM({});
        if (data.number != null) {
            message.number = data.number;
        }
        if (data.parent != null) {
            message.parent = data.parent;
        }
        if (data.parentIndexInBc != null) {
            message.parentIndexInBc = data.parentIndexInBc;
        }
        if (data.nonce != null) {
            message.nonce = data.nonce;
        }
        if (data.difficulty != null) {
            message.difficulty = data.difficulty;
        }
        if (data.size != null) {
            message.size = data.size;
        }
        if (data.gasLimit != null) {
            message.gasLimit = data.gasLimit;
        }
        if (data.gasUsed != null) {
            message.gasUsed = data.gasUsed;
        }
        return message;
    }
    toObject() {
        const data: BlockEVM.AsObject = {
            number: this.number,
            parent: this.parent,
            parentIndexInBc: this.parentIndexInBc,
            nonce: this.nonce,
            difficulty: this.difficulty,
            size: this.size,
            gasLimit: this.gasLimit,
            gasUsed: this.gasUsed
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.number != 0)
            writer.writeUint64(1, this.number);
        if (this.parent.length)
            writer.writeString(2, this.parent);
        if (this.parentIndexInBc != 0)
            writer.writeInt64(3, this.parentIndexInBc);
        if (this.nonce != 0)
            writer.writeUint64(4, this.nonce);
        if (this.difficulty != 0)
            writer.writeUint64(5, this.difficulty);
        if (this.size != 0)
            writer.writeUint64(6, this.size);
        if (this.gasLimit != 0)
            writer.writeUint64(7, this.gasLimit);
        if (this.gasUsed != 0)
            writer.writeUint64(8, this.gasUsed);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockEVM {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockEVM();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.number = reader.readUint64();
                    break;
                case 2:
                    message.parent = reader.readString();
                    break;
                case 3:
                    message.parentIndexInBc = reader.readInt64();
                    break;
                case 4:
                    message.nonce = reader.readUint64();
                    break;
                case 5:
                    message.difficulty = reader.readUint64();
                    break;
                case 6:
                    message.size = reader.readUint64();
                    break;
                case 7:
                    message.gasLimit = reader.readUint64();
                    break;
                case 8:
                    message.gasUsed = reader.readUint64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BlockEVM {
        return BlockEVM.deserialize(bytes);
    }
}
export namespace BlockEVM {
    export type AsObject = {
        number: number;
        parent: string;
        parentIndexInBc: number;
        nonce: number;
        difficulty: number;
        size: number;
        gasLimit: number;
        gasUsed: number;
    };
    export type AsObjectPartial = {
        number?: number;
        parent?: string;
        parentIndexInBc?: number;
        nonce?: number;
        difficulty?: number;
        size?: number;
        gasLimit?: number;
        gasUsed?: number;
    };
}
export class Block extends pb_1.Message {
    #one_of_decls: number[][] = [[9, 10, 11]];
    constructor(data?: any[] | ({
        index?: number;
        indexInBc?: number;
        id?: string;
        blockchainId?: string;
        blockchainIndex?: number;
        transactions?: string[];
        transactionIndicesInBc?: number[];
        timestamp?: number;
    } & (({
        avm?: BlockAVM;
        pvm?: never;
        evm?: never;
    } | {
        avm?: never;
        pvm?: BlockPVM;
        evm?: never;
    } | {
        avm?: never;
        pvm?: never;
        evm?: BlockEVM;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [6, 7], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("index" in data && data.index != undefined) {
                this.index = data.index;
            }
            if ("indexInBc" in data && data.indexInBc != undefined) {
                this.indexInBc = data.indexInBc;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("blockchainId" in data && data.blockchainId != undefined) {
                this.blockchainId = data.blockchainId;
            }
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("transactions" in data && data.transactions != undefined) {
                this.transactions = data.transactions;
            }
            if ("transactionIndicesInBc" in data && data.transactionIndicesInBc != undefined) {
                this.transactionIndicesInBc = data.transactionIndicesInBc;
            }
            if ("timestamp" in data && data.timestamp != undefined) {
                this.timestamp = data.timestamp;
            }
            if ("avm" in data && data.avm != undefined) {
                this.avm = data.avm;
            }
            if ("pvm" in data && data.pvm != undefined) {
                this.pvm = data.pvm;
            }
            if ("evm" in data && data.evm != undefined) {
                this.evm = data.evm;
            }
        }
    }
    get index() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set index(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get indexInBc() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set indexInBc(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get blockchainId() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set blockchainId(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get transactions() {
        return pb_1.Message.getFieldWithDefault(this, 6, []) as string[];
    }
    set transactions(value: string[]) {
        pb_1.Message.setField(this, 6, value);
    }
    get transactionIndicesInBc() {
        return pb_1.Message.getFieldWithDefault(this, 7, []) as number[];
    }
    set transactionIndicesInBc(value: number[]) {
        pb_1.Message.setField(this, 7, value);
    }
    get timestamp() {
        return pb_1.Message.getFieldWithDefault(this, 8, 0) as number;
    }
    set timestamp(value: number) {
        pb_1.Message.setField(this, 8, value);
    }
    get avm() {
        return pb_1.Message.getWrapperField(this, BlockAVM, 9) as BlockAVM | undefined;
    }
    set avm(value: BlockAVM | undefined) {
        pb_1.Message.setOneofWrapperField(this, 9, this.#one_of_decls[0]!, value);
    }
    get hasAvm() {
        return pb_1.Message.getField(this, 9) != null;
    }
    clearAvm(): void {
        this.avm = undefined!;
    }
    get pvm() {
        return pb_1.Message.getWrapperField(this, BlockPVM, 10) as BlockPVM | undefined;
    }
    set pvm(value: BlockPVM | undefined) {
        pb_1.Message.setOneofWrapperField(this, 10, this.#one_of_decls[0]!, value);
    }
    get hasPvm() {
        return pb_1.Message.getField(this, 10) != null;
    }
    clearPvm(): void {
        this.pvm = undefined!;
    }
    get evm() {
        return pb_1.Message.getWrapperField(this, BlockEVM, 11) as BlockEVM | undefined;
    }
    set evm(value: BlockEVM | undefined) {
        pb_1.Message.setOneofWrapperField(this, 11, this.#one_of_decls[0]!, value);
    }
    get hasEvm() {
        return pb_1.Message.getField(this, 11) != null;
    }
    clearEvm(): void {
        this.evm = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "avm" | "pvm" | "evm";
        } = {
            0: "none",
            9: "avm",
            10: "pvm",
            11: "evm"
        };
        return cases[pb_1.Message.computeOneofCase(this, [9, 10, 11])]!;
    }
    static fromObject(data?: Block.AsObjectPartial): Block {
        if (!data) {
            return new Block();
        }
        const message = new Block({});
        if (data.index != null) {
            message.index = data.index;
        }
        if (data.indexInBc != null) {
            message.indexInBc = data.indexInBc;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.blockchainId != null) {
            message.blockchainId = data.blockchainId;
        }
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.transactions != null) {
            message.transactions = data.transactions;
        }
        if (data.transactionIndicesInBc != null) {
            message.transactionIndicesInBc = data.transactionIndicesInBc;
        }
        if (data.timestamp != null) {
            message.timestamp = data.timestamp;
        }
        if (data.avm != null) {
            message.avm = BlockAVM.fromObject(data.avm);
        }
        if (data.pvm != null) {
            message.pvm = BlockPVM.fromObject(data.pvm);
        }
        if (data.evm != null) {
            message.evm = BlockEVM.fromObject(data.evm);
        }
        return message;
    }
    toObject() {
        const data: Block.AsObject = {
            index: this.index,
            indexInBc: this.indexInBc,
            id: this.id,
            blockchainId: this.blockchainId,
            blockchainIndex: this.blockchainIndex,
            transactions: this.transactions,
            transactionIndicesInBc: this.transactionIndicesInBc,
            timestamp: this.timestamp
        };
        if (this.avm != null) {
            data.avm = this.avm.toObject();
        }
        if (this.pvm != null) {
            data.pvm = this.pvm.toObject();
        }
        if (this.evm != null) {
            data.evm = this.evm.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.index != 0)
            writer.writeInt64(1, this.index);
        if (this.indexInBc != 0)
            writer.writeInt64(2, this.indexInBc);
        if (this.id.length)
            writer.writeString(3, this.id);
        if (this.blockchainId.length)
            writer.writeString(4, this.blockchainId);
        if (this.blockchainIndex != 0)
            writer.writeInt64(5, this.blockchainIndex);
        if (this.transactions.length)
            writer.writeRepeatedString(6, this.transactions);
        if (this.transactionIndicesInBc.length)
            writer.writePackedInt64(7, this.transactionIndicesInBc);
        if (this.timestamp != 0)
            writer.writeInt64(8, this.timestamp);
        if (this.hasAvm)
            writer.writeMessage(9, this.avm, () => this.avm!.serialize(writer));
        if (this.hasPvm)
            writer.writeMessage(10, this.pvm, () => this.pvm!.serialize(writer));
        if (this.hasEvm)
            writer.writeMessage(11, this.evm, () => this.evm!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Block {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Block();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.index = reader.readInt64();
                    break;
                case 2:
                    message.indexInBc = reader.readInt64();
                    break;
                case 3:
                    message.id = reader.readString();
                    break;
                case 4:
                    message.blockchainId = reader.readString();
                    break;
                case 5:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 6:
                    pb_1.Message.addToRepeatedField(message, 6, reader.readString());
                    break;
                case 7:
                    message.transactionIndicesInBc = reader.readPackedInt64();
                    break;
                case 8:
                    message.timestamp = reader.readInt64();
                    break;
                case 9:
                    reader.readMessage(message.avm, () => message.avm = BlockAVM.deserialize(reader));
                    break;
                case 10:
                    reader.readMessage(message.pvm, () => message.pvm = BlockPVM.deserialize(reader));
                    break;
                case 11:
                    reader.readMessage(message.evm, () => message.evm = BlockEVM.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): Block {
        return Block.deserialize(bytes);
    }
}
export namespace Block {
    export type AsObject = {
        index: number;
        indexInBc: number;
        id: string;
        blockchainId: string;
        blockchainIndex: number;
        transactions: string[];
        transactionIndicesInBc: number[];
        timestamp: number;
        avm?: BlockAVM.AsObject;
        pvm?: BlockPVM.AsObject;
        evm?: BlockEVM.AsObject;
    };
    export type AsObjectPartial = {
        index?: number;
        indexInBc?: number;
        id?: string;
        blockchainId?: string;
        blockchainIndex?: number;
        transactions?: string[];
        transactionIndicesInBc?: number[];
        timestamp?: number;
        avm?: BlockAVM.AsObjectPartial;
        pvm?: BlockPVM.AsObjectPartial;
        evm?: BlockEVM.AsObjectPartial;
    };
}
export class BlockListRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3, 4, 5]];
    constructor(data?: any[] | ({
        page?: dependency_2.Page;
    } & (({
        all?: boolean;
        latestSingle?: never;
        latestMultiple?: never;
        id?: never;
        index?: never;
    } | {
        all?: never;
        latestSingle?: boolean;
        latestMultiple?: never;
        id?: never;
        index?: never;
    } | {
        all?: never;
        latestSingle?: never;
        latestMultiple?: boolean;
        id?: never;
        index?: never;
    } | {
        all?: never;
        latestSingle?: never;
        latestMultiple?: never;
        id?: string;
        index?: never;
    } | {
        all?: never;
        latestSingle?: never;
        latestMultiple?: never;
        id?: never;
        index?: BlockListRequest.IndexSearch;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("all" in data && data.all != undefined) {
                this.all = data.all;
            }
            if ("latestSingle" in data && data.latestSingle != undefined) {
                this.latestSingle = data.latestSingle;
            }
            if ("latestMultiple" in data && data.latestMultiple != undefined) {
                this.latestMultiple = data.latestMultiple;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("index" in data && data.index != undefined) {
                this.index = data.index;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get all() {
        return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
    }
    set all(value: boolean) {
        pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0]!, value);
    }
    get hasAll() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearAll(): void {
        this.all = undefined!;
    }
    get latestSingle() {
        return pb_1.Message.getFieldWithDefault(this, 2, false) as boolean;
    }
    set latestSingle(value: boolean) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasLatestSingle() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearLatestSingle(): void {
        this.latestSingle = undefined!;
    }
    get latestMultiple() {
        return pb_1.Message.getFieldWithDefault(this, 3, false) as boolean;
    }
    set latestMultiple(value: boolean) {
        pb_1.Message.setOneofField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasLatestMultiple() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearLatestMultiple(): void {
        this.latestMultiple = undefined!;
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setOneofField(this, 4, this.#one_of_decls[0]!, value);
    }
    get hasId() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearId(): void {
        this.id = undefined!;
    }
    get index() {
        return pb_1.Message.getWrapperField(this, BlockListRequest.IndexSearch, 5) as BlockListRequest.IndexSearch | undefined;
    }
    set index(value: BlockListRequest.IndexSearch | undefined) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0]!, value);
    }
    get hasIndex() {
        return pb_1.Message.getField(this, 5) != null;
    }
    clearIndex(): void {
        this.index = undefined!;
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_2.Page, 6) as dependency_2.Page | undefined;
    }
    set page(value: dependency_2.Page | undefined) {
        pb_1.Message.setWrapperField(this, 6, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 6) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "all" | "latestSingle" | "latestMultiple" | "id" | "index";
        } = {
            0: "none",
            1: "all",
            2: "latestSingle",
            3: "latestMultiple",
            4: "id",
            5: "index"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4, 5])]!;
    }
    static fromObject(data?: BlockListRequest.AsObjectPartial): BlockListRequest {
        if (!data) {
            return new BlockListRequest();
        }
        const message = new BlockListRequest({});
        if (data.all != null) {
            message.all = data.all;
        }
        if (data.latestSingle != null) {
            message.latestSingle = data.latestSingle;
        }
        if (data.latestMultiple != null) {
            message.latestMultiple = data.latestMultiple;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.index != null) {
            message.index = BlockListRequest.IndexSearch.fromObject(data.index);
        }
        if (data.page != null) {
            message.page = dependency_2.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: BlockListRequest.AsObject = {
            all: this.all,
            latestSingle: this.latestSingle,
            latestMultiple: this.latestMultiple,
            id: this.id
        };
        if (this.index != null) {
            data.index = this.index.toObject();
        }
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasAll)
            writer.writeBool(1, this.all);
        if (this.hasLatestSingle)
            writer.writeBool(2, this.latestSingle);
        if (this.hasLatestMultiple)
            writer.writeBool(3, this.latestMultiple);
        if (this.hasId)
            writer.writeString(4, this.id);
        if (this.hasIndex)
            writer.writeMessage(5, this.index, () => this.index!.serialize(writer));
        if (this.hasPage)
            writer.writeMessage(6, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockListRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockListRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.all = reader.readBool();
                    break;
                case 2:
                    message.latestSingle = reader.readBool();
                    break;
                case 3:
                    message.latestMultiple = reader.readBool();
                    break;
                case 4:
                    message.id = reader.readString();
                    break;
                case 5:
                    reader.readMessage(message.index, () => message.index = BlockListRequest.IndexSearch.deserialize(reader));
                    break;
                case 6:
                    reader.readMessage(message.page, () => message.page = dependency_2.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BlockListRequest {
        return BlockListRequest.deserialize(bytes);
    }
}
export namespace BlockListRequest {
    export type AsObject = {
        all: boolean;
        latestSingle: boolean;
        latestMultiple: boolean;
        id: string;
        index?: BlockListRequest.IndexSearch.AsObject;
        page?: dependency_2.Page.AsObject;
    };
    export type AsObjectPartial = {
        all?: boolean;
        latestSingle?: boolean;
        latestMultiple?: boolean;
        id?: string;
        index?: BlockListRequest.IndexSearch.AsObjectPartial;
        page?: dependency_2.Page.AsObjectPartial;
    };
    export class IndexSearch extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            index?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("index" in data && data.index != undefined) {
                    this.index = data.index;
                }
            }
        }
        get index() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set index(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data?: IndexSearch.AsObjectPartial): IndexSearch {
            if (!data) {
                return new IndexSearch();
            }
            const message = new IndexSearch({});
            if (data.index != null) {
                message.index = data.index;
            }
            return message;
        }
        toObject() {
            const data: IndexSearch.AsObject = {
                index: this.index
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.index != 0)
                writer.writeInt64(1, this.index);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): IndexSearch {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new IndexSearch();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.index = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): IndexSearch {
            return IndexSearch.deserialize(bytes);
        }
    }
    export namespace IndexSearch {
        export type AsObject = {
            index: number;
        };
        export type AsObjectPartial = {
            index?: number;
        };
    }
}
export class BlockListForChainRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[2, 3, 4, 5, 6]];
    constructor(data?: any[] | ({
        blockchainIndex?: number;
        page?: dependency_2.Page;
    } & (({
        all?: boolean;
        latestSingle?: never;
        latestMultiple?: never;
        id?: never;
        index?: never;
    } | {
        all?: never;
        latestSingle?: boolean;
        latestMultiple?: never;
        id?: never;
        index?: never;
    } | {
        all?: never;
        latestSingle?: never;
        latestMultiple?: boolean;
        id?: never;
        index?: never;
    } | {
        all?: never;
        latestSingle?: never;
        latestMultiple?: never;
        id?: string;
        index?: never;
    } | {
        all?: never;
        latestSingle?: never;
        latestMultiple?: never;
        id?: never;
        index?: BlockListForChainRequest.IndexSearch;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("all" in data && data.all != undefined) {
                this.all = data.all;
            }
            if ("latestSingle" in data && data.latestSingle != undefined) {
                this.latestSingle = data.latestSingle;
            }
            if ("latestMultiple" in data && data.latestMultiple != undefined) {
                this.latestMultiple = data.latestMultiple;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("index" in data && data.index != undefined) {
                this.index = data.index;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get all() {
        return pb_1.Message.getFieldWithDefault(this, 2, false) as boolean;
    }
    set all(value: boolean) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasAll() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearAll(): void {
        this.all = undefined!;
    }
    get latestSingle() {
        return pb_1.Message.getFieldWithDefault(this, 3, false) as boolean;
    }
    set latestSingle(value: boolean) {
        pb_1.Message.setOneofField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasLatestSingle() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearLatestSingle(): void {
        this.latestSingle = undefined!;
    }
    get latestMultiple() {
        return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
    }
    set latestMultiple(value: boolean) {
        pb_1.Message.setOneofField(this, 4, this.#one_of_decls[0]!, value);
    }
    get hasLatestMultiple() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearLatestMultiple(): void {
        this.latestMultiple = undefined!;
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setOneofField(this, 5, this.#one_of_decls[0]!, value);
    }
    get hasId() {
        return pb_1.Message.getField(this, 5) != null;
    }
    clearId(): void {
        this.id = undefined!;
    }
    get index() {
        return pb_1.Message.getWrapperField(this, BlockListForChainRequest.IndexSearch, 6) as BlockListForChainRequest.IndexSearch | undefined;
    }
    set index(value: BlockListForChainRequest.IndexSearch | undefined) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0]!, value);
    }
    get hasIndex() {
        return pb_1.Message.getField(this, 6) != null;
    }
    clearIndex(): void {
        this.index = undefined!;
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_2.Page, 7) as dependency_2.Page | undefined;
    }
    set page(value: dependency_2.Page | undefined) {
        pb_1.Message.setWrapperField(this, 7, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 7) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "all" | "latestSingle" | "latestMultiple" | "id" | "index";
        } = {
            0: "none",
            2: "all",
            3: "latestSingle",
            4: "latestMultiple",
            5: "id",
            6: "index"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2, 3, 4, 5, 6])]!;
    }
    static fromObject(data?: BlockListForChainRequest.AsObjectPartial): BlockListForChainRequest {
        if (!data) {
            return new BlockListForChainRequest();
        }
        const message = new BlockListForChainRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.all != null) {
            message.all = data.all;
        }
        if (data.latestSingle != null) {
            message.latestSingle = data.latestSingle;
        }
        if (data.latestMultiple != null) {
            message.latestMultiple = data.latestMultiple;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.index != null) {
            message.index = BlockListForChainRequest.IndexSearch.fromObject(data.index);
        }
        if (data.page != null) {
            message.page = dependency_2.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: BlockListForChainRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            all: this.all,
            latestSingle: this.latestSingle,
            latestMultiple: this.latestMultiple,
            id: this.id
        };
        if (this.index != null) {
            data.index = this.index.toObject();
        }
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.hasAll)
            writer.writeBool(2, this.all);
        if (this.hasLatestSingle)
            writer.writeBool(3, this.latestSingle);
        if (this.hasLatestMultiple)
            writer.writeBool(4, this.latestMultiple);
        if (this.hasId)
            writer.writeString(5, this.id);
        if (this.hasIndex)
            writer.writeMessage(6, this.index, () => this.index!.serialize(writer));
        if (this.hasPage)
            writer.writeMessage(7, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockListForChainRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockListForChainRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.all = reader.readBool();
                    break;
                case 3:
                    message.latestSingle = reader.readBool();
                    break;
                case 4:
                    message.latestMultiple = reader.readBool();
                    break;
                case 5:
                    message.id = reader.readString();
                    break;
                case 6:
                    reader.readMessage(message.index, () => message.index = BlockListForChainRequest.IndexSearch.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.page, () => message.page = dependency_2.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BlockListForChainRequest {
        return BlockListForChainRequest.deserialize(bytes);
    }
}
export namespace BlockListForChainRequest {
    export type AsObject = {
        blockchainIndex: number;
        all: boolean;
        latestSingle: boolean;
        latestMultiple: boolean;
        id: string;
        index?: BlockListForChainRequest.IndexSearch.AsObject;
        page?: dependency_2.Page.AsObject;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        all?: boolean;
        latestSingle?: boolean;
        latestMultiple?: boolean;
        id?: string;
        index?: BlockListForChainRequest.IndexSearch.AsObjectPartial;
        page?: dependency_2.Page.AsObjectPartial;
    };
    export class IndexSearch extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            index?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("index" in data && data.index != undefined) {
                    this.index = data.index;
                }
            }
        }
        get index() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set index(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data?: IndexSearch.AsObjectPartial): IndexSearch {
            if (!data) {
                return new IndexSearch();
            }
            const message = new IndexSearch({});
            if (data.index != null) {
                message.index = data.index;
            }
            return message;
        }
        toObject() {
            const data: IndexSearch.AsObject = {
                index: this.index
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.index != 0)
                writer.writeInt64(1, this.index);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): IndexSearch {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new IndexSearch();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.index = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): IndexSearch {
            return IndexSearch.deserialize(bytes);
        }
    }
    export namespace IndexSearch {
        export type AsObject = {
            index: number;
        };
        export type AsObjectPartial = {
            index?: number;
        };
    }
}
export class BlockListResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blocks?: Block[];
        navigation?: dependency_2.NavigationData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blocks" in data && data.blocks != undefined) {
                this.blocks = data.blocks;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
        }
    }
    get blocks() {
        return pb_1.Message.getRepeatedWrapperField(this, Block, 1) as Block[];
    }
    set blocks(value: Block[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_2.NavigationData, 2) as dependency_2.NavigationData | undefined;
    }
    set navigation(value: dependency_2.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    static fromObject(data?: BlockListResponse.AsObjectPartial): BlockListResponse {
        if (!data) {
            return new BlockListResponse();
        }
        const message = new BlockListResponse({});
        if (data.blocks != null) {
            message.blocks = data.blocks.map(item => Block.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_2.NavigationData.fromObject(data.navigation);
        }
        return message;
    }
    toObject() {
        const data: BlockListResponse.AsObject = {
            blocks: this.blocks.map((item: Block) => item.toObject())
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blocks.length)
            writer.writeRepeatedMessage(1, this.blocks, (item: Block) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(2, this.navigation, () => this.navigation!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockListResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockListResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.blocks, () => pb_1.Message.addToRepeatedWrapperField(message, 1, Block.deserialize(reader), Block));
                    break;
                case 2:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_2.NavigationData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BlockListResponse {
        return BlockListResponse.deserialize(bytes);
    }
}
export namespace BlockListResponse {
    export type AsObject = {
        blocks: Block.AsObject[];
        navigation?: dependency_2.NavigationData.AsObject;
    };
    export type AsObjectPartial = {
        blocks?: Block.AsObjectPartial[];
        navigation?: dependency_2.NavigationData.AsObjectPartial;
    };
}
export class SaveBlockRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        block?: Block;
        transactions?: dependency_3.TransactionToSave[];
        rewardUtxos?: dependency_3.SaveRewardUtxosRequest;
        isGenesis?: boolean;
        blockchainIndex?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("block" in data && data.block != undefined) {
                this.block = data.block;
            }
            if ("transactions" in data && data.transactions != undefined) {
                this.transactions = data.transactions;
            }
            if ("rewardUtxos" in data && data.rewardUtxos != undefined) {
                this.rewardUtxos = data.rewardUtxos;
            }
            if ("isGenesis" in data && data.isGenesis != undefined) {
                this.isGenesis = data.isGenesis;
            }
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
        }
    }
    get block() {
        return pb_1.Message.getWrapperField(this, Block, 1) as Block | undefined;
    }
    set block(value: Block | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasBlock() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearBlock(): void {
        this.block = undefined!;
    }
    get transactions() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_3.TransactionToSave, 2) as dependency_3.TransactionToSave[];
    }
    set transactions(value: dependency_3.TransactionToSave[]) {
        pb_1.Message.setRepeatedWrapperField(this, 2, value);
    }
    get rewardUtxos() {
        return pb_1.Message.getWrapperField(this, dependency_3.SaveRewardUtxosRequest, 3) as dependency_3.SaveRewardUtxosRequest | undefined;
    }
    set rewardUtxos(value: dependency_3.SaveRewardUtxosRequest | undefined) {
        pb_1.Message.setWrapperField(this, 3, value);
    }
    get hasRewardUtxos() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearRewardUtxos(): void {
        this.rewardUtxos = undefined!;
    }
    get isGenesis() {
        return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
    }
    set isGenesis(value: boolean) {
        pb_1.Message.setField(this, 4, value);
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    static fromObject(data?: SaveBlockRequest.AsObjectPartial): SaveBlockRequest {
        if (!data) {
            return new SaveBlockRequest();
        }
        const message = new SaveBlockRequest({});
        if (data.block != null) {
            message.block = Block.fromObject(data.block);
        }
        if (data.transactions != null) {
            message.transactions = data.transactions.map(item => dependency_3.TransactionToSave.fromObject(item));
        }
        if (data.rewardUtxos != null) {
            message.rewardUtxos = dependency_3.SaveRewardUtxosRequest.fromObject(data.rewardUtxos);
        }
        if (data.isGenesis != null) {
            message.isGenesis = data.isGenesis;
        }
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        return message;
    }
    toObject() {
        const data: SaveBlockRequest.AsObject = {
            transactions: this.transactions.map((item: dependency_3.TransactionToSave) => item.toObject()),
            isGenesis: this.isGenesis,
            blockchainIndex: this.blockchainIndex
        };
        if (this.block != null) {
            data.block = this.block.toObject();
        }
        if (this.rewardUtxos != null) {
            data.rewardUtxos = this.rewardUtxos.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasBlock)
            writer.writeMessage(1, this.block, () => this.block!.serialize(writer));
        if (this.transactions.length)
            writer.writeRepeatedMessage(2, this.transactions, (item: dependency_3.TransactionToSave) => item!.serialize(writer));
        if (this.hasRewardUtxos)
            writer.writeMessage(3, this.rewardUtxos, () => this.rewardUtxos!.serialize(writer));
        if (this.isGenesis != false)
            writer.writeBool(4, this.isGenesis);
        if (this.blockchainIndex != 0)
            writer.writeInt64(5, this.blockchainIndex);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SaveBlockRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SaveBlockRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.block, () => message.block = Block.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.transactions, () => pb_1.Message.addToRepeatedWrapperField(message, 2, dependency_3.TransactionToSave.deserialize(reader), dependency_3.TransactionToSave));
                    break;
                case 3:
                    reader.readMessage(message.rewardUtxos, () => message.rewardUtxos = dependency_3.SaveRewardUtxosRequest.deserialize(reader));
                    break;
                case 4:
                    message.isGenesis = reader.readBool();
                    break;
                case 5:
                    message.blockchainIndex = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SaveBlockRequest {
        return SaveBlockRequest.deserialize(bytes);
    }
}
export namespace SaveBlockRequest {
    export type AsObject = {
        block?: Block.AsObject;
        transactions: dependency_3.TransactionToSave.AsObject[];
        rewardUtxos?: dependency_3.SaveRewardUtxosRequest.AsObject;
        isGenesis: boolean;
        blockchainIndex: number;
    };
    export type AsObjectPartial = {
        block?: Block.AsObjectPartial;
        transactions?: dependency_3.TransactionToSave.AsObjectPartial[];
        rewardUtxos?: dependency_3.SaveRewardUtxosRequest.AsObjectPartial;
        isGenesis?: boolean;
        blockchainIndex?: number;
    };
}
export class SaveBlockResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        block?: Block;
        transactions?: dependency_3.TransactionToSave[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("block" in data && data.block != undefined) {
                this.block = data.block;
            }
            if ("transactions" in data && data.transactions != undefined) {
                this.transactions = data.transactions;
            }
        }
    }
    get block() {
        return pb_1.Message.getWrapperField(this, Block, 1) as Block | undefined;
    }
    set block(value: Block | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasBlock() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearBlock(): void {
        this.block = undefined!;
    }
    get transactions() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_3.TransactionToSave, 2) as dependency_3.TransactionToSave[];
    }
    set transactions(value: dependency_3.TransactionToSave[]) {
        pb_1.Message.setRepeatedWrapperField(this, 2, value);
    }
    static fromObject(data?: SaveBlockResponse.AsObjectPartial): SaveBlockResponse {
        if (!data) {
            return new SaveBlockResponse();
        }
        const message = new SaveBlockResponse({});
        if (data.block != null) {
            message.block = Block.fromObject(data.block);
        }
        if (data.transactions != null) {
            message.transactions = data.transactions.map(item => dependency_3.TransactionToSave.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: SaveBlockResponse.AsObject = {
            transactions: this.transactions.map((item: dependency_3.TransactionToSave) => item.toObject())
        };
        if (this.block != null) {
            data.block = this.block.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasBlock)
            writer.writeMessage(1, this.block, () => this.block!.serialize(writer));
        if (this.transactions.length)
            writer.writeRepeatedMessage(2, this.transactions, (item: dependency_3.TransactionToSave) => item!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SaveBlockResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SaveBlockResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.block, () => message.block = Block.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.transactions, () => pb_1.Message.addToRepeatedWrapperField(message, 2, dependency_3.TransactionToSave.deserialize(reader), dependency_3.TransactionToSave));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SaveBlockResponse {
        return SaveBlockResponse.deserialize(bytes);
    }
}
export namespace SaveBlockResponse {
    export type AsObject = {
        block?: Block.AsObject;
        transactions: dependency_3.TransactionToSave.AsObject[];
    };
    export type AsObjectPartial = {
        block?: Block.AsObjectPartial;
        transactions?: dependency_3.TransactionToSave.AsObjectPartial[];
    };
}
export class BlockSub extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        block?: Block;
        transactions?: dependency_3.TransactionShort[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("block" in data && data.block != undefined) {
                this.block = data.block;
            }
            if ("transactions" in data && data.transactions != undefined) {
                this.transactions = data.transactions;
            }
        }
    }
    get block() {
        return pb_1.Message.getWrapperField(this, Block, 1) as Block | undefined;
    }
    set block(value: Block | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasBlock() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearBlock(): void {
        this.block = undefined!;
    }
    get transactions() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_3.TransactionShort, 2) as dependency_3.TransactionShort[];
    }
    set transactions(value: dependency_3.TransactionShort[]) {
        pb_1.Message.setRepeatedWrapperField(this, 2, value);
    }
    static fromObject(data?: BlockSub.AsObjectPartial): BlockSub {
        if (!data) {
            return new BlockSub();
        }
        const message = new BlockSub({});
        if (data.block != null) {
            message.block = Block.fromObject(data.block);
        }
        if (data.transactions != null) {
            message.transactions = data.transactions.map(item => dependency_3.TransactionShort.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: BlockSub.AsObject = {
            transactions: this.transactions.map((item: dependency_3.TransactionShort) => item.toObject())
        };
        if (this.block != null) {
            data.block = this.block.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasBlock)
            writer.writeMessage(1, this.block, () => this.block!.serialize(writer));
        if (this.transactions.length)
            writer.writeRepeatedMessage(2, this.transactions, (item: dependency_3.TransactionShort) => item!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockSub {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockSub();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.block, () => message.block = Block.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.transactions, () => pb_1.Message.addToRepeatedWrapperField(message, 2, dependency_3.TransactionShort.deserialize(reader), dependency_3.TransactionShort));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BlockSub {
        return BlockSub.deserialize(bytes);
    }
}
export namespace BlockSub {
    export type AsObject = {
        block?: Block.AsObject;
        transactions: dependency_3.TransactionShort.AsObject[];
    };
    export type AsObjectPartial = {
        block?: Block.AsObjectPartial;
        transactions?: dependency_3.TransactionShort.AsObjectPartial[];
    };
}
export class LatestBlockNumberRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        index?: number;
        kind?: dependency_2.BlockchainKind;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("index" in data && data.index != undefined) {
                this.index = data.index;
            }
            if ("kind" in data && data.kind != undefined) {
                this.kind = data.kind;
            }
        }
    }
    get index() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set index(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get kind() {
        return pb_1.Message.getFieldWithDefault(this, 2, dependency_2.BlockchainKind.BlockchainKindAVM) as dependency_2.BlockchainKind;
    }
    set kind(value: dependency_2.BlockchainKind) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: LatestBlockNumberRequest.AsObjectPartial): LatestBlockNumberRequest {
        if (!data) {
            return new LatestBlockNumberRequest();
        }
        const message = new LatestBlockNumberRequest({});
        if (data.index != null) {
            message.index = data.index;
        }
        if (data.kind != null) {
            message.kind = data.kind;
        }
        return message;
    }
    toObject() {
        const data: LatestBlockNumberRequest.AsObject = {
            index: this.index,
            kind: this.kind
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.index != 0)
            writer.writeInt64(1, this.index);
        if (this.kind != dependency_2.BlockchainKind.BlockchainKindAVM)
            writer.writeEnum(2, this.kind);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): LatestBlockNumberRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new LatestBlockNumberRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.index = reader.readInt64();
                    break;
                case 2:
                    message.kind = reader.readEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): LatestBlockNumberRequest {
        return LatestBlockNumberRequest.deserialize(bytes);
    }
}
export namespace LatestBlockNumberRequest {
    export type AsObject = {
        index: number;
        kind: dependency_2.BlockchainKind;
    };
    export type AsObjectPartial = {
        index?: number;
        kind?: dependency_2.BlockchainKind;
    };
}
export class LatestBlockNumberResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        num?: number;
        existed?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("num" in data && data.num != undefined) {
                this.num = data.num;
            }
            if ("existed" in data && data.existed != undefined) {
                this.existed = data.existed;
            }
        }
    }
    get num() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set num(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get existed() {
        return pb_1.Message.getFieldWithDefault(this, 2, false) as boolean;
    }
    set existed(value: boolean) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: LatestBlockNumberResponse.AsObjectPartial): LatestBlockNumberResponse {
        if (!data) {
            return new LatestBlockNumberResponse();
        }
        const message = new LatestBlockNumberResponse({});
        if (data.num != null) {
            message.num = data.num;
        }
        if (data.existed != null) {
            message.existed = data.existed;
        }
        return message;
    }
    toObject() {
        const data: LatestBlockNumberResponse.AsObject = {
            num: this.num,
            existed: this.existed
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.num != 0)
            writer.writeInt64(1, this.num);
        if (this.existed != false)
            writer.writeBool(2, this.existed);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): LatestBlockNumberResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new LatestBlockNumberResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.num = reader.readInt64();
                    break;
                case 2:
                    message.existed = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): LatestBlockNumberResponse {
        return LatestBlockNumberResponse.deserialize(bytes);
    }
}
export namespace LatestBlockNumberResponse {
    export type AsObject = {
        num: number;
        existed: boolean;
    };
    export type AsObjectPartial = {
        num?: number;
        existed?: boolean;
    };
}
