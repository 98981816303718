// error translates
export const ErrorMessages: Record<string, string> = {
  "no connection": "Cannot connect to the server. Please, try later.",
  "unhandled error": "Unknown error. Please, try later.",
  "not found": "No such item.",
  "service unavailable": "Server error. Please, try later.",
  "internal server error": "Critical server error. Please, try later.",
  "bad gateway": "Server error. Please, try later.",
  "gateway timeout": "Server didn't respond in time. Please, try later.",
  "failed auth": "Wrong login or password",
  forbidden: "No access to this content",
  "timeout has occurred": "Connection to the server has took too long.",
  "address not found": "Cannot find coordinates by address",
  "phone exists": "Phone exists",
  "geocode was not successful for the following reason zero_results":
    "Address not found. Please, modify it and try again.",
  "database failed": "Database failed",
  "permission denied": "Permission denied",
  timeout: "Handling request has took too long.",
  "unknown method": "Unknown method",
  "invalid image": "Invalid image",
  "image disabled": "Image disabled",
  "auth timeout": "Connection was not authenticated too long",
  "auth required": "Auth required",
  "missing credentials": "Missing credentials",
  "wrong filter type": "Wrong filter type",
  "invalid version": "Deprecated version of app. Please, refresh the page.",
  "invalid token": "Invalid auth token",
};

// this object contains errors that occur during requests,
// which cannot be eliminated programmatically
// (the error was created by the user or the network)
// such errors are not sent in bug reports to the server
export const DoNotSendReason: { [key: string]: boolean } = {
  "no connection": true,
  "failed auth": true,
  "phone exists": true,
  "invalid version": true,
};
