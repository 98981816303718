/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.13.0
 * source: credits.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./validate/validate";
import * as pb_1 from "google-protobuf";
export class BytesForChainRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data?: BytesForChainRequest.AsObjectPartial): BytesForChainRequest {
        if (!data) {
            return new BytesForChainRequest();
        }
        const message = new BytesForChainRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        return message;
    }
    toObject() {
        const data: BytesForChainRequest.AsObject = {
            blockchainIndex: this.blockchainIndex
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BytesForChainRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BytesForChainRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BytesForChainRequest {
        return BytesForChainRequest.deserialize(bytes);
    }
}
export namespace BytesForChainRequest {
    export type AsObject = {
        blockchainIndex: number;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
    };
}
export class BytesForChainResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        size?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("size" in data && data.size != undefined) {
                this.size = data.size;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get size() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set size(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: BytesForChainResponse.AsObjectPartial): BytesForChainResponse {
        if (!data) {
            return new BytesForChainResponse();
        }
        const message = new BytesForChainResponse({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.size != null) {
            message.size = data.size;
        }
        return message;
    }
    toObject() {
        const data: BytesForChainResponse.AsObject = {
            blockchainIndex: this.blockchainIndex,
            size: this.size
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.size != 0)
            writer.writeInt64(2, this.size);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BytesForChainResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BytesForChainResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.size = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BytesForChainResponse {
        return BytesForChainResponse.deserialize(bytes);
    }
}
export namespace BytesForChainResponse {
    export type AsObject = {
        blockchainIndex: number;
        size: number;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        size?: number;
    };
}
export class BlockBytesRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        blockId?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("blockId" in data && data.blockId != undefined) {
                this.blockId = data.blockId;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get blockId() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set blockId(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: BlockBytesRequest.AsObjectPartial): BlockBytesRequest {
        if (!data) {
            return new BlockBytesRequest();
        }
        const message = new BlockBytesRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.blockId != null) {
            message.blockId = data.blockId;
        }
        return message;
    }
    toObject() {
        const data: BlockBytesRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            blockId: this.blockId
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.blockId.length)
            writer.writeString(2, this.blockId);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockBytesRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockBytesRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.blockId = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BlockBytesRequest {
        return BlockBytesRequest.deserialize(bytes);
    }
}
export namespace BlockBytesRequest {
    export type AsObject = {
        blockchainIndex: number;
        blockId: string;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        blockId?: string;
    };
}
export class BlockBytesResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        blockId?: string;
        size?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("blockId" in data && data.blockId != undefined) {
                this.blockId = data.blockId;
            }
            if ("size" in data && data.size != undefined) {
                this.size = data.size;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get blockId() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set blockId(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get size() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set size(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data?: BlockBytesResponse.AsObjectPartial): BlockBytesResponse {
        if (!data) {
            return new BlockBytesResponse();
        }
        const message = new BlockBytesResponse({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.blockId != null) {
            message.blockId = data.blockId;
        }
        if (data.size != null) {
            message.size = data.size;
        }
        return message;
    }
    toObject() {
        const data: BlockBytesResponse.AsObject = {
            blockchainIndex: this.blockchainIndex,
            blockId: this.blockId,
            size: this.size
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.blockId.length)
            writer.writeString(2, this.blockId);
        if (this.size != 0)
            writer.writeInt64(3, this.size);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockBytesResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockBytesResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.blockId = reader.readString();
                    break;
                case 3:
                    message.size = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BlockBytesResponse {
        return BlockBytesResponse.deserialize(bytes);
    }
}
export namespace BlockBytesResponse {
    export type AsObject = {
        blockchainIndex: number;
        blockId: string;
        size: number;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        blockId?: string;
        size?: number;
    };
}
