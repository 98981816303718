/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.13.0
 * source: address.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./validate/validate";
import * as dependency_2 from "./evm";
import * as dependency_3 from "./models";
import * as dependency_4 from "./transaction";
import * as pb_1 from "google-protobuf";
export class AddressData extends pb_1.Message {
    #one_of_decls: number[][] = [[6]];
    constructor(data?: any[] | ({
        address?: string;
        blockchainIndex?: number;
        balance?: string;
        transactions?: dependency_4.TransactionShort[];
        navigation?: dependency_3.NavigationData;
        totalStakeAmount?: string;
    } & (({
        evm?: dependency_2.AddressDataEVM;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [4], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("balance" in data && data.balance != undefined) {
                this.balance = data.balance;
            }
            if ("transactions" in data && data.transactions != undefined) {
                this.transactions = data.transactions;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
            if ("totalStakeAmount" in data && data.totalStakeAmount != undefined) {
                this.totalStakeAmount = data.totalStakeAmount;
            }
            if ("evm" in data && data.evm != undefined) {
                this.evm = data.evm;
            }
        }
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get balance() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set balance(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get transactions() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_4.TransactionShort, 4) as dependency_4.TransactionShort[];
    }
    set transactions(value: dependency_4.TransactionShort[]) {
        pb_1.Message.setRepeatedWrapperField(this, 4, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_3.NavigationData, 5) as dependency_3.NavigationData | undefined;
    }
    set navigation(value: dependency_3.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 5, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 5) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    get totalStakeAmount() {
        return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
    }
    set totalStakeAmount(value: string) {
        pb_1.Message.setField(this, 7, value);
    }
    get evm() {
        return pb_1.Message.getWrapperField(this, dependency_2.AddressDataEVM, 6) as dependency_2.AddressDataEVM | undefined;
    }
    set evm(value: dependency_2.AddressDataEVM | undefined) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0]!, value);
    }
    get hasEvm() {
        return pb_1.Message.getField(this, 6) != null;
    }
    clearEvm(): void {
        this.evm = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "evm";
        } = {
            0: "none",
            6: "evm"
        };
        return cases[pb_1.Message.computeOneofCase(this, [6])]!;
    }
    static fromObject(data?: AddressData.AsObjectPartial): AddressData {
        if (!data) {
            return new AddressData();
        }
        const message = new AddressData({});
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.balance != null) {
            message.balance = data.balance;
        }
        if (data.transactions != null) {
            message.transactions = data.transactions.map(item => dependency_4.TransactionShort.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_3.NavigationData.fromObject(data.navigation);
        }
        if (data.totalStakeAmount != null) {
            message.totalStakeAmount = data.totalStakeAmount;
        }
        if (data.evm != null) {
            message.evm = dependency_2.AddressDataEVM.fromObject(data.evm);
        }
        return message;
    }
    toObject() {
        const data: AddressData.AsObject = {
            address: this.address,
            blockchainIndex: this.blockchainIndex,
            balance: this.balance,
            transactions: this.transactions.map((item: dependency_4.TransactionShort) => item.toObject()),
            totalStakeAmount: this.totalStakeAmount
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        if (this.evm != null) {
            data.evm = this.evm.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.address.length)
            writer.writeString(1, this.address);
        if (this.blockchainIndex != 0)
            writer.writeInt64(2, this.blockchainIndex);
        if (this.balance.length)
            writer.writeString(3, this.balance);
        if (this.transactions.length)
            writer.writeRepeatedMessage(4, this.transactions, (item: dependency_4.TransactionShort) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(5, this.navigation, () => this.navigation!.serialize(writer));
        if (this.totalStakeAmount.length)
            writer.writeString(7, this.totalStakeAmount);
        if (this.hasEvm)
            writer.writeMessage(6, this.evm, () => this.evm!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AddressData {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AddressData();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.address = reader.readString();
                    break;
                case 2:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 3:
                    message.balance = reader.readString();
                    break;
                case 4:
                    reader.readMessage(message.transactions, () => pb_1.Message.addToRepeatedWrapperField(message, 4, dependency_4.TransactionShort.deserialize(reader), dependency_4.TransactionShort));
                    break;
                case 5:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_3.NavigationData.deserialize(reader));
                    break;
                case 7:
                    message.totalStakeAmount = reader.readString();
                    break;
                case 6:
                    reader.readMessage(message.evm, () => message.evm = dependency_2.AddressDataEVM.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AddressData {
        return AddressData.deserialize(bytes);
    }
}
export namespace AddressData {
    export type AsObject = {
        address: string;
        blockchainIndex: number;
        balance: string;
        transactions: dependency_4.TransactionShort.AsObject[];
        navigation?: dependency_3.NavigationData.AsObject;
        totalStakeAmount: string;
        evm?: dependency_2.AddressDataEVM.AsObject;
    };
    export type AsObjectPartial = {
        address?: string;
        blockchainIndex?: number;
        balance?: string;
        transactions?: dependency_4.TransactionShort.AsObjectPartial[];
        navigation?: dependency_3.NavigationData.AsObjectPartial;
        totalStakeAmount?: string;
        evm?: dependency_2.AddressDataEVM.AsObjectPartial;
    };
}
export class AddressDataRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        address?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: AddressDataRequest.AsObjectPartial): AddressDataRequest {
        if (!data) {
            return new AddressDataRequest();
        }
        const message = new AddressDataRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        return message;
    }
    toObject() {
        const data: AddressDataRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AddressDataRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AddressDataRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AddressDataRequest {
        return AddressDataRequest.deserialize(bytes);
    }
}
export namespace AddressDataRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
    };
}
export class AddressDataResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        data?: AddressData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("data" in data && data.data != undefined) {
                this.data = data.data;
            }
        }
    }
    get data() {
        return pb_1.Message.getWrapperField(this, AddressData, 1) as AddressData | undefined;
    }
    set data(value: AddressData | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasData() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearData(): void {
        this.data = undefined!;
    }
    static fromObject(data?: AddressDataResponse.AsObjectPartial): AddressDataResponse {
        if (!data) {
            return new AddressDataResponse();
        }
        const message = new AddressDataResponse({});
        if (data.data != null) {
            message.data = AddressData.fromObject(data.data);
        }
        return message;
    }
    toObject() {
        const data: AddressDataResponse.AsObject = {};
        if (this.data != null) {
            data.data = this.data.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasData)
            writer.writeMessage(1, this.data, () => this.data!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AddressDataResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AddressDataResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.data, () => message.data = AddressData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AddressDataResponse {
        return AddressDataResponse.deserialize(bytes);
    }
}
export namespace AddressDataResponse {
    export type AsObject = {
        data?: AddressData.AsObject;
    };
    export type AsObjectPartial = {
        data?: AddressData.AsObjectPartial;
    };
}
export class XChainBalanceRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        address?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: XChainBalanceRequest.AsObjectPartial): XChainBalanceRequest {
        if (!data) {
            return new XChainBalanceRequest();
        }
        const message = new XChainBalanceRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        return message;
    }
    toObject() {
        const data: XChainBalanceRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): XChainBalanceRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new XChainBalanceRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): XChainBalanceRequest {
        return XChainBalanceRequest.deserialize(bytes);
    }
}
export namespace XChainBalanceRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
    };
}
export class XChainBalanceResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        value?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("value" in data && data.value != undefined) {
                this.value = data.value;
            }
        }
    }
    get value() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set value(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data?: XChainBalanceResponse.AsObjectPartial): XChainBalanceResponse {
        if (!data) {
            return new XChainBalanceResponse();
        }
        const message = new XChainBalanceResponse({});
        if (data.value != null) {
            message.value = data.value;
        }
        return message;
    }
    toObject() {
        const data: XChainBalanceResponse.AsObject = {
            value: this.value
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.value.length)
            writer.writeString(1, this.value);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): XChainBalanceResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new XChainBalanceResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.value = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): XChainBalanceResponse {
        return XChainBalanceResponse.deserialize(bytes);
    }
}
export namespace XChainBalanceResponse {
    export type AsObject = {
        value: string;
    };
    export type AsObjectPartial = {
        value?: string;
    };
}
export class PChainBalanceRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        address?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: PChainBalanceRequest.AsObjectPartial): PChainBalanceRequest {
        if (!data) {
            return new PChainBalanceRequest();
        }
        const message = new PChainBalanceRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        return message;
    }
    toObject() {
        const data: PChainBalanceRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): PChainBalanceRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new PChainBalanceRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): PChainBalanceRequest {
        return PChainBalanceRequest.deserialize(bytes);
    }
}
export namespace PChainBalanceRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
    };
}
export class PChainBalanceResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        value?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("value" in data && data.value != undefined) {
                this.value = data.value;
            }
        }
    }
    get value() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set value(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data?: PChainBalanceResponse.AsObjectPartial): PChainBalanceResponse {
        if (!data) {
            return new PChainBalanceResponse();
        }
        const message = new PChainBalanceResponse({});
        if (data.value != null) {
            message.value = data.value;
        }
        return message;
    }
    toObject() {
        const data: PChainBalanceResponse.AsObject = {
            value: this.value
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.value.length)
            writer.writeString(1, this.value);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): PChainBalanceResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new PChainBalanceResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.value = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): PChainBalanceResponse {
        return PChainBalanceResponse.deserialize(bytes);
    }
}
export namespace PChainBalanceResponse {
    export type AsObject = {
        value: string;
    };
    export type AsObjectPartial = {
        value?: string;
    };
}
export class UtxoChainsBalancesRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        xChainAddress?: string;
        xChainBlockchainIndex?: number;
        pChainAddress?: string;
        pChainBlockchainIndex?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("xChainAddress" in data && data.xChainAddress != undefined) {
                this.xChainAddress = data.xChainAddress;
            }
            if ("xChainBlockchainIndex" in data && data.xChainBlockchainIndex != undefined) {
                this.xChainBlockchainIndex = data.xChainBlockchainIndex;
            }
            if ("pChainAddress" in data && data.pChainAddress != undefined) {
                this.pChainAddress = data.pChainAddress;
            }
            if ("pChainBlockchainIndex" in data && data.pChainBlockchainIndex != undefined) {
                this.pChainBlockchainIndex = data.pChainBlockchainIndex;
            }
        }
    }
    get xChainAddress() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set xChainAddress(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get xChainBlockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set xChainBlockchainIndex(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get pChainAddress() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set pChainAddress(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get pChainBlockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set pChainBlockchainIndex(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    static fromObject(data?: UtxoChainsBalancesRequest.AsObjectPartial): UtxoChainsBalancesRequest {
        if (!data) {
            return new UtxoChainsBalancesRequest();
        }
        const message = new UtxoChainsBalancesRequest({});
        if (data.xChainAddress != null) {
            message.xChainAddress = data.xChainAddress;
        }
        if (data.xChainBlockchainIndex != null) {
            message.xChainBlockchainIndex = data.xChainBlockchainIndex;
        }
        if (data.pChainAddress != null) {
            message.pChainAddress = data.pChainAddress;
        }
        if (data.pChainBlockchainIndex != null) {
            message.pChainBlockchainIndex = data.pChainBlockchainIndex;
        }
        return message;
    }
    toObject() {
        const data: UtxoChainsBalancesRequest.AsObject = {
            xChainAddress: this.xChainAddress,
            xChainBlockchainIndex: this.xChainBlockchainIndex,
            pChainAddress: this.pChainAddress,
            pChainBlockchainIndex: this.pChainBlockchainIndex
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.xChainAddress.length)
            writer.writeString(1, this.xChainAddress);
        if (this.xChainBlockchainIndex != 0)
            writer.writeInt64(2, this.xChainBlockchainIndex);
        if (this.pChainAddress.length)
            writer.writeString(3, this.pChainAddress);
        if (this.pChainBlockchainIndex != 0)
            writer.writeInt64(4, this.pChainBlockchainIndex);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UtxoChainsBalancesRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UtxoChainsBalancesRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.xChainAddress = reader.readString();
                    break;
                case 2:
                    message.xChainBlockchainIndex = reader.readInt64();
                    break;
                case 3:
                    message.pChainAddress = reader.readString();
                    break;
                case 4:
                    message.pChainBlockchainIndex = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UtxoChainsBalancesRequest {
        return UtxoChainsBalancesRequest.deserialize(bytes);
    }
}
export namespace UtxoChainsBalancesRequest {
    export type AsObject = {
        xChainAddress: string;
        xChainBlockchainIndex: number;
        pChainAddress: string;
        pChainBlockchainIndex: number;
    };
    export type AsObjectPartial = {
        xChainAddress?: string;
        xChainBlockchainIndex?: number;
        pChainAddress?: string;
        pChainBlockchainIndex?: number;
    };
}
export class UtxoChainsBalancesResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        xChainBalance?: string;
        pChainBalance?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("xChainBalance" in data && data.xChainBalance != undefined) {
                this.xChainBalance = data.xChainBalance;
            }
            if ("pChainBalance" in data && data.pChainBalance != undefined) {
                this.pChainBalance = data.pChainBalance;
            }
        }
    }
    get xChainBalance() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set xChainBalance(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get pChainBalance() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set pChainBalance(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: UtxoChainsBalancesResponse.AsObjectPartial): UtxoChainsBalancesResponse {
        if (!data) {
            return new UtxoChainsBalancesResponse();
        }
        const message = new UtxoChainsBalancesResponse({});
        if (data.xChainBalance != null) {
            message.xChainBalance = data.xChainBalance;
        }
        if (data.pChainBalance != null) {
            message.pChainBalance = data.pChainBalance;
        }
        return message;
    }
    toObject() {
        const data: UtxoChainsBalancesResponse.AsObject = {
            xChainBalance: this.xChainBalance,
            pChainBalance: this.pChainBalance
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.xChainBalance.length)
            writer.writeString(1, this.xChainBalance);
        if (this.pChainBalance.length)
            writer.writeString(2, this.pChainBalance);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UtxoChainsBalancesResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UtxoChainsBalancesResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.xChainBalance = reader.readString();
                    break;
                case 2:
                    message.pChainBalance = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UtxoChainsBalancesResponse {
        return UtxoChainsBalancesResponse.deserialize(bytes);
    }
}
export namespace UtxoChainsBalancesResponse {
    export type AsObject = {
        xChainBalance: string;
        pChainBalance: string;
    };
    export type AsObjectPartial = {
        xChainBalance?: string;
        pChainBalance?: string;
    };
}
export class UpdateBalancesRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        balanceDeltas?: Map<string, string>;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("balanceDeltas" in data && data.balanceDeltas != undefined) {
                this.balanceDeltas = data.balanceDeltas;
            }
        }
        if (!this.balanceDeltas)
            this.balanceDeltas = new Map();
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get balanceDeltas() {
        return pb_1.Message.getField(this, 2) as any as Map<string, string>;
    }
    set balanceDeltas(value: Map<string, string>) {
        pb_1.Message.setField(this, 2, value as any);
    }
    static fromObject(data?: UpdateBalancesRequest.AsObjectPartial): UpdateBalancesRequest {
        if (!data) {
            return new UpdateBalancesRequest();
        }
        const message = new UpdateBalancesRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (typeof data.balanceDeltas == "object") {
            message.balanceDeltas = new Map(Object.entries(data.balanceDeltas));
        }
        return message;
    }
    toObject() {
        const data: UpdateBalancesRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            balanceDeltas: (Object.fromEntries)(this.balanceDeltas)
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        for (const [key, value] of this.balanceDeltas) {
            writer.writeMessage(2, this.balanceDeltas, () => {
                writer.writeString(1, key);
                writer.writeString(2, value);
            });
        }
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UpdateBalancesRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UpdateBalancesRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.balanceDeltas as any, reader, reader.readString, reader.readString));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): UpdateBalancesRequest {
        return UpdateBalancesRequest.deserialize(bytes);
    }
}
export namespace UpdateBalancesRequest {
    export type AsObject = {
        blockchainIndex: number;
        balanceDeltas: {
            [key: string]: string;
        };
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        balanceDeltas?: {
            [key: string]: string;
        };
    };
}
export class IsContractBulkRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        queries?: IsContractBulkRequest.IsContractQuery[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("queries" in data && data.queries != undefined) {
                this.queries = data.queries;
            }
        }
    }
    get queries() {
        return pb_1.Message.getRepeatedWrapperField(this, IsContractBulkRequest.IsContractQuery, 1) as IsContractBulkRequest.IsContractQuery[];
    }
    set queries(value: IsContractBulkRequest.IsContractQuery[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data?: IsContractBulkRequest.AsObjectPartial): IsContractBulkRequest {
        if (!data) {
            return new IsContractBulkRequest();
        }
        const message = new IsContractBulkRequest({});
        if (data.queries != null) {
            message.queries = data.queries.map(item => IsContractBulkRequest.IsContractQuery.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: IsContractBulkRequest.AsObject = {
            queries: this.queries.map((item: IsContractBulkRequest.IsContractQuery) => item.toObject())
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.queries.length)
            writer.writeRepeatedMessage(1, this.queries, (item: IsContractBulkRequest.IsContractQuery) => item!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): IsContractBulkRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new IsContractBulkRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.queries, () => pb_1.Message.addToRepeatedWrapperField(message, 1, IsContractBulkRequest.IsContractQuery.deserialize(reader), IsContractBulkRequest.IsContractQuery));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): IsContractBulkRequest {
        return IsContractBulkRequest.deserialize(bytes);
    }
}
export namespace IsContractBulkRequest {
    export type AsObject = {
        queries: IsContractBulkRequest.IsContractQuery.AsObject[];
    };
    export type AsObjectPartial = {
        queries?: IsContractBulkRequest.IsContractQuery.AsObjectPartial[];
    };
    export class IsContractQuery extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            address?: string;
            blockchainIndex?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("address" in data && data.address != undefined) {
                    this.address = data.address;
                }
                if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                    this.blockchainIndex = data.blockchainIndex;
                }
            }
        }
        get address() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set address(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get blockchainIndex() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set blockchainIndex(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data?: IsContractQuery.AsObjectPartial): IsContractQuery {
            if (!data) {
                return new IsContractQuery();
            }
            const message = new IsContractQuery({});
            if (data.address != null) {
                message.address = data.address;
            }
            if (data.blockchainIndex != null) {
                message.blockchainIndex = data.blockchainIndex;
            }
            return message;
        }
        toObject() {
            const data: IsContractQuery.AsObject = {
                address: this.address,
                blockchainIndex: this.blockchainIndex
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.address.length)
                writer.writeString(1, this.address);
            if (this.blockchainIndex != 0)
                writer.writeInt64(2, this.blockchainIndex);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): IsContractQuery {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new IsContractQuery();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.address = reader.readString();
                        break;
                    case 2:
                        message.blockchainIndex = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): IsContractQuery {
            return IsContractQuery.deserialize(bytes);
        }
    }
    export namespace IsContractQuery {
        export type AsObject = {
            address: string;
            blockchainIndex: number;
        };
        export type AsObjectPartial = {
            address?: string;
            blockchainIndex?: number;
        };
    }
}
export class IsContractBulkResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        queryResponses?: boolean[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("queryResponses" in data && data.queryResponses != undefined) {
                this.queryResponses = data.queryResponses;
            }
        }
    }
    get queryResponses() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as boolean[];
    }
    set queryResponses(value: boolean[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data?: IsContractBulkResponse.AsObjectPartial): IsContractBulkResponse {
        if (!data) {
            return new IsContractBulkResponse();
        }
        const message = new IsContractBulkResponse({});
        if (data.queryResponses != null) {
            message.queryResponses = data.queryResponses;
        }
        return message;
    }
    toObject() {
        const data: IsContractBulkResponse.AsObject = {
            queryResponses: this.queryResponses
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.queryResponses.length)
            writer.writePackedBool(1, this.queryResponses);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): IsContractBulkResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new IsContractBulkResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.queryResponses = reader.readPackedBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): IsContractBulkResponse {
        return IsContractBulkResponse.deserialize(bytes);
    }
}
export namespace IsContractBulkResponse {
    export type AsObject = {
        queryResponses: boolean[];
    };
    export type AsObjectPartial = {
        queryResponses?: boolean[];
    };
}
export class ContractsRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        page?: dependency_3.Page;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_3.Page, 1) as dependency_3.Page | undefined;
    }
    set page(value: dependency_3.Page | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    static fromObject(data?: ContractsRequest.AsObjectPartial): ContractsRequest {
        if (!data) {
            return new ContractsRequest();
        }
        const message = new ContractsRequest({});
        if (data.page != null) {
            message.page = dependency_3.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: ContractsRequest.AsObject = {};
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasPage)
            writer.writeMessage(1, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ContractsRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ContractsRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.page, () => message.page = dependency_3.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ContractsRequest {
        return ContractsRequest.deserialize(bytes);
    }
}
export namespace ContractsRequest {
    export type AsObject = {
        page?: dependency_3.Page.AsObject;
    };
    export type AsObjectPartial = {
        page?: dependency_3.Page.AsObjectPartial;
    };
}
export class ContractsResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        contracts?: ContractsResponse.Contract[];
        navigation?: dependency_3.NavigationData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("contracts" in data && data.contracts != undefined) {
                this.contracts = data.contracts;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
        }
    }
    get contracts() {
        return pb_1.Message.getRepeatedWrapperField(this, ContractsResponse.Contract, 1) as ContractsResponse.Contract[];
    }
    set contracts(value: ContractsResponse.Contract[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_3.NavigationData, 2) as dependency_3.NavigationData | undefined;
    }
    set navigation(value: dependency_3.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    static fromObject(data?: ContractsResponse.AsObjectPartial): ContractsResponse {
        if (!data) {
            return new ContractsResponse();
        }
        const message = new ContractsResponse({});
        if (data.contracts != null) {
            message.contracts = data.contracts.map(item => ContractsResponse.Contract.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_3.NavigationData.fromObject(data.navigation);
        }
        return message;
    }
    toObject() {
        const data: ContractsResponse.AsObject = {
            contracts: this.contracts.map((item: ContractsResponse.Contract) => item.toObject())
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.contracts.length)
            writer.writeRepeatedMessage(1, this.contracts, (item: ContractsResponse.Contract) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(2, this.navigation, () => this.navigation!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ContractsResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ContractsResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.contracts, () => pb_1.Message.addToRepeatedWrapperField(message, 1, ContractsResponse.Contract.deserialize(reader), ContractsResponse.Contract));
                    break;
                case 2:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_3.NavigationData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ContractsResponse {
        return ContractsResponse.deserialize(bytes);
    }
}
export namespace ContractsResponse {
    export type AsObject = {
        contracts: ContractsResponse.Contract.AsObject[];
        navigation?: dependency_3.NavigationData.AsObject;
    };
    export type AsObjectPartial = {
        contracts?: ContractsResponse.Contract.AsObjectPartial[];
        navigation?: dependency_3.NavigationData.AsObjectPartial;
    };
    export class Contract extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            address?: string;
            blockchainIndex?: number;
            contract?: dependency_2.ContractAddressData;
            txCount?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("address" in data && data.address != undefined) {
                    this.address = data.address;
                }
                if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                    this.blockchainIndex = data.blockchainIndex;
                }
                if ("contract" in data && data.contract != undefined) {
                    this.contract = data.contract;
                }
                if ("txCount" in data && data.txCount != undefined) {
                    this.txCount = data.txCount;
                }
            }
        }
        get address() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set address(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get blockchainIndex() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set blockchainIndex(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get contract() {
            return pb_1.Message.getWrapperField(this, dependency_2.ContractAddressData, 3) as dependency_2.ContractAddressData | undefined;
        }
        set contract(value: dependency_2.ContractAddressData | undefined) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasContract() {
            return pb_1.Message.getField(this, 3) != null;
        }
        clearContract(): void {
            this.contract = undefined!;
        }
        get txCount() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set txCount(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        static fromObject(data?: Contract.AsObjectPartial): Contract {
            if (!data) {
                return new Contract();
            }
            const message = new Contract({});
            if (data.address != null) {
                message.address = data.address;
            }
            if (data.blockchainIndex != null) {
                message.blockchainIndex = data.blockchainIndex;
            }
            if (data.contract != null) {
                message.contract = dependency_2.ContractAddressData.fromObject(data.contract);
            }
            if (data.txCount != null) {
                message.txCount = data.txCount;
            }
            return message;
        }
        toObject() {
            const data: Contract.AsObject = {
                address: this.address,
                blockchainIndex: this.blockchainIndex,
                txCount: this.txCount
            };
            if (this.contract != null) {
                data.contract = this.contract.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.address.length)
                writer.writeString(1, this.address);
            if (this.blockchainIndex != 0)
                writer.writeInt64(2, this.blockchainIndex);
            if (this.hasContract)
                writer.writeMessage(3, this.contract, () => this.contract!.serialize(writer));
            if (this.txCount != 0)
                writer.writeInt64(4, this.txCount);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Contract {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Contract();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.address = reader.readString();
                        break;
                    case 2:
                        message.blockchainIndex = reader.readInt64();
                        break;
                    case 3:
                        reader.readMessage(message.contract, () => message.contract = dependency_2.ContractAddressData.deserialize(reader));
                        break;
                    case 4:
                        message.txCount = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Contract {
            return Contract.deserialize(bytes);
        }
    }
    export namespace Contract {
        export type AsObject = {
            address: string;
            blockchainIndex: number;
            contract?: dependency_2.ContractAddressData.AsObject;
            txCount: number;
        };
        export type AsObjectPartial = {
            address?: string;
            blockchainIndex?: number;
            contract?: dependency_2.ContractAddressData.AsObjectPartial;
            txCount?: number;
        };
    }
}
export class VerificationFormParams extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        module?: string;
        action?: string;
        contractaddress?: string;
        sourceCode?: string;
        codeformat?: string;
        contractname?: string;
        compilerversion?: string;
        optimizationUsed?: number;
        runs?: number;
        licenseType?: number;
        apikey?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("module" in data && data.module != undefined) {
                this.module = data.module;
            }
            if ("action" in data && data.action != undefined) {
                this.action = data.action;
            }
            if ("contractaddress" in data && data.contractaddress != undefined) {
                this.contractaddress = data.contractaddress;
            }
            if ("sourceCode" in data && data.sourceCode != undefined) {
                this.sourceCode = data.sourceCode;
            }
            if ("codeformat" in data && data.codeformat != undefined) {
                this.codeformat = data.codeformat;
            }
            if ("contractname" in data && data.contractname != undefined) {
                this.contractname = data.contractname;
            }
            if ("compilerversion" in data && data.compilerversion != undefined) {
                this.compilerversion = data.compilerversion;
            }
            if ("optimizationUsed" in data && data.optimizationUsed != undefined) {
                this.optimizationUsed = data.optimizationUsed;
            }
            if ("runs" in data && data.runs != undefined) {
                this.runs = data.runs;
            }
            if ("licenseType" in data && data.licenseType != undefined) {
                this.licenseType = data.licenseType;
            }
            if ("apikey" in data && data.apikey != undefined) {
                this.apikey = data.apikey;
            }
        }
    }
    get module() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set module(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get action() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set action(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get contractaddress() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set contractaddress(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get sourceCode() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set sourceCode(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get codeformat() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set codeformat(value: string) {
        pb_1.Message.setField(this, 5, value);
    }
    get contractname() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set contractname(value: string) {
        pb_1.Message.setField(this, 6, value);
    }
    get compilerversion() {
        return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
    }
    set compilerversion(value: string) {
        pb_1.Message.setField(this, 7, value);
    }
    get optimizationUsed() {
        return pb_1.Message.getFieldWithDefault(this, 8, 0) as number;
    }
    set optimizationUsed(value: number) {
        pb_1.Message.setField(this, 8, value);
    }
    get runs() {
        return pb_1.Message.getFieldWithDefault(this, 9, 0) as number;
    }
    set runs(value: number) {
        pb_1.Message.setField(this, 9, value);
    }
    get licenseType() {
        return pb_1.Message.getFieldWithDefault(this, 10, 0) as number;
    }
    set licenseType(value: number) {
        pb_1.Message.setField(this, 10, value);
    }
    get apikey() {
        return pb_1.Message.getFieldWithDefault(this, 11, "") as string;
    }
    set apikey(value: string) {
        pb_1.Message.setField(this, 11, value);
    }
    static fromObject(data?: VerificationFormParams.AsObjectPartial): VerificationFormParams {
        if (!data) {
            return new VerificationFormParams();
        }
        const message = new VerificationFormParams({});
        if (data.module != null) {
            message.module = data.module;
        }
        if (data.action != null) {
            message.action = data.action;
        }
        if (data.contractaddress != null) {
            message.contractaddress = data.contractaddress;
        }
        if (data.sourceCode != null) {
            message.sourceCode = data.sourceCode;
        }
        if (data.codeformat != null) {
            message.codeformat = data.codeformat;
        }
        if (data.contractname != null) {
            message.contractname = data.contractname;
        }
        if (data.compilerversion != null) {
            message.compilerversion = data.compilerversion;
        }
        if (data.optimizationUsed != null) {
            message.optimizationUsed = data.optimizationUsed;
        }
        if (data.runs != null) {
            message.runs = data.runs;
        }
        if (data.licenseType != null) {
            message.licenseType = data.licenseType;
        }
        if (data.apikey != null) {
            message.apikey = data.apikey;
        }
        return message;
    }
    toObject() {
        const data: VerificationFormParams.AsObject = {
            module: this.module,
            action: this.action,
            contractaddress: this.contractaddress,
            sourceCode: this.sourceCode,
            codeformat: this.codeformat,
            contractname: this.contractname,
            compilerversion: this.compilerversion,
            optimizationUsed: this.optimizationUsed,
            runs: this.runs,
            licenseType: this.licenseType,
            apikey: this.apikey
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.module.length)
            writer.writeString(1, this.module);
        if (this.action.length)
            writer.writeString(2, this.action);
        if (this.contractaddress.length)
            writer.writeString(3, this.contractaddress);
        if (this.sourceCode.length)
            writer.writeString(4, this.sourceCode);
        if (this.codeformat.length)
            writer.writeString(5, this.codeformat);
        if (this.contractname.length)
            writer.writeString(6, this.contractname);
        if (this.compilerversion.length)
            writer.writeString(7, this.compilerversion);
        if (this.optimizationUsed != 0)
            writer.writeInt32(8, this.optimizationUsed);
        if (this.runs != 0)
            writer.writeInt32(9, this.runs);
        if (this.licenseType != 0)
            writer.writeInt32(10, this.licenseType);
        if (this.apikey.length)
            writer.writeString(11, this.apikey);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): VerificationFormParams {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new VerificationFormParams();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.module = reader.readString();
                    break;
                case 2:
                    message.action = reader.readString();
                    break;
                case 3:
                    message.contractaddress = reader.readString();
                    break;
                case 4:
                    message.sourceCode = reader.readString();
                    break;
                case 5:
                    message.codeformat = reader.readString();
                    break;
                case 6:
                    message.contractname = reader.readString();
                    break;
                case 7:
                    message.compilerversion = reader.readString();
                    break;
                case 8:
                    message.optimizationUsed = reader.readInt32();
                    break;
                case 9:
                    message.runs = reader.readInt32();
                    break;
                case 10:
                    message.licenseType = reader.readInt32();
                    break;
                case 11:
                    message.apikey = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): VerificationFormParams {
        return VerificationFormParams.deserialize(bytes);
    }
}
export namespace VerificationFormParams {
    export type AsObject = {
        module: string;
        action: string;
        contractaddress: string;
        sourceCode: string;
        codeformat: string;
        contractname: string;
        compilerversion: string;
        optimizationUsed: number;
        runs: number;
        licenseType: number;
        apikey: string;
    };
    export type AsObjectPartial = {
        module?: string;
        action?: string;
        contractaddress?: string;
        sourceCode?: string;
        codeformat?: string;
        contractname?: string;
        compilerversion?: string;
        optimizationUsed?: number;
        runs?: number;
        licenseType?: number;
        apikey?: string;
    };
}
export class VerificationStatusFormParams extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        module?: string;
        action?: string;
        apikey?: string;
        guid?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("module" in data && data.module != undefined) {
                this.module = data.module;
            }
            if ("action" in data && data.action != undefined) {
                this.action = data.action;
            }
            if ("apikey" in data && data.apikey != undefined) {
                this.apikey = data.apikey;
            }
            if ("guid" in data && data.guid != undefined) {
                this.guid = data.guid;
            }
        }
    }
    get module() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set module(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get action() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set action(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get apikey() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set apikey(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get guid() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set guid(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    static fromObject(data?: VerificationStatusFormParams.AsObjectPartial): VerificationStatusFormParams {
        if (!data) {
            return new VerificationStatusFormParams();
        }
        const message = new VerificationStatusFormParams({});
        if (data.module != null) {
            message.module = data.module;
        }
        if (data.action != null) {
            message.action = data.action;
        }
        if (data.apikey != null) {
            message.apikey = data.apikey;
        }
        if (data.guid != null) {
            message.guid = data.guid;
        }
        return message;
    }
    toObject() {
        const data: VerificationStatusFormParams.AsObject = {
            module: this.module,
            action: this.action,
            apikey: this.apikey,
            guid: this.guid
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.module.length)
            writer.writeString(1, this.module);
        if (this.action.length)
            writer.writeString(2, this.action);
        if (this.apikey.length)
            writer.writeString(3, this.apikey);
        if (this.guid.length)
            writer.writeString(4, this.guid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): VerificationStatusFormParams {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new VerificationStatusFormParams();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.module = reader.readString();
                    break;
                case 2:
                    message.action = reader.readString();
                    break;
                case 3:
                    message.apikey = reader.readString();
                    break;
                case 4:
                    message.guid = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): VerificationStatusFormParams {
        return VerificationStatusFormParams.deserialize(bytes);
    }
}
export namespace VerificationStatusFormParams {
    export type AsObject = {
        module: string;
        action: string;
        apikey: string;
        guid: string;
    };
    export type AsObjectPartial = {
        module?: string;
        action?: string;
        apikey?: string;
        guid?: string;
    };
}
export class EtherscanLikeResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        status?: string;
        message?: string;
        result?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("status" in data && data.status != undefined) {
                this.status = data.status;
            }
            if ("message" in data && data.message != undefined) {
                this.message = data.message;
            }
            if ("result" in data && data.result != undefined) {
                this.result = data.result;
            }
        }
    }
    get status() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set status(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get message() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set message(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get result() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set result(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data?: EtherscanLikeResponse.AsObjectPartial): EtherscanLikeResponse {
        if (!data) {
            return new EtherscanLikeResponse();
        }
        const message = new EtherscanLikeResponse({});
        if (data.status != null) {
            message.status = data.status;
        }
        if (data.message != null) {
            message.message = data.message;
        }
        if (data.result != null) {
            message.result = data.result;
        }
        return message;
    }
    toObject() {
        const data: EtherscanLikeResponse.AsObject = {
            status: this.status,
            message: this.message,
            result: this.result
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.status.length)
            writer.writeString(1, this.status);
        if (this.message.length)
            writer.writeString(2, this.message);
        if (this.result.length)
            writer.writeString(3, this.result);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): EtherscanLikeResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new EtherscanLikeResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.status = reader.readString();
                    break;
                case 2:
                    message.message = reader.readString();
                    break;
                case 3:
                    message.result = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): EtherscanLikeResponse {
        return EtherscanLikeResponse.deserialize(bytes);
    }
}
export namespace EtherscanLikeResponse {
    export type AsObject = {
        status: string;
        message: string;
        result: string;
    };
    export type AsObjectPartial = {
        status?: string;
        message?: string;
        result?: string;
    };
}
