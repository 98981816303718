// safely converts an error to string
import { ErrorResponse } from "../../modelspb/models";
import { ErrorMessages } from "./messages";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const stringifyError = (error: any): string => {
  let str;
  try {
    str = JSON.stringify(error).replace(/"/g, "'");
    if (str === "{}") {
      str = `${error}`;
    }
  } catch (e) {
    str = `${error}`.replace(/"/g, "'");
  }
  return str;
};

// returns a user-friendly error description
export function getErrorText(reason: string): string {
  if (reason) {
    const errText = ErrorMessages[reason.trim().toLowerCase()];
    if (errText) {
      return errText;
    }
    // when there is no user-friendly description
    return `${reason}`;
  }
  return ErrorMessages["no connection"];
}

export function errorResponseToMessage(error: ErrorResponse.AsObject): string {
  const appendix = error.detail ? `: ${error.detail}` : "";
  // reason - common error description
  // detail - details that can be many and difficult to translate
  // therefore, `reason` is translated, but `detail` is not.
  return getErrorText(error.message) + appendix;
}

// converts an error to a user-friendly string
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function errorToUserText(error: ErrorResponse.AsObject | any): string {
  if (!error) {
    return getErrorText("unhandled error");
  }
  if (error.code && error.message && error.detail !== undefined) {
    return errorResponseToMessage(error);
  }
  if (error.message === "Timeout has occurred") {
    // timeout of socket request observable
    return getErrorText(error.message);
  }
  if (error.message) {
    return getErrorText(error.message);
  }
  if (typeof error === "string") {
    return getErrorText(error);
  }
  return getErrorText(stringifyError(error));
}
