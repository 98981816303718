/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.13.0
 * source: blockchain.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./validate/validate";
import * as dependency_2 from "./models";
import * as pb_1 from "google-protobuf";
export enum EVMKind {
    EVMKindNotEVM = 0,
    EVMKindCoreEth = 1,
    EVMKindSubnetEVM = 2
}
export class Supernet extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        id?: string;
        name?: string;
        description?: string;
        validatorsQty?: number;
        createdAt?: number;
        indexed?: boolean;
        transformed?: boolean;
        assetId?: string;
        rewardsPoolSupply?: string;
        rewardShare?: string;
        minValidatorStake?: string;
        maxValidatorStake?: string;
        minStakeDuration?: number;
        maxStakeDuration?: number;
        minDelegationFee?: number;
        maxDelegationFee?: number;
        minDelegatorStake?: string;
        maxValidatorWeightFactor?: number;
        uptimeRequirement?: number;
        sortIndex?: number;
        initialRewardPoolSupply?: string;
        startRewardShare?: number;
        startRewardTime?: number;
        targetRewardShare?: number;
        targetRewardTime?: number;
        stakePeriodRewardShare?: number;
        diminishingRewardShare?: number;
        diminishingRewardTime?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("description" in data && data.description != undefined) {
                this.description = data.description;
            }
            if ("validatorsQty" in data && data.validatorsQty != undefined) {
                this.validatorsQty = data.validatorsQty;
            }
            if ("createdAt" in data && data.createdAt != undefined) {
                this.createdAt = data.createdAt;
            }
            if ("indexed" in data && data.indexed != undefined) {
                this.indexed = data.indexed;
            }
            if ("transformed" in data && data.transformed != undefined) {
                this.transformed = data.transformed;
            }
            if ("assetId" in data && data.assetId != undefined) {
                this.assetId = data.assetId;
            }
            if ("rewardsPoolSupply" in data && data.rewardsPoolSupply != undefined) {
                this.rewardsPoolSupply = data.rewardsPoolSupply;
            }
            if ("rewardShare" in data && data.rewardShare != undefined) {
                this.rewardShare = data.rewardShare;
            }
            if ("minValidatorStake" in data && data.minValidatorStake != undefined) {
                this.minValidatorStake = data.minValidatorStake;
            }
            if ("maxValidatorStake" in data && data.maxValidatorStake != undefined) {
                this.maxValidatorStake = data.maxValidatorStake;
            }
            if ("minStakeDuration" in data && data.minStakeDuration != undefined) {
                this.minStakeDuration = data.minStakeDuration;
            }
            if ("maxStakeDuration" in data && data.maxStakeDuration != undefined) {
                this.maxStakeDuration = data.maxStakeDuration;
            }
            if ("minDelegationFee" in data && data.minDelegationFee != undefined) {
                this.minDelegationFee = data.minDelegationFee;
            }
            if ("maxDelegationFee" in data && data.maxDelegationFee != undefined) {
                this.maxDelegationFee = data.maxDelegationFee;
            }
            if ("minDelegatorStake" in data && data.minDelegatorStake != undefined) {
                this.minDelegatorStake = data.minDelegatorStake;
            }
            if ("maxValidatorWeightFactor" in data && data.maxValidatorWeightFactor != undefined) {
                this.maxValidatorWeightFactor = data.maxValidatorWeightFactor;
            }
            if ("uptimeRequirement" in data && data.uptimeRequirement != undefined) {
                this.uptimeRequirement = data.uptimeRequirement;
            }
            if ("sortIndex" in data && data.sortIndex != undefined) {
                this.sortIndex = data.sortIndex;
            }
            if ("initialRewardPoolSupply" in data && data.initialRewardPoolSupply != undefined) {
                this.initialRewardPoolSupply = data.initialRewardPoolSupply;
            }
            if ("startRewardShare" in data && data.startRewardShare != undefined) {
                this.startRewardShare = data.startRewardShare;
            }
            if ("startRewardTime" in data && data.startRewardTime != undefined) {
                this.startRewardTime = data.startRewardTime;
            }
            if ("targetRewardShare" in data && data.targetRewardShare != undefined) {
                this.targetRewardShare = data.targetRewardShare;
            }
            if ("targetRewardTime" in data && data.targetRewardTime != undefined) {
                this.targetRewardTime = data.targetRewardTime;
            }
            if ("stakePeriodRewardShare" in data && data.stakePeriodRewardShare != undefined) {
                this.stakePeriodRewardShare = data.stakePeriodRewardShare;
            }
            if ("diminishingRewardShare" in data && data.diminishingRewardShare != undefined) {
                this.diminishingRewardShare = data.diminishingRewardShare;
            }
            if ("diminishingRewardTime" in data && data.diminishingRewardTime != undefined) {
                this.diminishingRewardTime = data.diminishingRewardTime;
            }
        }
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get description() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set description(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get validatorsQty() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set validatorsQty(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get createdAt() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set createdAt(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get indexed() {
        return pb_1.Message.getFieldWithDefault(this, 6, false) as boolean;
    }
    set indexed(value: boolean) {
        pb_1.Message.setField(this, 6, value);
    }
    get transformed() {
        return pb_1.Message.getFieldWithDefault(this, 7, false) as boolean;
    }
    set transformed(value: boolean) {
        pb_1.Message.setField(this, 7, value);
    }
    get assetId() {
        return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
    }
    set assetId(value: string) {
        pb_1.Message.setField(this, 8, value);
    }
    get rewardsPoolSupply() {
        return pb_1.Message.getFieldWithDefault(this, 9, "") as string;
    }
    set rewardsPoolSupply(value: string) {
        pb_1.Message.setField(this, 9, value);
    }
    get rewardShare() {
        return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
    }
    set rewardShare(value: string) {
        pb_1.Message.setField(this, 10, value);
    }
    get minValidatorStake() {
        return pb_1.Message.getFieldWithDefault(this, 11, "") as string;
    }
    set minValidatorStake(value: string) {
        pb_1.Message.setField(this, 11, value);
    }
    get maxValidatorStake() {
        return pb_1.Message.getFieldWithDefault(this, 12, "") as string;
    }
    set maxValidatorStake(value: string) {
        pb_1.Message.setField(this, 12, value);
    }
    get minStakeDuration() {
        return pb_1.Message.getFieldWithDefault(this, 13, 0) as number;
    }
    set minStakeDuration(value: number) {
        pb_1.Message.setField(this, 13, value);
    }
    get maxStakeDuration() {
        return pb_1.Message.getFieldWithDefault(this, 14, 0) as number;
    }
    set maxStakeDuration(value: number) {
        pb_1.Message.setField(this, 14, value);
    }
    get minDelegationFee() {
        return pb_1.Message.getFieldWithDefault(this, 15, 0) as number;
    }
    set minDelegationFee(value: number) {
        pb_1.Message.setField(this, 15, value);
    }
    get maxDelegationFee() {
        return pb_1.Message.getFieldWithDefault(this, 20, 0) as number;
    }
    set maxDelegationFee(value: number) {
        pb_1.Message.setField(this, 20, value);
    }
    get minDelegatorStake() {
        return pb_1.Message.getFieldWithDefault(this, 16, "") as string;
    }
    set minDelegatorStake(value: string) {
        pb_1.Message.setField(this, 16, value);
    }
    get maxValidatorWeightFactor() {
        return pb_1.Message.getFieldWithDefault(this, 17, 0) as number;
    }
    set maxValidatorWeightFactor(value: number) {
        pb_1.Message.setField(this, 17, value);
    }
    get uptimeRequirement() {
        return pb_1.Message.getFieldWithDefault(this, 18, 0) as number;
    }
    set uptimeRequirement(value: number) {
        pb_1.Message.setField(this, 18, value);
    }
    get sortIndex() {
        return pb_1.Message.getFieldWithDefault(this, 19, 0) as number;
    }
    set sortIndex(value: number) {
        pb_1.Message.setField(this, 19, value);
    }
    get initialRewardPoolSupply() {
        return pb_1.Message.getFieldWithDefault(this, 21, "") as string;
    }
    set initialRewardPoolSupply(value: string) {
        pb_1.Message.setField(this, 21, value);
    }
    get startRewardShare() {
        return pb_1.Message.getFieldWithDefault(this, 22, 0) as number;
    }
    set startRewardShare(value: number) {
        pb_1.Message.setField(this, 22, value);
    }
    get startRewardTime() {
        return pb_1.Message.getFieldWithDefault(this, 23, 0) as number;
    }
    set startRewardTime(value: number) {
        pb_1.Message.setField(this, 23, value);
    }
    get targetRewardShare() {
        return pb_1.Message.getFieldWithDefault(this, 24, 0) as number;
    }
    set targetRewardShare(value: number) {
        pb_1.Message.setField(this, 24, value);
    }
    get targetRewardTime() {
        return pb_1.Message.getFieldWithDefault(this, 25, 0) as number;
    }
    set targetRewardTime(value: number) {
        pb_1.Message.setField(this, 25, value);
    }
    get stakePeriodRewardShare() {
        return pb_1.Message.getFieldWithDefault(this, 26, 0) as number;
    }
    set stakePeriodRewardShare(value: number) {
        pb_1.Message.setField(this, 26, value);
    }
    get diminishingRewardShare() {
        return pb_1.Message.getFieldWithDefault(this, 27, 0) as number;
    }
    set diminishingRewardShare(value: number) {
        pb_1.Message.setField(this, 27, value);
    }
    get diminishingRewardTime() {
        return pb_1.Message.getFieldWithDefault(this, 28, 0) as number;
    }
    set diminishingRewardTime(value: number) {
        pb_1.Message.setField(this, 28, value);
    }
    static fromObject(data?: Supernet.AsObjectPartial): Supernet {
        if (!data) {
            return new Supernet();
        }
        const message = new Supernet({});
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.description != null) {
            message.description = data.description;
        }
        if (data.validatorsQty != null) {
            message.validatorsQty = data.validatorsQty;
        }
        if (data.createdAt != null) {
            message.createdAt = data.createdAt;
        }
        if (data.indexed != null) {
            message.indexed = data.indexed;
        }
        if (data.transformed != null) {
            message.transformed = data.transformed;
        }
        if (data.assetId != null) {
            message.assetId = data.assetId;
        }
        if (data.rewardsPoolSupply != null) {
            message.rewardsPoolSupply = data.rewardsPoolSupply;
        }
        if (data.rewardShare != null) {
            message.rewardShare = data.rewardShare;
        }
        if (data.minValidatorStake != null) {
            message.minValidatorStake = data.minValidatorStake;
        }
        if (data.maxValidatorStake != null) {
            message.maxValidatorStake = data.maxValidatorStake;
        }
        if (data.minStakeDuration != null) {
            message.minStakeDuration = data.minStakeDuration;
        }
        if (data.maxStakeDuration != null) {
            message.maxStakeDuration = data.maxStakeDuration;
        }
        if (data.minDelegationFee != null) {
            message.minDelegationFee = data.minDelegationFee;
        }
        if (data.maxDelegationFee != null) {
            message.maxDelegationFee = data.maxDelegationFee;
        }
        if (data.minDelegatorStake != null) {
            message.minDelegatorStake = data.minDelegatorStake;
        }
        if (data.maxValidatorWeightFactor != null) {
            message.maxValidatorWeightFactor = data.maxValidatorWeightFactor;
        }
        if (data.uptimeRequirement != null) {
            message.uptimeRequirement = data.uptimeRequirement;
        }
        if (data.sortIndex != null) {
            message.sortIndex = data.sortIndex;
        }
        if (data.initialRewardPoolSupply != null) {
            message.initialRewardPoolSupply = data.initialRewardPoolSupply;
        }
        if (data.startRewardShare != null) {
            message.startRewardShare = data.startRewardShare;
        }
        if (data.startRewardTime != null) {
            message.startRewardTime = data.startRewardTime;
        }
        if (data.targetRewardShare != null) {
            message.targetRewardShare = data.targetRewardShare;
        }
        if (data.targetRewardTime != null) {
            message.targetRewardTime = data.targetRewardTime;
        }
        if (data.stakePeriodRewardShare != null) {
            message.stakePeriodRewardShare = data.stakePeriodRewardShare;
        }
        if (data.diminishingRewardShare != null) {
            message.diminishingRewardShare = data.diminishingRewardShare;
        }
        if (data.diminishingRewardTime != null) {
            message.diminishingRewardTime = data.diminishingRewardTime;
        }
        return message;
    }
    toObject() {
        const data: Supernet.AsObject = {
            id: this.id,
            name: this.name,
            description: this.description,
            validatorsQty: this.validatorsQty,
            createdAt: this.createdAt,
            indexed: this.indexed,
            transformed: this.transformed,
            assetId: this.assetId,
            rewardsPoolSupply: this.rewardsPoolSupply,
            rewardShare: this.rewardShare,
            minValidatorStake: this.minValidatorStake,
            maxValidatorStake: this.maxValidatorStake,
            minStakeDuration: this.minStakeDuration,
            maxStakeDuration: this.maxStakeDuration,
            minDelegationFee: this.minDelegationFee,
            maxDelegationFee: this.maxDelegationFee,
            minDelegatorStake: this.minDelegatorStake,
            maxValidatorWeightFactor: this.maxValidatorWeightFactor,
            uptimeRequirement: this.uptimeRequirement,
            sortIndex: this.sortIndex,
            initialRewardPoolSupply: this.initialRewardPoolSupply,
            startRewardShare: this.startRewardShare,
            startRewardTime: this.startRewardTime,
            targetRewardShare: this.targetRewardShare,
            targetRewardTime: this.targetRewardTime,
            stakePeriodRewardShare: this.stakePeriodRewardShare,
            diminishingRewardShare: this.diminishingRewardShare,
            diminishingRewardTime: this.diminishingRewardTime
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.id.length)
            writer.writeString(1, this.id);
        if (this.name.length)
            writer.writeString(2, this.name);
        if (this.description.length)
            writer.writeString(3, this.description);
        if (this.validatorsQty != 0)
            writer.writeInt64(4, this.validatorsQty);
        if (this.createdAt != 0)
            writer.writeInt64(5, this.createdAt);
        if (this.indexed != false)
            writer.writeBool(6, this.indexed);
        if (this.transformed != false)
            writer.writeBool(7, this.transformed);
        if (this.assetId.length)
            writer.writeString(8, this.assetId);
        if (this.rewardsPoolSupply.length)
            writer.writeString(9, this.rewardsPoolSupply);
        if (this.rewardShare.length)
            writer.writeString(10, this.rewardShare);
        if (this.minValidatorStake.length)
            writer.writeString(11, this.minValidatorStake);
        if (this.maxValidatorStake.length)
            writer.writeString(12, this.maxValidatorStake);
        if (this.minStakeDuration != 0)
            writer.writeInt64(13, this.minStakeDuration);
        if (this.maxStakeDuration != 0)
            writer.writeInt64(14, this.maxStakeDuration);
        if (this.minDelegationFee != 0)
            writer.writeInt64(15, this.minDelegationFee);
        if (this.maxDelegationFee != 0)
            writer.writeInt64(20, this.maxDelegationFee);
        if (this.minDelegatorStake.length)
            writer.writeString(16, this.minDelegatorStake);
        if (this.maxValidatorWeightFactor != 0)
            writer.writeUint32(17, this.maxValidatorWeightFactor);
        if (this.uptimeRequirement != 0)
            writer.writeInt64(18, this.uptimeRequirement);
        if (this.sortIndex != 0)
            writer.writeInt64(19, this.sortIndex);
        if (this.initialRewardPoolSupply.length)
            writer.writeString(21, this.initialRewardPoolSupply);
        if (this.startRewardShare != 0)
            writer.writeInt64(22, this.startRewardShare);
        if (this.startRewardTime != 0)
            writer.writeInt64(23, this.startRewardTime);
        if (this.targetRewardShare != 0)
            writer.writeInt64(24, this.targetRewardShare);
        if (this.targetRewardTime != 0)
            writer.writeInt64(25, this.targetRewardTime);
        if (this.stakePeriodRewardShare != 0)
            writer.writeInt64(26, this.stakePeriodRewardShare);
        if (this.diminishingRewardShare != 0)
            writer.writeInt64(27, this.diminishingRewardShare);
        if (this.diminishingRewardTime != 0)
            writer.writeInt64(28, this.diminishingRewardTime);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Supernet {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Supernet();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.id = reader.readString();
                    break;
                case 2:
                    message.name = reader.readString();
                    break;
                case 3:
                    message.description = reader.readString();
                    break;
                case 4:
                    message.validatorsQty = reader.readInt64();
                    break;
                case 5:
                    message.createdAt = reader.readInt64();
                    break;
                case 6:
                    message.indexed = reader.readBool();
                    break;
                case 7:
                    message.transformed = reader.readBool();
                    break;
                case 8:
                    message.assetId = reader.readString();
                    break;
                case 9:
                    message.rewardsPoolSupply = reader.readString();
                    break;
                case 10:
                    message.rewardShare = reader.readString();
                    break;
                case 11:
                    message.minValidatorStake = reader.readString();
                    break;
                case 12:
                    message.maxValidatorStake = reader.readString();
                    break;
                case 13:
                    message.minStakeDuration = reader.readInt64();
                    break;
                case 14:
                    message.maxStakeDuration = reader.readInt64();
                    break;
                case 15:
                    message.minDelegationFee = reader.readInt64();
                    break;
                case 20:
                    message.maxDelegationFee = reader.readInt64();
                    break;
                case 16:
                    message.minDelegatorStake = reader.readString();
                    break;
                case 17:
                    message.maxValidatorWeightFactor = reader.readUint32();
                    break;
                case 18:
                    message.uptimeRequirement = reader.readInt64();
                    break;
                case 19:
                    message.sortIndex = reader.readInt64();
                    break;
                case 21:
                    message.initialRewardPoolSupply = reader.readString();
                    break;
                case 22:
                    message.startRewardShare = reader.readInt64();
                    break;
                case 23:
                    message.startRewardTime = reader.readInt64();
                    break;
                case 24:
                    message.targetRewardShare = reader.readInt64();
                    break;
                case 25:
                    message.targetRewardTime = reader.readInt64();
                    break;
                case 26:
                    message.stakePeriodRewardShare = reader.readInt64();
                    break;
                case 27:
                    message.diminishingRewardShare = reader.readInt64();
                    break;
                case 28:
                    message.diminishingRewardTime = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): Supernet {
        return Supernet.deserialize(bytes);
    }
}
export namespace Supernet {
    export type AsObject = {
        id: string;
        name: string;
        description: string;
        validatorsQty: number;
        createdAt: number;
        indexed: boolean;
        transformed: boolean;
        assetId: string;
        rewardsPoolSupply: string;
        rewardShare: string;
        minValidatorStake: string;
        maxValidatorStake: string;
        minStakeDuration: number;
        maxStakeDuration: number;
        minDelegationFee: number;
        maxDelegationFee: number;
        minDelegatorStake: string;
        maxValidatorWeightFactor: number;
        uptimeRequirement: number;
        sortIndex: number;
        initialRewardPoolSupply: string;
        startRewardShare: number;
        startRewardTime: number;
        targetRewardShare: number;
        targetRewardTime: number;
        stakePeriodRewardShare: number;
        diminishingRewardShare: number;
        diminishingRewardTime: number;
    };
    export type AsObjectPartial = {
        id?: string;
        name?: string;
        description?: string;
        validatorsQty?: number;
        createdAt?: number;
        indexed?: boolean;
        transformed?: boolean;
        assetId?: string;
        rewardsPoolSupply?: string;
        rewardShare?: string;
        minValidatorStake?: string;
        maxValidatorStake?: string;
        minStakeDuration?: number;
        maxStakeDuration?: number;
        minDelegationFee?: number;
        maxDelegationFee?: number;
        minDelegatorStake?: string;
        maxValidatorWeightFactor?: number;
        uptimeRequirement?: number;
        sortIndex?: number;
        initialRewardPoolSupply?: string;
        startRewardShare?: number;
        startRewardTime?: number;
        targetRewardShare?: number;
        targetRewardTime?: number;
        stakePeriodRewardShare?: number;
        diminishingRewardShare?: number;
        diminishingRewardTime?: number;
    };
}
export class Blockchain extends pb_1.Message {
    #one_of_decls: number[][] = [[19, 20, 21]];
    constructor(data?: any[] | ({
        id?: string;
        name?: string;
        index?: number;
        supernetId?: string;
        kind?: dependency_2.BlockchainKind;
        decimals?: number;
        currency?: string;
        createdAt?: number;
        vmId?: string;
        evmGenesis?: string;
        evmChainId?: number;
        indexed?: boolean;
        description?: string;
        host?: string;
        rpcUrls?: string[];
        assetId?: string;
        evmKind?: EVMKind;
        sortIndex?: number;
        disabled?: boolean;
        iconPath?: string;
    } & (({
        evm?: Blockchain.KindEVM;
        avm?: never;
        pvm?: never;
    } | {
        evm?: never;
        avm?: Blockchain.KindAVM;
        pvm?: never;
    } | {
        evm?: never;
        avm?: never;
        pvm?: Blockchain.KindPVM;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [16], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("index" in data && data.index != undefined) {
                this.index = data.index;
            }
            if ("supernetId" in data && data.supernetId != undefined) {
                this.supernetId = data.supernetId;
            }
            if ("kind" in data && data.kind != undefined) {
                this.kind = data.kind;
            }
            if ("decimals" in data && data.decimals != undefined) {
                this.decimals = data.decimals;
            }
            if ("currency" in data && data.currency != undefined) {
                this.currency = data.currency;
            }
            if ("createdAt" in data && data.createdAt != undefined) {
                this.createdAt = data.createdAt;
            }
            if ("vmId" in data && data.vmId != undefined) {
                this.vmId = data.vmId;
            }
            if ("evmGenesis" in data && data.evmGenesis != undefined) {
                this.evmGenesis = data.evmGenesis;
            }
            if ("evmChainId" in data && data.evmChainId != undefined) {
                this.evmChainId = data.evmChainId;
            }
            if ("indexed" in data && data.indexed != undefined) {
                this.indexed = data.indexed;
            }
            if ("description" in data && data.description != undefined) {
                this.description = data.description;
            }
            if ("host" in data && data.host != undefined) {
                this.host = data.host;
            }
            if ("rpcUrls" in data && data.rpcUrls != undefined) {
                this.rpcUrls = data.rpcUrls;
            }
            if ("assetId" in data && data.assetId != undefined) {
                this.assetId = data.assetId;
            }
            if ("evmKind" in data && data.evmKind != undefined) {
                this.evmKind = data.evmKind;
            }
            if ("evm" in data && data.evm != undefined) {
                this.evm = data.evm;
            }
            if ("avm" in data && data.avm != undefined) {
                this.avm = data.avm;
            }
            if ("pvm" in data && data.pvm != undefined) {
                this.pvm = data.pvm;
            }
            if ("sortIndex" in data && data.sortIndex != undefined) {
                this.sortIndex = data.sortIndex;
            }
            if ("disabled" in data && data.disabled != undefined) {
                this.disabled = data.disabled;
            }
            if ("iconPath" in data && data.iconPath != undefined) {
                this.iconPath = data.iconPath;
            }
        }
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get index() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set index(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get supernetId() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set supernetId(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get kind() {
        return pb_1.Message.getFieldWithDefault(this, 6, dependency_2.BlockchainKind.BlockchainKindAVM) as dependency_2.BlockchainKind;
    }
    set kind(value: dependency_2.BlockchainKind) {
        pb_1.Message.setField(this, 6, value);
    }
    get decimals() {
        return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
    }
    set decimals(value: number) {
        pb_1.Message.setField(this, 7, value);
    }
    get currency() {
        return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
    }
    set currency(value: string) {
        pb_1.Message.setField(this, 8, value);
    }
    get createdAt() {
        return pb_1.Message.getFieldWithDefault(this, 9, 0) as number;
    }
    set createdAt(value: number) {
        pb_1.Message.setField(this, 9, value);
    }
    get vmId() {
        return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
    }
    set vmId(value: string) {
        pb_1.Message.setField(this, 10, value);
    }
    get evmGenesis() {
        return pb_1.Message.getFieldWithDefault(this, 11, "") as string;
    }
    set evmGenesis(value: string) {
        pb_1.Message.setField(this, 11, value);
    }
    get evmChainId() {
        return pb_1.Message.getFieldWithDefault(this, 12, 0) as number;
    }
    set evmChainId(value: number) {
        pb_1.Message.setField(this, 12, value);
    }
    get indexed() {
        return pb_1.Message.getFieldWithDefault(this, 13, false) as boolean;
    }
    set indexed(value: boolean) {
        pb_1.Message.setField(this, 13, value);
    }
    get description() {
        return pb_1.Message.getFieldWithDefault(this, 14, "") as string;
    }
    set description(value: string) {
        pb_1.Message.setField(this, 14, value);
    }
    get host() {
        return pb_1.Message.getFieldWithDefault(this, 15, "") as string;
    }
    set host(value: string) {
        pb_1.Message.setField(this, 15, value);
    }
    get rpcUrls() {
        return pb_1.Message.getFieldWithDefault(this, 16, []) as string[];
    }
    set rpcUrls(value: string[]) {
        pb_1.Message.setField(this, 16, value);
    }
    get assetId() {
        return pb_1.Message.getFieldWithDefault(this, 17, "") as string;
    }
    set assetId(value: string) {
        pb_1.Message.setField(this, 17, value);
    }
    get evmKind() {
        return pb_1.Message.getFieldWithDefault(this, 18, EVMKind.EVMKindNotEVM) as EVMKind;
    }
    set evmKind(value: EVMKind) {
        pb_1.Message.setField(this, 18, value);
    }
    get evm() {
        return pb_1.Message.getWrapperField(this, Blockchain.KindEVM, 19) as Blockchain.KindEVM | undefined;
    }
    set evm(value: Blockchain.KindEVM | undefined) {
        pb_1.Message.setOneofWrapperField(this, 19, this.#one_of_decls[0]!, value);
    }
    get hasEvm() {
        return pb_1.Message.getField(this, 19) != null;
    }
    clearEvm(): void {
        this.evm = undefined!;
    }
    get avm() {
        return pb_1.Message.getWrapperField(this, Blockchain.KindAVM, 20) as Blockchain.KindAVM | undefined;
    }
    set avm(value: Blockchain.KindAVM | undefined) {
        pb_1.Message.setOneofWrapperField(this, 20, this.#one_of_decls[0]!, value);
    }
    get hasAvm() {
        return pb_1.Message.getField(this, 20) != null;
    }
    clearAvm(): void {
        this.avm = undefined!;
    }
    get pvm() {
        return pb_1.Message.getWrapperField(this, Blockchain.KindPVM, 21) as Blockchain.KindPVM | undefined;
    }
    set pvm(value: Blockchain.KindPVM | undefined) {
        pb_1.Message.setOneofWrapperField(this, 21, this.#one_of_decls[0]!, value);
    }
    get hasPvm() {
        return pb_1.Message.getField(this, 21) != null;
    }
    clearPvm(): void {
        this.pvm = undefined!;
    }
    get sortIndex() {
        return pb_1.Message.getFieldWithDefault(this, 22, 0) as number;
    }
    set sortIndex(value: number) {
        pb_1.Message.setField(this, 22, value);
    }
    get disabled() {
        return pb_1.Message.getFieldWithDefault(this, 23, false) as boolean;
    }
    set disabled(value: boolean) {
        pb_1.Message.setField(this, 23, value);
    }
    get iconPath() {
        return pb_1.Message.getFieldWithDefault(this, 24, "") as string;
    }
    set iconPath(value: string) {
        pb_1.Message.setField(this, 24, value);
    }
    get chain_kind() {
        const cases: {
            [index: number]: "none" | "evm" | "avm" | "pvm";
        } = {
            0: "none",
            19: "evm",
            20: "avm",
            21: "pvm"
        };
        return cases[pb_1.Message.computeOneofCase(this, [19, 20, 21])]!;
    }
    static fromObject(data?: Blockchain.AsObjectPartial): Blockchain {
        if (!data) {
            return new Blockchain();
        }
        const message = new Blockchain({});
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.index != null) {
            message.index = data.index;
        }
        if (data.supernetId != null) {
            message.supernetId = data.supernetId;
        }
        if (data.kind != null) {
            message.kind = data.kind;
        }
        if (data.decimals != null) {
            message.decimals = data.decimals;
        }
        if (data.currency != null) {
            message.currency = data.currency;
        }
        if (data.createdAt != null) {
            message.createdAt = data.createdAt;
        }
        if (data.vmId != null) {
            message.vmId = data.vmId;
        }
        if (data.evmGenesis != null) {
            message.evmGenesis = data.evmGenesis;
        }
        if (data.evmChainId != null) {
            message.evmChainId = data.evmChainId;
        }
        if (data.indexed != null) {
            message.indexed = data.indexed;
        }
        if (data.description != null) {
            message.description = data.description;
        }
        if (data.host != null) {
            message.host = data.host;
        }
        if (data.rpcUrls != null) {
            message.rpcUrls = data.rpcUrls;
        }
        if (data.assetId != null) {
            message.assetId = data.assetId;
        }
        if (data.evmKind != null) {
            message.evmKind = data.evmKind;
        }
        if (data.evm != null) {
            message.evm = Blockchain.KindEVM.fromObject(data.evm);
        }
        if (data.avm != null) {
            message.avm = Blockchain.KindAVM.fromObject(data.avm);
        }
        if (data.pvm != null) {
            message.pvm = Blockchain.KindPVM.fromObject(data.pvm);
        }
        if (data.sortIndex != null) {
            message.sortIndex = data.sortIndex;
        }
        if (data.disabled != null) {
            message.disabled = data.disabled;
        }
        if (data.iconPath != null) {
            message.iconPath = data.iconPath;
        }
        return message;
    }
    toObject() {
        const data: Blockchain.AsObject = {
            id: this.id,
            name: this.name,
            index: this.index,
            supernetId: this.supernetId,
            kind: this.kind,
            decimals: this.decimals,
            currency: this.currency,
            createdAt: this.createdAt,
            vmId: this.vmId,
            evmGenesis: this.evmGenesis,
            evmChainId: this.evmChainId,
            indexed: this.indexed,
            description: this.description,
            host: this.host,
            rpcUrls: this.rpcUrls,
            assetId: this.assetId,
            evmKind: this.evmKind,
            sortIndex: this.sortIndex,
            disabled: this.disabled,
            iconPath: this.iconPath
        };
        if (this.evm != null) {
            data.evm = this.evm.toObject();
        }
        if (this.avm != null) {
            data.avm = this.avm.toObject();
        }
        if (this.pvm != null) {
            data.pvm = this.pvm.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.id.length)
            writer.writeString(1, this.id);
        if (this.name.length)
            writer.writeString(2, this.name);
        if (this.index != 0)
            writer.writeInt64(3, this.index);
        if (this.supernetId.length)
            writer.writeString(4, this.supernetId);
        if (this.kind != dependency_2.BlockchainKind.BlockchainKindAVM)
            writer.writeEnum(6, this.kind);
        if (this.decimals != 0)
            writer.writeInt64(7, this.decimals);
        if (this.currency.length)
            writer.writeString(8, this.currency);
        if (this.createdAt != 0)
            writer.writeInt64(9, this.createdAt);
        if (this.vmId.length)
            writer.writeString(10, this.vmId);
        if (this.evmGenesis.length)
            writer.writeString(11, this.evmGenesis);
        if (this.evmChainId != 0)
            writer.writeUint32(12, this.evmChainId);
        if (this.indexed != false)
            writer.writeBool(13, this.indexed);
        if (this.description.length)
            writer.writeString(14, this.description);
        if (this.host.length)
            writer.writeString(15, this.host);
        if (this.rpcUrls.length)
            writer.writeRepeatedString(16, this.rpcUrls);
        if (this.assetId.length)
            writer.writeString(17, this.assetId);
        if (this.evmKind != EVMKind.EVMKindNotEVM)
            writer.writeEnum(18, this.evmKind);
        if (this.hasEvm)
            writer.writeMessage(19, this.evm, () => this.evm!.serialize(writer));
        if (this.hasAvm)
            writer.writeMessage(20, this.avm, () => this.avm!.serialize(writer));
        if (this.hasPvm)
            writer.writeMessage(21, this.pvm, () => this.pvm!.serialize(writer));
        if (this.sortIndex != 0)
            writer.writeInt64(22, this.sortIndex);
        if (this.disabled != false)
            writer.writeBool(23, this.disabled);
        if (this.iconPath.length)
            writer.writeString(24, this.iconPath);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Blockchain {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Blockchain();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.id = reader.readString();
                    break;
                case 2:
                    message.name = reader.readString();
                    break;
                case 3:
                    message.index = reader.readInt64();
                    break;
                case 4:
                    message.supernetId = reader.readString();
                    break;
                case 6:
                    message.kind = reader.readEnum();
                    break;
                case 7:
                    message.decimals = reader.readInt64();
                    break;
                case 8:
                    message.currency = reader.readString();
                    break;
                case 9:
                    message.createdAt = reader.readInt64();
                    break;
                case 10:
                    message.vmId = reader.readString();
                    break;
                case 11:
                    message.evmGenesis = reader.readString();
                    break;
                case 12:
                    message.evmChainId = reader.readUint32();
                    break;
                case 13:
                    message.indexed = reader.readBool();
                    break;
                case 14:
                    message.description = reader.readString();
                    break;
                case 15:
                    message.host = reader.readString();
                    break;
                case 16:
                    pb_1.Message.addToRepeatedField(message, 16, reader.readString());
                    break;
                case 17:
                    message.assetId = reader.readString();
                    break;
                case 18:
                    message.evmKind = reader.readEnum();
                    break;
                case 19:
                    reader.readMessage(message.evm, () => message.evm = Blockchain.KindEVM.deserialize(reader));
                    break;
                case 20:
                    reader.readMessage(message.avm, () => message.avm = Blockchain.KindAVM.deserialize(reader));
                    break;
                case 21:
                    reader.readMessage(message.pvm, () => message.pvm = Blockchain.KindPVM.deserialize(reader));
                    break;
                case 22:
                    message.sortIndex = reader.readInt64();
                    break;
                case 23:
                    message.disabled = reader.readBool();
                    break;
                case 24:
                    message.iconPath = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): Blockchain {
        return Blockchain.deserialize(bytes);
    }
}
export namespace Blockchain {
    export type AsObject = {
        id: string;
        name: string;
        index: number;
        supernetId: string;
        kind: dependency_2.BlockchainKind;
        decimals: number;
        currency: string;
        createdAt: number;
        vmId: string;
        evmGenesis: string;
        evmChainId: number;
        indexed: boolean;
        description: string;
        host: string;
        rpcUrls: string[];
        assetId: string;
        evmKind: EVMKind;
        evm?: Blockchain.KindEVM.AsObject;
        avm?: Blockchain.KindAVM.AsObject;
        pvm?: Blockchain.KindPVM.AsObject;
        sortIndex: number;
        disabled: boolean;
        iconPath: string;
    };
    export type AsObjectPartial = {
        id?: string;
        name?: string;
        index?: number;
        supernetId?: string;
        kind?: dependency_2.BlockchainKind;
        decimals?: number;
        currency?: string;
        createdAt?: number;
        vmId?: string;
        evmGenesis?: string;
        evmChainId?: number;
        indexed?: boolean;
        description?: string;
        host?: string;
        rpcUrls?: string[];
        assetId?: string;
        evmKind?: EVMKind;
        evm?: Blockchain.KindEVM.AsObjectPartial;
        avm?: Blockchain.KindAVM.AsObjectPartial;
        pvm?: Blockchain.KindPVM.AsObjectPartial;
        sortIndex?: number;
        disabled?: boolean;
        iconPath?: string;
    };
    export class KindEVM extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            evmKind?: EVMKind;
            evmGenesis?: string;
            evmChainId?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("evmKind" in data && data.evmKind != undefined) {
                    this.evmKind = data.evmKind;
                }
                if ("evmGenesis" in data && data.evmGenesis != undefined) {
                    this.evmGenesis = data.evmGenesis;
                }
                if ("evmChainId" in data && data.evmChainId != undefined) {
                    this.evmChainId = data.evmChainId;
                }
            }
        }
        get evmKind() {
            return pb_1.Message.getFieldWithDefault(this, 1, EVMKind.EVMKindNotEVM) as EVMKind;
        }
        set evmKind(value: EVMKind) {
            pb_1.Message.setField(this, 1, value);
        }
        get evmGenesis() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set evmGenesis(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get evmChainId() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set evmChainId(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data?: KindEVM.AsObjectPartial): KindEVM {
            if (!data) {
                return new KindEVM();
            }
            const message = new KindEVM({});
            if (data.evmKind != null) {
                message.evmKind = data.evmKind;
            }
            if (data.evmGenesis != null) {
                message.evmGenesis = data.evmGenesis;
            }
            if (data.evmChainId != null) {
                message.evmChainId = data.evmChainId;
            }
            return message;
        }
        toObject() {
            const data: KindEVM.AsObject = {
                evmKind: this.evmKind,
                evmGenesis: this.evmGenesis,
                evmChainId: this.evmChainId
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.evmKind != EVMKind.EVMKindNotEVM)
                writer.writeEnum(1, this.evmKind);
            if (this.evmGenesis.length)
                writer.writeString(2, this.evmGenesis);
            if (this.evmChainId != 0)
                writer.writeUint32(3, this.evmChainId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): KindEVM {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new KindEVM();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.evmKind = reader.readEnum();
                        break;
                    case 2:
                        message.evmGenesis = reader.readString();
                        break;
                    case 3:
                        message.evmChainId = reader.readUint32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): KindEVM {
            return KindEVM.deserialize(bytes);
        }
    }
    export namespace KindEVM {
        export type AsObject = {
            evmKind: EVMKind;
            evmGenesis: string;
            evmChainId: number;
        };
        export type AsObjectPartial = {
            evmKind?: EVMKind;
            evmGenesis?: string;
            evmChainId?: number;
        };
    }
    export class KindAVM extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            isLinearized?: boolean;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("isLinearized" in data && data.isLinearized != undefined) {
                    this.isLinearized = data.isLinearized;
                }
            }
        }
        get isLinearized() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set isLinearized(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data?: KindAVM.AsObjectPartial): KindAVM {
            if (!data) {
                return new KindAVM();
            }
            const message = new KindAVM({});
            if (data.isLinearized != null) {
                message.isLinearized = data.isLinearized;
            }
            return message;
        }
        toObject() {
            const data: KindAVM.AsObject = {
                isLinearized: this.isLinearized
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.isLinearized != false)
                writer.writeBool(1, this.isLinearized);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): KindAVM {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new KindAVM();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.isLinearized = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): KindAVM {
            return KindAVM.deserialize(bytes);
        }
    }
    export namespace KindAVM {
        export type AsObject = {
            isLinearized: boolean;
        };
        export type AsObjectPartial = {
            isLinearized?: boolean;
        };
    }
    export class KindPVM extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data?: KindPVM.AsObjectPartial): KindPVM {
            if (!data) {
                return new KindPVM();
            }
            const message = new KindPVM({});
            return message;
        }
        toObject() {
            const data: KindPVM.AsObject = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): KindPVM {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new KindPVM();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): KindPVM {
            return KindPVM.deserialize(bytes);
        }
    }
    export namespace KindPVM {
        export type AsObject = {};
        export type AsObjectPartial = {};
    }
}
export class SupernetListRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3]];
    constructor(data?: any[] | ({} & (({
        all?: boolean;
        id?: never;
        indexed?: never;
    } | {
        all?: never;
        id?: string;
        indexed?: never;
    } | {
        all?: never;
        id?: never;
        indexed?: boolean;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("all" in data && data.all != undefined) {
                this.all = data.all;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("indexed" in data && data.indexed != undefined) {
                this.indexed = data.indexed;
            }
        }
    }
    get all() {
        return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
    }
    set all(value: boolean) {
        pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0]!, value);
    }
    get hasAll() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearAll(): void {
        this.all = undefined!;
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasId() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearId(): void {
        this.id = undefined!;
    }
    get indexed() {
        return pb_1.Message.getFieldWithDefault(this, 3, false) as boolean;
    }
    set indexed(value: boolean) {
        pb_1.Message.setOneofField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasIndexed() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearIndexed(): void {
        this.indexed = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "all" | "id" | "indexed";
        } = {
            0: "none",
            1: "all",
            2: "id",
            3: "indexed"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3])]!;
    }
    static fromObject(data?: SupernetListRequest.AsObjectPartial): SupernetListRequest {
        if (!data) {
            return new SupernetListRequest();
        }
        const message = new SupernetListRequest({});
        if (data.all != null) {
            message.all = data.all;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.indexed != null) {
            message.indexed = data.indexed;
        }
        return message;
    }
    toObject() {
        const data: SupernetListRequest.AsObject = {
            all: this.all,
            id: this.id,
            indexed: this.indexed
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasAll)
            writer.writeBool(1, this.all);
        if (this.hasId)
            writer.writeString(2, this.id);
        if (this.hasIndexed)
            writer.writeBool(3, this.indexed);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SupernetListRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SupernetListRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.all = reader.readBool();
                    break;
                case 2:
                    message.id = reader.readString();
                    break;
                case 3:
                    message.indexed = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SupernetListRequest {
        return SupernetListRequest.deserialize(bytes);
    }
}
export namespace SupernetListRequest {
    export type AsObject = {
        all: boolean;
        id: string;
        indexed: boolean;
    };
    export type AsObjectPartial = {
        all?: boolean;
        id?: string;
        indexed?: boolean;
    };
}
export class SupernetListResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        supernets?: Supernet[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("supernets" in data && data.supernets != undefined) {
                this.supernets = data.supernets;
            }
        }
    }
    get supernets() {
        return pb_1.Message.getRepeatedWrapperField(this, Supernet, 1) as Supernet[];
    }
    set supernets(value: Supernet[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data?: SupernetListResponse.AsObjectPartial): SupernetListResponse {
        if (!data) {
            return new SupernetListResponse();
        }
        const message = new SupernetListResponse({});
        if (data.supernets != null) {
            message.supernets = data.supernets.map(item => Supernet.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: SupernetListResponse.AsObject = {
            supernets: this.supernets.map((item: Supernet) => item.toObject())
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.supernets.length)
            writer.writeRepeatedMessage(1, this.supernets, (item: Supernet) => item!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SupernetListResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SupernetListResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.supernets, () => pb_1.Message.addToRepeatedWrapperField(message, 1, Supernet.deserialize(reader), Supernet));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SupernetListResponse {
        return SupernetListResponse.deserialize(bytes);
    }
}
export namespace SupernetListResponse {
    export type AsObject = {
        supernets: Supernet.AsObject[];
    };
    export type AsObjectPartial = {
        supernets?: Supernet.AsObjectPartial[];
    };
}
export class BlockchainListRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3, 4, 5]];
    constructor(data?: any[] | ({} & (({
        indexed?: boolean;
        supernetId?: never;
        id?: never;
        index?: never;
        all?: never;
    } | {
        indexed?: never;
        supernetId?: string;
        id?: never;
        index?: never;
        all?: never;
    } | {
        indexed?: never;
        supernetId?: never;
        id?: string;
        index?: never;
        all?: never;
    } | {
        indexed?: never;
        supernetId?: never;
        id?: never;
        index?: BlockchainListRequest.IndexSearch;
        all?: never;
    } | {
        indexed?: never;
        supernetId?: never;
        id?: never;
        index?: never;
        all?: boolean;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("indexed" in data && data.indexed != undefined) {
                this.indexed = data.indexed;
            }
            if ("supernetId" in data && data.supernetId != undefined) {
                this.supernetId = data.supernetId;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("index" in data && data.index != undefined) {
                this.index = data.index;
            }
            if ("all" in data && data.all != undefined) {
                this.all = data.all;
            }
        }
    }
    get indexed() {
        return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
    }
    set indexed(value: boolean) {
        pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0]!, value);
    }
    get hasIndexed() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearIndexed(): void {
        this.indexed = undefined!;
    }
    get supernetId() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set supernetId(value: string) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasSupernetId() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearSupernetId(): void {
        this.supernetId = undefined!;
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setOneofField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasId() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearId(): void {
        this.id = undefined!;
    }
    get index() {
        return pb_1.Message.getWrapperField(this, BlockchainListRequest.IndexSearch, 4) as BlockchainListRequest.IndexSearch | undefined;
    }
    set index(value: BlockchainListRequest.IndexSearch | undefined) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0]!, value);
    }
    get hasIndex() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearIndex(): void {
        this.index = undefined!;
    }
    get all() {
        return pb_1.Message.getFieldWithDefault(this, 5, false) as boolean;
    }
    set all(value: boolean) {
        pb_1.Message.setOneofField(this, 5, this.#one_of_decls[0]!, value);
    }
    get hasAll() {
        return pb_1.Message.getField(this, 5) != null;
    }
    clearAll(): void {
        this.all = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "indexed" | "supernetId" | "id" | "index" | "all";
        } = {
            0: "none",
            1: "indexed",
            2: "supernetId",
            3: "id",
            4: "index",
            5: "all"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4, 5])]!;
    }
    static fromObject(data?: BlockchainListRequest.AsObjectPartial): BlockchainListRequest {
        if (!data) {
            return new BlockchainListRequest();
        }
        const message = new BlockchainListRequest({});
        if (data.indexed != null) {
            message.indexed = data.indexed;
        }
        if (data.supernetId != null) {
            message.supernetId = data.supernetId;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.index != null) {
            message.index = BlockchainListRequest.IndexSearch.fromObject(data.index);
        }
        if (data.all != null) {
            message.all = data.all;
        }
        return message;
    }
    toObject() {
        const data: BlockchainListRequest.AsObject = {
            indexed: this.indexed,
            supernetId: this.supernetId,
            id: this.id,
            all: this.all
        };
        if (this.index != null) {
            data.index = this.index.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasIndexed)
            writer.writeBool(1, this.indexed);
        if (this.hasSupernetId)
            writer.writeString(2, this.supernetId);
        if (this.hasId)
            writer.writeString(3, this.id);
        if (this.hasIndex)
            writer.writeMessage(4, this.index, () => this.index!.serialize(writer));
        if (this.hasAll)
            writer.writeBool(5, this.all);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockchainListRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockchainListRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.indexed = reader.readBool();
                    break;
                case 2:
                    message.supernetId = reader.readString();
                    break;
                case 3:
                    message.id = reader.readString();
                    break;
                case 4:
                    reader.readMessage(message.index, () => message.index = BlockchainListRequest.IndexSearch.deserialize(reader));
                    break;
                case 5:
                    message.all = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BlockchainListRequest {
        return BlockchainListRequest.deserialize(bytes);
    }
}
export namespace BlockchainListRequest {
    export type AsObject = {
        indexed: boolean;
        supernetId: string;
        id: string;
        index?: BlockchainListRequest.IndexSearch.AsObject;
        all: boolean;
    };
    export type AsObjectPartial = {
        indexed?: boolean;
        supernetId?: string;
        id?: string;
        index?: BlockchainListRequest.IndexSearch.AsObjectPartial;
        all?: boolean;
    };
    export class IndexSearch extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            index?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("index" in data && data.index != undefined) {
                    this.index = data.index;
                }
            }
        }
        get index() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set index(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data?: IndexSearch.AsObjectPartial): IndexSearch {
            if (!data) {
                return new IndexSearch();
            }
            const message = new IndexSearch({});
            if (data.index != null) {
                message.index = data.index;
            }
            return message;
        }
        toObject() {
            const data: IndexSearch.AsObject = {
                index: this.index
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.index != 0)
                writer.writeInt64(1, this.index);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): IndexSearch {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new IndexSearch();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.index = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): IndexSearch {
            return IndexSearch.deserialize(bytes);
        }
    }
    export namespace IndexSearch {
        export type AsObject = {
            index: number;
        };
        export type AsObjectPartial = {
            index?: number;
        };
    }
}
export class BlockchainListResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchains?: Blockchain[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchains" in data && data.blockchains != undefined) {
                this.blockchains = data.blockchains;
            }
        }
    }
    get blockchains() {
        return pb_1.Message.getRepeatedWrapperField(this, Blockchain, 1) as Blockchain[];
    }
    set blockchains(value: Blockchain[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data?: BlockchainListResponse.AsObjectPartial): BlockchainListResponse {
        if (!data) {
            return new BlockchainListResponse();
        }
        const message = new BlockchainListResponse({});
        if (data.blockchains != null) {
            message.blockchains = data.blockchains.map(item => Blockchain.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: BlockchainListResponse.AsObject = {
            blockchains: this.blockchains.map((item: Blockchain) => item.toObject())
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchains.length)
            writer.writeRepeatedMessage(1, this.blockchains, (item: Blockchain) => item!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockchainListResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockchainListResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.blockchains, () => pb_1.Message.addToRepeatedWrapperField(message, 1, Blockchain.deserialize(reader), Blockchain));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BlockchainListResponse {
        return BlockchainListResponse.deserialize(bytes);
    }
}
export namespace BlockchainListResponse {
    export type AsObject = {
        blockchains: Blockchain.AsObject[];
    };
    export type AsObjectPartial = {
        blockchains?: Blockchain.AsObjectPartial[];
    };
}
export class SupernetAddRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        supernet?: Supernet;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("supernet" in data && data.supernet != undefined) {
                this.supernet = data.supernet;
            }
        }
    }
    get supernet() {
        return pb_1.Message.getWrapperField(this, Supernet, 1) as Supernet | undefined;
    }
    set supernet(value: Supernet | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasSupernet() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearSupernet(): void {
        this.supernet = undefined!;
    }
    static fromObject(data?: SupernetAddRequest.AsObjectPartial): SupernetAddRequest {
        if (!data) {
            return new SupernetAddRequest();
        }
        const message = new SupernetAddRequest({});
        if (data.supernet != null) {
            message.supernet = Supernet.fromObject(data.supernet);
        }
        return message;
    }
    toObject() {
        const data: SupernetAddRequest.AsObject = {};
        if (this.supernet != null) {
            data.supernet = this.supernet.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasSupernet)
            writer.writeMessage(1, this.supernet, () => this.supernet!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SupernetAddRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SupernetAddRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.supernet, () => message.supernet = Supernet.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SupernetAddRequest {
        return SupernetAddRequest.deserialize(bytes);
    }
}
export namespace SupernetAddRequest {
    export type AsObject = {
        supernet?: Supernet.AsObject;
    };
    export type AsObjectPartial = {
        supernet?: Supernet.AsObjectPartial;
    };
}
export class SupernetAddResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        supernet?: Supernet;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("supernet" in data && data.supernet != undefined) {
                this.supernet = data.supernet;
            }
        }
    }
    get supernet() {
        return pb_1.Message.getWrapperField(this, Supernet, 1) as Supernet | undefined;
    }
    set supernet(value: Supernet | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasSupernet() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearSupernet(): void {
        this.supernet = undefined!;
    }
    static fromObject(data?: SupernetAddResponse.AsObjectPartial): SupernetAddResponse {
        if (!data) {
            return new SupernetAddResponse();
        }
        const message = new SupernetAddResponse({});
        if (data.supernet != null) {
            message.supernet = Supernet.fromObject(data.supernet);
        }
        return message;
    }
    toObject() {
        const data: SupernetAddResponse.AsObject = {};
        if (this.supernet != null) {
            data.supernet = this.supernet.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasSupernet)
            writer.writeMessage(1, this.supernet, () => this.supernet!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SupernetAddResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SupernetAddResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.supernet, () => message.supernet = Supernet.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SupernetAddResponse {
        return SupernetAddResponse.deserialize(bytes);
    }
}
export namespace SupernetAddResponse {
    export type AsObject = {
        supernet?: Supernet.AsObject;
    };
    export type AsObjectPartial = {
        supernet?: Supernet.AsObjectPartial;
    };
}
export class SupernetUpdateRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        supernetId?: string;
        supernet?: Supernet;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("supernetId" in data && data.supernetId != undefined) {
                this.supernetId = data.supernetId;
            }
            if ("supernet" in data && data.supernet != undefined) {
                this.supernet = data.supernet;
            }
        }
    }
    get supernetId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set supernetId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get supernet() {
        return pb_1.Message.getWrapperField(this, Supernet, 2) as Supernet | undefined;
    }
    set supernet(value: Supernet | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasSupernet() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearSupernet(): void {
        this.supernet = undefined!;
    }
    static fromObject(data?: SupernetUpdateRequest.AsObjectPartial): SupernetUpdateRequest {
        if (!data) {
            return new SupernetUpdateRequest();
        }
        const message = new SupernetUpdateRequest({});
        if (data.supernetId != null) {
            message.supernetId = data.supernetId;
        }
        if (data.supernet != null) {
            message.supernet = Supernet.fromObject(data.supernet);
        }
        return message;
    }
    toObject() {
        const data: SupernetUpdateRequest.AsObject = {
            supernetId: this.supernetId
        };
        if (this.supernet != null) {
            data.supernet = this.supernet.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.supernetId.length)
            writer.writeString(1, this.supernetId);
        if (this.hasSupernet)
            writer.writeMessage(2, this.supernet, () => this.supernet!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SupernetUpdateRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SupernetUpdateRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.supernetId = reader.readString();
                    break;
                case 2:
                    reader.readMessage(message.supernet, () => message.supernet = Supernet.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SupernetUpdateRequest {
        return SupernetUpdateRequest.deserialize(bytes);
    }
}
export namespace SupernetUpdateRequest {
    export type AsObject = {
        supernetId: string;
        supernet?: Supernet.AsObject;
    };
    export type AsObjectPartial = {
        supernetId?: string;
        supernet?: Supernet.AsObjectPartial;
    };
}
export class SupernetUpdateResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        supernet?: Supernet;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("supernet" in data && data.supernet != undefined) {
                this.supernet = data.supernet;
            }
        }
    }
    get supernet() {
        return pb_1.Message.getWrapperField(this, Supernet, 1) as Supernet | undefined;
    }
    set supernet(value: Supernet | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasSupernet() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearSupernet(): void {
        this.supernet = undefined!;
    }
    static fromObject(data?: SupernetUpdateResponse.AsObjectPartial): SupernetUpdateResponse {
        if (!data) {
            return new SupernetUpdateResponse();
        }
        const message = new SupernetUpdateResponse({});
        if (data.supernet != null) {
            message.supernet = Supernet.fromObject(data.supernet);
        }
        return message;
    }
    toObject() {
        const data: SupernetUpdateResponse.AsObject = {};
        if (this.supernet != null) {
            data.supernet = this.supernet.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasSupernet)
            writer.writeMessage(1, this.supernet, () => this.supernet!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SupernetUpdateResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SupernetUpdateResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.supernet, () => message.supernet = Supernet.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SupernetUpdateResponse {
        return SupernetUpdateResponse.deserialize(bytes);
    }
}
export namespace SupernetUpdateResponse {
    export type AsObject = {
        supernet?: Supernet.AsObject;
    };
    export type AsObjectPartial = {
        supernet?: Supernet.AsObjectPartial;
    };
}
export class SupernetUpdateValidatorsRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        validators?: Map<string, number>;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("validators" in data && data.validators != undefined) {
                this.validators = data.validators;
            }
        }
        if (!this.validators)
            this.validators = new Map();
    }
    get validators() {
        return pb_1.Message.getField(this, 1) as any as Map<string, number>;
    }
    set validators(value: Map<string, number>) {
        pb_1.Message.setField(this, 1, value as any);
    }
    static fromObject(data?: SupernetUpdateValidatorsRequest.AsObjectPartial): SupernetUpdateValidatorsRequest {
        if (!data) {
            return new SupernetUpdateValidatorsRequest();
        }
        const message = new SupernetUpdateValidatorsRequest({});
        if (typeof data.validators == "object") {
            message.validators = new Map(Object.entries(data.validators));
        }
        return message;
    }
    toObject() {
        const data: SupernetUpdateValidatorsRequest.AsObject = {
            validators: (Object.fromEntries)(this.validators)
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        for (const [key, value] of this.validators) {
            writer.writeMessage(1, this.validators, () => {
                writer.writeString(1, key);
                writer.writeInt64(2, value);
            });
        }
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SupernetUpdateValidatorsRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SupernetUpdateValidatorsRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.validators as any, reader, reader.readString, reader.readInt64));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SupernetUpdateValidatorsRequest {
        return SupernetUpdateValidatorsRequest.deserialize(bytes);
    }
}
export namespace SupernetUpdateValidatorsRequest {
    export type AsObject = {
        validators: {
            [key: string]: number;
        };
    };
    export type AsObjectPartial = {
        validators?: {
            [key: string]: number;
        };
    };
}
export class SupernetUpdateValidatorsResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        supernets?: Supernet[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("supernets" in data && data.supernets != undefined) {
                this.supernets = data.supernets;
            }
        }
    }
    get supernets() {
        return pb_1.Message.getRepeatedWrapperField(this, Supernet, 1) as Supernet[];
    }
    set supernets(value: Supernet[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data?: SupernetUpdateValidatorsResponse.AsObjectPartial): SupernetUpdateValidatorsResponse {
        if (!data) {
            return new SupernetUpdateValidatorsResponse();
        }
        const message = new SupernetUpdateValidatorsResponse({});
        if (data.supernets != null) {
            message.supernets = data.supernets.map(item => Supernet.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: SupernetUpdateValidatorsResponse.AsObject = {
            supernets: this.supernets.map((item: Supernet) => item.toObject())
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.supernets.length)
            writer.writeRepeatedMessage(1, this.supernets, (item: Supernet) => item!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SupernetUpdateValidatorsResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SupernetUpdateValidatorsResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.supernets, () => pb_1.Message.addToRepeatedWrapperField(message, 1, Supernet.deserialize(reader), Supernet));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SupernetUpdateValidatorsResponse {
        return SupernetUpdateValidatorsResponse.deserialize(bytes);
    }
}
export namespace SupernetUpdateValidatorsResponse {
    export type AsObject = {
        supernets: Supernet.AsObject[];
    };
    export type AsObjectPartial = {
        supernets?: Supernet.AsObjectPartial[];
    };
}
export class BlockchainAddRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchain?: Blockchain;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchain" in data && data.blockchain != undefined) {
                this.blockchain = data.blockchain;
            }
        }
    }
    get blockchain() {
        return pb_1.Message.getWrapperField(this, Blockchain, 1) as Blockchain | undefined;
    }
    set blockchain(value: Blockchain | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasBlockchain() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearBlockchain(): void {
        this.blockchain = undefined!;
    }
    static fromObject(data?: BlockchainAddRequest.AsObjectPartial): BlockchainAddRequest {
        if (!data) {
            return new BlockchainAddRequest();
        }
        const message = new BlockchainAddRequest({});
        if (data.blockchain != null) {
            message.blockchain = Blockchain.fromObject(data.blockchain);
        }
        return message;
    }
    toObject() {
        const data: BlockchainAddRequest.AsObject = {};
        if (this.blockchain != null) {
            data.blockchain = this.blockchain.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasBlockchain)
            writer.writeMessage(1, this.blockchain, () => this.blockchain!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockchainAddRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockchainAddRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.blockchain, () => message.blockchain = Blockchain.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BlockchainAddRequest {
        return BlockchainAddRequest.deserialize(bytes);
    }
}
export namespace BlockchainAddRequest {
    export type AsObject = {
        blockchain?: Blockchain.AsObject;
    };
    export type AsObjectPartial = {
        blockchain?: Blockchain.AsObjectPartial;
    };
}
export class BlockchainAddResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchain?: Blockchain;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchain" in data && data.blockchain != undefined) {
                this.blockchain = data.blockchain;
            }
        }
    }
    get blockchain() {
        return pb_1.Message.getWrapperField(this, Blockchain, 1) as Blockchain | undefined;
    }
    set blockchain(value: Blockchain | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasBlockchain() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearBlockchain(): void {
        this.blockchain = undefined!;
    }
    static fromObject(data?: BlockchainAddResponse.AsObjectPartial): BlockchainAddResponse {
        if (!data) {
            return new BlockchainAddResponse();
        }
        const message = new BlockchainAddResponse({});
        if (data.blockchain != null) {
            message.blockchain = Blockchain.fromObject(data.blockchain);
        }
        return message;
    }
    toObject() {
        const data: BlockchainAddResponse.AsObject = {};
        if (this.blockchain != null) {
            data.blockchain = this.blockchain.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasBlockchain)
            writer.writeMessage(1, this.blockchain, () => this.blockchain!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockchainAddResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockchainAddResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.blockchain, () => message.blockchain = Blockchain.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BlockchainAddResponse {
        return BlockchainAddResponse.deserialize(bytes);
    }
}
export namespace BlockchainAddResponse {
    export type AsObject = {
        blockchain?: Blockchain.AsObject;
    };
    export type AsObjectPartial = {
        blockchain?: Blockchain.AsObjectPartial;
    };
}
export class BlockchainUpdateRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        blockchain?: Blockchain;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("blockchain" in data && data.blockchain != undefined) {
                this.blockchain = data.blockchain;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get blockchain() {
        return pb_1.Message.getWrapperField(this, Blockchain, 2) as Blockchain | undefined;
    }
    set blockchain(value: Blockchain | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasBlockchain() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearBlockchain(): void {
        this.blockchain = undefined!;
    }
    static fromObject(data?: BlockchainUpdateRequest.AsObjectPartial): BlockchainUpdateRequest {
        if (!data) {
            return new BlockchainUpdateRequest();
        }
        const message = new BlockchainUpdateRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.blockchain != null) {
            message.blockchain = Blockchain.fromObject(data.blockchain);
        }
        return message;
    }
    toObject() {
        const data: BlockchainUpdateRequest.AsObject = {
            blockchainIndex: this.blockchainIndex
        };
        if (this.blockchain != null) {
            data.blockchain = this.blockchain.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.hasBlockchain)
            writer.writeMessage(2, this.blockchain, () => this.blockchain!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockchainUpdateRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockchainUpdateRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    reader.readMessage(message.blockchain, () => message.blockchain = Blockchain.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BlockchainUpdateRequest {
        return BlockchainUpdateRequest.deserialize(bytes);
    }
}
export namespace BlockchainUpdateRequest {
    export type AsObject = {
        blockchainIndex: number;
        blockchain?: Blockchain.AsObject;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        blockchain?: Blockchain.AsObjectPartial;
    };
}
export class BlockchainUpdateResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchain?: Blockchain;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchain" in data && data.blockchain != undefined) {
                this.blockchain = data.blockchain;
            }
        }
    }
    get blockchain() {
        return pb_1.Message.getWrapperField(this, Blockchain, 1) as Blockchain | undefined;
    }
    set blockchain(value: Blockchain | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasBlockchain() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearBlockchain(): void {
        this.blockchain = undefined!;
    }
    static fromObject(data?: BlockchainUpdateResponse.AsObjectPartial): BlockchainUpdateResponse {
        if (!data) {
            return new BlockchainUpdateResponse();
        }
        const message = new BlockchainUpdateResponse({});
        if (data.blockchain != null) {
            message.blockchain = Blockchain.fromObject(data.blockchain);
        }
        return message;
    }
    toObject() {
        const data: BlockchainUpdateResponse.AsObject = {};
        if (this.blockchain != null) {
            data.blockchain = this.blockchain.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasBlockchain)
            writer.writeMessage(1, this.blockchain, () => this.blockchain!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockchainUpdateResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockchainUpdateResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.blockchain, () => message.blockchain = Blockchain.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BlockchainUpdateResponse {
        return BlockchainUpdateResponse.deserialize(bytes);
    }
}
export namespace BlockchainUpdateResponse {
    export type AsObject = {
        blockchain?: Blockchain.AsObject;
    };
    export type AsObjectPartial = {
        blockchain?: Blockchain.AsObjectPartial;
    };
}
export class BlockchainUpdateIconRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        icon?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("icon" in data && data.icon != undefined) {
                this.icon = data.icon;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get icon() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array()) as Uint8Array;
    }
    set icon(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: BlockchainUpdateIconRequest.AsObjectPartial): BlockchainUpdateIconRequest {
        if (!data) {
            return new BlockchainUpdateIconRequest();
        }
        const message = new BlockchainUpdateIconRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.icon != null) {
            message.icon = data.icon;
        }
        return message;
    }
    toObject() {
        const data: BlockchainUpdateIconRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            icon: this.icon
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.icon.length)
            writer.writeBytes(2, this.icon);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockchainUpdateIconRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockchainUpdateIconRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.icon = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BlockchainUpdateIconRequest {
        return BlockchainUpdateIconRequest.deserialize(bytes);
    }
}
export namespace BlockchainUpdateIconRequest {
    export type AsObject = {
        blockchainIndex: number;
        icon: Uint8Array;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        icon?: Uint8Array;
    };
}
export class BlockchainUpdateIconResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchain?: Blockchain;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchain" in data && data.blockchain != undefined) {
                this.blockchain = data.blockchain;
            }
        }
    }
    get blockchain() {
        return pb_1.Message.getWrapperField(this, Blockchain, 1) as Blockchain | undefined;
    }
    set blockchain(value: Blockchain | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasBlockchain() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearBlockchain(): void {
        this.blockchain = undefined!;
    }
    static fromObject(data?: BlockchainUpdateIconResponse.AsObjectPartial): BlockchainUpdateIconResponse {
        if (!data) {
            return new BlockchainUpdateIconResponse();
        }
        const message = new BlockchainUpdateIconResponse({});
        if (data.blockchain != null) {
            message.blockchain = Blockchain.fromObject(data.blockchain);
        }
        return message;
    }
    toObject() {
        const data: BlockchainUpdateIconResponse.AsObject = {};
        if (this.blockchain != null) {
            data.blockchain = this.blockchain.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasBlockchain)
            writer.writeMessage(1, this.blockchain, () => this.blockchain!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockchainUpdateIconResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockchainUpdateIconResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.blockchain, () => message.blockchain = Blockchain.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BlockchainUpdateIconResponse {
        return BlockchainUpdateIconResponse.deserialize(bytes);
    }
}
export namespace BlockchainUpdateIconResponse {
    export type AsObject = {
        blockchain?: Blockchain.AsObject;
    };
    export type AsObjectPartial = {
        blockchain?: Blockchain.AsObjectPartial;
    };
}
export class BlockchainDisableRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        disabled?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("disabled" in data && data.disabled != undefined) {
                this.disabled = data.disabled;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get disabled() {
        return pb_1.Message.getFieldWithDefault(this, 2, false) as boolean;
    }
    set disabled(value: boolean) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: BlockchainDisableRequest.AsObjectPartial): BlockchainDisableRequest {
        if (!data) {
            return new BlockchainDisableRequest();
        }
        const message = new BlockchainDisableRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.disabled != null) {
            message.disabled = data.disabled;
        }
        return message;
    }
    toObject() {
        const data: BlockchainDisableRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            disabled: this.disabled
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.disabled != false)
            writer.writeBool(2, this.disabled);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockchainDisableRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockchainDisableRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.disabled = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BlockchainDisableRequest {
        return BlockchainDisableRequest.deserialize(bytes);
    }
}
export namespace BlockchainDisableRequest {
    export type AsObject = {
        blockchainIndex: number;
        disabled: boolean;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        disabled?: boolean;
    };
}
export class BlockchainDisableResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchain?: Blockchain;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchain" in data && data.blockchain != undefined) {
                this.blockchain = data.blockchain;
            }
        }
    }
    get blockchain() {
        return pb_1.Message.getWrapperField(this, Blockchain, 1) as Blockchain | undefined;
    }
    set blockchain(value: Blockchain | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasBlockchain() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearBlockchain(): void {
        this.blockchain = undefined!;
    }
    static fromObject(data?: BlockchainDisableResponse.AsObjectPartial): BlockchainDisableResponse {
        if (!data) {
            return new BlockchainDisableResponse();
        }
        const message = new BlockchainDisableResponse({});
        if (data.blockchain != null) {
            message.blockchain = Blockchain.fromObject(data.blockchain);
        }
        return message;
    }
    toObject() {
        const data: BlockchainDisableResponse.AsObject = {};
        if (this.blockchain != null) {
            data.blockchain = this.blockchain.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasBlockchain)
            writer.writeMessage(1, this.blockchain, () => this.blockchain!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockchainDisableResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockchainDisableResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.blockchain, () => message.blockchain = Blockchain.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BlockchainDisableResponse {
        return BlockchainDisableResponse.deserialize(bytes);
    }
}
export namespace BlockchainDisableResponse {
    export type AsObject = {
        blockchain?: Blockchain.AsObject;
    };
    export type AsObjectPartial = {
        blockchain?: Blockchain.AsObjectPartial;
    };
}
export class BlockchainListUnfilteredRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3]];
    constructor(data?: any[] | ({} & (({
        index?: BlockchainListUnfilteredRequest.IndexSearch;
        id?: never;
        all?: never;
    } | {
        index?: never;
        id?: string;
        all?: never;
    } | {
        index?: never;
        id?: never;
        all?: boolean;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("index" in data && data.index != undefined) {
                this.index = data.index;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("all" in data && data.all != undefined) {
                this.all = data.all;
            }
        }
    }
    get index() {
        return pb_1.Message.getWrapperField(this, BlockchainListUnfilteredRequest.IndexSearch, 1) as BlockchainListUnfilteredRequest.IndexSearch | undefined;
    }
    set index(value: BlockchainListUnfilteredRequest.IndexSearch | undefined) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0]!, value);
    }
    get hasIndex() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearIndex(): void {
        this.index = undefined!;
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasId() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearId(): void {
        this.id = undefined!;
    }
    get all() {
        return pb_1.Message.getFieldWithDefault(this, 3, false) as boolean;
    }
    set all(value: boolean) {
        pb_1.Message.setOneofField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasAll() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearAll(): void {
        this.all = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "index" | "id" | "all";
        } = {
            0: "none",
            1: "index",
            2: "id",
            3: "all"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3])]!;
    }
    static fromObject(data?: BlockchainListUnfilteredRequest.AsObjectPartial): BlockchainListUnfilteredRequest {
        if (!data) {
            return new BlockchainListUnfilteredRequest();
        }
        const message = new BlockchainListUnfilteredRequest({});
        if (data.index != null) {
            message.index = BlockchainListUnfilteredRequest.IndexSearch.fromObject(data.index);
        }
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.all != null) {
            message.all = data.all;
        }
        return message;
    }
    toObject() {
        const data: BlockchainListUnfilteredRequest.AsObject = {
            id: this.id,
            all: this.all
        };
        if (this.index != null) {
            data.index = this.index.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasIndex)
            writer.writeMessage(1, this.index, () => this.index!.serialize(writer));
        if (this.hasId)
            writer.writeString(2, this.id);
        if (this.hasAll)
            writer.writeBool(3, this.all);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockchainListUnfilteredRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockchainListUnfilteredRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.index, () => message.index = BlockchainListUnfilteredRequest.IndexSearch.deserialize(reader));
                    break;
                case 2:
                    message.id = reader.readString();
                    break;
                case 3:
                    message.all = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BlockchainListUnfilteredRequest {
        return BlockchainListUnfilteredRequest.deserialize(bytes);
    }
}
export namespace BlockchainListUnfilteredRequest {
    export type AsObject = {
        index?: BlockchainListUnfilteredRequest.IndexSearch.AsObject;
        id: string;
        all: boolean;
    };
    export type AsObjectPartial = {
        index?: BlockchainListUnfilteredRequest.IndexSearch.AsObjectPartial;
        id?: string;
        all?: boolean;
    };
    export class IndexSearch extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            index?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("index" in data && data.index != undefined) {
                    this.index = data.index;
                }
            }
        }
        get index() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set index(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data?: IndexSearch.AsObjectPartial): IndexSearch {
            if (!data) {
                return new IndexSearch();
            }
            const message = new IndexSearch({});
            if (data.index != null) {
                message.index = data.index;
            }
            return message;
        }
        toObject() {
            const data: IndexSearch.AsObject = {
                index: this.index
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.index != 0)
                writer.writeInt64(1, this.index);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): IndexSearch {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new IndexSearch();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.index = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): IndexSearch {
            return IndexSearch.deserialize(bytes);
        }
    }
    export namespace IndexSearch {
        export type AsObject = {
            index: number;
        };
        export type AsObjectPartial = {
            index?: number;
        };
    }
}
