/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.13.0
 * source: search.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./address";
import * as dependency_2 from "./block";
import * as dependency_3 from "./blockchain";
import * as dependency_4 from "./transaction";
import * as dependency_5 from "./validator";
import * as pb_1 from "google-protobuf";
export class SearchRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        text?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("text" in data && data.text != undefined) {
                this.text = data.text;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get text() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set text(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: SearchRequest.AsObjectPartial): SearchRequest {
        if (!data) {
            return new SearchRequest();
        }
        const message = new SearchRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.text != null) {
            message.text = data.text;
        }
        return message;
    }
    toObject() {
        const data: SearchRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            text: this.text
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.text.length)
            writer.writeString(2, this.text);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SearchRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SearchRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.text = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SearchRequest {
        return SearchRequest.deserialize(bytes);
    }
}
export namespace SearchRequest {
    export type AsObject = {
        blockchainIndex: number;
        text: string;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        text?: string;
    };
}
export class SearchResponse extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3, 4, 5, 6]];
    constructor(data?: any[] | ({} & (({
        block?: dependency_2.Block;
        blockchain?: never;
        supernet?: never;
        transaction?: never;
        validator?: never;
        address?: never;
    } | {
        block?: never;
        blockchain?: dependency_3.Blockchain;
        supernet?: never;
        transaction?: never;
        validator?: never;
        address?: never;
    } | {
        block?: never;
        blockchain?: never;
        supernet?: dependency_3.Supernet;
        transaction?: never;
        validator?: never;
        address?: never;
    } | {
        block?: never;
        blockchain?: never;
        supernet?: never;
        transaction?: dependency_4.Transaction;
        validator?: never;
        address?: never;
    } | {
        block?: never;
        blockchain?: never;
        supernet?: never;
        transaction?: never;
        validator?: dependency_5.Validator;
        address?: never;
    } | {
        block?: never;
        blockchain?: never;
        supernet?: never;
        transaction?: never;
        validator?: never;
        address?: dependency_1.AddressData;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("block" in data && data.block != undefined) {
                this.block = data.block;
            }
            if ("blockchain" in data && data.blockchain != undefined) {
                this.blockchain = data.blockchain;
            }
            if ("supernet" in data && data.supernet != undefined) {
                this.supernet = data.supernet;
            }
            if ("transaction" in data && data.transaction != undefined) {
                this.transaction = data.transaction;
            }
            if ("validator" in data && data.validator != undefined) {
                this.validator = data.validator;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
        }
    }
    get block() {
        return pb_1.Message.getWrapperField(this, dependency_2.Block, 1) as dependency_2.Block | undefined;
    }
    set block(value: dependency_2.Block | undefined) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0]!, value);
    }
    get hasBlock() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearBlock(): void {
        this.block = undefined!;
    }
    get blockchain() {
        return pb_1.Message.getWrapperField(this, dependency_3.Blockchain, 2) as dependency_3.Blockchain | undefined;
    }
    set blockchain(value: dependency_3.Blockchain | undefined) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasBlockchain() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearBlockchain(): void {
        this.blockchain = undefined!;
    }
    get supernet() {
        return pb_1.Message.getWrapperField(this, dependency_3.Supernet, 3) as dependency_3.Supernet | undefined;
    }
    set supernet(value: dependency_3.Supernet | undefined) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasSupernet() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearSupernet(): void {
        this.supernet = undefined!;
    }
    get transaction() {
        return pb_1.Message.getWrapperField(this, dependency_4.Transaction, 4) as dependency_4.Transaction | undefined;
    }
    set transaction(value: dependency_4.Transaction | undefined) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0]!, value);
    }
    get hasTransaction() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearTransaction(): void {
        this.transaction = undefined!;
    }
    get validator() {
        return pb_1.Message.getWrapperField(this, dependency_5.Validator, 5) as dependency_5.Validator | undefined;
    }
    set validator(value: dependency_5.Validator | undefined) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0]!, value);
    }
    get hasValidator() {
        return pb_1.Message.getField(this, 5) != null;
    }
    clearValidator(): void {
        this.validator = undefined!;
    }
    get address() {
        return pb_1.Message.getWrapperField(this, dependency_1.AddressData, 6) as dependency_1.AddressData | undefined;
    }
    set address(value: dependency_1.AddressData | undefined) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0]!, value);
    }
    get hasAddress() {
        return pb_1.Message.getField(this, 6) != null;
    }
    clearAddress(): void {
        this.address = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "block" | "blockchain" | "supernet" | "transaction" | "validator" | "address";
        } = {
            0: "none",
            1: "block",
            2: "blockchain",
            3: "supernet",
            4: "transaction",
            5: "validator",
            6: "address"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4, 5, 6])]!;
    }
    static fromObject(data?: SearchResponse.AsObjectPartial): SearchResponse {
        if (!data) {
            return new SearchResponse();
        }
        const message = new SearchResponse({});
        if (data.block != null) {
            message.block = dependency_2.Block.fromObject(data.block);
        }
        if (data.blockchain != null) {
            message.blockchain = dependency_3.Blockchain.fromObject(data.blockchain);
        }
        if (data.supernet != null) {
            message.supernet = dependency_3.Supernet.fromObject(data.supernet);
        }
        if (data.transaction != null) {
            message.transaction = dependency_4.Transaction.fromObject(data.transaction);
        }
        if (data.validator != null) {
            message.validator = dependency_5.Validator.fromObject(data.validator);
        }
        if (data.address != null) {
            message.address = dependency_1.AddressData.fromObject(data.address);
        }
        return message;
    }
    toObject() {
        const data: SearchResponse.AsObject = {};
        if (this.block != null) {
            data.block = this.block.toObject();
        }
        if (this.blockchain != null) {
            data.blockchain = this.blockchain.toObject();
        }
        if (this.supernet != null) {
            data.supernet = this.supernet.toObject();
        }
        if (this.transaction != null) {
            data.transaction = this.transaction.toObject();
        }
        if (this.validator != null) {
            data.validator = this.validator.toObject();
        }
        if (this.address != null) {
            data.address = this.address.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasBlock)
            writer.writeMessage(1, this.block, () => this.block!.serialize(writer));
        if (this.hasBlockchain)
            writer.writeMessage(2, this.blockchain, () => this.blockchain!.serialize(writer));
        if (this.hasSupernet)
            writer.writeMessage(3, this.supernet, () => this.supernet!.serialize(writer));
        if (this.hasTransaction)
            writer.writeMessage(4, this.transaction, () => this.transaction!.serialize(writer));
        if (this.hasValidator)
            writer.writeMessage(5, this.validator, () => this.validator!.serialize(writer));
        if (this.hasAddress)
            writer.writeMessage(6, this.address, () => this.address!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SearchResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SearchResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.block, () => message.block = dependency_2.Block.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.blockchain, () => message.blockchain = dependency_3.Blockchain.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.supernet, () => message.supernet = dependency_3.Supernet.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.transaction, () => message.transaction = dependency_4.Transaction.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.validator, () => message.validator = dependency_5.Validator.deserialize(reader));
                    break;
                case 6:
                    reader.readMessage(message.address, () => message.address = dependency_1.AddressData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SearchResponse {
        return SearchResponse.deserialize(bytes);
    }
}
export namespace SearchResponse {
    export type AsObject = {
        block?: dependency_2.Block.AsObject;
        blockchain?: dependency_3.Blockchain.AsObject;
        supernet?: dependency_3.Supernet.AsObject;
        transaction?: dependency_4.Transaction.AsObject;
        validator?: dependency_5.Validator.AsObject;
        address?: dependency_1.AddressData.AsObject;
    };
    export type AsObjectPartial = {
        block?: dependency_2.Block.AsObjectPartial;
        blockchain?: dependency_3.Blockchain.AsObjectPartial;
        supernet?: dependency_3.Supernet.AsObjectPartial;
        transaction?: dependency_4.Transaction.AsObjectPartial;
        validator?: dependency_5.Validator.AsObjectPartial;
        address?: dependency_1.AddressData.AsObjectPartial;
    };
}
