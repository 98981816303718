/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.13.0
 * source: auth.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
export class CheckLogPassRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        login?: string;
        password?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("login" in data && data.login != undefined) {
                this.login = data.login;
            }
            if ("password" in data && data.password != undefined) {
                this.password = data.password;
            }
        }
    }
    get login() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set login(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get password() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set password(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: CheckLogPassRequest.AsObjectPartial): CheckLogPassRequest {
        if (!data) {
            return new CheckLogPassRequest();
        }
        const message = new CheckLogPassRequest({});
        if (data.login != null) {
            message.login = data.login;
        }
        if (data.password != null) {
            message.password = data.password;
        }
        return message;
    }
    toObject() {
        const data: CheckLogPassRequest.AsObject = {
            login: this.login,
            password: this.password
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.login.length)
            writer.writeString(1, this.login);
        if (this.password.length)
            writer.writeString(2, this.password);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CheckLogPassRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CheckLogPassRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.login = reader.readString();
                    break;
                case 2:
                    message.password = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): CheckLogPassRequest {
        return CheckLogPassRequest.deserialize(bytes);
    }
}
export namespace CheckLogPassRequest {
    export type AsObject = {
        login: string;
        password: string;
    };
    export type AsObjectPartial = {
        login?: string;
        password?: string;
    };
}
export class CheckLogPassResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        valid?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("valid" in data && data.valid != undefined) {
                this.valid = data.valid;
            }
        }
    }
    get valid() {
        return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
    }
    set valid(value: boolean) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data?: CheckLogPassResponse.AsObjectPartial): CheckLogPassResponse {
        if (!data) {
            return new CheckLogPassResponse();
        }
        const message = new CheckLogPassResponse({});
        if (data.valid != null) {
            message.valid = data.valid;
        }
        return message;
    }
    toObject() {
        const data: CheckLogPassResponse.AsObject = {
            valid: this.valid
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.valid != false)
            writer.writeBool(1, this.valid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CheckLogPassResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CheckLogPassResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.valid = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): CheckLogPassResponse {
        return CheckLogPassResponse.deserialize(bytes);
    }
}
export namespace CheckLogPassResponse {
    export type AsObject = {
        valid: boolean;
    };
    export type AsObjectPartial = {
        valid?: boolean;
    };
}
