/// <reference types="vite-plugin-svgr/client" />

import "./App.scss";

import { lazy, Suspense, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { Spinner } from "./mcn-react-ui";
import AccessWallet from "./pages/Onboarding/AccessWallet/AccessWallet";
import CreateWallet from "./pages/Onboarding/CreateWallet/CreateWallet";
import WalletSetup from "./pages/Onboarding/WalletSetup/WalletSetup";
import useAccountStore from "./stores/AccountStore";
import useIconStore from "./stores/IconStore";
import useNetworkStore from "./stores/NetworkStore";
import useTokensStore from "./stores/TokensStore";

const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy/PrivacyPolicy"));
const TermsOfUses = lazy(() => import("./pages/TermsOfUses/TermsOfUses"));
const App = lazy(() => import("./App"));

function Account() {
  const {
    masterWallet,
    mcnAccount,
    isConnected,

    initializeAccount,
    initializeNetworkInformations,
  } = useAccountStore();

  const {
    provider,
    network,
    initializeNetwork,
    setBlockchainSelect,
    blockchainSelect,
  } = useNetworkStore();

  const { initializeTokens } = useTokensStore();
  const { initializeIcons } = useIconStore();

  // used to load the app after 0.1s
  // we load app when the user is on the onboarding page
  useEffect(() => {
    setTimeout(() => {
      import("./App");
    }, 100);
  }, []);

  useEffect(() => {
    if (masterWallet) {
      // boucle on the masterwallet to set the hrp
      masterWallet.forEach((wallet) => {
        wallet.setHrp(provider.mcn.hrp);
      });
    }
    if (!provider.mcn.primary.chains.includes(blockchainSelect)) {
      setBlockchainSelect(provider.mcn.primary.june);
    }
    initializeIcons(network, provider);

    // initalise all the stores
    initializeTokens(provider.mcn.primary.chains, provider);
    initializeNetwork(provider);
    initializeAccount(provider);
    initializeNetworkInformations();
  }, [provider]);

  // re-initialize network informations when the mnemonic or private key changes
  // ( for multi sig change )
  useEffect(() => {
    initializeNetworkInformations();
  }, [mcnAccount.wallet.mnemonic, mcnAccount.wallet.privateKey]);

  return (
    <div className="App">
      <BrowserRouter>
        <Suspense fallback={<Spinner />}>
          {isConnected && masterWallet ? (
            <App />
          ) : (
            <Routes>
              <Route path="/" element={<WalletSetup />} />
              <Route path="/accessWallet" element={<AccessWallet />} />
              <Route path="/createWallet" element={<CreateWallet />} />
              <Route path="/termsOfUses" element={<TermsOfUses />} />
              <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          )}
        </Suspense>
      </BrowserRouter>
      <Toaster position="bottom-right" reverseOrder={false} />
    </div>
  );
}

export default Account;
