/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.13.0
 * source: erc20.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./validate/validate";
import * as dependency_2 from "./models";
import * as pb_1 from "google-protobuf";
export class ERC20Token extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        address?: string;
        name?: string;
        symbol?: string;
        decimals?: number;
        totalSupply?: string;
        holders?: number;
        transfersCount?: number;
        site?: string;
        email?: string;
        twitter?: string;
        whitepaper?: string;
        coinmarketcap?: string;
        customName?: string;
        tags?: string[];
        price?: number;
        priceChange?: number;
        volume?: string;
        marketCap?: string;
        onChainMarketCap?: string;
        verified?: boolean;
        transfersCount24h?: number;
        transfersCount2d?: number;
        iconPath?: string;
        disabled?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [14], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("symbol" in data && data.symbol != undefined) {
                this.symbol = data.symbol;
            }
            if ("decimals" in data && data.decimals != undefined) {
                this.decimals = data.decimals;
            }
            if ("totalSupply" in data && data.totalSupply != undefined) {
                this.totalSupply = data.totalSupply;
            }
            if ("holders" in data && data.holders != undefined) {
                this.holders = data.holders;
            }
            if ("transfersCount" in data && data.transfersCount != undefined) {
                this.transfersCount = data.transfersCount;
            }
            if ("site" in data && data.site != undefined) {
                this.site = data.site;
            }
            if ("email" in data && data.email != undefined) {
                this.email = data.email;
            }
            if ("twitter" in data && data.twitter != undefined) {
                this.twitter = data.twitter;
            }
            if ("whitepaper" in data && data.whitepaper != undefined) {
                this.whitepaper = data.whitepaper;
            }
            if ("coinmarketcap" in data && data.coinmarketcap != undefined) {
                this.coinmarketcap = data.coinmarketcap;
            }
            if ("customName" in data && data.customName != undefined) {
                this.customName = data.customName;
            }
            if ("tags" in data && data.tags != undefined) {
                this.tags = data.tags;
            }
            if ("price" in data && data.price != undefined) {
                this.price = data.price;
            }
            if ("priceChange" in data && data.priceChange != undefined) {
                this.priceChange = data.priceChange;
            }
            if ("volume" in data && data.volume != undefined) {
                this.volume = data.volume;
            }
            if ("marketCap" in data && data.marketCap != undefined) {
                this.marketCap = data.marketCap;
            }
            if ("onChainMarketCap" in data && data.onChainMarketCap != undefined) {
                this.onChainMarketCap = data.onChainMarketCap;
            }
            if ("verified" in data && data.verified != undefined) {
                this.verified = data.verified;
            }
            if ("transfersCount24h" in data && data.transfersCount24h != undefined) {
                this.transfersCount24h = data.transfersCount24h;
            }
            if ("transfersCount2d" in data && data.transfersCount2d != undefined) {
                this.transfersCount2d = data.transfersCount2d;
            }
            if ("iconPath" in data && data.iconPath != undefined) {
                this.iconPath = data.iconPath;
            }
            if ("disabled" in data && data.disabled != undefined) {
                this.disabled = data.disabled;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 15, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 15, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get symbol() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set symbol(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get decimals() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set decimals(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get totalSupply() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set totalSupply(value: string) {
        pb_1.Message.setField(this, 5, value);
    }
    get holders() {
        return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
    }
    set holders(value: number) {
        pb_1.Message.setField(this, 6, value);
    }
    get transfersCount() {
        return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
    }
    set transfersCount(value: number) {
        pb_1.Message.setField(this, 7, value);
    }
    get site() {
        return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
    }
    set site(value: string) {
        pb_1.Message.setField(this, 8, value);
    }
    get email() {
        return pb_1.Message.getFieldWithDefault(this, 9, "") as string;
    }
    set email(value: string) {
        pb_1.Message.setField(this, 9, value);
    }
    get twitter() {
        return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
    }
    set twitter(value: string) {
        pb_1.Message.setField(this, 10, value);
    }
    get whitepaper() {
        return pb_1.Message.getFieldWithDefault(this, 11, "") as string;
    }
    set whitepaper(value: string) {
        pb_1.Message.setField(this, 11, value);
    }
    get coinmarketcap() {
        return pb_1.Message.getFieldWithDefault(this, 12, "") as string;
    }
    set coinmarketcap(value: string) {
        pb_1.Message.setField(this, 12, value);
    }
    get customName() {
        return pb_1.Message.getFieldWithDefault(this, 13, "") as string;
    }
    set customName(value: string) {
        pb_1.Message.setField(this, 13, value);
    }
    get tags() {
        return pb_1.Message.getFieldWithDefault(this, 14, []) as string[];
    }
    set tags(value: string[]) {
        pb_1.Message.setField(this, 14, value);
    }
    get price() {
        return pb_1.Message.getFieldWithDefault(this, 16, 0) as number;
    }
    set price(value: number) {
        pb_1.Message.setField(this, 16, value);
    }
    get priceChange() {
        return pb_1.Message.getFieldWithDefault(this, 17, 0) as number;
    }
    set priceChange(value: number) {
        pb_1.Message.setField(this, 17, value);
    }
    get volume() {
        return pb_1.Message.getFieldWithDefault(this, 18, "") as string;
    }
    set volume(value: string) {
        pb_1.Message.setField(this, 18, value);
    }
    get marketCap() {
        return pb_1.Message.getFieldWithDefault(this, 19, "") as string;
    }
    set marketCap(value: string) {
        pb_1.Message.setField(this, 19, value);
    }
    get onChainMarketCap() {
        return pb_1.Message.getFieldWithDefault(this, 20, "") as string;
    }
    set onChainMarketCap(value: string) {
        pb_1.Message.setField(this, 20, value);
    }
    get verified() {
        return pb_1.Message.getFieldWithDefault(this, 21, false) as boolean;
    }
    set verified(value: boolean) {
        pb_1.Message.setField(this, 21, value);
    }
    get transfersCount24h() {
        return pb_1.Message.getFieldWithDefault(this, 22, 0) as number;
    }
    set transfersCount24h(value: number) {
        pb_1.Message.setField(this, 22, value);
    }
    get transfersCount2d() {
        return pb_1.Message.getFieldWithDefault(this, 23, 0) as number;
    }
    set transfersCount2d(value: number) {
        pb_1.Message.setField(this, 23, value);
    }
    get iconPath() {
        return pb_1.Message.getFieldWithDefault(this, 24, "") as string;
    }
    set iconPath(value: string) {
        pb_1.Message.setField(this, 24, value);
    }
    get disabled() {
        return pb_1.Message.getFieldWithDefault(this, 25, false) as boolean;
    }
    set disabled(value: boolean) {
        pb_1.Message.setField(this, 25, value);
    }
    static fromObject(data?: ERC20Token.AsObjectPartial): ERC20Token {
        if (!data) {
            return new ERC20Token();
        }
        const message = new ERC20Token({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.symbol != null) {
            message.symbol = data.symbol;
        }
        if (data.decimals != null) {
            message.decimals = data.decimals;
        }
        if (data.totalSupply != null) {
            message.totalSupply = data.totalSupply;
        }
        if (data.holders != null) {
            message.holders = data.holders;
        }
        if (data.transfersCount != null) {
            message.transfersCount = data.transfersCount;
        }
        if (data.site != null) {
            message.site = data.site;
        }
        if (data.email != null) {
            message.email = data.email;
        }
        if (data.twitter != null) {
            message.twitter = data.twitter;
        }
        if (data.whitepaper != null) {
            message.whitepaper = data.whitepaper;
        }
        if (data.coinmarketcap != null) {
            message.coinmarketcap = data.coinmarketcap;
        }
        if (data.customName != null) {
            message.customName = data.customName;
        }
        if (data.tags != null) {
            message.tags = data.tags;
        }
        if (data.price != null) {
            message.price = data.price;
        }
        if (data.priceChange != null) {
            message.priceChange = data.priceChange;
        }
        if (data.volume != null) {
            message.volume = data.volume;
        }
        if (data.marketCap != null) {
            message.marketCap = data.marketCap;
        }
        if (data.onChainMarketCap != null) {
            message.onChainMarketCap = data.onChainMarketCap;
        }
        if (data.verified != null) {
            message.verified = data.verified;
        }
        if (data.transfersCount24h != null) {
            message.transfersCount24h = data.transfersCount24h;
        }
        if (data.transfersCount2d != null) {
            message.transfersCount2d = data.transfersCount2d;
        }
        if (data.iconPath != null) {
            message.iconPath = data.iconPath;
        }
        if (data.disabled != null) {
            message.disabled = data.disabled;
        }
        return message;
    }
    toObject() {
        const data: ERC20Token.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address,
            name: this.name,
            symbol: this.symbol,
            decimals: this.decimals,
            totalSupply: this.totalSupply,
            holders: this.holders,
            transfersCount: this.transfersCount,
            site: this.site,
            email: this.email,
            twitter: this.twitter,
            whitepaper: this.whitepaper,
            coinmarketcap: this.coinmarketcap,
            customName: this.customName,
            tags: this.tags,
            price: this.price,
            priceChange: this.priceChange,
            volume: this.volume,
            marketCap: this.marketCap,
            onChainMarketCap: this.onChainMarketCap,
            verified: this.verified,
            transfersCount24h: this.transfersCount24h,
            transfersCount2d: this.transfersCount2d,
            iconPath: this.iconPath,
            disabled: this.disabled
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(15, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(4, this.address);
        if (this.name.length)
            writer.writeString(1, this.name);
        if (this.symbol.length)
            writer.writeString(2, this.symbol);
        if (this.decimals != 0)
            writer.writeUint32(3, this.decimals);
        if (this.totalSupply.length)
            writer.writeString(5, this.totalSupply);
        if (this.holders != 0)
            writer.writeInt64(6, this.holders);
        if (this.transfersCount != 0)
            writer.writeInt64(7, this.transfersCount);
        if (this.site.length)
            writer.writeString(8, this.site);
        if (this.email.length)
            writer.writeString(9, this.email);
        if (this.twitter.length)
            writer.writeString(10, this.twitter);
        if (this.whitepaper.length)
            writer.writeString(11, this.whitepaper);
        if (this.coinmarketcap.length)
            writer.writeString(12, this.coinmarketcap);
        if (this.customName.length)
            writer.writeString(13, this.customName);
        if (this.tags.length)
            writer.writeRepeatedString(14, this.tags);
        if (this.price != 0)
            writer.writeInt64(16, this.price);
        if (this.priceChange != 0)
            writer.writeInt64(17, this.priceChange);
        if (this.volume.length)
            writer.writeString(18, this.volume);
        if (this.marketCap.length)
            writer.writeString(19, this.marketCap);
        if (this.onChainMarketCap.length)
            writer.writeString(20, this.onChainMarketCap);
        if (this.verified != false)
            writer.writeBool(21, this.verified);
        if (this.transfersCount24h != 0)
            writer.writeInt64(22, this.transfersCount24h);
        if (this.transfersCount2d != 0)
            writer.writeInt64(23, this.transfersCount2d);
        if (this.iconPath.length)
            writer.writeString(24, this.iconPath);
        if (this.disabled != false)
            writer.writeBool(25, this.disabled);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20Token {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20Token();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 15:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 4:
                    message.address = reader.readString();
                    break;
                case 1:
                    message.name = reader.readString();
                    break;
                case 2:
                    message.symbol = reader.readString();
                    break;
                case 3:
                    message.decimals = reader.readUint32();
                    break;
                case 5:
                    message.totalSupply = reader.readString();
                    break;
                case 6:
                    message.holders = reader.readInt64();
                    break;
                case 7:
                    message.transfersCount = reader.readInt64();
                    break;
                case 8:
                    message.site = reader.readString();
                    break;
                case 9:
                    message.email = reader.readString();
                    break;
                case 10:
                    message.twitter = reader.readString();
                    break;
                case 11:
                    message.whitepaper = reader.readString();
                    break;
                case 12:
                    message.coinmarketcap = reader.readString();
                    break;
                case 13:
                    message.customName = reader.readString();
                    break;
                case 14:
                    pb_1.Message.addToRepeatedField(message, 14, reader.readString());
                    break;
                case 16:
                    message.price = reader.readInt64();
                    break;
                case 17:
                    message.priceChange = reader.readInt64();
                    break;
                case 18:
                    message.volume = reader.readString();
                    break;
                case 19:
                    message.marketCap = reader.readString();
                    break;
                case 20:
                    message.onChainMarketCap = reader.readString();
                    break;
                case 21:
                    message.verified = reader.readBool();
                    break;
                case 22:
                    message.transfersCount24h = reader.readInt64();
                    break;
                case 23:
                    message.transfersCount2d = reader.readInt64();
                    break;
                case 24:
                    message.iconPath = reader.readString();
                    break;
                case 25:
                    message.disabled = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20Token {
        return ERC20Token.deserialize(bytes);
    }
}
export namespace ERC20Token {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
        name: string;
        symbol: string;
        decimals: number;
        totalSupply: string;
        holders: number;
        transfersCount: number;
        site: string;
        email: string;
        twitter: string;
        whitepaper: string;
        coinmarketcap: string;
        customName: string;
        tags: string[];
        price: number;
        priceChange: number;
        volume: string;
        marketCap: string;
        onChainMarketCap: string;
        verified: boolean;
        transfersCount24h: number;
        transfersCount2d: number;
        iconPath: string;
        disabled: boolean;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
        name?: string;
        symbol?: string;
        decimals?: number;
        totalSupply?: string;
        holders?: number;
        transfersCount?: number;
        site?: string;
        email?: string;
        twitter?: string;
        whitepaper?: string;
        coinmarketcap?: string;
        customName?: string;
        tags?: string[];
        price?: number;
        priceChange?: number;
        volume?: string;
        marketCap?: string;
        onChainMarketCap?: string;
        verified?: boolean;
        transfersCount24h?: number;
        transfersCount2d?: number;
        iconPath?: string;
        disabled?: boolean;
    };
}
export class ERC20Transfer extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        txId?: string;
        txIndex?: number;
        txIndexInBc?: number;
        methodName?: string;
        timestamp?: number;
        from?: string;
        to?: string;
        value?: string;
        meta?: ERC20Metadata;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("txId" in data && data.txId != undefined) {
                this.txId = data.txId;
            }
            if ("txIndex" in data && data.txIndex != undefined) {
                this.txIndex = data.txIndex;
            }
            if ("txIndexInBc" in data && data.txIndexInBc != undefined) {
                this.txIndexInBc = data.txIndexInBc;
            }
            if ("methodName" in data && data.methodName != undefined) {
                this.methodName = data.methodName;
            }
            if ("timestamp" in data && data.timestamp != undefined) {
                this.timestamp = data.timestamp;
            }
            if ("from" in data && data.from != undefined) {
                this.from = data.from;
            }
            if ("to" in data && data.to != undefined) {
                this.to = data.to;
            }
            if ("value" in data && data.value != undefined) {
                this.value = data.value;
            }
            if ("meta" in data && data.meta != undefined) {
                this.meta = data.meta;
            }
        }
    }
    get txId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set txId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get txIndex() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set txIndex(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get txIndexInBc() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set txIndexInBc(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get methodName() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set methodName(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get timestamp() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set timestamp(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get from() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set from(value: string) {
        pb_1.Message.setField(this, 6, value);
    }
    get to() {
        return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
    }
    set to(value: string) {
        pb_1.Message.setField(this, 7, value);
    }
    get value() {
        return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
    }
    set value(value: string) {
        pb_1.Message.setField(this, 8, value);
    }
    get meta() {
        return pb_1.Message.getWrapperField(this, ERC20Metadata, 9) as ERC20Metadata | undefined;
    }
    set meta(value: ERC20Metadata | undefined) {
        pb_1.Message.setWrapperField(this, 9, value);
    }
    get hasMeta() {
        return pb_1.Message.getField(this, 9) != null;
    }
    clearMeta(): void {
        this.meta = undefined!;
    }
    static fromObject(data?: ERC20Transfer.AsObjectPartial): ERC20Transfer {
        if (!data) {
            return new ERC20Transfer();
        }
        const message = new ERC20Transfer({});
        if (data.txId != null) {
            message.txId = data.txId;
        }
        if (data.txIndex != null) {
            message.txIndex = data.txIndex;
        }
        if (data.txIndexInBc != null) {
            message.txIndexInBc = data.txIndexInBc;
        }
        if (data.methodName != null) {
            message.methodName = data.methodName;
        }
        if (data.timestamp != null) {
            message.timestamp = data.timestamp;
        }
        if (data.from != null) {
            message.from = data.from;
        }
        if (data.to != null) {
            message.to = data.to;
        }
        if (data.value != null) {
            message.value = data.value;
        }
        if (data.meta != null) {
            message.meta = ERC20Metadata.fromObject(data.meta);
        }
        return message;
    }
    toObject() {
        const data: ERC20Transfer.AsObject = {
            txId: this.txId,
            txIndex: this.txIndex,
            txIndexInBc: this.txIndexInBc,
            methodName: this.methodName,
            timestamp: this.timestamp,
            from: this.from,
            to: this.to,
            value: this.value
        };
        if (this.meta != null) {
            data.meta = this.meta.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.txId.length)
            writer.writeString(1, this.txId);
        if (this.txIndex != 0)
            writer.writeInt64(2, this.txIndex);
        if (this.txIndexInBc != 0)
            writer.writeInt64(3, this.txIndexInBc);
        if (this.methodName.length)
            writer.writeString(4, this.methodName);
        if (this.timestamp != 0)
            writer.writeInt64(5, this.timestamp);
        if (this.from.length)
            writer.writeString(6, this.from);
        if (this.to.length)
            writer.writeString(7, this.to);
        if (this.value.length)
            writer.writeString(8, this.value);
        if (this.hasMeta)
            writer.writeMessage(9, this.meta, () => this.meta!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20Transfer {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20Transfer();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.txId = reader.readString();
                    break;
                case 2:
                    message.txIndex = reader.readInt64();
                    break;
                case 3:
                    message.txIndexInBc = reader.readInt64();
                    break;
                case 4:
                    message.methodName = reader.readString();
                    break;
                case 5:
                    message.timestamp = reader.readInt64();
                    break;
                case 6:
                    message.from = reader.readString();
                    break;
                case 7:
                    message.to = reader.readString();
                    break;
                case 8:
                    message.value = reader.readString();
                    break;
                case 9:
                    reader.readMessage(message.meta, () => message.meta = ERC20Metadata.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20Transfer {
        return ERC20Transfer.deserialize(bytes);
    }
}
export namespace ERC20Transfer {
    export type AsObject = {
        txId: string;
        txIndex: number;
        txIndexInBc: number;
        methodName: string;
        timestamp: number;
        from: string;
        to: string;
        value: string;
        meta?: ERC20Metadata.AsObject;
    };
    export type AsObjectPartial = {
        txId?: string;
        txIndex?: number;
        txIndexInBc?: number;
        methodName?: string;
        timestamp?: number;
        from?: string;
        to?: string;
        value?: string;
        meta?: ERC20Metadata.AsObjectPartial;
    };
}
export class ERC20Holder extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        address?: string;
        name?: string;
        value?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("value" in data && data.value != undefined) {
                this.value = data.value;
            }
        }
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get value() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set value(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data?: ERC20Holder.AsObjectPartial): ERC20Holder {
        if (!data) {
            return new ERC20Holder();
        }
        const message = new ERC20Holder({});
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.value != null) {
            message.value = data.value;
        }
        return message;
    }
    toObject() {
        const data: ERC20Holder.AsObject = {
            address: this.address,
            name: this.name,
            value: this.value
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.address.length)
            writer.writeString(1, this.address);
        if (this.name.length)
            writer.writeString(2, this.name);
        if (this.value.length)
            writer.writeString(3, this.value);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20Holder {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20Holder();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.address = reader.readString();
                    break;
                case 2:
                    message.name = reader.readString();
                    break;
                case 3:
                    message.value = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20Holder {
        return ERC20Holder.deserialize(bytes);
    }
}
export namespace ERC20Holder {
    export type AsObject = {
        address: string;
        name: string;
        value: string;
    };
    export type AsObjectPartial = {
        address?: string;
        name?: string;
        value?: string;
    };
}
export class ERC20Metadata extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        address?: string;
        name?: string;
        symbol?: string;
        decimals?: number;
        iconPath?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("symbol" in data && data.symbol != undefined) {
                this.symbol = data.symbol;
            }
            if ("decimals" in data && data.decimals != undefined) {
                this.decimals = data.decimals;
            }
            if ("iconPath" in data && data.iconPath != undefined) {
                this.iconPath = data.iconPath;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get symbol() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set symbol(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get decimals() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set decimals(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get iconPath() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set iconPath(value: string) {
        pb_1.Message.setField(this, 6, value);
    }
    static fromObject(data?: ERC20Metadata.AsObjectPartial): ERC20Metadata {
        if (!data) {
            return new ERC20Metadata();
        }
        const message = new ERC20Metadata({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.symbol != null) {
            message.symbol = data.symbol;
        }
        if (data.decimals != null) {
            message.decimals = data.decimals;
        }
        if (data.iconPath != null) {
            message.iconPath = data.iconPath;
        }
        return message;
    }
    toObject() {
        const data: ERC20Metadata.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address,
            name: this.name,
            symbol: this.symbol,
            decimals: this.decimals,
            iconPath: this.iconPath
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (this.name.length)
            writer.writeString(3, this.name);
        if (this.symbol.length)
            writer.writeString(4, this.symbol);
        if (this.decimals != 0)
            writer.writeUint32(5, this.decimals);
        if (this.iconPath.length)
            writer.writeString(6, this.iconPath);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20Metadata {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20Metadata();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                case 3:
                    message.name = reader.readString();
                    break;
                case 4:
                    message.symbol = reader.readString();
                    break;
                case 5:
                    message.decimals = reader.readUint32();
                    break;
                case 6:
                    message.iconPath = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20Metadata {
        return ERC20Metadata.deserialize(bytes);
    }
}
export namespace ERC20Metadata {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
        name: string;
        symbol: string;
        decimals: number;
        iconPath: string;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
        name?: string;
        symbol?: string;
        decimals?: number;
        iconPath?: string;
    };
}
export class ERC20Balance extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        meta?: ERC20Metadata;
        balance?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("meta" in data && data.meta != undefined) {
                this.meta = data.meta;
            }
            if ("balance" in data && data.balance != undefined) {
                this.balance = data.balance;
            }
        }
    }
    get meta() {
        return pb_1.Message.getWrapperField(this, ERC20Metadata, 1) as ERC20Metadata | undefined;
    }
    set meta(value: ERC20Metadata | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasMeta() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearMeta(): void {
        this.meta = undefined!;
    }
    get balance() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set balance(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: ERC20Balance.AsObjectPartial): ERC20Balance {
        if (!data) {
            return new ERC20Balance();
        }
        const message = new ERC20Balance({});
        if (data.meta != null) {
            message.meta = ERC20Metadata.fromObject(data.meta);
        }
        if (data.balance != null) {
            message.balance = data.balance;
        }
        return message;
    }
    toObject() {
        const data: ERC20Balance.AsObject = {
            balance: this.balance
        };
        if (this.meta != null) {
            data.meta = this.meta.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasMeta)
            writer.writeMessage(1, this.meta, () => this.meta!.serialize(writer));
        if (this.balance.length)
            writer.writeString(2, this.balance);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20Balance {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20Balance();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.meta, () => message.meta = ERC20Metadata.deserialize(reader));
                    break;
                case 2:
                    message.balance = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20Balance {
        return ERC20Balance.deserialize(bytes);
    }
}
export namespace ERC20Balance {
    export type AsObject = {
        meta?: ERC20Metadata.AsObject;
        balance: string;
    };
    export type AsObjectPartial = {
        meta?: ERC20Metadata.AsObjectPartial;
        balance?: string;
    };
}
export class ERC20TokenDetails extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        token?: ERC20Token;
        transfers?: ERC20Transfer[];
        totalTransfers?: number;
        holders?: ERC20Holder[];
        totalHolders?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1, 3], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("token" in data && data.token != undefined) {
                this.token = data.token;
            }
            if ("transfers" in data && data.transfers != undefined) {
                this.transfers = data.transfers;
            }
            if ("totalTransfers" in data && data.totalTransfers != undefined) {
                this.totalTransfers = data.totalTransfers;
            }
            if ("holders" in data && data.holders != undefined) {
                this.holders = data.holders;
            }
            if ("totalHolders" in data && data.totalHolders != undefined) {
                this.totalHolders = data.totalHolders;
            }
        }
    }
    get token() {
        return pb_1.Message.getWrapperField(this, ERC20Token, 5) as ERC20Token | undefined;
    }
    set token(value: ERC20Token | undefined) {
        pb_1.Message.setWrapperField(this, 5, value);
    }
    get hasToken() {
        return pb_1.Message.getField(this, 5) != null;
    }
    clearToken(): void {
        this.token = undefined!;
    }
    get transfers() {
        return pb_1.Message.getRepeatedWrapperField(this, ERC20Transfer, 1) as ERC20Transfer[];
    }
    set transfers(value: ERC20Transfer[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get totalTransfers() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set totalTransfers(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get holders() {
        return pb_1.Message.getRepeatedWrapperField(this, ERC20Holder, 3) as ERC20Holder[];
    }
    set holders(value: ERC20Holder[]) {
        pb_1.Message.setRepeatedWrapperField(this, 3, value);
    }
    get totalHolders() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set totalHolders(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    static fromObject(data?: ERC20TokenDetails.AsObjectPartial): ERC20TokenDetails {
        if (!data) {
            return new ERC20TokenDetails();
        }
        const message = new ERC20TokenDetails({});
        if (data.token != null) {
            message.token = ERC20Token.fromObject(data.token);
        }
        if (data.transfers != null) {
            message.transfers = data.transfers.map(item => ERC20Transfer.fromObject(item));
        }
        if (data.totalTransfers != null) {
            message.totalTransfers = data.totalTransfers;
        }
        if (data.holders != null) {
            message.holders = data.holders.map(item => ERC20Holder.fromObject(item));
        }
        if (data.totalHolders != null) {
            message.totalHolders = data.totalHolders;
        }
        return message;
    }
    toObject() {
        const data: ERC20TokenDetails.AsObject = {
            transfers: this.transfers.map((item: ERC20Transfer) => item.toObject()),
            totalTransfers: this.totalTransfers,
            holders: this.holders.map((item: ERC20Holder) => item.toObject()),
            totalHolders: this.totalHolders
        };
        if (this.token != null) {
            data.token = this.token.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasToken)
            writer.writeMessage(5, this.token, () => this.token!.serialize(writer));
        if (this.transfers.length)
            writer.writeRepeatedMessage(1, this.transfers, (item: ERC20Transfer) => item!.serialize(writer));
        if (this.totalTransfers != 0)
            writer.writeInt64(2, this.totalTransfers);
        if (this.holders.length)
            writer.writeRepeatedMessage(3, this.holders, (item: ERC20Holder) => item!.serialize(writer));
        if (this.totalHolders != 0)
            writer.writeInt64(4, this.totalHolders);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20TokenDetails {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20TokenDetails();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 5:
                    reader.readMessage(message.token, () => message.token = ERC20Token.deserialize(reader));
                    break;
                case 1:
                    reader.readMessage(message.transfers, () => pb_1.Message.addToRepeatedWrapperField(message, 1, ERC20Transfer.deserialize(reader), ERC20Transfer));
                    break;
                case 2:
                    message.totalTransfers = reader.readInt64();
                    break;
                case 3:
                    reader.readMessage(message.holders, () => pb_1.Message.addToRepeatedWrapperField(message, 3, ERC20Holder.deserialize(reader), ERC20Holder));
                    break;
                case 4:
                    message.totalHolders = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20TokenDetails {
        return ERC20TokenDetails.deserialize(bytes);
    }
}
export namespace ERC20TokenDetails {
    export type AsObject = {
        token?: ERC20Token.AsObject;
        transfers: ERC20Transfer.AsObject[];
        totalTransfers: number;
        holders: ERC20Holder.AsObject[];
        totalHolders: number;
    };
    export type AsObjectPartial = {
        token?: ERC20Token.AsObjectPartial;
        transfers?: ERC20Transfer.AsObjectPartial[];
        totalTransfers?: number;
        holders?: ERC20Holder.AsObjectPartial[];
        totalHolders?: number;
    };
}
export class ERC20ApproveAction extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        address?: string;
        owner?: string;
        spender?: string;
        value?: string;
        name?: string;
        symbol?: string;
        decimals?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("owner" in data && data.owner != undefined) {
                this.owner = data.owner;
            }
            if ("spender" in data && data.spender != undefined) {
                this.spender = data.spender;
            }
            if ("value" in data && data.value != undefined) {
                this.value = data.value;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("symbol" in data && data.symbol != undefined) {
                this.symbol = data.symbol;
            }
            if ("decimals" in data && data.decimals != undefined) {
                this.decimals = data.decimals;
            }
        }
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get owner() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set owner(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get spender() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set spender(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get value() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set value(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 5, value);
    }
    get symbol() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set symbol(value: string) {
        pb_1.Message.setField(this, 6, value);
    }
    get decimals() {
        return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
    }
    set decimals(value: number) {
        pb_1.Message.setField(this, 7, value);
    }
    static fromObject(data?: ERC20ApproveAction.AsObjectPartial): ERC20ApproveAction {
        if (!data) {
            return new ERC20ApproveAction();
        }
        const message = new ERC20ApproveAction({});
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.owner != null) {
            message.owner = data.owner;
        }
        if (data.spender != null) {
            message.spender = data.spender;
        }
        if (data.value != null) {
            message.value = data.value;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.symbol != null) {
            message.symbol = data.symbol;
        }
        if (data.decimals != null) {
            message.decimals = data.decimals;
        }
        return message;
    }
    toObject() {
        const data: ERC20ApproveAction.AsObject = {
            address: this.address,
            owner: this.owner,
            spender: this.spender,
            value: this.value,
            name: this.name,
            symbol: this.symbol,
            decimals: this.decimals
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.address.length)
            writer.writeString(1, this.address);
        if (this.owner.length)
            writer.writeString(2, this.owner);
        if (this.spender.length)
            writer.writeString(3, this.spender);
        if (this.value.length)
            writer.writeString(4, this.value);
        if (this.name.length)
            writer.writeString(5, this.name);
        if (this.symbol.length)
            writer.writeString(6, this.symbol);
        if (this.decimals != 0)
            writer.writeUint32(7, this.decimals);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20ApproveAction {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20ApproveAction();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.address = reader.readString();
                    break;
                case 2:
                    message.owner = reader.readString();
                    break;
                case 3:
                    message.spender = reader.readString();
                    break;
                case 4:
                    message.value = reader.readString();
                    break;
                case 5:
                    message.name = reader.readString();
                    break;
                case 6:
                    message.symbol = reader.readString();
                    break;
                case 7:
                    message.decimals = reader.readUint32();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20ApproveAction {
        return ERC20ApproveAction.deserialize(bytes);
    }
}
export namespace ERC20ApproveAction {
    export type AsObject = {
        address: string;
        owner: string;
        spender: string;
        value: string;
        name: string;
        symbol: string;
        decimals: number;
    };
    export type AsObjectPartial = {
        address?: string;
        owner?: string;
        spender?: string;
        value?: string;
        name?: string;
        symbol?: string;
        decimals?: number;
    };
}
export class ERC20TokenListRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        page?: dependency_2.Page;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_2.Page, 2) as dependency_2.Page | undefined;
    }
    set page(value: dependency_2.Page | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    static fromObject(data?: ERC20TokenListRequest.AsObjectPartial): ERC20TokenListRequest {
        if (!data) {
            return new ERC20TokenListRequest();
        }
        const message = new ERC20TokenListRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.page != null) {
            message.page = dependency_2.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: ERC20TokenListRequest.AsObject = {
            blockchainIndex: this.blockchainIndex
        };
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.hasPage)
            writer.writeMessage(2, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20TokenListRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20TokenListRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    reader.readMessage(message.page, () => message.page = dependency_2.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20TokenListRequest {
        return ERC20TokenListRequest.deserialize(bytes);
    }
}
export namespace ERC20TokenListRequest {
    export type AsObject = {
        blockchainIndex: number;
        page?: dependency_2.Page.AsObject;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        page?: dependency_2.Page.AsObjectPartial;
    };
}
export class ERC20TokenListResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        erc20Tokens?: ERC20Token[];
        navigation?: dependency_2.NavigationData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("erc20Tokens" in data && data.erc20Tokens != undefined) {
                this.erc20Tokens = data.erc20Tokens;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
        }
    }
    get erc20Tokens() {
        return pb_1.Message.getRepeatedWrapperField(this, ERC20Token, 1) as ERC20Token[];
    }
    set erc20Tokens(value: ERC20Token[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_2.NavigationData, 2) as dependency_2.NavigationData | undefined;
    }
    set navigation(value: dependency_2.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    static fromObject(data?: ERC20TokenListResponse.AsObjectPartial): ERC20TokenListResponse {
        if (!data) {
            return new ERC20TokenListResponse();
        }
        const message = new ERC20TokenListResponse({});
        if (data.erc20Tokens != null) {
            message.erc20Tokens = data.erc20Tokens.map(item => ERC20Token.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_2.NavigationData.fromObject(data.navigation);
        }
        return message;
    }
    toObject() {
        const data: ERC20TokenListResponse.AsObject = {
            erc20Tokens: this.erc20Tokens.map((item: ERC20Token) => item.toObject())
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.erc20Tokens.length)
            writer.writeRepeatedMessage(1, this.erc20Tokens, (item: ERC20Token) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(2, this.navigation, () => this.navigation!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20TokenListResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20TokenListResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.erc20Tokens, () => pb_1.Message.addToRepeatedWrapperField(message, 1, ERC20Token.deserialize(reader), ERC20Token));
                    break;
                case 2:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_2.NavigationData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20TokenListResponse {
        return ERC20TokenListResponse.deserialize(bytes);
    }
}
export namespace ERC20TokenListResponse {
    export type AsObject = {
        erc20Tokens: ERC20Token.AsObject[];
        navigation?: dependency_2.NavigationData.AsObject;
    };
    export type AsObjectPartial = {
        erc20Tokens?: ERC20Token.AsObjectPartial[];
        navigation?: dependency_2.NavigationData.AsObjectPartial;
    };
}
export class ERC20TokenDetailsRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        address?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: ERC20TokenDetailsRequest.AsObjectPartial): ERC20TokenDetailsRequest {
        if (!data) {
            return new ERC20TokenDetailsRequest();
        }
        const message = new ERC20TokenDetailsRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        return message;
    }
    toObject() {
        const data: ERC20TokenDetailsRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20TokenDetailsRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20TokenDetailsRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20TokenDetailsRequest {
        return ERC20TokenDetailsRequest.deserialize(bytes);
    }
}
export namespace ERC20TokenDetailsRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
    };
}
export class ERC20TokenDetailsResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        details?: ERC20TokenDetails;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("details" in data && data.details != undefined) {
                this.details = data.details;
            }
        }
    }
    get details() {
        return pb_1.Message.getWrapperField(this, ERC20TokenDetails, 1) as ERC20TokenDetails | undefined;
    }
    set details(value: ERC20TokenDetails | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasDetails() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearDetails(): void {
        this.details = undefined!;
    }
    static fromObject(data?: ERC20TokenDetailsResponse.AsObjectPartial): ERC20TokenDetailsResponse {
        if (!data) {
            return new ERC20TokenDetailsResponse();
        }
        const message = new ERC20TokenDetailsResponse({});
        if (data.details != null) {
            message.details = ERC20TokenDetails.fromObject(data.details);
        }
        return message;
    }
    toObject() {
        const data: ERC20TokenDetailsResponse.AsObject = {};
        if (this.details != null) {
            data.details = this.details.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasDetails)
            writer.writeMessage(1, this.details, () => this.details!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20TokenDetailsResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20TokenDetailsResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.details, () => message.details = ERC20TokenDetails.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20TokenDetailsResponse {
        return ERC20TokenDetailsResponse.deserialize(bytes);
    }
}
export namespace ERC20TokenDetailsResponse {
    export type AsObject = {
        details?: ERC20TokenDetails.AsObject;
    };
    export type AsObjectPartial = {
        details?: ERC20TokenDetails.AsObjectPartial;
    };
}
export class ERC20TransferListRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[2, 3, 4, 6]];
    constructor(data?: any[] | ({
        blockchainIndex?: number;
        page?: dependency_2.Page;
    } & (({
        address?: string;
        account?: never;
        all?: never;
        addressAccount?: never;
    } | {
        address?: never;
        account?: string;
        all?: never;
        addressAccount?: never;
    } | {
        address?: never;
        account?: never;
        all?: boolean;
        addressAccount?: never;
    } | {
        address?: never;
        account?: never;
        all?: never;
        addressAccount?: ERC20TransferListRequest.AddressAccount;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("account" in data && data.account != undefined) {
                this.account = data.account;
            }
            if ("all" in data && data.all != undefined) {
                this.all = data.all;
            }
            if ("addressAccount" in data && data.addressAccount != undefined) {
                this.addressAccount = data.addressAccount;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasAddress() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearAddress(): void {
        this.address = undefined!;
    }
    get account() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set account(value: string) {
        pb_1.Message.setOneofField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasAccount() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearAccount(): void {
        this.account = undefined!;
    }
    get all() {
        return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
    }
    set all(value: boolean) {
        pb_1.Message.setOneofField(this, 4, this.#one_of_decls[0]!, value);
    }
    get hasAll() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearAll(): void {
        this.all = undefined!;
    }
    get addressAccount() {
        return pb_1.Message.getWrapperField(this, ERC20TransferListRequest.AddressAccount, 6) as ERC20TransferListRequest.AddressAccount | undefined;
    }
    set addressAccount(value: ERC20TransferListRequest.AddressAccount | undefined) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0]!, value);
    }
    get hasAddressAccount() {
        return pb_1.Message.getField(this, 6) != null;
    }
    clearAddressAccount(): void {
        this.addressAccount = undefined!;
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_2.Page, 5) as dependency_2.Page | undefined;
    }
    set page(value: dependency_2.Page | undefined) {
        pb_1.Message.setWrapperField(this, 5, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 5) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "address" | "account" | "all" | "addressAccount";
        } = {
            0: "none",
            2: "address",
            3: "account",
            4: "all",
            6: "addressAccount"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2, 3, 4, 6])]!;
    }
    static fromObject(data?: ERC20TransferListRequest.AsObjectPartial): ERC20TransferListRequest {
        if (!data) {
            return new ERC20TransferListRequest();
        }
        const message = new ERC20TransferListRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.account != null) {
            message.account = data.account;
        }
        if (data.all != null) {
            message.all = data.all;
        }
        if (data.addressAccount != null) {
            message.addressAccount = ERC20TransferListRequest.AddressAccount.fromObject(data.addressAccount);
        }
        if (data.page != null) {
            message.page = dependency_2.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: ERC20TransferListRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address,
            account: this.account,
            all: this.all
        };
        if (this.addressAccount != null) {
            data.addressAccount = this.addressAccount.toObject();
        }
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.hasAddress)
            writer.writeString(2, this.address);
        if (this.hasAccount)
            writer.writeString(3, this.account);
        if (this.hasAll)
            writer.writeBool(4, this.all);
        if (this.hasAddressAccount)
            writer.writeMessage(6, this.addressAccount, () => this.addressAccount!.serialize(writer));
        if (this.hasPage)
            writer.writeMessage(5, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20TransferListRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20TransferListRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                case 3:
                    message.account = reader.readString();
                    break;
                case 4:
                    message.all = reader.readBool();
                    break;
                case 6:
                    reader.readMessage(message.addressAccount, () => message.addressAccount = ERC20TransferListRequest.AddressAccount.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.page, () => message.page = dependency_2.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20TransferListRequest {
        return ERC20TransferListRequest.deserialize(bytes);
    }
}
export namespace ERC20TransferListRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
        account: string;
        all: boolean;
        addressAccount?: ERC20TransferListRequest.AddressAccount.AsObject;
        page?: dependency_2.Page.AsObject;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
        account?: string;
        all?: boolean;
        addressAccount?: ERC20TransferListRequest.AddressAccount.AsObjectPartial;
        page?: dependency_2.Page.AsObjectPartial;
    };
    export class AddressAccount extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            address?: string;
            account?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("address" in data && data.address != undefined) {
                    this.address = data.address;
                }
                if ("account" in data && data.account != undefined) {
                    this.account = data.account;
                }
            }
        }
        get address() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set address(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get account() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set account(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data?: AddressAccount.AsObjectPartial): AddressAccount {
            if (!data) {
                return new AddressAccount();
            }
            const message = new AddressAccount({});
            if (data.address != null) {
                message.address = data.address;
            }
            if (data.account != null) {
                message.account = data.account;
            }
            return message;
        }
        toObject() {
            const data: AddressAccount.AsObject = {
                address: this.address,
                account: this.account
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.address.length)
                writer.writeString(1, this.address);
            if (this.account.length)
                writer.writeString(2, this.account);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AddressAccount {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AddressAccount();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.address = reader.readString();
                        break;
                    case 2:
                        message.account = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): AddressAccount {
            return AddressAccount.deserialize(bytes);
        }
    }
    export namespace AddressAccount {
        export type AsObject = {
            address: string;
            account: string;
        };
        export type AsObjectPartial = {
            address?: string;
            account?: string;
        };
    }
}
export class ERC20TransferListResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        transfers?: ERC20Transfer[];
        navigation?: dependency_2.NavigationData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("transfers" in data && data.transfers != undefined) {
                this.transfers = data.transfers;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
        }
    }
    get transfers() {
        return pb_1.Message.getRepeatedWrapperField(this, ERC20Transfer, 1) as ERC20Transfer[];
    }
    set transfers(value: ERC20Transfer[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_2.NavigationData, 2) as dependency_2.NavigationData | undefined;
    }
    set navigation(value: dependency_2.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    static fromObject(data?: ERC20TransferListResponse.AsObjectPartial): ERC20TransferListResponse {
        if (!data) {
            return new ERC20TransferListResponse();
        }
        const message = new ERC20TransferListResponse({});
        if (data.transfers != null) {
            message.transfers = data.transfers.map(item => ERC20Transfer.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_2.NavigationData.fromObject(data.navigation);
        }
        return message;
    }
    toObject() {
        const data: ERC20TransferListResponse.AsObject = {
            transfers: this.transfers.map((item: ERC20Transfer) => item.toObject())
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.transfers.length)
            writer.writeRepeatedMessage(1, this.transfers, (item: ERC20Transfer) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(2, this.navigation, () => this.navigation!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20TransferListResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20TransferListResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.transfers, () => pb_1.Message.addToRepeatedWrapperField(message, 1, ERC20Transfer.deserialize(reader), ERC20Transfer));
                    break;
                case 2:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_2.NavigationData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20TransferListResponse {
        return ERC20TransferListResponse.deserialize(bytes);
    }
}
export namespace ERC20TransferListResponse {
    export type AsObject = {
        transfers: ERC20Transfer.AsObject[];
        navigation?: dependency_2.NavigationData.AsObject;
    };
    export type AsObjectPartial = {
        transfers?: ERC20Transfer.AsObjectPartial[];
        navigation?: dependency_2.NavigationData.AsObjectPartial;
    };
}
export class ERC20HolderListRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[4, 5]];
    constructor(data?: any[] | ({
        blockchainIndex?: number;
        address?: string;
        page?: dependency_2.Page;
    } & (({
        all?: boolean;
        account?: never;
    } | {
        all?: never;
        account?: string;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("all" in data && data.all != undefined) {
                this.all = data.all;
            }
            if ("account" in data && data.account != undefined) {
                this.account = data.account;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get all() {
        return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
    }
    set all(value: boolean) {
        pb_1.Message.setOneofField(this, 4, this.#one_of_decls[0]!, value);
    }
    get hasAll() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearAll(): void {
        this.all = undefined!;
    }
    get account() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set account(value: string) {
        pb_1.Message.setOneofField(this, 5, this.#one_of_decls[0]!, value);
    }
    get hasAccount() {
        return pb_1.Message.getField(this, 5) != null;
    }
    clearAccount(): void {
        this.account = undefined!;
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_2.Page, 3) as dependency_2.Page | undefined;
    }
    set page(value: dependency_2.Page | undefined) {
        pb_1.Message.setWrapperField(this, 3, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "all" | "account";
        } = {
            0: "none",
            4: "all",
            5: "account"
        };
        return cases[pb_1.Message.computeOneofCase(this, [4, 5])]!;
    }
    static fromObject(data?: ERC20HolderListRequest.AsObjectPartial): ERC20HolderListRequest {
        if (!data) {
            return new ERC20HolderListRequest();
        }
        const message = new ERC20HolderListRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.all != null) {
            message.all = data.all;
        }
        if (data.account != null) {
            message.account = data.account;
        }
        if (data.page != null) {
            message.page = dependency_2.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: ERC20HolderListRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address,
            all: this.all,
            account: this.account
        };
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (this.hasAll)
            writer.writeBool(4, this.all);
        if (this.hasAccount)
            writer.writeString(5, this.account);
        if (this.hasPage)
            writer.writeMessage(3, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20HolderListRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20HolderListRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                case 4:
                    message.all = reader.readBool();
                    break;
                case 5:
                    message.account = reader.readString();
                    break;
                case 3:
                    reader.readMessage(message.page, () => message.page = dependency_2.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20HolderListRequest {
        return ERC20HolderListRequest.deserialize(bytes);
    }
}
export namespace ERC20HolderListRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
        all: boolean;
        account: string;
        page?: dependency_2.Page.AsObject;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
        all?: boolean;
        account?: string;
        page?: dependency_2.Page.AsObjectPartial;
    };
    export class AddressAccount extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            address?: string;
            account?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("address" in data && data.address != undefined) {
                    this.address = data.address;
                }
                if ("account" in data && data.account != undefined) {
                    this.account = data.account;
                }
            }
        }
        get address() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set address(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get account() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set account(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data?: AddressAccount.AsObjectPartial): AddressAccount {
            if (!data) {
                return new AddressAccount();
            }
            const message = new AddressAccount({});
            if (data.address != null) {
                message.address = data.address;
            }
            if (data.account != null) {
                message.account = data.account;
            }
            return message;
        }
        toObject() {
            const data: AddressAccount.AsObject = {
                address: this.address,
                account: this.account
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.address.length)
                writer.writeString(1, this.address);
            if (this.account.length)
                writer.writeString(2, this.account);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AddressAccount {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AddressAccount();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.address = reader.readString();
                        break;
                    case 2:
                        message.account = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): AddressAccount {
            return AddressAccount.deserialize(bytes);
        }
    }
    export namespace AddressAccount {
        export type AsObject = {
            address: string;
            account: string;
        };
        export type AsObjectPartial = {
            address?: string;
            account?: string;
        };
    }
}
export class ERC20HolderListResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        holders?: ERC20Holder[];
        navigation?: dependency_2.NavigationData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("holders" in data && data.holders != undefined) {
                this.holders = data.holders;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
        }
    }
    get holders() {
        return pb_1.Message.getRepeatedWrapperField(this, ERC20Holder, 1) as ERC20Holder[];
    }
    set holders(value: ERC20Holder[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_2.NavigationData, 2) as dependency_2.NavigationData | undefined;
    }
    set navigation(value: dependency_2.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    static fromObject(data?: ERC20HolderListResponse.AsObjectPartial): ERC20HolderListResponse {
        if (!data) {
            return new ERC20HolderListResponse();
        }
        const message = new ERC20HolderListResponse({});
        if (data.holders != null) {
            message.holders = data.holders.map(item => ERC20Holder.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_2.NavigationData.fromObject(data.navigation);
        }
        return message;
    }
    toObject() {
        const data: ERC20HolderListResponse.AsObject = {
            holders: this.holders.map((item: ERC20Holder) => item.toObject())
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.holders.length)
            writer.writeRepeatedMessage(1, this.holders, (item: ERC20Holder) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(2, this.navigation, () => this.navigation!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20HolderListResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20HolderListResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.holders, () => pb_1.Message.addToRepeatedWrapperField(message, 1, ERC20Holder.deserialize(reader), ERC20Holder));
                    break;
                case 2:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_2.NavigationData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20HolderListResponse {
        return ERC20HolderListResponse.deserialize(bytes);
    }
}
export namespace ERC20HolderListResponse {
    export type AsObject = {
        holders: ERC20Holder.AsObject[];
        navigation?: dependency_2.NavigationData.AsObject;
    };
    export type AsObjectPartial = {
        holders?: ERC20Holder.AsObjectPartial[];
        navigation?: dependency_2.NavigationData.AsObjectPartial;
    };
}
export class ERC20UpdateIconRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        address?: string;
        icon?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("icon" in data && data.icon != undefined) {
                this.icon = data.icon;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get icon() {
        return pb_1.Message.getFieldWithDefault(this, 3, new Uint8Array()) as Uint8Array;
    }
    set icon(value: Uint8Array) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data?: ERC20UpdateIconRequest.AsObjectPartial): ERC20UpdateIconRequest {
        if (!data) {
            return new ERC20UpdateIconRequest();
        }
        const message = new ERC20UpdateIconRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.icon != null) {
            message.icon = data.icon;
        }
        return message;
    }
    toObject() {
        const data: ERC20UpdateIconRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address,
            icon: this.icon
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (this.icon.length)
            writer.writeBytes(3, this.icon);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20UpdateIconRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20UpdateIconRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                case 3:
                    message.icon = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20UpdateIconRequest {
        return ERC20UpdateIconRequest.deserialize(bytes);
    }
}
export namespace ERC20UpdateIconRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
        icon: Uint8Array;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
        icon?: Uint8Array;
    };
}
export class ERC20UpdateIconResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        token?: ERC20Token;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("token" in data && data.token != undefined) {
                this.token = data.token;
            }
        }
    }
    get token() {
        return pb_1.Message.getWrapperField(this, ERC20Token, 1) as ERC20Token | undefined;
    }
    set token(value: ERC20Token | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasToken() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearToken(): void {
        this.token = undefined!;
    }
    static fromObject(data?: ERC20UpdateIconResponse.AsObjectPartial): ERC20UpdateIconResponse {
        if (!data) {
            return new ERC20UpdateIconResponse();
        }
        const message = new ERC20UpdateIconResponse({});
        if (data.token != null) {
            message.token = ERC20Token.fromObject(data.token);
        }
        return message;
    }
    toObject() {
        const data: ERC20UpdateIconResponse.AsObject = {};
        if (this.token != null) {
            data.token = this.token.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasToken)
            writer.writeMessage(1, this.token, () => this.token!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20UpdateIconResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20UpdateIconResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.token, () => message.token = ERC20Token.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20UpdateIconResponse {
        return ERC20UpdateIconResponse.deserialize(bytes);
    }
}
export namespace ERC20UpdateIconResponse {
    export type AsObject = {
        token?: ERC20Token.AsObject;
    };
    export type AsObjectPartial = {
        token?: ERC20Token.AsObjectPartial;
    };
}
export class ERC20UpdateRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        address?: string;
        token?: ERC20Token;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("token" in data && data.token != undefined) {
                this.token = data.token;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get token() {
        return pb_1.Message.getWrapperField(this, ERC20Token, 3) as ERC20Token | undefined;
    }
    set token(value: ERC20Token | undefined) {
        pb_1.Message.setWrapperField(this, 3, value);
    }
    get hasToken() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearToken(): void {
        this.token = undefined!;
    }
    static fromObject(data?: ERC20UpdateRequest.AsObjectPartial): ERC20UpdateRequest {
        if (!data) {
            return new ERC20UpdateRequest();
        }
        const message = new ERC20UpdateRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.token != null) {
            message.token = ERC20Token.fromObject(data.token);
        }
        return message;
    }
    toObject() {
        const data: ERC20UpdateRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address
        };
        if (this.token != null) {
            data.token = this.token.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (this.hasToken)
            writer.writeMessage(3, this.token, () => this.token!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20UpdateRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20UpdateRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                case 3:
                    reader.readMessage(message.token, () => message.token = ERC20Token.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20UpdateRequest {
        return ERC20UpdateRequest.deserialize(bytes);
    }
}
export namespace ERC20UpdateRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
        token?: ERC20Token.AsObject;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
        token?: ERC20Token.AsObjectPartial;
    };
}
export class ERC20UpdateResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        token?: ERC20Token;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("token" in data && data.token != undefined) {
                this.token = data.token;
            }
        }
    }
    get token() {
        return pb_1.Message.getWrapperField(this, ERC20Token, 1) as ERC20Token | undefined;
    }
    set token(value: ERC20Token | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasToken() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearToken(): void {
        this.token = undefined!;
    }
    static fromObject(data?: ERC20UpdateResponse.AsObjectPartial): ERC20UpdateResponse {
        if (!data) {
            return new ERC20UpdateResponse();
        }
        const message = new ERC20UpdateResponse({});
        if (data.token != null) {
            message.token = ERC20Token.fromObject(data.token);
        }
        return message;
    }
    toObject() {
        const data: ERC20UpdateResponse.AsObject = {};
        if (this.token != null) {
            data.token = this.token.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasToken)
            writer.writeMessage(1, this.token, () => this.token!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20UpdateResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20UpdateResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.token, () => message.token = ERC20Token.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20UpdateResponse {
        return ERC20UpdateResponse.deserialize(bytes);
    }
}
export namespace ERC20UpdateResponse {
    export type AsObject = {
        token?: ERC20Token.AsObject;
    };
    export type AsObjectPartial = {
        token?: ERC20Token.AsObjectPartial;
    };
}
export class ERC20TokenGetOrCreateRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        address?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: ERC20TokenGetOrCreateRequest.AsObjectPartial): ERC20TokenGetOrCreateRequest {
        if (!data) {
            return new ERC20TokenGetOrCreateRequest();
        }
        const message = new ERC20TokenGetOrCreateRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        return message;
    }
    toObject() {
        const data: ERC20TokenGetOrCreateRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20TokenGetOrCreateRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20TokenGetOrCreateRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20TokenGetOrCreateRequest {
        return ERC20TokenGetOrCreateRequest.deserialize(bytes);
    }
}
export namespace ERC20TokenGetOrCreateRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
    };
}
export class ERC20TokensAddRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        tokens?: ERC20Token[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("tokens" in data && data.tokens != undefined) {
                this.tokens = data.tokens;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get tokens() {
        return pb_1.Message.getRepeatedWrapperField(this, ERC20Token, 2) as ERC20Token[];
    }
    set tokens(value: ERC20Token[]) {
        pb_1.Message.setRepeatedWrapperField(this, 2, value);
    }
    static fromObject(data?: ERC20TokensAddRequest.AsObjectPartial): ERC20TokensAddRequest {
        if (!data) {
            return new ERC20TokensAddRequest();
        }
        const message = new ERC20TokensAddRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.tokens != null) {
            message.tokens = data.tokens.map(item => ERC20Token.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: ERC20TokensAddRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            tokens: this.tokens.map((item: ERC20Token) => item.toObject())
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.tokens.length)
            writer.writeRepeatedMessage(2, this.tokens, (item: ERC20Token) => item!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20TokensAddRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20TokensAddRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    reader.readMessage(message.tokens, () => pb_1.Message.addToRepeatedWrapperField(message, 2, ERC20Token.deserialize(reader), ERC20Token));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20TokensAddRequest {
        return ERC20TokensAddRequest.deserialize(bytes);
    }
}
export namespace ERC20TokensAddRequest {
    export type AsObject = {
        blockchainIndex: number;
        tokens: ERC20Token.AsObject[];
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        tokens?: ERC20Token.AsObjectPartial[];
    };
}
export class ERC20TransfersAddRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        transfers?: ERC20TransfersAddRequest.ERC20TransferToAdd[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("transfers" in data && data.transfers != undefined) {
                this.transfers = data.transfers;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get transfers() {
        return pb_1.Message.getRepeatedWrapperField(this, ERC20TransfersAddRequest.ERC20TransferToAdd, 2) as ERC20TransfersAddRequest.ERC20TransferToAdd[];
    }
    set transfers(value: ERC20TransfersAddRequest.ERC20TransferToAdd[]) {
        pb_1.Message.setRepeatedWrapperField(this, 2, value);
    }
    static fromObject(data?: ERC20TransfersAddRequest.AsObjectPartial): ERC20TransfersAddRequest {
        if (!data) {
            return new ERC20TransfersAddRequest();
        }
        const message = new ERC20TransfersAddRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.transfers != null) {
            message.transfers = data.transfers.map(item => ERC20TransfersAddRequest.ERC20TransferToAdd.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: ERC20TransfersAddRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            transfers: this.transfers.map((item: ERC20TransfersAddRequest.ERC20TransferToAdd) => item.toObject())
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.transfers.length)
            writer.writeRepeatedMessage(2, this.transfers, (item: ERC20TransfersAddRequest.ERC20TransferToAdd) => item!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20TransfersAddRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20TransfersAddRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    reader.readMessage(message.transfers, () => pb_1.Message.addToRepeatedWrapperField(message, 2, ERC20TransfersAddRequest.ERC20TransferToAdd.deserialize(reader), ERC20TransfersAddRequest.ERC20TransferToAdd));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20TransfersAddRequest {
        return ERC20TransfersAddRequest.deserialize(bytes);
    }
}
export namespace ERC20TransfersAddRequest {
    export type AsObject = {
        blockchainIndex: number;
        transfers: ERC20TransfersAddRequest.ERC20TransferToAdd.AsObject[];
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        transfers?: ERC20TransfersAddRequest.ERC20TransferToAdd.AsObjectPartial[];
    };
    export class ERC20TransferToAdd extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            tokenAddress?: string;
            transfer?: ERC20Transfer;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("tokenAddress" in data && data.tokenAddress != undefined) {
                    this.tokenAddress = data.tokenAddress;
                }
                if ("transfer" in data && data.transfer != undefined) {
                    this.transfer = data.transfer;
                }
            }
        }
        get tokenAddress() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set tokenAddress(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get transfer() {
            return pb_1.Message.getWrapperField(this, ERC20Transfer, 2) as ERC20Transfer | undefined;
        }
        set transfer(value: ERC20Transfer | undefined) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get hasTransfer() {
            return pb_1.Message.getField(this, 2) != null;
        }
        clearTransfer(): void {
            this.transfer = undefined!;
        }
        static fromObject(data?: ERC20TransferToAdd.AsObjectPartial): ERC20TransferToAdd {
            if (!data) {
                return new ERC20TransferToAdd();
            }
            const message = new ERC20TransferToAdd({});
            if (data.tokenAddress != null) {
                message.tokenAddress = data.tokenAddress;
            }
            if (data.transfer != null) {
                message.transfer = ERC20Transfer.fromObject(data.transfer);
            }
            return message;
        }
        toObject() {
            const data: ERC20TransferToAdd.AsObject = {
                tokenAddress: this.tokenAddress
            };
            if (this.transfer != null) {
                data.transfer = this.transfer.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.tokenAddress.length)
                writer.writeString(1, this.tokenAddress);
            if (this.hasTransfer)
                writer.writeMessage(2, this.transfer, () => this.transfer!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20TransferToAdd {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20TransferToAdd();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.tokenAddress = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.transfer, () => message.transfer = ERC20Transfer.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ERC20TransferToAdd {
            return ERC20TransferToAdd.deserialize(bytes);
        }
    }
    export namespace ERC20TransferToAdd {
        export type AsObject = {
            tokenAddress: string;
            transfer?: ERC20Transfer.AsObject;
        };
        export type AsObjectPartial = {
            tokenAddress?: string;
            transfer?: ERC20Transfer.AsObjectPartial;
        };
    }
}
export class ERC20DisableRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        address?: string;
        disabled?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("disabled" in data && data.disabled != undefined) {
                this.disabled = data.disabled;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get disabled() {
        return pb_1.Message.getFieldWithDefault(this, 3, false) as boolean;
    }
    set disabled(value: boolean) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data?: ERC20DisableRequest.AsObjectPartial): ERC20DisableRequest {
        if (!data) {
            return new ERC20DisableRequest();
        }
        const message = new ERC20DisableRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.disabled != null) {
            message.disabled = data.disabled;
        }
        return message;
    }
    toObject() {
        const data: ERC20DisableRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address,
            disabled: this.disabled
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (this.disabled != false)
            writer.writeBool(3, this.disabled);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20DisableRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20DisableRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                case 3:
                    message.disabled = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20DisableRequest {
        return ERC20DisableRequest.deserialize(bytes);
    }
}
export namespace ERC20DisableRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
        disabled: boolean;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
        disabled?: boolean;
    };
}
export class ERC20DisableResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        token?: ERC20Token;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("token" in data && data.token != undefined) {
                this.token = data.token;
            }
        }
    }
    get token() {
        return pb_1.Message.getWrapperField(this, ERC20Token, 1) as ERC20Token | undefined;
    }
    set token(value: ERC20Token | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasToken() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearToken(): void {
        this.token = undefined!;
    }
    static fromObject(data?: ERC20DisableResponse.AsObjectPartial): ERC20DisableResponse {
        if (!data) {
            return new ERC20DisableResponse();
        }
        const message = new ERC20DisableResponse({});
        if (data.token != null) {
            message.token = ERC20Token.fromObject(data.token);
        }
        return message;
    }
    toObject() {
        const data: ERC20DisableResponse.AsObject = {};
        if (this.token != null) {
            data.token = this.token.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasToken)
            writer.writeMessage(1, this.token, () => this.token!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20DisableResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20DisableResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.token, () => message.token = ERC20Token.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20DisableResponse {
        return ERC20DisableResponse.deserialize(bytes);
    }
}
export namespace ERC20DisableResponse {
    export type AsObject = {
        token?: ERC20Token.AsObject;
    };
    export type AsObjectPartial = {
        token?: ERC20Token.AsObjectPartial;
    };
}
export class ERC20TokenListUnfilteredRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        page?: dependency_2.Page;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_2.Page, 2) as dependency_2.Page | undefined;
    }
    set page(value: dependency_2.Page | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    static fromObject(data?: ERC20TokenListUnfilteredRequest.AsObjectPartial): ERC20TokenListUnfilteredRequest {
        if (!data) {
            return new ERC20TokenListUnfilteredRequest();
        }
        const message = new ERC20TokenListUnfilteredRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.page != null) {
            message.page = dependency_2.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: ERC20TokenListUnfilteredRequest.AsObject = {
            blockchainIndex: this.blockchainIndex
        };
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.hasPage)
            writer.writeMessage(2, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20TokenListUnfilteredRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20TokenListUnfilteredRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    reader.readMessage(message.page, () => message.page = dependency_2.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERC20TokenListUnfilteredRequest {
        return ERC20TokenListUnfilteredRequest.deserialize(bytes);
    }
}
export namespace ERC20TokenListUnfilteredRequest {
    export type AsObject = {
        blockchainIndex: number;
        page?: dependency_2.Page.AsObject;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        page?: dependency_2.Page.AsObjectPartial;
    };
}
