/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.13.0
 * source: evm.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./validate/validate";
import * as dependency_2 from "./erc20";
import * as dependency_3 from "./erc721";
import * as dependency_4 from "./erc1155";
import * as dependency_5 from "./models";
import * as pb_1 from "google-protobuf";
export enum ContractKind {
    ContractKindUnknown = 0,
    ContractKindVerified = 1,
    ContractKindERC20 = 2,
    ContractKindERC721 = 3,
    ContractKindERC1155 = 4,
    ContractKindVerifiedERC20 = 5,
    ContractKindVerifiedERC721 = 6,
    ContractKindVerifiedERC1155 = 7
}
export class Log extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        address?: string;
        txId?: string;
        txIndex?: number;
        txIndexInBc?: number;
        indexInTx?: number;
        indexInAddress?: number;
        topics?: string[];
        data?: string;
        eventName?: string;
        eventArgs?: Log.Arg[];
        decodedArgs?: string[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [7, 10, 11], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("txId" in data && data.txId != undefined) {
                this.txId = data.txId;
            }
            if ("txIndex" in data && data.txIndex != undefined) {
                this.txIndex = data.txIndex;
            }
            if ("txIndexInBc" in data && data.txIndexInBc != undefined) {
                this.txIndexInBc = data.txIndexInBc;
            }
            if ("indexInTx" in data && data.indexInTx != undefined) {
                this.indexInTx = data.indexInTx;
            }
            if ("indexInAddress" in data && data.indexInAddress != undefined) {
                this.indexInAddress = data.indexInAddress;
            }
            if ("topics" in data && data.topics != undefined) {
                this.topics = data.topics;
            }
            if ("data" in data && data.data != undefined) {
                this.data = data.data;
            }
            if ("eventName" in data && data.eventName != undefined) {
                this.eventName = data.eventName;
            }
            if ("eventArgs" in data && data.eventArgs != undefined) {
                this.eventArgs = data.eventArgs;
            }
            if ("decodedArgs" in data && data.decodedArgs != undefined) {
                this.decodedArgs = data.decodedArgs;
            }
        }
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get txId() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set txId(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get txIndex() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set txIndex(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get txIndexInBc() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set txIndexInBc(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get indexInTx() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set indexInTx(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get indexInAddress() {
        return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
    }
    set indexInAddress(value: number) {
        pb_1.Message.setField(this, 6, value);
    }
    get topics() {
        return pb_1.Message.getFieldWithDefault(this, 7, []) as string[];
    }
    set topics(value: string[]) {
        pb_1.Message.setField(this, 7, value);
    }
    get data() {
        return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
    }
    set data(value: string) {
        pb_1.Message.setField(this, 8, value);
    }
    get eventName() {
        return pb_1.Message.getFieldWithDefault(this, 9, "") as string;
    }
    set eventName(value: string) {
        pb_1.Message.setField(this, 9, value);
    }
    get eventArgs() {
        return pb_1.Message.getRepeatedWrapperField(this, Log.Arg, 10) as Log.Arg[];
    }
    set eventArgs(value: Log.Arg[]) {
        pb_1.Message.setRepeatedWrapperField(this, 10, value);
    }
    get decodedArgs() {
        return pb_1.Message.getFieldWithDefault(this, 11, []) as string[];
    }
    set decodedArgs(value: string[]) {
        pb_1.Message.setField(this, 11, value);
    }
    static fromObject(data?: Log.AsObjectPartial): Log {
        if (!data) {
            return new Log();
        }
        const message = new Log({});
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.txId != null) {
            message.txId = data.txId;
        }
        if (data.txIndex != null) {
            message.txIndex = data.txIndex;
        }
        if (data.txIndexInBc != null) {
            message.txIndexInBc = data.txIndexInBc;
        }
        if (data.indexInTx != null) {
            message.indexInTx = data.indexInTx;
        }
        if (data.indexInAddress != null) {
            message.indexInAddress = data.indexInAddress;
        }
        if (data.topics != null) {
            message.topics = data.topics;
        }
        if (data.data != null) {
            message.data = data.data;
        }
        if (data.eventName != null) {
            message.eventName = data.eventName;
        }
        if (data.eventArgs != null) {
            message.eventArgs = data.eventArgs.map(item => Log.Arg.fromObject(item));
        }
        if (data.decodedArgs != null) {
            message.decodedArgs = data.decodedArgs;
        }
        return message;
    }
    toObject() {
        const data: Log.AsObject = {
            address: this.address,
            txId: this.txId,
            txIndex: this.txIndex,
            txIndexInBc: this.txIndexInBc,
            indexInTx: this.indexInTx,
            indexInAddress: this.indexInAddress,
            topics: this.topics,
            data: this.data,
            eventName: this.eventName,
            eventArgs: this.eventArgs.map((item: Log.Arg) => item.toObject()),
            decodedArgs: this.decodedArgs
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.address.length)
            writer.writeString(1, this.address);
        if (this.txId.length)
            writer.writeString(2, this.txId);
        if (this.txIndex != 0)
            writer.writeInt64(3, this.txIndex);
        if (this.txIndexInBc != 0)
            writer.writeInt64(4, this.txIndexInBc);
        if (this.indexInTx != 0)
            writer.writeInt64(5, this.indexInTx);
        if (this.indexInAddress != 0)
            writer.writeInt64(6, this.indexInAddress);
        if (this.topics.length)
            writer.writeRepeatedString(7, this.topics);
        if (this.data.length)
            writer.writeString(8, this.data);
        if (this.eventName.length)
            writer.writeString(9, this.eventName);
        if (this.eventArgs.length)
            writer.writeRepeatedMessage(10, this.eventArgs, (item: Log.Arg) => item!.serialize(writer));
        if (this.decodedArgs.length)
            writer.writeRepeatedString(11, this.decodedArgs);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Log {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Log();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.address = reader.readString();
                    break;
                case 2:
                    message.txId = reader.readString();
                    break;
                case 3:
                    message.txIndex = reader.readInt64();
                    break;
                case 4:
                    message.txIndexInBc = reader.readInt64();
                    break;
                case 5:
                    message.indexInTx = reader.readInt64();
                    break;
                case 6:
                    message.indexInAddress = reader.readInt64();
                    break;
                case 7:
                    pb_1.Message.addToRepeatedField(message, 7, reader.readString());
                    break;
                case 8:
                    message.data = reader.readString();
                    break;
                case 9:
                    message.eventName = reader.readString();
                    break;
                case 10:
                    reader.readMessage(message.eventArgs, () => pb_1.Message.addToRepeatedWrapperField(message, 10, Log.Arg.deserialize(reader), Log.Arg));
                    break;
                case 11:
                    pb_1.Message.addToRepeatedField(message, 11, reader.readString());
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): Log {
        return Log.deserialize(bytes);
    }
}
export namespace Log {
    export type AsObject = {
        address: string;
        txId: string;
        txIndex: number;
        txIndexInBc: number;
        indexInTx: number;
        indexInAddress: number;
        topics: string[];
        data: string;
        eventName: string;
        eventArgs: Log.Arg.AsObject[];
        decodedArgs: string[];
    };
    export type AsObjectPartial = {
        address?: string;
        txId?: string;
        txIndex?: number;
        txIndexInBc?: number;
        indexInTx?: number;
        indexInAddress?: number;
        topics?: string[];
        data?: string;
        eventName?: string;
        eventArgs?: Log.Arg.AsObjectPartial[];
        decodedArgs?: string[];
    };
    export class Arg extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            name?: string;
            type?: string;
            indexed?: boolean;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("name" in data && data.name != undefined) {
                    this.name = data.name;
                }
                if ("type" in data && data.type != undefined) {
                    this.type = data.type;
                }
                if ("indexed" in data && data.indexed != undefined) {
                    this.indexed = data.indexed;
                }
            }
        }
        get name() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set name(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get type() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set type(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get indexed() {
            return pb_1.Message.getFieldWithDefault(this, 3, false) as boolean;
        }
        set indexed(value: boolean) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data?: Arg.AsObjectPartial): Arg {
            if (!data) {
                return new Arg();
            }
            const message = new Arg({});
            if (data.name != null) {
                message.name = data.name;
            }
            if (data.type != null) {
                message.type = data.type;
            }
            if (data.indexed != null) {
                message.indexed = data.indexed;
            }
            return message;
        }
        toObject() {
            const data: Arg.AsObject = {
                name: this.name,
                type: this.type,
                indexed: this.indexed
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.name.length)
                writer.writeString(1, this.name);
            if (this.type.length)
                writer.writeString(2, this.type);
            if (this.indexed != false)
                writer.writeBool(3, this.indexed);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Arg {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Arg();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.name = reader.readString();
                        break;
                    case 2:
                        message.type = reader.readString();
                        break;
                    case 3:
                        message.indexed = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Arg {
            return Arg.deserialize(bytes);
        }
    }
    export namespace Arg {
        export type AsObject = {
            name: string;
            type: string;
            indexed: boolean;
        };
        export type AsObjectPartial = {
            name?: string;
            type?: string;
            indexed?: boolean;
        };
    }
}
export class InternalTx extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        txId?: string;
        txIndex?: number;
        txIndexInBc?: number;
        indexInTx?: number;
        type?: string;
        from?: string;
        to?: string;
        value?: string;
        gas?: string;
        gasUsed?: string;
        input?: string;
        output?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("txId" in data && data.txId != undefined) {
                this.txId = data.txId;
            }
            if ("txIndex" in data && data.txIndex != undefined) {
                this.txIndex = data.txIndex;
            }
            if ("txIndexInBc" in data && data.txIndexInBc != undefined) {
                this.txIndexInBc = data.txIndexInBc;
            }
            if ("indexInTx" in data && data.indexInTx != undefined) {
                this.indexInTx = data.indexInTx;
            }
            if ("type" in data && data.type != undefined) {
                this.type = data.type;
            }
            if ("from" in data && data.from != undefined) {
                this.from = data.from;
            }
            if ("to" in data && data.to != undefined) {
                this.to = data.to;
            }
            if ("value" in data && data.value != undefined) {
                this.value = data.value;
            }
            if ("gas" in data && data.gas != undefined) {
                this.gas = data.gas;
            }
            if ("gasUsed" in data && data.gasUsed != undefined) {
                this.gasUsed = data.gasUsed;
            }
            if ("input" in data && data.input != undefined) {
                this.input = data.input;
            }
            if ("output" in data && data.output != undefined) {
                this.output = data.output;
            }
        }
    }
    get txId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set txId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get txIndex() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set txIndex(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get txIndexInBc() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set txIndexInBc(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get indexInTx() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set indexInTx(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get type() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set type(value: string) {
        pb_1.Message.setField(this, 5, value);
    }
    get from() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set from(value: string) {
        pb_1.Message.setField(this, 6, value);
    }
    get to() {
        return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
    }
    set to(value: string) {
        pb_1.Message.setField(this, 7, value);
    }
    get value() {
        return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
    }
    set value(value: string) {
        pb_1.Message.setField(this, 8, value);
    }
    get gas() {
        return pb_1.Message.getFieldWithDefault(this, 9, "") as string;
    }
    set gas(value: string) {
        pb_1.Message.setField(this, 9, value);
    }
    get gasUsed() {
        return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
    }
    set gasUsed(value: string) {
        pb_1.Message.setField(this, 10, value);
    }
    get input() {
        return pb_1.Message.getFieldWithDefault(this, 11, "") as string;
    }
    set input(value: string) {
        pb_1.Message.setField(this, 11, value);
    }
    get output() {
        return pb_1.Message.getFieldWithDefault(this, 12, "") as string;
    }
    set output(value: string) {
        pb_1.Message.setField(this, 12, value);
    }
    static fromObject(data?: InternalTx.AsObjectPartial): InternalTx {
        if (!data) {
            return new InternalTx();
        }
        const message = new InternalTx({});
        if (data.txId != null) {
            message.txId = data.txId;
        }
        if (data.txIndex != null) {
            message.txIndex = data.txIndex;
        }
        if (data.txIndexInBc != null) {
            message.txIndexInBc = data.txIndexInBc;
        }
        if (data.indexInTx != null) {
            message.indexInTx = data.indexInTx;
        }
        if (data.type != null) {
            message.type = data.type;
        }
        if (data.from != null) {
            message.from = data.from;
        }
        if (data.to != null) {
            message.to = data.to;
        }
        if (data.value != null) {
            message.value = data.value;
        }
        if (data.gas != null) {
            message.gas = data.gas;
        }
        if (data.gasUsed != null) {
            message.gasUsed = data.gasUsed;
        }
        if (data.input != null) {
            message.input = data.input;
        }
        if (data.output != null) {
            message.output = data.output;
        }
        return message;
    }
    toObject() {
        const data: InternalTx.AsObject = {
            txId: this.txId,
            txIndex: this.txIndex,
            txIndexInBc: this.txIndexInBc,
            indexInTx: this.indexInTx,
            type: this.type,
            from: this.from,
            to: this.to,
            value: this.value,
            gas: this.gas,
            gasUsed: this.gasUsed,
            input: this.input,
            output: this.output
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.txId.length)
            writer.writeString(1, this.txId);
        if (this.txIndex != 0)
            writer.writeInt64(2, this.txIndex);
        if (this.txIndexInBc != 0)
            writer.writeInt64(3, this.txIndexInBc);
        if (this.indexInTx != 0)
            writer.writeInt64(4, this.indexInTx);
        if (this.type.length)
            writer.writeString(5, this.type);
        if (this.from.length)
            writer.writeString(6, this.from);
        if (this.to.length)
            writer.writeString(7, this.to);
        if (this.value.length)
            writer.writeString(8, this.value);
        if (this.gas.length)
            writer.writeString(9, this.gas);
        if (this.gasUsed.length)
            writer.writeString(10, this.gasUsed);
        if (this.input.length)
            writer.writeString(11, this.input);
        if (this.output.length)
            writer.writeString(12, this.output);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InternalTx {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InternalTx();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.txId = reader.readString();
                    break;
                case 2:
                    message.txIndex = reader.readInt64();
                    break;
                case 3:
                    message.txIndexInBc = reader.readInt64();
                    break;
                case 4:
                    message.indexInTx = reader.readInt64();
                    break;
                case 5:
                    message.type = reader.readString();
                    break;
                case 6:
                    message.from = reader.readString();
                    break;
                case 7:
                    message.to = reader.readString();
                    break;
                case 8:
                    message.value = reader.readString();
                    break;
                case 9:
                    message.gas = reader.readString();
                    break;
                case 10:
                    message.gasUsed = reader.readString();
                    break;
                case 11:
                    message.input = reader.readString();
                    break;
                case 12:
                    message.output = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): InternalTx {
        return InternalTx.deserialize(bytes);
    }
}
export namespace InternalTx {
    export type AsObject = {
        txId: string;
        txIndex: number;
        txIndexInBc: number;
        indexInTx: number;
        type: string;
        from: string;
        to: string;
        value: string;
        gas: string;
        gasUsed: string;
        input: string;
        output: string;
    };
    export type AsObjectPartial = {
        txId?: string;
        txIndex?: number;
        txIndexInBc?: number;
        indexInTx?: number;
        type?: string;
        from?: string;
        to?: string;
        value?: string;
        gas?: string;
        gasUsed?: string;
        input?: string;
        output?: string;
    };
}
export class PlainAddressData extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        name?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
        }
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data?: PlainAddressData.AsObjectPartial): PlainAddressData {
        if (!data) {
            return new PlainAddressData();
        }
        const message = new PlainAddressData({});
        if (data.name != null) {
            message.name = data.name;
        }
        return message;
    }
    toObject() {
        const data: PlainAddressData.AsObject = {
            name: this.name
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.name.length)
            writer.writeString(1, this.name);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): PlainAddressData {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new PlainAddressData();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.name = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): PlainAddressData {
        return PlainAddressData.deserialize(bytes);
    }
}
export namespace PlainAddressData {
    export type AsObject = {
        name: string;
    };
    export type AsObjectPartial = {
        name?: string;
    };
}
export class ContractAddressData extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        name?: string;
        createdBy?: string;
        creationTxId?: string;
        creationTxIndex?: number;
        creationTxIndexInBc?: number;
        tokenTracker?: ContractAddressData.TokenTracker;
        kind?: ContractKind;
        abi?: string;
        sourcecode?: string;
        bytecode?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("createdBy" in data && data.createdBy != undefined) {
                this.createdBy = data.createdBy;
            }
            if ("creationTxId" in data && data.creationTxId != undefined) {
                this.creationTxId = data.creationTxId;
            }
            if ("creationTxIndex" in data && data.creationTxIndex != undefined) {
                this.creationTxIndex = data.creationTxIndex;
            }
            if ("creationTxIndexInBc" in data && data.creationTxIndexInBc != undefined) {
                this.creationTxIndexInBc = data.creationTxIndexInBc;
            }
            if ("tokenTracker" in data && data.tokenTracker != undefined) {
                this.tokenTracker = data.tokenTracker;
            }
            if ("kind" in data && data.kind != undefined) {
                this.kind = data.kind;
            }
            if ("abi" in data && data.abi != undefined) {
                this.abi = data.abi;
            }
            if ("sourcecode" in data && data.sourcecode != undefined) {
                this.sourcecode = data.sourcecode;
            }
            if ("bytecode" in data && data.bytecode != undefined) {
                this.bytecode = data.bytecode;
            }
        }
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get createdBy() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set createdBy(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get creationTxId() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set creationTxId(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get creationTxIndex() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set creationTxIndex(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get creationTxIndexInBc() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set creationTxIndexInBc(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get tokenTracker() {
        return pb_1.Message.getWrapperField(this, ContractAddressData.TokenTracker, 6) as ContractAddressData.TokenTracker | undefined;
    }
    set tokenTracker(value: ContractAddressData.TokenTracker | undefined) {
        pb_1.Message.setWrapperField(this, 6, value);
    }
    get hasTokenTracker() {
        return pb_1.Message.getField(this, 6) != null;
    }
    clearTokenTracker(): void {
        this.tokenTracker = undefined!;
    }
    get kind() {
        return pb_1.Message.getFieldWithDefault(this, 7, ContractKind.ContractKindUnknown) as ContractKind;
    }
    set kind(value: ContractKind) {
        pb_1.Message.setField(this, 7, value);
    }
    get abi() {
        return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
    }
    set abi(value: string) {
        pb_1.Message.setField(this, 8, value);
    }
    get sourcecode() {
        return pb_1.Message.getFieldWithDefault(this, 9, "") as string;
    }
    set sourcecode(value: string) {
        pb_1.Message.setField(this, 9, value);
    }
    get bytecode() {
        return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
    }
    set bytecode(value: string) {
        pb_1.Message.setField(this, 10, value);
    }
    static fromObject(data?: ContractAddressData.AsObjectPartial): ContractAddressData {
        if (!data) {
            return new ContractAddressData();
        }
        const message = new ContractAddressData({});
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.createdBy != null) {
            message.createdBy = data.createdBy;
        }
        if (data.creationTxId != null) {
            message.creationTxId = data.creationTxId;
        }
        if (data.creationTxIndex != null) {
            message.creationTxIndex = data.creationTxIndex;
        }
        if (data.creationTxIndexInBc != null) {
            message.creationTxIndexInBc = data.creationTxIndexInBc;
        }
        if (data.tokenTracker != null) {
            message.tokenTracker = ContractAddressData.TokenTracker.fromObject(data.tokenTracker);
        }
        if (data.kind != null) {
            message.kind = data.kind;
        }
        if (data.abi != null) {
            message.abi = data.abi;
        }
        if (data.sourcecode != null) {
            message.sourcecode = data.sourcecode;
        }
        if (data.bytecode != null) {
            message.bytecode = data.bytecode;
        }
        return message;
    }
    toObject() {
        const data: ContractAddressData.AsObject = {
            name: this.name,
            createdBy: this.createdBy,
            creationTxId: this.creationTxId,
            creationTxIndex: this.creationTxIndex,
            creationTxIndexInBc: this.creationTxIndexInBc,
            kind: this.kind,
            abi: this.abi,
            sourcecode: this.sourcecode,
            bytecode: this.bytecode
        };
        if (this.tokenTracker != null) {
            data.tokenTracker = this.tokenTracker.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.name.length)
            writer.writeString(1, this.name);
        if (this.createdBy.length)
            writer.writeString(2, this.createdBy);
        if (this.creationTxId.length)
            writer.writeString(3, this.creationTxId);
        if (this.creationTxIndex != 0)
            writer.writeInt64(4, this.creationTxIndex);
        if (this.creationTxIndexInBc != 0)
            writer.writeInt64(5, this.creationTxIndexInBc);
        if (this.hasTokenTracker)
            writer.writeMessage(6, this.tokenTracker, () => this.tokenTracker!.serialize(writer));
        if (this.kind != ContractKind.ContractKindUnknown)
            writer.writeEnum(7, this.kind);
        if (this.abi.length)
            writer.writeString(8, this.abi);
        if (this.sourcecode.length)
            writer.writeString(9, this.sourcecode);
        if (this.bytecode.length)
            writer.writeString(10, this.bytecode);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ContractAddressData {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ContractAddressData();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.name = reader.readString();
                    break;
                case 2:
                    message.createdBy = reader.readString();
                    break;
                case 3:
                    message.creationTxId = reader.readString();
                    break;
                case 4:
                    message.creationTxIndex = reader.readInt64();
                    break;
                case 5:
                    message.creationTxIndexInBc = reader.readInt64();
                    break;
                case 6:
                    reader.readMessage(message.tokenTracker, () => message.tokenTracker = ContractAddressData.TokenTracker.deserialize(reader));
                    break;
                case 7:
                    message.kind = reader.readEnum();
                    break;
                case 8:
                    message.abi = reader.readString();
                    break;
                case 9:
                    message.sourcecode = reader.readString();
                    break;
                case 10:
                    message.bytecode = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ContractAddressData {
        return ContractAddressData.deserialize(bytes);
    }
}
export namespace ContractAddressData {
    export type AsObject = {
        name: string;
        createdBy: string;
        creationTxId: string;
        creationTxIndex: number;
        creationTxIndexInBc: number;
        tokenTracker?: ContractAddressData.TokenTracker.AsObject;
        kind: ContractKind;
        abi: string;
        sourcecode: string;
        bytecode: string;
    };
    export type AsObjectPartial = {
        name?: string;
        createdBy?: string;
        creationTxId?: string;
        creationTxIndex?: number;
        creationTxIndexInBc?: number;
        tokenTracker?: ContractAddressData.TokenTracker.AsObjectPartial;
        kind?: ContractKind;
        abi?: string;
        sourcecode?: string;
        bytecode?: string;
    };
    export class TokenTracker extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            kind?: dependency_5.TokenKind;
            name?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("kind" in data && data.kind != undefined) {
                    this.kind = data.kind;
                }
                if ("name" in data && data.name != undefined) {
                    this.name = data.name;
                }
            }
        }
        get kind() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_5.TokenKind.TokenKindERC20) as dependency_5.TokenKind;
        }
        set kind(value: dependency_5.TokenKind) {
            pb_1.Message.setField(this, 1, value);
        }
        get name() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set name(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data?: TokenTracker.AsObjectPartial): TokenTracker {
            if (!data) {
                return new TokenTracker();
            }
            const message = new TokenTracker({});
            if (data.kind != null) {
                message.kind = data.kind;
            }
            if (data.name != null) {
                message.name = data.name;
            }
            return message;
        }
        toObject() {
            const data: TokenTracker.AsObject = {
                kind: this.kind,
                name: this.name
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.kind != dependency_5.TokenKind.TokenKindERC20)
                writer.writeEnum(1, this.kind);
            if (this.name.length)
                writer.writeString(2, this.name);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TokenTracker {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TokenTracker();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.kind = reader.readEnum();
                        break;
                    case 2:
                        message.name = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): TokenTracker {
            return TokenTracker.deserialize(bytes);
        }
    }
    export namespace TokenTracker {
        export type AsObject = {
            kind: dependency_5.TokenKind;
            name: string;
        };
        export type AsObjectPartial = {
            kind?: dependency_5.TokenKind;
            name?: string;
        };
    }
}
export class AddressDataEVM extends pb_1.Message {
    #one_of_decls: number[][] = [[3, 4]];
    constructor(data?: any[] | ({
        erc20Balances?: dependency_2.ERC20Balance[];
        erc721Balances?: dependency_3.ERC721Balance[];
        erc1155Balances?: dependency_4.ERC1155Balance[];
    } & (({
        plain?: PlainAddressData;
        contract?: never;
    } | {
        plain?: never;
        contract?: ContractAddressData;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [5, 6, 7], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("erc20Balances" in data && data.erc20Balances != undefined) {
                this.erc20Balances = data.erc20Balances;
            }
            if ("erc721Balances" in data && data.erc721Balances != undefined) {
                this.erc721Balances = data.erc721Balances;
            }
            if ("erc1155Balances" in data && data.erc1155Balances != undefined) {
                this.erc1155Balances = data.erc1155Balances;
            }
            if ("plain" in data && data.plain != undefined) {
                this.plain = data.plain;
            }
            if ("contract" in data && data.contract != undefined) {
                this.contract = data.contract;
            }
        }
    }
    get erc20Balances() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_2.ERC20Balance, 5) as dependency_2.ERC20Balance[];
    }
    set erc20Balances(value: dependency_2.ERC20Balance[]) {
        pb_1.Message.setRepeatedWrapperField(this, 5, value);
    }
    get erc721Balances() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_3.ERC721Balance, 6) as dependency_3.ERC721Balance[];
    }
    set erc721Balances(value: dependency_3.ERC721Balance[]) {
        pb_1.Message.setRepeatedWrapperField(this, 6, value);
    }
    get erc1155Balances() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_4.ERC1155Balance, 7) as dependency_4.ERC1155Balance[];
    }
    set erc1155Balances(value: dependency_4.ERC1155Balance[]) {
        pb_1.Message.setRepeatedWrapperField(this, 7, value);
    }
    get plain() {
        return pb_1.Message.getWrapperField(this, PlainAddressData, 3) as PlainAddressData | undefined;
    }
    set plain(value: PlainAddressData | undefined) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasPlain() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearPlain(): void {
        this.plain = undefined!;
    }
    get contract() {
        return pb_1.Message.getWrapperField(this, ContractAddressData, 4) as ContractAddressData | undefined;
    }
    set contract(value: ContractAddressData | undefined) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0]!, value);
    }
    get hasContract() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearContract(): void {
        this.contract = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "plain" | "contract";
        } = {
            0: "none",
            3: "plain",
            4: "contract"
        };
        return cases[pb_1.Message.computeOneofCase(this, [3, 4])]!;
    }
    static fromObject(data?: AddressDataEVM.AsObjectPartial): AddressDataEVM {
        if (!data) {
            return new AddressDataEVM();
        }
        const message = new AddressDataEVM({});
        if (data.erc20Balances != null) {
            message.erc20Balances = data.erc20Balances.map(item => dependency_2.ERC20Balance.fromObject(item));
        }
        if (data.erc721Balances != null) {
            message.erc721Balances = data.erc721Balances.map(item => dependency_3.ERC721Balance.fromObject(item));
        }
        if (data.erc1155Balances != null) {
            message.erc1155Balances = data.erc1155Balances.map(item => dependency_4.ERC1155Balance.fromObject(item));
        }
        if (data.plain != null) {
            message.plain = PlainAddressData.fromObject(data.plain);
        }
        if (data.contract != null) {
            message.contract = ContractAddressData.fromObject(data.contract);
        }
        return message;
    }
    toObject() {
        const data: AddressDataEVM.AsObject = {
            erc20Balances: this.erc20Balances.map((item: dependency_2.ERC20Balance) => item.toObject()),
            erc721Balances: this.erc721Balances.map((item: dependency_3.ERC721Balance) => item.toObject()),
            erc1155Balances: this.erc1155Balances.map((item: dependency_4.ERC1155Balance) => item.toObject())
        };
        if (this.plain != null) {
            data.plain = this.plain.toObject();
        }
        if (this.contract != null) {
            data.contract = this.contract.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.erc20Balances.length)
            writer.writeRepeatedMessage(5, this.erc20Balances, (item: dependency_2.ERC20Balance) => item!.serialize(writer));
        if (this.erc721Balances.length)
            writer.writeRepeatedMessage(6, this.erc721Balances, (item: dependency_3.ERC721Balance) => item!.serialize(writer));
        if (this.erc1155Balances.length)
            writer.writeRepeatedMessage(7, this.erc1155Balances, (item: dependency_4.ERC1155Balance) => item!.serialize(writer));
        if (this.hasPlain)
            writer.writeMessage(3, this.plain, () => this.plain!.serialize(writer));
        if (this.hasContract)
            writer.writeMessage(4, this.contract, () => this.contract!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AddressDataEVM {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AddressDataEVM();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 5:
                    reader.readMessage(message.erc20Balances, () => pb_1.Message.addToRepeatedWrapperField(message, 5, dependency_2.ERC20Balance.deserialize(reader), dependency_2.ERC20Balance));
                    break;
                case 6:
                    reader.readMessage(message.erc721Balances, () => pb_1.Message.addToRepeatedWrapperField(message, 6, dependency_3.ERC721Balance.deserialize(reader), dependency_3.ERC721Balance));
                    break;
                case 7:
                    reader.readMessage(message.erc1155Balances, () => pb_1.Message.addToRepeatedWrapperField(message, 7, dependency_4.ERC1155Balance.deserialize(reader), dependency_4.ERC1155Balance));
                    break;
                case 3:
                    reader.readMessage(message.plain, () => message.plain = PlainAddressData.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.contract, () => message.contract = ContractAddressData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AddressDataEVM {
        return AddressDataEVM.deserialize(bytes);
    }
}
export namespace AddressDataEVM {
    export type AsObject = {
        erc20Balances: dependency_2.ERC20Balance.AsObject[];
        erc721Balances: dependency_3.ERC721Balance.AsObject[];
        erc1155Balances: dependency_4.ERC1155Balance.AsObject[];
        plain?: PlainAddressData.AsObject;
        contract?: ContractAddressData.AsObject;
    };
    export type AsObjectPartial = {
        erc20Balances?: dependency_2.ERC20Balance.AsObjectPartial[];
        erc721Balances?: dependency_3.ERC721Balance.AsObjectPartial[];
        erc1155Balances?: dependency_4.ERC1155Balance.AsObjectPartial[];
        plain?: PlainAddressData.AsObjectPartial;
        contract?: ContractAddressData.AsObjectPartial;
    };
}
export class ContractsAddRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        contracts?: ContractsAddRequest.Contract[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("contracts" in data && data.contracts != undefined) {
                this.contracts = data.contracts;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get contracts() {
        return pb_1.Message.getRepeatedWrapperField(this, ContractsAddRequest.Contract, 2) as ContractsAddRequest.Contract[];
    }
    set contracts(value: ContractsAddRequest.Contract[]) {
        pb_1.Message.setRepeatedWrapperField(this, 2, value);
    }
    static fromObject(data?: ContractsAddRequest.AsObjectPartial): ContractsAddRequest {
        if (!data) {
            return new ContractsAddRequest();
        }
        const message = new ContractsAddRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.contracts != null) {
            message.contracts = data.contracts.map(item => ContractsAddRequest.Contract.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: ContractsAddRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            contracts: this.contracts.map((item: ContractsAddRequest.Contract) => item.toObject())
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.contracts.length)
            writer.writeRepeatedMessage(2, this.contracts, (item: ContractsAddRequest.Contract) => item!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ContractsAddRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ContractsAddRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    reader.readMessage(message.contracts, () => pb_1.Message.addToRepeatedWrapperField(message, 2, ContractsAddRequest.Contract.deserialize(reader), ContractsAddRequest.Contract));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ContractsAddRequest {
        return ContractsAddRequest.deserialize(bytes);
    }
}
export namespace ContractsAddRequest {
    export type AsObject = {
        blockchainIndex: number;
        contracts: ContractsAddRequest.Contract.AsObject[];
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        contracts?: ContractsAddRequest.Contract.AsObjectPartial[];
    };
    export class Contract extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            address?: string;
            contract?: ContractAddressData;
            txCount?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("address" in data && data.address != undefined) {
                    this.address = data.address;
                }
                if ("contract" in data && data.contract != undefined) {
                    this.contract = data.contract;
                }
                if ("txCount" in data && data.txCount != undefined) {
                    this.txCount = data.txCount;
                }
            }
        }
        get address() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set address(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get contract() {
            return pb_1.Message.getWrapperField(this, ContractAddressData, 2) as ContractAddressData | undefined;
        }
        set contract(value: ContractAddressData | undefined) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get hasContract() {
            return pb_1.Message.getField(this, 2) != null;
        }
        clearContract(): void {
            this.contract = undefined!;
        }
        get txCount() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set txCount(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data?: Contract.AsObjectPartial): Contract {
            if (!data) {
                return new Contract();
            }
            const message = new Contract({});
            if (data.address != null) {
                message.address = data.address;
            }
            if (data.contract != null) {
                message.contract = ContractAddressData.fromObject(data.contract);
            }
            if (data.txCount != null) {
                message.txCount = data.txCount;
            }
            return message;
        }
        toObject() {
            const data: Contract.AsObject = {
                address: this.address,
                txCount: this.txCount
            };
            if (this.contract != null) {
                data.contract = this.contract.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.address.length)
                writer.writeString(1, this.address);
            if (this.hasContract)
                writer.writeMessage(2, this.contract, () => this.contract!.serialize(writer));
            if (this.txCount != 0)
                writer.writeInt64(3, this.txCount);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Contract {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Contract();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.address = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.contract, () => message.contract = ContractAddressData.deserialize(reader));
                        break;
                    case 3:
                        message.txCount = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Contract {
            return Contract.deserialize(bytes);
        }
    }
    export namespace Contract {
        export type AsObject = {
            address: string;
            contract?: ContractAddressData.AsObject;
            txCount: number;
        };
        export type AsObjectPartial = {
            address?: string;
            contract?: ContractAddressData.AsObjectPartial;
            txCount?: number;
        };
    }
}
export class LogListRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[2, 3]];
    constructor(data?: any[] | ({
        blockchainIndex?: number;
        page?: dependency_5.Page;
    } & (({
        txIndex?: number;
        address?: never;
    } | {
        txIndex?: never;
        address?: string;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("txIndex" in data && data.txIndex != undefined) {
                this.txIndex = data.txIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get txIndex() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set txIndex(value: number) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasTxIndex() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearTxIndex(): void {
        this.txIndex = undefined!;
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setOneofField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasAddress() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearAddress(): void {
        this.address = undefined!;
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_5.Page, 4) as dependency_5.Page | undefined;
    }
    set page(value: dependency_5.Page | undefined) {
        pb_1.Message.setWrapperField(this, 4, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "txIndex" | "address";
        } = {
            0: "none",
            2: "txIndex",
            3: "address"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2, 3])]!;
    }
    static fromObject(data?: LogListRequest.AsObjectPartial): LogListRequest {
        if (!data) {
            return new LogListRequest();
        }
        const message = new LogListRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.txIndex != null) {
            message.txIndex = data.txIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.page != null) {
            message.page = dependency_5.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: LogListRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            txIndex: this.txIndex,
            address: this.address
        };
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.hasTxIndex)
            writer.writeInt64(2, this.txIndex);
        if (this.hasAddress)
            writer.writeString(3, this.address);
        if (this.hasPage)
            writer.writeMessage(4, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): LogListRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new LogListRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.txIndex = reader.readInt64();
                    break;
                case 3:
                    message.address = reader.readString();
                    break;
                case 4:
                    reader.readMessage(message.page, () => message.page = dependency_5.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): LogListRequest {
        return LogListRequest.deserialize(bytes);
    }
}
export namespace LogListRequest {
    export type AsObject = {
        blockchainIndex: number;
        txIndex: number;
        address: string;
        page?: dependency_5.Page.AsObject;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        txIndex?: number;
        address?: string;
        page?: dependency_5.Page.AsObjectPartial;
    };
}
export class LogListResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        logs?: Log[];
        navigation?: dependency_5.NavigationData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("logs" in data && data.logs != undefined) {
                this.logs = data.logs;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
        }
    }
    get logs() {
        return pb_1.Message.getRepeatedWrapperField(this, Log, 1) as Log[];
    }
    set logs(value: Log[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_5.NavigationData, 2) as dependency_5.NavigationData | undefined;
    }
    set navigation(value: dependency_5.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    static fromObject(data?: LogListResponse.AsObjectPartial): LogListResponse {
        if (!data) {
            return new LogListResponse();
        }
        const message = new LogListResponse({});
        if (data.logs != null) {
            message.logs = data.logs.map(item => Log.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_5.NavigationData.fromObject(data.navigation);
        }
        return message;
    }
    toObject() {
        const data: LogListResponse.AsObject = {
            logs: this.logs.map((item: Log) => item.toObject())
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.logs.length)
            writer.writeRepeatedMessage(1, this.logs, (item: Log) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(2, this.navigation, () => this.navigation!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): LogListResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new LogListResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.logs, () => pb_1.Message.addToRepeatedWrapperField(message, 1, Log.deserialize(reader), Log));
                    break;
                case 2:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_5.NavigationData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): LogListResponse {
        return LogListResponse.deserialize(bytes);
    }
}
export namespace LogListResponse {
    export type AsObject = {
        logs: Log.AsObject[];
        navigation?: dependency_5.NavigationData.AsObject;
    };
    export type AsObjectPartial = {
        logs?: Log.AsObjectPartial[];
        navigation?: dependency_5.NavigationData.AsObjectPartial;
    };
}
export class InternalTransactionListRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        txIndex?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("txIndex" in data && data.txIndex != undefined) {
                this.txIndex = data.txIndex;
            }
        }
    }
    get txIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set txIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data?: InternalTransactionListRequest.AsObjectPartial): InternalTransactionListRequest {
        if (!data) {
            return new InternalTransactionListRequest();
        }
        const message = new InternalTransactionListRequest({});
        if (data.txIndex != null) {
            message.txIndex = data.txIndex;
        }
        return message;
    }
    toObject() {
        const data: InternalTransactionListRequest.AsObject = {
            txIndex: this.txIndex
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.txIndex != 0)
            writer.writeInt64(1, this.txIndex);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InternalTransactionListRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InternalTransactionListRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.txIndex = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): InternalTransactionListRequest {
        return InternalTransactionListRequest.deserialize(bytes);
    }
}
export namespace InternalTransactionListRequest {
    export type AsObject = {
        txIndex: number;
    };
    export type AsObjectPartial = {
        txIndex?: number;
    };
}
export class InternalTransactionListResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        internalTransactions?: InternalTx[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("internalTransactions" in data && data.internalTransactions != undefined) {
                this.internalTransactions = data.internalTransactions;
            }
        }
    }
    get internalTransactions() {
        return pb_1.Message.getRepeatedWrapperField(this, InternalTx, 1) as InternalTx[];
    }
    set internalTransactions(value: InternalTx[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data?: InternalTransactionListResponse.AsObjectPartial): InternalTransactionListResponse {
        if (!data) {
            return new InternalTransactionListResponse();
        }
        const message = new InternalTransactionListResponse({});
        if (data.internalTransactions != null) {
            message.internalTransactions = data.internalTransactions.map(item => InternalTx.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: InternalTransactionListResponse.AsObject = {
            internalTransactions: this.internalTransactions.map((item: InternalTx) => item.toObject())
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.internalTransactions.length)
            writer.writeRepeatedMessage(1, this.internalTransactions, (item: InternalTx) => item!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InternalTransactionListResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InternalTransactionListResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.internalTransactions, () => pb_1.Message.addToRepeatedWrapperField(message, 1, InternalTx.deserialize(reader), InternalTx));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): InternalTransactionListResponse {
        return InternalTransactionListResponse.deserialize(bytes);
    }
}
export namespace InternalTransactionListResponse {
    export type AsObject = {
        internalTransactions: InternalTx.AsObject[];
    };
    export type AsObjectPartial = {
        internalTransactions?: InternalTx.AsObjectPartial[];
    };
}
