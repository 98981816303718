/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.13.0
 * source: token.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./validate/validate";
import * as dependency_2 from "./erc20";
import * as dependency_3 from "./erc721";
import * as dependency_4 from "./erc1155";
import * as dependency_5 from "./models";
import * as pb_1 from "google-protobuf";
export class Token extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3]];
    constructor(data?: any[] | ({} & (({
        erc20?: dependency_2.ERC20Token;
        erc721?: never;
        erc1155?: never;
    } | {
        erc20?: never;
        erc721?: dependency_3.ERC721Token;
        erc1155?: never;
    } | {
        erc20?: never;
        erc721?: never;
        erc1155?: dependency_4.ERC1155Token;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("erc20" in data && data.erc20 != undefined) {
                this.erc20 = data.erc20;
            }
            if ("erc721" in data && data.erc721 != undefined) {
                this.erc721 = data.erc721;
            }
            if ("erc1155" in data && data.erc1155 != undefined) {
                this.erc1155 = data.erc1155;
            }
        }
    }
    get erc20() {
        return pb_1.Message.getWrapperField(this, dependency_2.ERC20Token, 1) as dependency_2.ERC20Token | undefined;
    }
    set erc20(value: dependency_2.ERC20Token | undefined) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0]!, value);
    }
    get hasErc20() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearErc20(): void {
        this.erc20 = undefined!;
    }
    get erc721() {
        return pb_1.Message.getWrapperField(this, dependency_3.ERC721Token, 2) as dependency_3.ERC721Token | undefined;
    }
    set erc721(value: dependency_3.ERC721Token | undefined) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasErc721() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearErc721(): void {
        this.erc721 = undefined!;
    }
    get erc1155() {
        return pb_1.Message.getWrapperField(this, dependency_4.ERC1155Token, 3) as dependency_4.ERC1155Token | undefined;
    }
    set erc1155(value: dependency_4.ERC1155Token | undefined) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasErc1155() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearErc1155(): void {
        this.erc1155 = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "erc20" | "erc721" | "erc1155";
        } = {
            0: "none",
            1: "erc20",
            2: "erc721",
            3: "erc1155"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3])]!;
    }
    static fromObject(data?: Token.AsObjectPartial): Token {
        if (!data) {
            return new Token();
        }
        const message = new Token({});
        if (data.erc20 != null) {
            message.erc20 = dependency_2.ERC20Token.fromObject(data.erc20);
        }
        if (data.erc721 != null) {
            message.erc721 = dependency_3.ERC721Token.fromObject(data.erc721);
        }
        if (data.erc1155 != null) {
            message.erc1155 = dependency_4.ERC1155Token.fromObject(data.erc1155);
        }
        return message;
    }
    toObject() {
        const data: Token.AsObject = {};
        if (this.erc20 != null) {
            data.erc20 = this.erc20.toObject();
        }
        if (this.erc721 != null) {
            data.erc721 = this.erc721.toObject();
        }
        if (this.erc1155 != null) {
            data.erc1155 = this.erc1155.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasErc20)
            writer.writeMessage(1, this.erc20, () => this.erc20!.serialize(writer));
        if (this.hasErc721)
            writer.writeMessage(2, this.erc721, () => this.erc721!.serialize(writer));
        if (this.hasErc1155)
            writer.writeMessage(3, this.erc1155, () => this.erc1155!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Token {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Token();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.erc20, () => message.erc20 = dependency_2.ERC20Token.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.erc721, () => message.erc721 = dependency_3.ERC721Token.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.erc1155, () => message.erc1155 = dependency_4.ERC1155Token.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): Token {
        return Token.deserialize(bytes);
    }
}
export namespace Token {
    export type AsObject = {
        erc20?: dependency_2.ERC20Token.AsObject;
        erc721?: dependency_3.ERC721Token.AsObject;
        erc1155?: dependency_4.ERC1155Token.AsObject;
    };
    export type AsObjectPartial = {
        erc20?: dependency_2.ERC20Token.AsObjectPartial;
        erc721?: dependency_3.ERC721Token.AsObjectPartial;
        erc1155?: dependency_4.ERC1155Token.AsObjectPartial;
    };
}
export class TokenDetails extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3]];
    constructor(data?: any[] | ({} & (({
        erc20?: dependency_2.ERC20TokenDetails;
        erc721?: never;
        erc1155?: never;
    } | {
        erc20?: never;
        erc721?: dependency_3.ERC721TokenDetails;
        erc1155?: never;
    } | {
        erc20?: never;
        erc721?: never;
        erc1155?: dependency_4.ERC1155TokenDetails;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("erc20" in data && data.erc20 != undefined) {
                this.erc20 = data.erc20;
            }
            if ("erc721" in data && data.erc721 != undefined) {
                this.erc721 = data.erc721;
            }
            if ("erc1155" in data && data.erc1155 != undefined) {
                this.erc1155 = data.erc1155;
            }
        }
    }
    get erc20() {
        return pb_1.Message.getWrapperField(this, dependency_2.ERC20TokenDetails, 1) as dependency_2.ERC20TokenDetails | undefined;
    }
    set erc20(value: dependency_2.ERC20TokenDetails | undefined) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0]!, value);
    }
    get hasErc20() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearErc20(): void {
        this.erc20 = undefined!;
    }
    get erc721() {
        return pb_1.Message.getWrapperField(this, dependency_3.ERC721TokenDetails, 2) as dependency_3.ERC721TokenDetails | undefined;
    }
    set erc721(value: dependency_3.ERC721TokenDetails | undefined) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasErc721() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearErc721(): void {
        this.erc721 = undefined!;
    }
    get erc1155() {
        return pb_1.Message.getWrapperField(this, dependency_4.ERC1155TokenDetails, 3) as dependency_4.ERC1155TokenDetails | undefined;
    }
    set erc1155(value: dependency_4.ERC1155TokenDetails | undefined) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasErc1155() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearErc1155(): void {
        this.erc1155 = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "erc20" | "erc721" | "erc1155";
        } = {
            0: "none",
            1: "erc20",
            2: "erc721",
            3: "erc1155"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3])]!;
    }
    static fromObject(data?: TokenDetails.AsObjectPartial): TokenDetails {
        if (!data) {
            return new TokenDetails();
        }
        const message = new TokenDetails({});
        if (data.erc20 != null) {
            message.erc20 = dependency_2.ERC20TokenDetails.fromObject(data.erc20);
        }
        if (data.erc721 != null) {
            message.erc721 = dependency_3.ERC721TokenDetails.fromObject(data.erc721);
        }
        if (data.erc1155 != null) {
            message.erc1155 = dependency_4.ERC1155TokenDetails.fromObject(data.erc1155);
        }
        return message;
    }
    toObject() {
        const data: TokenDetails.AsObject = {};
        if (this.erc20 != null) {
            data.erc20 = this.erc20.toObject();
        }
        if (this.erc721 != null) {
            data.erc721 = this.erc721.toObject();
        }
        if (this.erc1155 != null) {
            data.erc1155 = this.erc1155.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasErc20)
            writer.writeMessage(1, this.erc20, () => this.erc20!.serialize(writer));
        if (this.hasErc721)
            writer.writeMessage(2, this.erc721, () => this.erc721!.serialize(writer));
        if (this.hasErc1155)
            writer.writeMessage(3, this.erc1155, () => this.erc1155!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TokenDetails {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TokenDetails();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.erc20, () => message.erc20 = dependency_2.ERC20TokenDetails.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.erc721, () => message.erc721 = dependency_3.ERC721TokenDetails.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.erc1155, () => message.erc1155 = dependency_4.ERC1155TokenDetails.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TokenDetails {
        return TokenDetails.deserialize(bytes);
    }
}
export namespace TokenDetails {
    export type AsObject = {
        erc20?: dependency_2.ERC20TokenDetails.AsObject;
        erc721?: dependency_3.ERC721TokenDetails.AsObject;
        erc1155?: dependency_4.ERC1155TokenDetails.AsObject;
    };
    export type AsObjectPartial = {
        erc20?: dependency_2.ERC20TokenDetails.AsObjectPartial;
        erc721?: dependency_3.ERC721TokenDetails.AsObjectPartial;
        erc1155?: dependency_4.ERC1155TokenDetails.AsObjectPartial;
    };
}
export class Transfer extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3]];
    constructor(data?: any[] | ({} & (({
        erc20?: dependency_2.ERC20Transfer;
        erc721?: never;
        erc1155?: never;
    } | {
        erc20?: never;
        erc721?: dependency_3.ERC721Transfer;
        erc1155?: never;
    } | {
        erc20?: never;
        erc721?: never;
        erc1155?: dependency_4.ERC1155Transfer;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("erc20" in data && data.erc20 != undefined) {
                this.erc20 = data.erc20;
            }
            if ("erc721" in data && data.erc721 != undefined) {
                this.erc721 = data.erc721;
            }
            if ("erc1155" in data && data.erc1155 != undefined) {
                this.erc1155 = data.erc1155;
            }
        }
    }
    get erc20() {
        return pb_1.Message.getWrapperField(this, dependency_2.ERC20Transfer, 1) as dependency_2.ERC20Transfer | undefined;
    }
    set erc20(value: dependency_2.ERC20Transfer | undefined) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0]!, value);
    }
    get hasErc20() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearErc20(): void {
        this.erc20 = undefined!;
    }
    get erc721() {
        return pb_1.Message.getWrapperField(this, dependency_3.ERC721Transfer, 2) as dependency_3.ERC721Transfer | undefined;
    }
    set erc721(value: dependency_3.ERC721Transfer | undefined) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasErc721() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearErc721(): void {
        this.erc721 = undefined!;
    }
    get erc1155() {
        return pb_1.Message.getWrapperField(this, dependency_4.ERC1155Transfer, 3) as dependency_4.ERC1155Transfer | undefined;
    }
    set erc1155(value: dependency_4.ERC1155Transfer | undefined) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasErc1155() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearErc1155(): void {
        this.erc1155 = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "erc20" | "erc721" | "erc1155";
        } = {
            0: "none",
            1: "erc20",
            2: "erc721",
            3: "erc1155"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3])]!;
    }
    static fromObject(data?: Transfer.AsObjectPartial): Transfer {
        if (!data) {
            return new Transfer();
        }
        const message = new Transfer({});
        if (data.erc20 != null) {
            message.erc20 = dependency_2.ERC20Transfer.fromObject(data.erc20);
        }
        if (data.erc721 != null) {
            message.erc721 = dependency_3.ERC721Transfer.fromObject(data.erc721);
        }
        if (data.erc1155 != null) {
            message.erc1155 = dependency_4.ERC1155Transfer.fromObject(data.erc1155);
        }
        return message;
    }
    toObject() {
        const data: Transfer.AsObject = {};
        if (this.erc20 != null) {
            data.erc20 = this.erc20.toObject();
        }
        if (this.erc721 != null) {
            data.erc721 = this.erc721.toObject();
        }
        if (this.erc1155 != null) {
            data.erc1155 = this.erc1155.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasErc20)
            writer.writeMessage(1, this.erc20, () => this.erc20!.serialize(writer));
        if (this.hasErc721)
            writer.writeMessage(2, this.erc721, () => this.erc721!.serialize(writer));
        if (this.hasErc1155)
            writer.writeMessage(3, this.erc1155, () => this.erc1155!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Transfer {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Transfer();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.erc20, () => message.erc20 = dependency_2.ERC20Transfer.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.erc721, () => message.erc721 = dependency_3.ERC721Transfer.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.erc1155, () => message.erc1155 = dependency_4.ERC1155Transfer.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): Transfer {
        return Transfer.deserialize(bytes);
    }
}
export namespace Transfer {
    export type AsObject = {
        erc20?: dependency_2.ERC20Transfer.AsObject;
        erc721?: dependency_3.ERC721Transfer.AsObject;
        erc1155?: dependency_4.ERC1155Transfer.AsObject;
    };
    export type AsObjectPartial = {
        erc20?: dependency_2.ERC20Transfer.AsObjectPartial;
        erc721?: dependency_3.ERC721Transfer.AsObjectPartial;
        erc1155?: dependency_4.ERC1155Transfer.AsObjectPartial;
    };
}
export class Holder extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3]];
    constructor(data?: any[] | ({} & (({
        erc20?: dependency_2.ERC20Holder;
        erc721?: never;
        erc1155?: never;
    } | {
        erc20?: never;
        erc721?: dependency_3.ERC721Holder;
        erc1155?: never;
    } | {
        erc20?: never;
        erc721?: never;
        erc1155?: dependency_4.ERC1155Holder;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("erc20" in data && data.erc20 != undefined) {
                this.erc20 = data.erc20;
            }
            if ("erc721" in data && data.erc721 != undefined) {
                this.erc721 = data.erc721;
            }
            if ("erc1155" in data && data.erc1155 != undefined) {
                this.erc1155 = data.erc1155;
            }
        }
    }
    get erc20() {
        return pb_1.Message.getWrapperField(this, dependency_2.ERC20Holder, 1) as dependency_2.ERC20Holder | undefined;
    }
    set erc20(value: dependency_2.ERC20Holder | undefined) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0]!, value);
    }
    get hasErc20() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearErc20(): void {
        this.erc20 = undefined!;
    }
    get erc721() {
        return pb_1.Message.getWrapperField(this, dependency_3.ERC721Holder, 2) as dependency_3.ERC721Holder | undefined;
    }
    set erc721(value: dependency_3.ERC721Holder | undefined) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasErc721() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearErc721(): void {
        this.erc721 = undefined!;
    }
    get erc1155() {
        return pb_1.Message.getWrapperField(this, dependency_4.ERC1155Holder, 3) as dependency_4.ERC1155Holder | undefined;
    }
    set erc1155(value: dependency_4.ERC1155Holder | undefined) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasErc1155() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearErc1155(): void {
        this.erc1155 = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "erc20" | "erc721" | "erc1155";
        } = {
            0: "none",
            1: "erc20",
            2: "erc721",
            3: "erc1155"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3])]!;
    }
    static fromObject(data?: Holder.AsObjectPartial): Holder {
        if (!data) {
            return new Holder();
        }
        const message = new Holder({});
        if (data.erc20 != null) {
            message.erc20 = dependency_2.ERC20Holder.fromObject(data.erc20);
        }
        if (data.erc721 != null) {
            message.erc721 = dependency_3.ERC721Holder.fromObject(data.erc721);
        }
        if (data.erc1155 != null) {
            message.erc1155 = dependency_4.ERC1155Holder.fromObject(data.erc1155);
        }
        return message;
    }
    toObject() {
        const data: Holder.AsObject = {};
        if (this.erc20 != null) {
            data.erc20 = this.erc20.toObject();
        }
        if (this.erc721 != null) {
            data.erc721 = this.erc721.toObject();
        }
        if (this.erc1155 != null) {
            data.erc1155 = this.erc1155.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasErc20)
            writer.writeMessage(1, this.erc20, () => this.erc20!.serialize(writer));
        if (this.hasErc721)
            writer.writeMessage(2, this.erc721, () => this.erc721!.serialize(writer));
        if (this.hasErc1155)
            writer.writeMessage(3, this.erc1155, () => this.erc1155!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Holder {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Holder();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.erc20, () => message.erc20 = dependency_2.ERC20Holder.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.erc721, () => message.erc721 = dependency_3.ERC721Holder.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.erc1155, () => message.erc1155 = dependency_4.ERC1155Holder.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): Holder {
        return Holder.deserialize(bytes);
    }
}
export namespace Holder {
    export type AsObject = {
        erc20?: dependency_2.ERC20Holder.AsObject;
        erc721?: dependency_3.ERC721Holder.AsObject;
        erc1155?: dependency_4.ERC1155Holder.AsObject;
    };
    export type AsObjectPartial = {
        erc20?: dependency_2.ERC20Holder.AsObjectPartial;
        erc721?: dependency_3.ERC721Holder.AsObjectPartial;
        erc1155?: dependency_4.ERC1155Holder.AsObjectPartial;
    };
}
export class NFT extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2]];
    constructor(data?: any[] | ({} & (({
        erc721?: dependency_3.ERC721NFT;
        erc1155?: never;
    } | {
        erc721?: never;
        erc1155?: dependency_4.ERC1155NFT;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("erc721" in data && data.erc721 != undefined) {
                this.erc721 = data.erc721;
            }
            if ("erc1155" in data && data.erc1155 != undefined) {
                this.erc1155 = data.erc1155;
            }
        }
    }
    get erc721() {
        return pb_1.Message.getWrapperField(this, dependency_3.ERC721NFT, 1) as dependency_3.ERC721NFT | undefined;
    }
    set erc721(value: dependency_3.ERC721NFT | undefined) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0]!, value);
    }
    get hasErc721() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearErc721(): void {
        this.erc721 = undefined!;
    }
    get erc1155() {
        return pb_1.Message.getWrapperField(this, dependency_4.ERC1155NFT, 2) as dependency_4.ERC1155NFT | undefined;
    }
    set erc1155(value: dependency_4.ERC1155NFT | undefined) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasErc1155() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearErc1155(): void {
        this.erc1155 = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "erc721" | "erc1155";
        } = {
            0: "none",
            1: "erc721",
            2: "erc1155"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2])]!;
    }
    static fromObject(data?: NFT.AsObjectPartial): NFT {
        if (!data) {
            return new NFT();
        }
        const message = new NFT({});
        if (data.erc721 != null) {
            message.erc721 = dependency_3.ERC721NFT.fromObject(data.erc721);
        }
        if (data.erc1155 != null) {
            message.erc1155 = dependency_4.ERC1155NFT.fromObject(data.erc1155);
        }
        return message;
    }
    toObject() {
        const data: NFT.AsObject = {};
        if (this.erc721 != null) {
            data.erc721 = this.erc721.toObject();
        }
        if (this.erc1155 != null) {
            data.erc1155 = this.erc1155.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasErc721)
            writer.writeMessage(1, this.erc721, () => this.erc721!.serialize(writer));
        if (this.hasErc1155)
            writer.writeMessage(2, this.erc1155, () => this.erc1155!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NFT {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NFT();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.erc721, () => message.erc721 = dependency_3.ERC721NFT.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.erc1155, () => message.erc1155 = dependency_4.ERC1155NFT.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): NFT {
        return NFT.deserialize(bytes);
    }
}
export namespace NFT {
    export type AsObject = {
        erc721?: dependency_3.ERC721NFT.AsObject;
        erc1155?: dependency_4.ERC1155NFT.AsObject;
    };
    export type AsObjectPartial = {
        erc721?: dependency_3.ERC721NFT.AsObjectPartial;
        erc1155?: dependency_4.ERC1155NFT.AsObjectPartial;
    };
}
export class TokenListRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        page?: dependency_5.Page;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_5.Page, 2) as dependency_5.Page | undefined;
    }
    set page(value: dependency_5.Page | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    static fromObject(data?: TokenListRequest.AsObjectPartial): TokenListRequest {
        if (!data) {
            return new TokenListRequest();
        }
        const message = new TokenListRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.page != null) {
            message.page = dependency_5.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: TokenListRequest.AsObject = {
            blockchainIndex: this.blockchainIndex
        };
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.hasPage)
            writer.writeMessage(2, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TokenListRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TokenListRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    reader.readMessage(message.page, () => message.page = dependency_5.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TokenListRequest {
        return TokenListRequest.deserialize(bytes);
    }
}
export namespace TokenListRequest {
    export type AsObject = {
        blockchainIndex: number;
        page?: dependency_5.Page.AsObject;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        page?: dependency_5.Page.AsObjectPartial;
    };
}
export class TokenListResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        tokens?: Token[];
        navigation?: dependency_5.NavigationData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("tokens" in data && data.tokens != undefined) {
                this.tokens = data.tokens;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
        }
    }
    get tokens() {
        return pb_1.Message.getRepeatedWrapperField(this, Token, 1) as Token[];
    }
    set tokens(value: Token[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_5.NavigationData, 2) as dependency_5.NavigationData | undefined;
    }
    set navigation(value: dependency_5.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    static fromObject(data?: TokenListResponse.AsObjectPartial): TokenListResponse {
        if (!data) {
            return new TokenListResponse();
        }
        const message = new TokenListResponse({});
        if (data.tokens != null) {
            message.tokens = data.tokens.map(item => Token.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_5.NavigationData.fromObject(data.navigation);
        }
        return message;
    }
    toObject() {
        const data: TokenListResponse.AsObject = {
            tokens: this.tokens.map((item: Token) => item.toObject())
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.tokens.length)
            writer.writeRepeatedMessage(1, this.tokens, (item: Token) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(2, this.navigation, () => this.navigation!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TokenListResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TokenListResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.tokens, () => pb_1.Message.addToRepeatedWrapperField(message, 1, Token.deserialize(reader), Token));
                    break;
                case 2:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_5.NavigationData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TokenListResponse {
        return TokenListResponse.deserialize(bytes);
    }
}
export namespace TokenListResponse {
    export type AsObject = {
        tokens: Token.AsObject[];
        navigation?: dependency_5.NavigationData.AsObject;
    };
    export type AsObjectPartial = {
        tokens?: Token.AsObjectPartial[];
        navigation?: dependency_5.NavigationData.AsObjectPartial;
    };
}
export class TokenDetailsRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        address?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: TokenDetailsRequest.AsObjectPartial): TokenDetailsRequest {
        if (!data) {
            return new TokenDetailsRequest();
        }
        const message = new TokenDetailsRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        return message;
    }
    toObject() {
        const data: TokenDetailsRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TokenDetailsRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TokenDetailsRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TokenDetailsRequest {
        return TokenDetailsRequest.deserialize(bytes);
    }
}
export namespace TokenDetailsRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
    };
}
export class TokenDetailsResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        details?: TokenDetails;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("details" in data && data.details != undefined) {
                this.details = data.details;
            }
        }
    }
    get details() {
        return pb_1.Message.getWrapperField(this, TokenDetails, 1) as TokenDetails | undefined;
    }
    set details(value: TokenDetails | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasDetails() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearDetails(): void {
        this.details = undefined!;
    }
    static fromObject(data?: TokenDetailsResponse.AsObjectPartial): TokenDetailsResponse {
        if (!data) {
            return new TokenDetailsResponse();
        }
        const message = new TokenDetailsResponse({});
        if (data.details != null) {
            message.details = TokenDetails.fromObject(data.details);
        }
        return message;
    }
    toObject() {
        const data: TokenDetailsResponse.AsObject = {};
        if (this.details != null) {
            data.details = this.details.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasDetails)
            writer.writeMessage(1, this.details, () => this.details!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TokenDetailsResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TokenDetailsResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.details, () => message.details = TokenDetails.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TokenDetailsResponse {
        return TokenDetailsResponse.deserialize(bytes);
    }
}
export namespace TokenDetailsResponse {
    export type AsObject = {
        details?: TokenDetails.AsObject;
    };
    export type AsObjectPartial = {
        details?: TokenDetails.AsObjectPartial;
    };
}
export class NFTDetailsRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        address?: string;
        tokenId?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("tokenId" in data && data.tokenId != undefined) {
                this.tokenId = data.tokenId;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get tokenId() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set tokenId(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data?: NFTDetailsRequest.AsObjectPartial): NFTDetailsRequest {
        if (!data) {
            return new NFTDetailsRequest();
        }
        const message = new NFTDetailsRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.tokenId != null) {
            message.tokenId = data.tokenId;
        }
        return message;
    }
    toObject() {
        const data: NFTDetailsRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address,
            tokenId: this.tokenId
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (this.tokenId.length)
            writer.writeString(3, this.tokenId);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NFTDetailsRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NFTDetailsRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                case 3:
                    message.tokenId = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): NFTDetailsRequest {
        return NFTDetailsRequest.deserialize(bytes);
    }
}
export namespace NFTDetailsRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
        tokenId: string;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
        tokenId?: string;
    };
}
export class NFTDetailsResponse extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2]];
    constructor(data?: any[] | ({} & (({
        erc721?: dependency_3.ERC721NFT;
        erc1155?: never;
    } | {
        erc721?: never;
        erc1155?: dependency_4.ERC1155NFTList;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("erc721" in data && data.erc721 != undefined) {
                this.erc721 = data.erc721;
            }
            if ("erc1155" in data && data.erc1155 != undefined) {
                this.erc1155 = data.erc1155;
            }
        }
    }
    get erc721() {
        return pb_1.Message.getWrapperField(this, dependency_3.ERC721NFT, 1) as dependency_3.ERC721NFT | undefined;
    }
    set erc721(value: dependency_3.ERC721NFT | undefined) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0]!, value);
    }
    get hasErc721() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearErc721(): void {
        this.erc721 = undefined!;
    }
    get erc1155() {
        return pb_1.Message.getWrapperField(this, dependency_4.ERC1155NFTList, 2) as dependency_4.ERC1155NFTList | undefined;
    }
    set erc1155(value: dependency_4.ERC1155NFTList | undefined) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasErc1155() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearErc1155(): void {
        this.erc1155 = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "erc721" | "erc1155";
        } = {
            0: "none",
            1: "erc721",
            2: "erc1155"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2])]!;
    }
    static fromObject(data?: NFTDetailsResponse.AsObjectPartial): NFTDetailsResponse {
        if (!data) {
            return new NFTDetailsResponse();
        }
        const message = new NFTDetailsResponse({});
        if (data.erc721 != null) {
            message.erc721 = dependency_3.ERC721NFT.fromObject(data.erc721);
        }
        if (data.erc1155 != null) {
            message.erc1155 = dependency_4.ERC1155NFTList.fromObject(data.erc1155);
        }
        return message;
    }
    toObject() {
        const data: NFTDetailsResponse.AsObject = {};
        if (this.erc721 != null) {
            data.erc721 = this.erc721.toObject();
        }
        if (this.erc1155 != null) {
            data.erc1155 = this.erc1155.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasErc721)
            writer.writeMessage(1, this.erc721, () => this.erc721!.serialize(writer));
        if (this.hasErc1155)
            writer.writeMessage(2, this.erc1155, () => this.erc1155!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NFTDetailsResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NFTDetailsResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.erc721, () => message.erc721 = dependency_3.ERC721NFT.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.erc1155, () => message.erc1155 = dependency_4.ERC1155NFTList.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): NFTDetailsResponse {
        return NFTDetailsResponse.deserialize(bytes);
    }
}
export namespace NFTDetailsResponse {
    export type AsObject = {
        erc721?: dependency_3.ERC721NFT.AsObject;
        erc1155?: dependency_4.ERC1155NFTList.AsObject;
    };
    export type AsObjectPartial = {
        erc721?: dependency_3.ERC721NFT.AsObjectPartial;
        erc1155?: dependency_4.ERC1155NFTList.AsObjectPartial;
    };
}
export class TransferListRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[2, 5, 3, 6, 7, 8]];
    constructor(data?: any[] | ({
        blockchainIndex?: number;
        page?: dependency_5.Page;
    } & (({
        address?: string;
        account?: never;
        all?: never;
        singleToken?: never;
        addressAccount?: never;
        singleTokenAccount?: never;
    } | {
        address?: never;
        account?: string;
        all?: never;
        singleToken?: never;
        addressAccount?: never;
        singleTokenAccount?: never;
    } | {
        address?: never;
        account?: never;
        all?: boolean;
        singleToken?: never;
        addressAccount?: never;
        singleTokenAccount?: never;
    } | {
        address?: never;
        account?: never;
        all?: never;
        singleToken?: TransferListRequest.SingleToken;
        addressAccount?: never;
        singleTokenAccount?: never;
    } | {
        address?: never;
        account?: never;
        all?: never;
        singleToken?: never;
        addressAccount?: TransferListRequest.AddressAccount;
        singleTokenAccount?: never;
    } | {
        address?: never;
        account?: never;
        all?: never;
        singleToken?: never;
        addressAccount?: never;
        singleTokenAccount?: TransferListRequest.SingleTokenAccount;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("account" in data && data.account != undefined) {
                this.account = data.account;
            }
            if ("all" in data && data.all != undefined) {
                this.all = data.all;
            }
            if ("singleToken" in data && data.singleToken != undefined) {
                this.singleToken = data.singleToken;
            }
            if ("addressAccount" in data && data.addressAccount != undefined) {
                this.addressAccount = data.addressAccount;
            }
            if ("singleTokenAccount" in data && data.singleTokenAccount != undefined) {
                this.singleTokenAccount = data.singleTokenAccount;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasAddress() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearAddress(): void {
        this.address = undefined!;
    }
    get account() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set account(value: string) {
        pb_1.Message.setOneofField(this, 5, this.#one_of_decls[0]!, value);
    }
    get hasAccount() {
        return pb_1.Message.getField(this, 5) != null;
    }
    clearAccount(): void {
        this.account = undefined!;
    }
    get all() {
        return pb_1.Message.getFieldWithDefault(this, 3, false) as boolean;
    }
    set all(value: boolean) {
        pb_1.Message.setOneofField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasAll() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearAll(): void {
        this.all = undefined!;
    }
    get singleToken() {
        return pb_1.Message.getWrapperField(this, TransferListRequest.SingleToken, 6) as TransferListRequest.SingleToken | undefined;
    }
    set singleToken(value: TransferListRequest.SingleToken | undefined) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0]!, value);
    }
    get hasSingleToken() {
        return pb_1.Message.getField(this, 6) != null;
    }
    clearSingleToken(): void {
        this.singleToken = undefined!;
    }
    get addressAccount() {
        return pb_1.Message.getWrapperField(this, TransferListRequest.AddressAccount, 7) as TransferListRequest.AddressAccount | undefined;
    }
    set addressAccount(value: TransferListRequest.AddressAccount | undefined) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0]!, value);
    }
    get hasAddressAccount() {
        return pb_1.Message.getField(this, 7) != null;
    }
    clearAddressAccount(): void {
        this.addressAccount = undefined!;
    }
    get singleTokenAccount() {
        return pb_1.Message.getWrapperField(this, TransferListRequest.SingleTokenAccount, 8) as TransferListRequest.SingleTokenAccount | undefined;
    }
    set singleTokenAccount(value: TransferListRequest.SingleTokenAccount | undefined) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0]!, value);
    }
    get hasSingleTokenAccount() {
        return pb_1.Message.getField(this, 8) != null;
    }
    clearSingleTokenAccount(): void {
        this.singleTokenAccount = undefined!;
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_5.Page, 4) as dependency_5.Page | undefined;
    }
    set page(value: dependency_5.Page | undefined) {
        pb_1.Message.setWrapperField(this, 4, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "address" | "account" | "all" | "singleToken" | "addressAccount" | "singleTokenAccount";
        } = {
            0: "none",
            2: "address",
            5: "account",
            3: "all",
            6: "singleToken",
            7: "addressAccount",
            8: "singleTokenAccount"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2, 5, 3, 6, 7, 8])]!;
    }
    static fromObject(data?: TransferListRequest.AsObjectPartial): TransferListRequest {
        if (!data) {
            return new TransferListRequest();
        }
        const message = new TransferListRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.account != null) {
            message.account = data.account;
        }
        if (data.all != null) {
            message.all = data.all;
        }
        if (data.singleToken != null) {
            message.singleToken = TransferListRequest.SingleToken.fromObject(data.singleToken);
        }
        if (data.addressAccount != null) {
            message.addressAccount = TransferListRequest.AddressAccount.fromObject(data.addressAccount);
        }
        if (data.singleTokenAccount != null) {
            message.singleTokenAccount = TransferListRequest.SingleTokenAccount.fromObject(data.singleTokenAccount);
        }
        if (data.page != null) {
            message.page = dependency_5.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: TransferListRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address,
            account: this.account,
            all: this.all
        };
        if (this.singleToken != null) {
            data.singleToken = this.singleToken.toObject();
        }
        if (this.addressAccount != null) {
            data.addressAccount = this.addressAccount.toObject();
        }
        if (this.singleTokenAccount != null) {
            data.singleTokenAccount = this.singleTokenAccount.toObject();
        }
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.hasAddress)
            writer.writeString(2, this.address);
        if (this.hasAccount)
            writer.writeString(5, this.account);
        if (this.hasAll)
            writer.writeBool(3, this.all);
        if (this.hasSingleToken)
            writer.writeMessage(6, this.singleToken, () => this.singleToken!.serialize(writer));
        if (this.hasAddressAccount)
            writer.writeMessage(7, this.addressAccount, () => this.addressAccount!.serialize(writer));
        if (this.hasSingleTokenAccount)
            writer.writeMessage(8, this.singleTokenAccount, () => this.singleTokenAccount!.serialize(writer));
        if (this.hasPage)
            writer.writeMessage(4, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransferListRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransferListRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                case 5:
                    message.account = reader.readString();
                    break;
                case 3:
                    message.all = reader.readBool();
                    break;
                case 6:
                    reader.readMessage(message.singleToken, () => message.singleToken = TransferListRequest.SingleToken.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.addressAccount, () => message.addressAccount = TransferListRequest.AddressAccount.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.singleTokenAccount, () => message.singleTokenAccount = TransferListRequest.SingleTokenAccount.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.page, () => message.page = dependency_5.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TransferListRequest {
        return TransferListRequest.deserialize(bytes);
    }
}
export namespace TransferListRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
        account: string;
        all: boolean;
        singleToken?: TransferListRequest.SingleToken.AsObject;
        addressAccount?: TransferListRequest.AddressAccount.AsObject;
        singleTokenAccount?: TransferListRequest.SingleTokenAccount.AsObject;
        page?: dependency_5.Page.AsObject;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
        account?: string;
        all?: boolean;
        singleToken?: TransferListRequest.SingleToken.AsObjectPartial;
        addressAccount?: TransferListRequest.AddressAccount.AsObjectPartial;
        singleTokenAccount?: TransferListRequest.SingleTokenAccount.AsObjectPartial;
        page?: dependency_5.Page.AsObjectPartial;
    };
    export class SingleToken extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            address?: string;
            tokenId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("address" in data && data.address != undefined) {
                    this.address = data.address;
                }
                if ("tokenId" in data && data.tokenId != undefined) {
                    this.tokenId = data.tokenId;
                }
            }
        }
        get address() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set address(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get tokenId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set tokenId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data?: SingleToken.AsObjectPartial): SingleToken {
            if (!data) {
                return new SingleToken();
            }
            const message = new SingleToken({});
            if (data.address != null) {
                message.address = data.address;
            }
            if (data.tokenId != null) {
                message.tokenId = data.tokenId;
            }
            return message;
        }
        toObject() {
            const data: SingleToken.AsObject = {
                address: this.address,
                tokenId: this.tokenId
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.address.length)
                writer.writeString(1, this.address);
            if (this.tokenId.length)
                writer.writeString(2, this.tokenId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SingleToken {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SingleToken();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.address = reader.readString();
                        break;
                    case 2:
                        message.tokenId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SingleToken {
            return SingleToken.deserialize(bytes);
        }
    }
    export namespace SingleToken {
        export type AsObject = {
            address: string;
            tokenId: string;
        };
        export type AsObjectPartial = {
            address?: string;
            tokenId?: string;
        };
    }
    export class AddressAccount extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            address?: string;
            account?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("address" in data && data.address != undefined) {
                    this.address = data.address;
                }
                if ("account" in data && data.account != undefined) {
                    this.account = data.account;
                }
            }
        }
        get address() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set address(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get account() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set account(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data?: AddressAccount.AsObjectPartial): AddressAccount {
            if (!data) {
                return new AddressAccount();
            }
            const message = new AddressAccount({});
            if (data.address != null) {
                message.address = data.address;
            }
            if (data.account != null) {
                message.account = data.account;
            }
            return message;
        }
        toObject() {
            const data: AddressAccount.AsObject = {
                address: this.address,
                account: this.account
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.address.length)
                writer.writeString(1, this.address);
            if (this.account.length)
                writer.writeString(2, this.account);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AddressAccount {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AddressAccount();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.address = reader.readString();
                        break;
                    case 2:
                        message.account = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): AddressAccount {
            return AddressAccount.deserialize(bytes);
        }
    }
    export namespace AddressAccount {
        export type AsObject = {
            address: string;
            account: string;
        };
        export type AsObjectPartial = {
            address?: string;
            account?: string;
        };
    }
    export class SingleTokenAccount extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            address?: string;
            tokenId?: string;
            account?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("address" in data && data.address != undefined) {
                    this.address = data.address;
                }
                if ("tokenId" in data && data.tokenId != undefined) {
                    this.tokenId = data.tokenId;
                }
                if ("account" in data && data.account != undefined) {
                    this.account = data.account;
                }
            }
        }
        get address() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set address(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get tokenId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set tokenId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get account() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set account(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data?: SingleTokenAccount.AsObjectPartial): SingleTokenAccount {
            if (!data) {
                return new SingleTokenAccount();
            }
            const message = new SingleTokenAccount({});
            if (data.address != null) {
                message.address = data.address;
            }
            if (data.tokenId != null) {
                message.tokenId = data.tokenId;
            }
            if (data.account != null) {
                message.account = data.account;
            }
            return message;
        }
        toObject() {
            const data: SingleTokenAccount.AsObject = {
                address: this.address,
                tokenId: this.tokenId,
                account: this.account
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.address.length)
                writer.writeString(1, this.address);
            if (this.tokenId.length)
                writer.writeString(2, this.tokenId);
            if (this.account.length)
                writer.writeString(3, this.account);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SingleTokenAccount {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SingleTokenAccount();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.address = reader.readString();
                        break;
                    case 2:
                        message.tokenId = reader.readString();
                        break;
                    case 3:
                        message.account = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SingleTokenAccount {
            return SingleTokenAccount.deserialize(bytes);
        }
    }
    export namespace SingleTokenAccount {
        export type AsObject = {
            address: string;
            tokenId: string;
            account: string;
        };
        export type AsObjectPartial = {
            address?: string;
            tokenId?: string;
            account?: string;
        };
    }
}
export class TransferListResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        transfers?: Transfer[];
        navigation?: dependency_5.NavigationData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("transfers" in data && data.transfers != undefined) {
                this.transfers = data.transfers;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
        }
    }
    get transfers() {
        return pb_1.Message.getRepeatedWrapperField(this, Transfer, 1) as Transfer[];
    }
    set transfers(value: Transfer[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_5.NavigationData, 2) as dependency_5.NavigationData | undefined;
    }
    set navigation(value: dependency_5.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    static fromObject(data?: TransferListResponse.AsObjectPartial): TransferListResponse {
        if (!data) {
            return new TransferListResponse();
        }
        const message = new TransferListResponse({});
        if (data.transfers != null) {
            message.transfers = data.transfers.map(item => Transfer.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_5.NavigationData.fromObject(data.navigation);
        }
        return message;
    }
    toObject() {
        const data: TransferListResponse.AsObject = {
            transfers: this.transfers.map((item: Transfer) => item.toObject())
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.transfers.length)
            writer.writeRepeatedMessage(1, this.transfers, (item: Transfer) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(2, this.navigation, () => this.navigation!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransferListResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransferListResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.transfers, () => pb_1.Message.addToRepeatedWrapperField(message, 1, Transfer.deserialize(reader), Transfer));
                    break;
                case 2:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_5.NavigationData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TransferListResponse {
        return TransferListResponse.deserialize(bytes);
    }
}
export namespace TransferListResponse {
    export type AsObject = {
        transfers: Transfer.AsObject[];
        navigation?: dependency_5.NavigationData.AsObject;
    };
    export type AsObjectPartial = {
        transfers?: Transfer.AsObjectPartial[];
        navigation?: dependency_5.NavigationData.AsObjectPartial;
    };
}
export class HolderListRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[4, 5, 6, 7]];
    constructor(data?: any[] | ({
        blockchainIndex?: number;
        address?: string;
        page?: dependency_5.Page;
    } & (({
        all?: boolean;
        account?: never;
        singleToken?: never;
        singleTokenAccount?: never;
    } | {
        all?: never;
        account?: string;
        singleToken?: never;
        singleTokenAccount?: never;
    } | {
        all?: never;
        account?: never;
        singleToken?: string;
        singleTokenAccount?: never;
    } | {
        all?: never;
        account?: never;
        singleToken?: never;
        singleTokenAccount?: HolderListRequest.SingleTokenAccount;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("all" in data && data.all != undefined) {
                this.all = data.all;
            }
            if ("account" in data && data.account != undefined) {
                this.account = data.account;
            }
            if ("singleToken" in data && data.singleToken != undefined) {
                this.singleToken = data.singleToken;
            }
            if ("singleTokenAccount" in data && data.singleTokenAccount != undefined) {
                this.singleTokenAccount = data.singleTokenAccount;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get all() {
        return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
    }
    set all(value: boolean) {
        pb_1.Message.setOneofField(this, 4, this.#one_of_decls[0]!, value);
    }
    get hasAll() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearAll(): void {
        this.all = undefined!;
    }
    get account() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set account(value: string) {
        pb_1.Message.setOneofField(this, 5, this.#one_of_decls[0]!, value);
    }
    get hasAccount() {
        return pb_1.Message.getField(this, 5) != null;
    }
    clearAccount(): void {
        this.account = undefined!;
    }
    get singleToken() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set singleToken(value: string) {
        pb_1.Message.setOneofField(this, 6, this.#one_of_decls[0]!, value);
    }
    get hasSingleToken() {
        return pb_1.Message.getField(this, 6) != null;
    }
    clearSingleToken(): void {
        this.singleToken = undefined!;
    }
    get singleTokenAccount() {
        return pb_1.Message.getWrapperField(this, HolderListRequest.SingleTokenAccount, 7) as HolderListRequest.SingleTokenAccount | undefined;
    }
    set singleTokenAccount(value: HolderListRequest.SingleTokenAccount | undefined) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0]!, value);
    }
    get hasSingleTokenAccount() {
        return pb_1.Message.getField(this, 7) != null;
    }
    clearSingleTokenAccount(): void {
        this.singleTokenAccount = undefined!;
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_5.Page, 3) as dependency_5.Page | undefined;
    }
    set page(value: dependency_5.Page | undefined) {
        pb_1.Message.setWrapperField(this, 3, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "all" | "account" | "singleToken" | "singleTokenAccount";
        } = {
            0: "none",
            4: "all",
            5: "account",
            6: "singleToken",
            7: "singleTokenAccount"
        };
        return cases[pb_1.Message.computeOneofCase(this, [4, 5, 6, 7])]!;
    }
    static fromObject(data?: HolderListRequest.AsObjectPartial): HolderListRequest {
        if (!data) {
            return new HolderListRequest();
        }
        const message = new HolderListRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.all != null) {
            message.all = data.all;
        }
        if (data.account != null) {
            message.account = data.account;
        }
        if (data.singleToken != null) {
            message.singleToken = data.singleToken;
        }
        if (data.singleTokenAccount != null) {
            message.singleTokenAccount = HolderListRequest.SingleTokenAccount.fromObject(data.singleTokenAccount);
        }
        if (data.page != null) {
            message.page = dependency_5.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: HolderListRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address,
            all: this.all,
            account: this.account,
            singleToken: this.singleToken
        };
        if (this.singleTokenAccount != null) {
            data.singleTokenAccount = this.singleTokenAccount.toObject();
        }
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (this.hasAll)
            writer.writeBool(4, this.all);
        if (this.hasAccount)
            writer.writeString(5, this.account);
        if (this.hasSingleToken)
            writer.writeString(6, this.singleToken);
        if (this.hasSingleTokenAccount)
            writer.writeMessage(7, this.singleTokenAccount, () => this.singleTokenAccount!.serialize(writer));
        if (this.hasPage)
            writer.writeMessage(3, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): HolderListRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new HolderListRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                case 4:
                    message.all = reader.readBool();
                    break;
                case 5:
                    message.account = reader.readString();
                    break;
                case 6:
                    message.singleToken = reader.readString();
                    break;
                case 7:
                    reader.readMessage(message.singleTokenAccount, () => message.singleTokenAccount = HolderListRequest.SingleTokenAccount.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.page, () => message.page = dependency_5.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): HolderListRequest {
        return HolderListRequest.deserialize(bytes);
    }
}
export namespace HolderListRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
        all: boolean;
        account: string;
        singleToken: string;
        singleTokenAccount?: HolderListRequest.SingleTokenAccount.AsObject;
        page?: dependency_5.Page.AsObject;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
        all?: boolean;
        account?: string;
        singleToken?: string;
        singleTokenAccount?: HolderListRequest.SingleTokenAccount.AsObjectPartial;
        page?: dependency_5.Page.AsObjectPartial;
    };
    export class SingleTokenAccount extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            tokenId?: string;
            account?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("tokenId" in data && data.tokenId != undefined) {
                    this.tokenId = data.tokenId;
                }
                if ("account" in data && data.account != undefined) {
                    this.account = data.account;
                }
            }
        }
        get tokenId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set tokenId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get account() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set account(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data?: SingleTokenAccount.AsObjectPartial): SingleTokenAccount {
            if (!data) {
                return new SingleTokenAccount();
            }
            const message = new SingleTokenAccount({});
            if (data.tokenId != null) {
                message.tokenId = data.tokenId;
            }
            if (data.account != null) {
                message.account = data.account;
            }
            return message;
        }
        toObject() {
            const data: SingleTokenAccount.AsObject = {
                tokenId: this.tokenId,
                account: this.account
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.tokenId.length)
                writer.writeString(1, this.tokenId);
            if (this.account.length)
                writer.writeString(2, this.account);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SingleTokenAccount {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SingleTokenAccount();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.tokenId = reader.readString();
                        break;
                    case 2:
                        message.account = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SingleTokenAccount {
            return SingleTokenAccount.deserialize(bytes);
        }
    }
    export namespace SingleTokenAccount {
        export type AsObject = {
            tokenId: string;
            account: string;
        };
        export type AsObjectPartial = {
            tokenId?: string;
            account?: string;
        };
    }
}
export class HolderListResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        holders?: Holder[];
        navigation?: dependency_5.NavigationData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("holders" in data && data.holders != undefined) {
                this.holders = data.holders;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
        }
    }
    get holders() {
        return pb_1.Message.getRepeatedWrapperField(this, Holder, 1) as Holder[];
    }
    set holders(value: Holder[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_5.NavigationData, 2) as dependency_5.NavigationData | undefined;
    }
    set navigation(value: dependency_5.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    static fromObject(data?: HolderListResponse.AsObjectPartial): HolderListResponse {
        if (!data) {
            return new HolderListResponse();
        }
        const message = new HolderListResponse({});
        if (data.holders != null) {
            message.holders = data.holders.map(item => Holder.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_5.NavigationData.fromObject(data.navigation);
        }
        return message;
    }
    toObject() {
        const data: HolderListResponse.AsObject = {
            holders: this.holders.map((item: Holder) => item.toObject())
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.holders.length)
            writer.writeRepeatedMessage(1, this.holders, (item: Holder) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(2, this.navigation, () => this.navigation!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): HolderListResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new HolderListResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.holders, () => pb_1.Message.addToRepeatedWrapperField(message, 1, Holder.deserialize(reader), Holder));
                    break;
                case 2:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_5.NavigationData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): HolderListResponse {
        return HolderListResponse.deserialize(bytes);
    }
}
export namespace HolderListResponse {
    export type AsObject = {
        holders: Holder.AsObject[];
        navigation?: dependency_5.NavigationData.AsObject;
    };
    export type AsObjectPartial = {
        holders?: Holder.AsObjectPartial[];
        navigation?: dependency_5.NavigationData.AsObjectPartial;
    };
}
export class InventoryListRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[4, 5, 6, 7]];
    constructor(data?: any[] | ({
        blockchainIndex?: number;
        address?: string;
        page?: dependency_5.Page;
    } & (({
        all?: boolean;
        owner?: never;
        singleToken?: never;
        singleTokenAccount?: never;
    } | {
        all?: never;
        owner?: string;
        singleToken?: never;
        singleTokenAccount?: never;
    } | {
        all?: never;
        owner?: never;
        singleToken?: string;
        singleTokenAccount?: never;
    } | {
        all?: never;
        owner?: never;
        singleToken?: never;
        singleTokenAccount?: InventoryListRequest.SingleTokenAccount;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("all" in data && data.all != undefined) {
                this.all = data.all;
            }
            if ("owner" in data && data.owner != undefined) {
                this.owner = data.owner;
            }
            if ("singleToken" in data && data.singleToken != undefined) {
                this.singleToken = data.singleToken;
            }
            if ("singleTokenAccount" in data && data.singleTokenAccount != undefined) {
                this.singleTokenAccount = data.singleTokenAccount;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get all() {
        return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
    }
    set all(value: boolean) {
        pb_1.Message.setOneofField(this, 4, this.#one_of_decls[0]!, value);
    }
    get hasAll() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearAll(): void {
        this.all = undefined!;
    }
    get owner() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set owner(value: string) {
        pb_1.Message.setOneofField(this, 5, this.#one_of_decls[0]!, value);
    }
    get hasOwner() {
        return pb_1.Message.getField(this, 5) != null;
    }
    clearOwner(): void {
        this.owner = undefined!;
    }
    get singleToken() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set singleToken(value: string) {
        pb_1.Message.setOneofField(this, 6, this.#one_of_decls[0]!, value);
    }
    get hasSingleToken() {
        return pb_1.Message.getField(this, 6) != null;
    }
    clearSingleToken(): void {
        this.singleToken = undefined!;
    }
    get singleTokenAccount() {
        return pb_1.Message.getWrapperField(this, InventoryListRequest.SingleTokenAccount, 7) as InventoryListRequest.SingleTokenAccount | undefined;
    }
    set singleTokenAccount(value: InventoryListRequest.SingleTokenAccount | undefined) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0]!, value);
    }
    get hasSingleTokenAccount() {
        return pb_1.Message.getField(this, 7) != null;
    }
    clearSingleTokenAccount(): void {
        this.singleTokenAccount = undefined!;
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_5.Page, 3) as dependency_5.Page | undefined;
    }
    set page(value: dependency_5.Page | undefined) {
        pb_1.Message.setWrapperField(this, 3, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "all" | "owner" | "singleToken" | "singleTokenAccount";
        } = {
            0: "none",
            4: "all",
            5: "owner",
            6: "singleToken",
            7: "singleTokenAccount"
        };
        return cases[pb_1.Message.computeOneofCase(this, [4, 5, 6, 7])]!;
    }
    static fromObject(data?: InventoryListRequest.AsObjectPartial): InventoryListRequest {
        if (!data) {
            return new InventoryListRequest();
        }
        const message = new InventoryListRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.all != null) {
            message.all = data.all;
        }
        if (data.owner != null) {
            message.owner = data.owner;
        }
        if (data.singleToken != null) {
            message.singleToken = data.singleToken;
        }
        if (data.singleTokenAccount != null) {
            message.singleTokenAccount = InventoryListRequest.SingleTokenAccount.fromObject(data.singleTokenAccount);
        }
        if (data.page != null) {
            message.page = dependency_5.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: InventoryListRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address,
            all: this.all,
            owner: this.owner,
            singleToken: this.singleToken
        };
        if (this.singleTokenAccount != null) {
            data.singleTokenAccount = this.singleTokenAccount.toObject();
        }
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (this.hasAll)
            writer.writeBool(4, this.all);
        if (this.hasOwner)
            writer.writeString(5, this.owner);
        if (this.hasSingleToken)
            writer.writeString(6, this.singleToken);
        if (this.hasSingleTokenAccount)
            writer.writeMessage(7, this.singleTokenAccount, () => this.singleTokenAccount!.serialize(writer));
        if (this.hasPage)
            writer.writeMessage(3, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InventoryListRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InventoryListRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                case 4:
                    message.all = reader.readBool();
                    break;
                case 5:
                    message.owner = reader.readString();
                    break;
                case 6:
                    message.singleToken = reader.readString();
                    break;
                case 7:
                    reader.readMessage(message.singleTokenAccount, () => message.singleTokenAccount = InventoryListRequest.SingleTokenAccount.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.page, () => message.page = dependency_5.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): InventoryListRequest {
        return InventoryListRequest.deserialize(bytes);
    }
}
export namespace InventoryListRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
        all: boolean;
        owner: string;
        singleToken: string;
        singleTokenAccount?: InventoryListRequest.SingleTokenAccount.AsObject;
        page?: dependency_5.Page.AsObject;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
        all?: boolean;
        owner?: string;
        singleToken?: string;
        singleTokenAccount?: InventoryListRequest.SingleTokenAccount.AsObjectPartial;
        page?: dependency_5.Page.AsObjectPartial;
    };
    export class SingleTokenAccount extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            tokenId?: string;
            account?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("tokenId" in data && data.tokenId != undefined) {
                    this.tokenId = data.tokenId;
                }
                if ("account" in data && data.account != undefined) {
                    this.account = data.account;
                }
            }
        }
        get tokenId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set tokenId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get account() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set account(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data?: SingleTokenAccount.AsObjectPartial): SingleTokenAccount {
            if (!data) {
                return new SingleTokenAccount();
            }
            const message = new SingleTokenAccount({});
            if (data.tokenId != null) {
                message.tokenId = data.tokenId;
            }
            if (data.account != null) {
                message.account = data.account;
            }
            return message;
        }
        toObject() {
            const data: SingleTokenAccount.AsObject = {
                tokenId: this.tokenId,
                account: this.account
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.tokenId.length)
                writer.writeString(1, this.tokenId);
            if (this.account.length)
                writer.writeString(2, this.account);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SingleTokenAccount {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SingleTokenAccount();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.tokenId = reader.readString();
                        break;
                    case 2:
                        message.account = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SingleTokenAccount {
            return SingleTokenAccount.deserialize(bytes);
        }
    }
    export namespace SingleTokenAccount {
        export type AsObject = {
            tokenId: string;
            account: string;
        };
        export type AsObjectPartial = {
            tokenId?: string;
            account?: string;
        };
    }
}
export class InventoryListResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        nfts?: NFT[];
        navigation?: dependency_5.NavigationData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("nfts" in data && data.nfts != undefined) {
                this.nfts = data.nfts;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
        }
    }
    get nfts() {
        return pb_1.Message.getRepeatedWrapperField(this, NFT, 1) as NFT[];
    }
    set nfts(value: NFT[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_5.NavigationData, 2) as dependency_5.NavigationData | undefined;
    }
    set navigation(value: dependency_5.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    static fromObject(data?: InventoryListResponse.AsObjectPartial): InventoryListResponse {
        if (!data) {
            return new InventoryListResponse();
        }
        const message = new InventoryListResponse({});
        if (data.nfts != null) {
            message.nfts = data.nfts.map(item => NFT.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_5.NavigationData.fromObject(data.navigation);
        }
        return message;
    }
    toObject() {
        const data: InventoryListResponse.AsObject = {
            nfts: this.nfts.map((item: NFT) => item.toObject())
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.nfts.length)
            writer.writeRepeatedMessage(1, this.nfts, (item: NFT) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(2, this.navigation, () => this.navigation!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InventoryListResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InventoryListResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.nfts, () => pb_1.Message.addToRepeatedWrapperField(message, 1, NFT.deserialize(reader), NFT));
                    break;
                case 2:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_5.NavigationData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): InventoryListResponse {
        return InventoryListResponse.deserialize(bytes);
    }
}
export namespace InventoryListResponse {
    export type AsObject = {
        nfts: NFT.AsObject[];
        navigation?: dependency_5.NavigationData.AsObject;
    };
    export type AsObjectPartial = {
        nfts?: NFT.AsObjectPartial[];
        navigation?: dependency_5.NavigationData.AsObjectPartial;
    };
}
export class ERCBalancesRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        address?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: ERCBalancesRequest.AsObjectPartial): ERCBalancesRequest {
        if (!data) {
            return new ERCBalancesRequest();
        }
        const message = new ERCBalancesRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        return message;
    }
    toObject() {
        const data: ERCBalancesRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERCBalancesRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERCBalancesRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERCBalancesRequest {
        return ERCBalancesRequest.deserialize(bytes);
    }
}
export namespace ERCBalancesRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
    };
}
export class ERCBalancesResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        erc20Balances?: dependency_2.ERC20Balance[];
        erc721Balances?: dependency_3.ERC721Balance[];
        erc1155Balances?: dependency_4.ERC1155Balance[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1, 2, 3], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("erc20Balances" in data && data.erc20Balances != undefined) {
                this.erc20Balances = data.erc20Balances;
            }
            if ("erc721Balances" in data && data.erc721Balances != undefined) {
                this.erc721Balances = data.erc721Balances;
            }
            if ("erc1155Balances" in data && data.erc1155Balances != undefined) {
                this.erc1155Balances = data.erc1155Balances;
            }
        }
    }
    get erc20Balances() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_2.ERC20Balance, 1) as dependency_2.ERC20Balance[];
    }
    set erc20Balances(value: dependency_2.ERC20Balance[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get erc721Balances() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_3.ERC721Balance, 2) as dependency_3.ERC721Balance[];
    }
    set erc721Balances(value: dependency_3.ERC721Balance[]) {
        pb_1.Message.setRepeatedWrapperField(this, 2, value);
    }
    get erc1155Balances() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_4.ERC1155Balance, 3) as dependency_4.ERC1155Balance[];
    }
    set erc1155Balances(value: dependency_4.ERC1155Balance[]) {
        pb_1.Message.setRepeatedWrapperField(this, 3, value);
    }
    static fromObject(data?: ERCBalancesResponse.AsObjectPartial): ERCBalancesResponse {
        if (!data) {
            return new ERCBalancesResponse();
        }
        const message = new ERCBalancesResponse({});
        if (data.erc20Balances != null) {
            message.erc20Balances = data.erc20Balances.map(item => dependency_2.ERC20Balance.fromObject(item));
        }
        if (data.erc721Balances != null) {
            message.erc721Balances = data.erc721Balances.map(item => dependency_3.ERC721Balance.fromObject(item));
        }
        if (data.erc1155Balances != null) {
            message.erc1155Balances = data.erc1155Balances.map(item => dependency_4.ERC1155Balance.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: ERCBalancesResponse.AsObject = {
            erc20Balances: this.erc20Balances.map((item: dependency_2.ERC20Balance) => item.toObject()),
            erc721Balances: this.erc721Balances.map((item: dependency_3.ERC721Balance) => item.toObject()),
            erc1155Balances: this.erc1155Balances.map((item: dependency_4.ERC1155Balance) => item.toObject())
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.erc20Balances.length)
            writer.writeRepeatedMessage(1, this.erc20Balances, (item: dependency_2.ERC20Balance) => item!.serialize(writer));
        if (this.erc721Balances.length)
            writer.writeRepeatedMessage(2, this.erc721Balances, (item: dependency_3.ERC721Balance) => item!.serialize(writer));
        if (this.erc1155Balances.length)
            writer.writeRepeatedMessage(3, this.erc1155Balances, (item: dependency_4.ERC1155Balance) => item!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERCBalancesResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERCBalancesResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.erc20Balances, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_2.ERC20Balance.deserialize(reader), dependency_2.ERC20Balance));
                    break;
                case 2:
                    reader.readMessage(message.erc721Balances, () => pb_1.Message.addToRepeatedWrapperField(message, 2, dependency_3.ERC721Balance.deserialize(reader), dependency_3.ERC721Balance));
                    break;
                case 3:
                    reader.readMessage(message.erc1155Balances, () => pb_1.Message.addToRepeatedWrapperField(message, 3, dependency_4.ERC1155Balance.deserialize(reader), dependency_4.ERC1155Balance));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ERCBalancesResponse {
        return ERCBalancesResponse.deserialize(bytes);
    }
}
export namespace ERCBalancesResponse {
    export type AsObject = {
        erc20Balances: dependency_2.ERC20Balance.AsObject[];
        erc721Balances: dependency_3.ERC721Balance.AsObject[];
        erc1155Balances: dependency_4.ERC1155Balance.AsObject[];
    };
    export type AsObjectPartial = {
        erc20Balances?: dependency_2.ERC20Balance.AsObjectPartial[];
        erc721Balances?: dependency_3.ERC721Balance.AsObjectPartial[];
        erc1155Balances?: dependency_4.ERC1155Balance.AsObjectPartial[];
    };
}
export class NativeBalancesRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2]];
    constructor(data?: any[] | ({
        blockchainIndex?: number;
        page?: dependency_5.Page;
    } & (({
        address?: string;
        all?: never;
    } | {
        address?: never;
        all?: boolean;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("all" in data && data.all != undefined) {
                this.all = data.all;
            }
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0]!, value);
    }
    get hasAddress() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearAddress(): void {
        this.address = undefined!;
    }
    get all() {
        return pb_1.Message.getFieldWithDefault(this, 2, false) as boolean;
    }
    set all(value: boolean) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasAll() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearAll(): void {
        this.all = undefined!;
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_5.Page, 4) as dependency_5.Page | undefined;
    }
    set page(value: dependency_5.Page | undefined) {
        pb_1.Message.setWrapperField(this, 4, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "address" | "all";
        } = {
            0: "none",
            1: "address",
            2: "all"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2])]!;
    }
    static fromObject(data?: NativeBalancesRequest.AsObjectPartial): NativeBalancesRequest {
        if (!data) {
            return new NativeBalancesRequest();
        }
        const message = new NativeBalancesRequest({});
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.all != null) {
            message.all = data.all;
        }
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.page != null) {
            message.page = dependency_5.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: NativeBalancesRequest.AsObject = {
            address: this.address,
            all: this.all,
            blockchainIndex: this.blockchainIndex
        };
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasAddress)
            writer.writeString(1, this.address);
        if (this.hasAll)
            writer.writeBool(2, this.all);
        if (this.blockchainIndex != 0)
            writer.writeInt64(3, this.blockchainIndex);
        if (this.hasPage)
            writer.writeMessage(4, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NativeBalancesRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NativeBalancesRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.address = reader.readString();
                    break;
                case 2:
                    message.all = reader.readBool();
                    break;
                case 3:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 4:
                    reader.readMessage(message.page, () => message.page = dependency_5.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): NativeBalancesRequest {
        return NativeBalancesRequest.deserialize(bytes);
    }
}
export namespace NativeBalancesRequest {
    export type AsObject = {
        address: string;
        all: boolean;
        blockchainIndex: number;
        page?: dependency_5.Page.AsObject;
    };
    export type AsObjectPartial = {
        address?: string;
        all?: boolean;
        blockchainIndex?: number;
        page?: dependency_5.Page.AsObjectPartial;
    };
}
export class NativeBalancesResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        balances?: NativeBalancesResponse.NativeBalance[];
        navigation?: dependency_5.NavigationData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("balances" in data && data.balances != undefined) {
                this.balances = data.balances;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
        }
    }
    get balances() {
        return pb_1.Message.getRepeatedWrapperField(this, NativeBalancesResponse.NativeBalance, 1) as NativeBalancesResponse.NativeBalance[];
    }
    set balances(value: NativeBalancesResponse.NativeBalance[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_5.NavigationData, 2) as dependency_5.NavigationData | undefined;
    }
    set navigation(value: dependency_5.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    static fromObject(data?: NativeBalancesResponse.AsObjectPartial): NativeBalancesResponse {
        if (!data) {
            return new NativeBalancesResponse();
        }
        const message = new NativeBalancesResponse({});
        if (data.balances != null) {
            message.balances = data.balances.map(item => NativeBalancesResponse.NativeBalance.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_5.NavigationData.fromObject(data.navigation);
        }
        return message;
    }
    toObject() {
        const data: NativeBalancesResponse.AsObject = {
            balances: this.balances.map((item: NativeBalancesResponse.NativeBalance) => item.toObject())
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.balances.length)
            writer.writeRepeatedMessage(1, this.balances, (item: NativeBalancesResponse.NativeBalance) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(2, this.navigation, () => this.navigation!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NativeBalancesResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NativeBalancesResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.balances, () => pb_1.Message.addToRepeatedWrapperField(message, 1, NativeBalancesResponse.NativeBalance.deserialize(reader), NativeBalancesResponse.NativeBalance));
                    break;
                case 2:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_5.NavigationData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): NativeBalancesResponse {
        return NativeBalancesResponse.deserialize(bytes);
    }
}
export namespace NativeBalancesResponse {
    export type AsObject = {
        balances: NativeBalancesResponse.NativeBalance.AsObject[];
        navigation?: dependency_5.NavigationData.AsObject;
    };
    export type AsObjectPartial = {
        balances?: NativeBalancesResponse.NativeBalance.AsObjectPartial[];
        navigation?: dependency_5.NavigationData.AsObjectPartial;
    };
    export class NativeBalance extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            blockchainIndex?: number;
            address?: string;
            balance?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                    this.blockchainIndex = data.blockchainIndex;
                }
                if ("address" in data && data.address != undefined) {
                    this.address = data.address;
                }
                if ("balance" in data && data.balance != undefined) {
                    this.balance = data.balance;
                }
            }
        }
        get blockchainIndex() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set blockchainIndex(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get address() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set address(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get balance() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set balance(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data?: NativeBalance.AsObjectPartial): NativeBalance {
            if (!data) {
                return new NativeBalance();
            }
            const message = new NativeBalance({});
            if (data.blockchainIndex != null) {
                message.blockchainIndex = data.blockchainIndex;
            }
            if (data.address != null) {
                message.address = data.address;
            }
            if (data.balance != null) {
                message.balance = data.balance;
            }
            return message;
        }
        toObject() {
            const data: NativeBalance.AsObject = {
                blockchainIndex: this.blockchainIndex,
                address: this.address,
                balance: this.balance
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.blockchainIndex != 0)
                writer.writeInt64(1, this.blockchainIndex);
            if (this.address.length)
                writer.writeString(2, this.address);
            if (this.balance.length)
                writer.writeString(3, this.balance);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NativeBalance {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NativeBalance();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.blockchainIndex = reader.readInt64();
                        break;
                    case 2:
                        message.address = reader.readString();
                        break;
                    case 3:
                        message.balance = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): NativeBalance {
            return NativeBalance.deserialize(bytes);
        }
    }
    export namespace NativeBalance {
        export type AsObject = {
            blockchainIndex: number;
            address: string;
            balance: string;
        };
        export type AsObjectPartial = {
            blockchainIndex?: number;
            address?: string;
            balance?: string;
        };
    }
}
export class TokenListUnfilteredRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        page?: dependency_5.Page;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_5.Page, 2) as dependency_5.Page | undefined;
    }
    set page(value: dependency_5.Page | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    static fromObject(data?: TokenListUnfilteredRequest.AsObjectPartial): TokenListUnfilteredRequest {
        if (!data) {
            return new TokenListUnfilteredRequest();
        }
        const message = new TokenListUnfilteredRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.page != null) {
            message.page = dependency_5.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: TokenListUnfilteredRequest.AsObject = {
            blockchainIndex: this.blockchainIndex
        };
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.hasPage)
            writer.writeMessage(2, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TokenListUnfilteredRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TokenListUnfilteredRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    reader.readMessage(message.page, () => message.page = dependency_5.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TokenListUnfilteredRequest {
        return TokenListUnfilteredRequest.deserialize(bytes);
    }
}
export namespace TokenListUnfilteredRequest {
    export type AsObject = {
        blockchainIndex: number;
        page?: dependency_5.Page.AsObject;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        page?: dependency_5.Page.AsObjectPartial;
    };
}
