import { MCNProvider } from "juneojs";
import { create } from "zustand";
import LocalStorageController, {
  LOCAL_STORAGE_KEYS,
} from "../controllers/LocalStorageController";
import { IconType } from "../types/Icon";
import NetworkType from "../types/Network";
import { blockchainsService } from "../utils/services/blockchain-service";

/**
 * Interface representing the state of the IconStore.
 */
interface IconState {
  erc20Icons: IconType; // ERC20 icons stored in the state
  setErc20Icons: (icons: IconType) => void; // Function to set ERC20 icons

  blockchainIcons: IconType; // Blockchain icons stored in the state
  setBlockchainIcons: (icons: IconType) => void; // Function to set Blockchain icons

  initializeIcons: (network: NetworkType, provider: MCNProvider) => void; // Function to initialize icons
}

/**
 * Zustand store for managing ERC20 and Blockchain icons.
 */
const useIconStore = create<IconState>((set) => ({
  erc20Icons:
    LocalStorageController.getItem<IconType>(LOCAL_STORAGE_KEYS.erc20Icons) ||
    {},
  setErc20Icons: (icons) => {
    LocalStorageController.setItem(LOCAL_STORAGE_KEYS.erc20Icons, icons);
    set({ erc20Icons: icons });
  },

  blockchainIcons:
    LocalStorageController.getItem<IconType>(
      LOCAL_STORAGE_KEYS.blockchainIcons,
    ) || {},
  setBlockchainIcons: (icons) => {
    LocalStorageController.setItem(LOCAL_STORAGE_KEYS.blockchainIcons, icons);
    set({ blockchainIcons: icons });
  },

  initializeIcons: async (network: NetworkType, provider: MCNProvider) => {
    try {
      await fetchAndSetIcons(
        network,
        provider,
        LOCAL_STORAGE_KEYS.erc20Icons,
        fetchErc20Icons,
        set,
      );
      await fetchAndSetIcons(
        network,
        provider,
        LOCAL_STORAGE_KEYS.blockchainIcons,
        fetchBlockchainIcons,
        set,
      );
    } catch (error) {
      console.error("Failed to initialize icons:", error);
    }
  },
}));

/**
 * Helper function to fetch and set icons.
 * @param network - The network type.
 * @param provider - The MCN provider.
 * @param storageKey - The local storage key for the icons.
 * @param fetchFunction - The function to fetch the icons.
 * @param set - The Zustand set function.
 */
const fetchAndSetIcons = async (
  network: NetworkType,
  provider: MCNProvider,
  storageKey: string,
  fetchFunction: (
    network: NetworkType,
    provider: MCNProvider,
  ) => Promise<IconType>,
  set: (state: Partial<IconState>) => void,
) => {
  const newIcons = await fetchFunction(network, provider);
  const currentIcons =
    LocalStorageController.getItem<IconType>(storageKey) || {};

  // Merge new icons with current icons
  const mergedIcons = { ...currentIcons, ...newIcons };

  if (JSON.stringify(mergedIcons) !== JSON.stringify(currentIcons)) {
    LocalStorageController.setItem(storageKey, mergedIcons);
    set({ [storageKey]: mergedIcons });
    console.log(`Fetched and set ${Object.keys(newIcons).length} icons`);
  }
};

/**
 * Fetches ERC20 icons from the network explorer.
 * @param network - The network type.
 * @param provider - The MCN provider.
 * @returns A promise that resolves to an IconType object containing ERC20 icons.
 */
export const fetchErc20Icons = async (
  network: NetworkType,
  provider: MCNProvider,
): Promise<IconType> => {
  const url = `${network.explorer}/static/erc20/`;
  const urlWithRandom = `${url}?v=${Date.now()}`; // Add a random query parameter to prevent caching

  const response = await fetch(urlWithRandom);

  if (!response.ok) {
    throw new Error(`Failed to fetch ERC20 icons from ${urlWithRandom}`);
  }

  const text = await response.text();

  // Parse the response to extract IDs and paths
  const regex = /<a href="([^"]+)">([^<]+)<\/a>/g;
  const iconMap: IconType = {};
  const addressMap: Record<string, string[]> = {};
  let match;

  // Collect all addresses and associate the index to each address
  while ((match = regex.exec(text)) !== null) {
    const filePath = match[1];
    const [index, address] = filePath.split("_");
    if (!addressMap[index]) {
      addressMap[index] = [];
    }
    addressMap[index].push(address);
  }

  const trustedSupernets = [provider.mcn.primary.id];

  // Fetch chain info for all trusted supernets
  const chainInfoPromises = trustedSupernets.map(async (supernetId) => {
    return blockchainsService.BlockchainListRequest(
      { supernetId },
      network.api,
    );
  });

  const chainInfoResponses = await Promise.all(chainInfoPromises);

  // Merge all chain info arrays into a single array
  const chainInfos = chainInfoResponses.flatMap(
    (response) => response.blockchains,
  );

  // Create the map with blockchainID_address : filePath
  chainInfos.forEach((chainInfo) => {
    const index = chainInfo.index.toString();
    const blockchainId = chainInfo.id.toLowerCase();
    if (blockchainId && addressMap[index]) {
      for (const address of addressMap[index]) {
        const addressWithoutExtension = address.split(".")[0];
        iconMap[
          `${provider.mcn.id}_${blockchainId}_${addressWithoutExtension.toLowerCase()}`
        ] = `${url}${index}_${address}`;
      }
    }
  });
  return iconMap;
};

/**
 * Fetches blockchain icons from the network explorer.
 * @param network - The network type.
 * @returns A promise that resolves to an IconType object containing blockchain icons.
 */
export const fetchBlockchainIcons = async (
  network: NetworkType,
  provider: MCNProvider,
): Promise<IconType> => {
  const url = `${network.explorer}/static/blockchains/`; // Use the explorer URL from the network
  const urlWithRandom = `${url}?v=${Date.now()}`; // Add a random query parameter to prevent caching
  const response = await fetch(urlWithRandom);
  if (!response.ok) {
    throw new Error(`Failed to fetch blockchain icons from ${urlWithRandom}`);
  }

  const text = await response.text();

  // Parse the response to extract IDs and paths
  const regex = /<a href="([^"]+)">([^<]+)<\/a>/g;
  const iconMap: IconType = {};
  let match;

  while ((match = regex.exec(text)) !== null) {
    const filePath = match[1];

    const id = filePath.split(".")[0].toLowerCase(); // Extract ID before the first period and convert to lowercase
    iconMap[`${provider.mcn.id}_${id}`] = url + filePath;
  }
  return iconMap;
};

export default useIconStore;
